import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { CardHeader } from '@mui/material';
import { Box } from '@mui/system';
import numeral from 'numeral';
import SkeletonList from '../elements/SkeletonList';
import { api } from "../api/apiBuilder";

const styles = theme => ({
  return: {
    textAlign: 'right',
    fontFamily: 'Fjalla One',
  },
  label: {
    textAlign: 'right',
  },
  flag: {
    width: "40px",
    maxHeight: "24px",
    borderRadius: "4px"
  }
});

class DetailsFundamental extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  toggleDescription() {
    this.setState({ descriptionOpen: !this.state.descriptionOpen })
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    api.get('/v1/data/fundamental/' + this.props.id, { cache: false })
      .then(response => {
        const data = response.data
        this.setState({ data, isLoading: false });
      }).catch(error => {
        this.setState({ isLoading: false })
        console.log(error);
      })
  }

  render() {
    const { data, isLoading } = this.state;
    return (
      <Card variant="outlined">
        <CardHeader sx={{ p: '16px 16px 0 16px!important' }}
          title={<Typography variant='overline'>Fundamental data</Typography>}
        />
        <CardContent>
          {isLoading ? <SkeletonList rows={5} /> :
            <Box sx={{ py: 2 }}>
              <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography variant='h6'>{numeral(data.cap).format('0,000.00a').toUpperCase()}</Typography>
                    <Typography sx={{ ml: 1 }} variant='caption'>{data.curr ? data.curr : ''}</Typography>
                  </Box>
                  <Typography variant='caption' color='textSecondary'>Market capitalization</Typography>
                </Box>
                <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6'>{data.peratio}</Typography>
                  <Typography variant='caption' color='textSecondary'>PE ratio</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6'>{data.earningsshare}</Typography>
                  <Typography variant='caption' color='textSecondary'>Earnings per share (EPS)</Typography>
                </Box>
                <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography variant='h6'>{numeral(data.ebitda).format('0,000.00a').toUpperCase()}</Typography>
                    <Typography sx={{ ml: 1 }} variant='caption'>{data.curr ? data.curr : ''}</Typography>
                  </Box>
                  <Typography variant='caption' color='textSecondary'>EBITDA</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6'>{data.dividendyield}</Typography>
                  <Typography variant='caption' color='textSecondary'>Dividend yield</Typography>
                </Box>
                <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6'>{data.beta}</Typography>
                  <Typography variant='caption' color='textSecondary'>Beta</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6'>{data.quarterlyearningsgrowthyoy}</Typography>
                  <Typography variant='caption' color='textSecondary'>Quarterly earnings growth</Typography>
                </Box>
                <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6'>{data.forwardpe}</Typography>
                  <Typography variant='caption' color='textSecondary'>Forward PE</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6'>{data.pricesalesttm}</Typography>
                  <Typography variant='caption' color='textSecondary'>Price/Sales</Typography>
                </Box>
                <Box sx={{ flex: '50%', display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h6'>{data.pricebookmrq}</Typography>
                  <Typography variant='caption' color='textSecondary'>Price/Book ratio</Typography>
                </Box>
              </Box>
            </Box>
          }
        </CardContent>
      </Card>
    );
  }
}

export default DetailsFundamental;
