import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { Avatar, CardHeader, Chip, Collapse, Divider, IconButton, Skeleton, ToggleButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { AccountBalance, AccountBalanceOutlined, ArrowDownward, ArrowUpward, Bookmark, BookmarkAdd, BookmarkBorder, Category, CheckCircleOutline, DeveloperBoard, Bolt, ExpandLess, ExpandMore, Factory, HealthAndSafety, HolidayVillage, MapsHomeWork, SatelliteAlt, Science, ShoppingCart, TipsAndUpdates } from '@mui/icons-material';
import SkeletonList2 from '../elements/SkeletonList2';
import SkeletonChart from '../elements/SkeletonChart';
import { api } from "../api/apiBuilder";

class DetailsOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null,
      descriptionOpen: false,
      bookmarked: false,

      snackOpen: false,
      snackMessage: '',
    }
  }

  toggleDescription() {
    this.setState({ descriptionOpen: !this.state.descriptionOpen })
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    api.get('/v1/data/snapshot/' + this.props.id, { cache: false })
      .then(response => {
        const data = response.data
        this.setState({ data, bookmarked: data.isFav });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.setState({ isLoading: false });
      })
  }

  toggleFav() {
  }

  render() {
    const { data, descriptionOpen, bookmarked, isLoading, snackOpen, snackMessage } = this.state;
    let logo_alt = '';
    if (data && data.sid)
      switch (data.sid) {
        case 22:
          logo_alt = <AccountBalance color="primary" fontSize="large" />
          break;
        case 7:
          logo_alt = <HealthAndSafety color="primary" fontSize="large" />
          break;
        case 15:
          logo_alt = <Factory color="primary" fontSize="large" />
          break;
        case 2:
          logo_alt = <DeveloperBoard color="primary" fontSize="large" />
          break;
        case 4:
          logo_alt = <ShoppingCart color="primary" fontSize="large" />
          break;
        case 9:
          logo_alt = <Science color="primary" fontSize="large" />
          break;
        case 19:
          logo_alt = <SatelliteAlt color="primary" fontSize="large" />
          break;
        case 14:
          logo_alt = <HolidayVillage color="primary" fontSize="large" />
          break;
        case 11:
          logo_alt = < Bolt color="primary" fontSize="large" />
          break;
        case 1:
          logo_alt = <TipsAndUpdates color="primary" fontSize="large" />
          break;
        case 21:
        case 25:
        case 10:
        case 3:
          logo_alt = <Category color="primary" fontSize="large" />
          break;
      }
    return (
      <>
        <Card variant="outlined">
          {/* <CardHeader
            title={<Typography variant="overline" component="h1">overview</Typography>}
            // action={<IconButton
            //   onClick={() => {
            //     this.toggleFav();
            //   }}
            // >
            //   <Tooltip title={bookmarked ? 'Remove from favorites' : 'Add to favorites'} arrow>
            //     {bookmarked ? <Bookmark color='primary' /> : <BookmarkBorder color='primary' />}
            //   </Tooltip>
            // </IconButton>}
            >

          </CardHeader> */}
          <CardContent>
            {data ? (
              <div>
                <Typography className='big' variant="h4" component="h2">{data.n}</Typography>
                <Typography className='big' variant="h6" component="h2">{data.t + '.' + data.mc}</Typography>
                <Collapse collapsedSize={60} in={descriptionOpen} sx={{ mt: 2, WebkitMaskImage: (!descriptionOpen ? "linear-gradient(180deg, rgba(0, 0, 0, 1) 70%, transparent)": 'none') }}>
                  <Typography gutterBottom variant="body2" component="div" sx={{fontWeight: 300}}>
                    {data.description}
                  </Typography>
                </Collapse>
                <Box sx={{ textAlign: 'center', mt: 1 }}>
                  <Tooltip title={descriptionOpen ? 'Reduce info' : 'Expand info'} arrow>
                    <Chip
                      variant='outlined'
                      avatar={descriptionOpen ? <ExpandLess /> : <ExpandMore />}
                      label={descriptionOpen ? 'Show less' : 'Show more'}
                      size='small'
                      onClick={() => this.toggleDescription()} />
                  </Tooltip>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 3 }}>
                  <Box sx={{ flex: '70%', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', margin: '8px 0' }}>
                      <Box sx={{ flex: '50%' }}>
                        <Typography variant="subtitle2" component="div">{data.mn}</Typography>
                        <Typography variant="caption" component="h2" color={'textSecondary'}>Market</Typography>
                      </Box>
                      <Box sx={{ flex: '50%' }}>
                        <Typography variant="subtitle2" component="h2">{data.isin || '-'}</Typography>
                        <Typography variant="caption" component="h2" color={'textSecondary'}>ISIN</Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', margin: '8px 0' }}>
                      <Box sx={{ flex: '50%' }}>
                        <Typography variant="subtitle2" component="h2">{data.sn}</Typography>
                        <Typography variant="caption" component="h2" color={'textSecondary'}>Sector</Typography>
                      </Box>
                      <Box sx={{ flex: '50%' }}>
                        <Typography variant="subtitle2" component="h2">{data.ind}</Typography>
                        <Typography variant="caption" component="h2" color={'textSecondary'}>Industry</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ flex: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {
                      isLoading ? <SkeletonChart /> :
                        data.logo ?
                          <img src={"https://eodhistoricaldata.com" + data.logo} style={{ maxHeight: "80px", marginRight: "8px", maxWidth: "100%" }} />
                          : logo_alt
                    }
                  </Box>
                </Box>
              </div>)
              : isLoading ? <SkeletonList2></SkeletonList2> : 'no data'
            }
          </CardContent>
        </Card>
      </>
    );
  }
}

export default DetailsOverview;
