import { withTheme } from '@emotion/react';
import { AccountBalanceOutlined, BoltOutlined, CategoryOutlined, Done, FactoryOutlined, HealthAndSafetyOutlined, IndeterminateCheckBoxOutlined, LibraryAddCheckOutlined, MemoryOutlined, MoreHorizOutlined, SatelliteAltOutlined, ScienceOutlined, ShoppingCartOutlined, TipsAndUpdatesOutlined } from '@mui/icons-material';
import { Alert, Avatar, Box, Button, Grid, IconButton, Typography } from '@mui/material';
import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function SettingsSectors(props) {
  const [selected, setSelected] = useState([]);
  const [cboxes, setCboxes] = useState([
    { v: 1, l: "Utilities", isChecked: false, description: "Essential services like water, electricity, and gas provision", icon: <TipsAndUpdatesOutlined /> },
    { v: 2, l: "Technology", isChecked: false, description: "Development and production of technology products and services", icon: <MemoryOutlined /> },
    { v: 4, l: "Consumer Cyclical", isChecked: false, description: "Goods and services sensitive to economic cycles and consumer spending trends", icon: <ShoppingCartOutlined /> },
    { v: 7, l: "Healthcare", isChecked: false, description: "Healthcare-related activities such as pharmaceuticals and biotechnology", icon: <HealthAndSafetyOutlined /> },
    { v: 9, l: "Basic Materials", isChecked: false, description: "Extraction, production, and distribution of raw materials like metals and chemicals", icon: <ScienceOutlined /> },
    { v: 10, l: "Consumer Defensive", isChecked: false, description: "Essential goods and services less affected by economic fluctuations", icon: <CategoryOutlined /> },
    { v: 11, l: "Energy", isChecked: false, description: "Exploration, production, and distribution of energy resources", icon: <MemoryOutlined /> },
    { v: 14, l: "Real Estate", isChecked: false, description: "Ownership, development, and management of real estate properties", icon: <BoltOutlined /> },
    { v: 15, l: "Industrials", isChecked: false, description: "Manufacturing and commercial services", icon: <FactoryOutlined /> },
    { v: 19, l: "Communication Services", isChecked: false, description: "Communication services like telecommunications and media", icon: <SatelliteAltOutlined /> },
    { v: 22, l: "Financial Services", isChecked: false, description: "Financial services such as banking and insurance", icon: <AccountBalanceOutlined /> },
    { v: 25, l: "Other", isChecked: false, description: "Sectors not classified under main GICS categories", icon: <MoreHorizOutlined /> }
  ]);
  const builtPtfSettings = useSelector(state => state.builtPtfSettings);

  useEffect(() => {
    let defaultSectors = [];
    let presets = JSON.parse(builtPtfSettings)
    log.debug(presets);
    if (presets && presets.sectors) {
      setSelected(presets.sectors);
      props.sendSectorsOrMarkets("sectors", presets.sectors);
      props.ready(props.step, isComplete(presets.sectors))
    } else {
      setSelected(defaultSectors);
      props.sendSectorsOrMarkets("sectors", defaultSectors);
      props.ready(props.step, isComplete(defaultSectors))
    }
  }, []);

  const handleAllChecked = (value) => {
    let newSelected = [];
    if (value) {
      cboxes.forEach((item) => {
        newSelected.push(item.v);
      });
    }
    setSelected(newSelected);
    sessionStorage.setItem("SECTORS", JSON.stringify(newSelected));
    log.debug("3 notify", props.step, isComplete(newSelected));
    props.sendSectorsOrMarkets("sectors", newSelected);
    props.ready(props.step, isComplete(newSelected))
  };

  const handleChange = (value) => {
    let newSelected = selected.includes(value) ? selected.filter(v => v !== value) : [...selected, value];
    setSelected(newSelected);
    sessionStorage.setItem("SECTORS", JSON.stringify(newSelected));
    log.debug("4 notify", props.step, isComplete(newSelected));
    props.sendSectorsOrMarkets("sectors", newSelected);
    props.ready(props.step, isComplete(newSelected))
  };

  const isComplete = (sett) => {
    let res = 1

    if (!sett || sett.length === 0) res = 0

    return res
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <Typography variant="body2" gutterBottom>
            Each sector represents a unique ecosystem of companies, opportunities, and risks. Choices made here, whether in technology, healthcare, or energy, shape the portfolio's diversification and risk exposure, influencing its resilience and potential for sustained growth.
          </Typography>
        </Box>
        <Box sx={{ mt: 2, display: "flex", flexDirection: "row", alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flex: "15%", alignItems: 'center' }}>
            <Typography variant='body2'>Sectors selection</Typography>
          </Box>
          <Box sx={{ flex: "85%" }}>
            <IconButton key={'b1'} onClick={() => handleAllChecked(true)} sx={{ mr: 1 }}><LibraryAddCheckOutlined color='primary' /></IconButton>
            <IconButton key={'b2'} onClick={() => handleAllChecked(false)} sx={{ mr: 1 }}><IndeterminateCheckBoxOutlined color='primary' /></IconButton>
          </Box>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={1}>
            {cboxes.map((item) => {
              const checked = selected && selected.includes(item.v);
              return (
                <Grid item xs={4} key={item.l} sx={{ minHeight: '100px' }}>
                  <Button
                    disableElevation
                    sx={{ p: 1.5, justifyContent: 'start', height: '100%', display: 'flex', flexDirection: 'column', borderWidth: 1, borderStyle: 'solid' }}
                    fullWidth
                    size='large'
                    variant={checked ? 'contained' : 'outlined'}
                    color={checked ? 'primary' : 'default'}
                    onClick={() => {
                      handleChange(item.v);
                    }}
                  >
                    <Box sx={{ textAlign: 'left', textTransform: 'capitalize', flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', }}>
                        <Avatar sx={{ width: 36, height: 36, bgcolor: 'transparent' }} ><Box color={checked ? props.theme.palette.secondary.main : props.theme.palette.primary.main}>
                          {checked ? <Done /> : item.icon}
                        </Box></Avatar>
                        <Typography variant='body1'>{item.l}</Typography>
                      </Box>
                      <Box>
                        <Typography variant='caption' component={'div'} sx={{ textTransform: 'initial', lineHeight: 'normal', fontWeight: 300 }}>{item.description}</Typography>
                      </Box>
                    </Box>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {props.alert &&
          <Alert severity="error" color='error'>{props.alert}</Alert>
        }
      </Box>
    </Box>
  );
}

export default withTheme(SettingsSectors);
