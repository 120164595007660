import { withTheme } from '@emotion/react';
import { Cancel, CancelOutlined, Close, HelpOutline, RestartAlt, Restore, Undo } from '@mui/icons-material';
import { Box, Button, CardActions, CardHeader, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, OutlinedInput, Skeleton, TextField, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ReactECharts from 'echarts-for-react';
import numeral from 'numeral';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../api/apiBuilder';
import { notifyInstrument } from '../../dispatcher/Actions';

const marketsInit = [
    { "id": 12, "name": "Euronext Amsterdam", "enabled": false },
    { "id": 13, "name": "Euronext Brussels", "enabled": false },
    { "id": 15, "name": "Copenhagen Exchange", "enabled": false },
    { "id": 9, "name": "Frankfurt Exchange", "enabled": false },
    { "id": 14, "name": "Helsinki Exchange", "enabled": false },
    { "id": 5, "name": "Hong Kong Exchange", "enabled": false },
    { "id": 3, "name": "Euronext Dublin", "enabled": false },
    { "id": 26, "name": "Euronext Lisbon", "enabled": false },
    { "id": 36, "name": "London Exchange", "enabled": false },
    { "id": 29, "name": "Madrid Exchange", "enabled": false },
    { "id": 1, "name": "Borsa Italiana", "enabled": false },
    { "id": 38, "name": "Nyse", "enabled": false },
    { "id": 39, "name": "Nasdaq", "enabled": false },
    { "id": 27, "name": "Oslo Stock Exchange", "enabled": false },
    { "id": 11, "name": "Euronext Paris", "enabled": false },
    { "id": 31, "name": "Stockholm Exchange", "enabled": false },
    { "id": 33, "name": "SIX Swiss Exchange", "enabled": false },
    { "id": 23, "name": "Tokyo Exchange", "enabled": false },
    { "id": 19, "name": "Vienna Exchange", "enabled": false },
    { "id": 102, "name": "Warsaw", "enabled": false },
    { "id": 7, "name": "XETRA Exchange", "enabled": false }]

const sectorsInit = [
    { id: 9, name: "Basic Materials", enabled: false },
    { id: 19, name: "Communications", enabled: false },
    { id: 4, name: "Consumer Cyclical", enabled: false },
    { id: 11, name: "Energy", enabled: false },
    { id: 7, name: "Healthcare", enabled: false },
    { id: 15, name: "Industrials", enabled: false },
    { id: 14, name: "Real Estate", enabled: false },
    { id: 2, name: "Technology", enabled: false },
    { id: 1, name: "Utilities", enabled: false },
    { id: 22, name: "Financial Services", enabled: false },
    { id: 10, name: "Consumer Defensive", enabled: false }]

const WaveGraph = (props) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [dataFull, setDataFull] = useState(null)
    const [options, setOptions] = useState(null)
    const [colors, setColors] = useState(null)
    const [helpOpen, setHelpOpen] = useState(false)
    const [menuMarketsOpen, setMenuMarketsOpen] = useState(false)
    const [markets, setMarkets] = useState(marketsInit);
    const [menuSectorsTarget, setMenuSectorsTarget] = useState(null)
    const [menuSectorsOpen, setMenuSectorsOpen] = useState(false)
    const [sectors, setSectors] = useState(sectorsInit);
    const [resettable, setResettable] = useState(false);
    const [search, setSearch] = useState('')
    const reload = useSelector(state => state.reload)
    const dispatch = useDispatch();
    const echartsRef = useRef(null);
    let zoomCount = 0
    let lastClickedItem = null
    let clickTimeout
    let clickCount = 0

    const generateDistinctColor = (index, totalColors) => {
        var hueIncrement = totalColors < 10 ? (360 / 10) : (360 / totalColors)
        var hue = hueIncrement * index
        var saturation = 65
        var lightness = 50
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    }

    useEffect(() => {
        if (data) {
            applySearch()
        }
    }, [search])

    useEffect(() => {
        fetch()
    }, []);

    useEffect(() => {
        if (data)
            plot()
    }, [props.theme])

    useEffect(() => {
        if (reload) {
            fetch()
        }
    }, [reload])

    useEffect(() => {
        if (data)
            setTimeout(() => {
                plot()
            }, 100);

    }, [data]);

    const calculateSymbolSize = (value, minValue, maxValue) => {
        const outputMin = 10;
        const outputMax = 100;

        if (maxValue === minValue) {
            return 50 // dimensione 50 con un solo nodo
        }

        const percentage = (value - minValue) / (maxValue - minValue);
        return Math.round(percentage * (outputMax - outputMin) + outputMin);
    }

    const handleReset = () => {
        setResettable(false)
    }

    const plot = () => {
        const minX = Math.min(...data.nodes.map(node => node.x));
        const minY = Math.min(...data.nodes.map(node => node.y));
        const maxX = Math.max(...data.nodes.map(node => node.x));
        const maxY = Math.max(...data.nodes.map(node => node.y));
        // Calculate the empirical zoom
        let empiricalZoom = Math.min(Math.abs(minX), Math.abs(maxX), Math.abs(minY), Math.abs(maxY));
        // Adapt it based on value
        empiricalZoom = empiricalZoom > 0.2 ? empiricalZoom : empiricalZoom/4;
        const zoom = empiricalZoom

        const options = {
            color: colors,
            tooltip: {
                formatter: function (params) {
                    if (params.dataType === 'edge') {
                        var theme = props.theme.palette.text;
                        const sourceNodeId = params.data.source;
                        const targetNodeId = params.data.target;
                        const edgeValue = params.data.th;

                        const sourceNode = data.nodes.find(node => node.id === sourceNodeId);
                        const targetNode = data.nodes.find(node => node.id === targetNodeId);


                        return `<span style='color:${theme.primary}'>${sourceNode.d} / ${targetNode.d}</span> <br/> <span style='color:${theme.secondary}'> ${edgeValue} </span>`;
                    } else {
                        var currentNode = data.nodes[params.dataIndex];
                        var colorSquare = "<div style='margin-right:8px;width:7px;height:7px;background:" + params.color + ";'></div>";
                        var theme = props.theme.palette.text;

                        return (
                            "<div style='display:flex;align-items:center;'>" +
                            colorSquare +
                            "<div style='color:" + theme.primary + "'>" + currentNode.d.toUpperCase() + "</div>" +
                            "</div><br/>" +
                            "<span style='color:" + theme.secondary + "'>Ticker</span>: " +
                            "<span style='color:" + theme.primary + "'>" + currentNode.name + "</span><br/>" +
                            "<span style='color:" + theme.secondary + "'>Market capitalization</span>: " +
                            "<span style='color:" + theme.primary + "'>€ " + numeral(currentNode.value).format('0,000.00a') + "<br/>" +
                            "<span style='color:" + theme.secondary + "'>Market</span>: " +
                            "<span style='color:" + theme.primary + "'>" + currentNode.mn + "</span>" + "<br/>" +
                            "<span style='color:" + theme.secondary + "'>Sector</span>: " +
                            "<span style='color:" + theme.primary + "'>" + currentNode.s + "</span>" + "<br/>" +
                            "<span style='color:" + theme.secondary + "'>Community</span>: " +
                            "<span style='color:" + theme.primary + "'>" + (currentNode.category + 1) + "</span><br/>"
                        );
                    }
                },
                valueFormatter: (value) => numeral(value).format('0,000.00a'),
                backgroundColor: props.theme.palette.grid,
                borderColor: 'transparent',
                showDelay: 100,
                textStyle: {
                    color: '#f0f0f0',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    textBorderType: 'solid',
                    textBorderDashOffset: 0,
                    textShadowColor: 'transparent',
                    textShadowBlur: 0,
                    textShadowOffsetX: 0,
                    textShadowOffsetY: 0,
                    overflow: 'none',
                    ellipsis: '...',
                },
                extraCssText: 'box-shadow: none;'
            },
            legend: [
                {
                    icon: 'circle',
                    backgroundColor: props.theme.palette.background.paper,
                    borderRadius: 4,
                    padding: [
                        32,  // up
                        32, // right
                        8,  // down
                        48, // left
                    ],
                    orient: 'vertical',
                    right: 8,
                    top: 8,
                    data: data.categories.map(function (a) {
                        return a.name
                    }),
                    selected: data.categories.map(function (a) {
                        return [a.name, true];
                    }),
                    textStyle: {
                        color: props.theme.palette.text.primary
                    },
                    itemStyle: {
                        borderWidth: 1,
                    },
                    emphasis: {
                        selector: ['all', 'inverse']
                    }
                }
            ],
            animationDurationUpdate: 1100,
            animationEasingUpdate: 'quinticInOut',
            series: [
                {
                    name: 'Market capitalization',
                    type: 'graph',
                    layout: 'none',
                    legendHoverLink: false,
                    center: [0, 0],
                    zoom,
                    edgeSymbol: ['none', 'none'],
                    data: data.nodes,
                    links: data.links,
                    categories: data.categories,
                    roam: true,
                    width: '100%',
                    label: {
                        fontFamily: 'Roboto',
                        color: props.theme.palette.text.primary,
                        position: 'right',
                        formatter: '{b}'
                    },
                    labelLayout: {
                        hideOverlap: true
                    },
                    lineStyle: {
                        color: 'source',
                        curveness: 0.3
                    },
                    emphasis: {
                        focus: 'adjacency',
                        blurScope: 'global',
                        lineStyle: {
                            width: 3,
                            color: props.theme.palette.text.primary
                        }
                    },
                    itemStyle: {
                        borderColor: props.theme.palette.background.paper,
                        borderWidth: 1,
                    },
                }
            ]
        }

        setOptions(options)
        setLoading(false)
    }

    const fetch = () => {
        setLoading(true)
        setSearch('')
        api.get(`/v1/dashboard/graph`, { id: `graph` })
            .then(response => {
                const apiData = response.data

                const values = apiData.nodes.map(item => parseInt(item.value));
                const minValue = Math.min(...values);
                const maxValue = Math.max(...values);

                apiData.nodes.forEach(node => {
                    node.symbolSize = calculateSymbolSize(parseInt(node.value), minValue, maxValue)
                    node.z = parseInt(1 / node.value)
                    node.category = parseInt(node.category)
                    node.label = {
                        show: node.symbolSize > 30
                    }
                });

                apiData.links.forEach(link => {
                    link.lineStyle = { "curveness": 0.1, "type": "solid", "join": "bevel", "color": props.theme.palette.edges, }
                });

                apiData.categories.forEach(category => {
                    category.name = `${category.name}`
                });

                let cols = Array.from({ length: apiData.categories.length }, (_, i) => generateDistinctColor(i, apiData.categories.length));
                setColors(cols)

                setData(apiData)
                setDataFull(apiData)
            }).catch(error => {
                console.error(error);
                setData(null)
                setOptions(null)
                setLoading(false)
            }).finally(() => {
            })
    }

    const onChartReadyCallback = () => {

    }

    const handleResetSearch = () => {
        setSearch('')
    }

    const applySearch = async () => {
        if (data) {
            let fullNodes = data.nodes;
            const value = search.toLowerCase();

            const newNodes = fullNodes.map(node => {
                let itemStyle;
                const name = node.d.toLowerCase();
                const ticker = node.name.toLowerCase();

                if (value !== ''
                    && (name.includes(value) || name === value || ticker.includes(value) || ticker === value)) {
                    itemStyle = {
                        opacity: 1,
                    };
                } else if (value !== '') {
                    itemStyle = {
                        opacity: .05,
                    };
                } else {
                    itemStyle = {
                        opacity: 1,
                    };
                }

                return {
                    ...node,
                    itemStyle,
                };
            });

            // Create a new object to avoid mutating the original data
            let newData = {
                ...data,
                nodes: newNodes,
            };

            setData(newData);
        }
    };

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleClick = (e) => {
        if (clickTimeout) {
            clearTimeout(clickTimeout);
            clickTimeout = null;
            return;
        }

        clickTimeout = setTimeout(function () {
            if (e.dataType && e.dataType === 'node') {
                const uniqueId = Math.random().toString(16).slice(2);
                dispatch(notifyInstrument({ id: e.data.id, data: { id_instr: e.data.id, id: e.data.id, ticker: e.data.ticker, model: 1 } }))
            }
            clickTimeout = null;
        }, 300);
    }

    const handleDataZoom = (params) => {
        setResettable(true)
        if (params.zoom) {
            const echartsInstance = echartsRef.current.getEchartsInstance();
            const option = echartsInstance.getOption();
            const zoom = params.zoom;

            let newZoomCount = zoomCount + (zoom > 1 ? 1 : -1)
            zoomCount = newZoomCount
            const newData = option.series[0].data.map(node => {
                const symbolSize = node.symbolSize;
                return {
                    ...node,
                    label: {
                        show: symbolSize > 30 / (Math.pow(1.2, newZoomCount)),
                    },
                };
            });

            option.series.data = newData

            echartsInstance.setOption({
                series: [
                    {
                        data: newData,
                    },
                ],
            });
        }
    };

    const handleLegend = (params) => {
        setResettable(true)
        const echartsInstance = echartsRef.current.getEchartsInstance();
        const selectedLegend = echartsInstance.getOption().legend[0].selected;
        const legendData = echartsInstance.getOption().legend[0].data;
        const newLegendSelected = {};

        legendData.forEach(name => {
            if (lastClickedItem == params.name) {
                newLegendSelected[name] = name == params.name ? true : !selectedLegend[name];
            } else {
                newLegendSelected[name] = name == params.name;
            }
        });

        const allItemsFalse = Object.values(params.selected).every(value => value === false);
        let center = echartsInstance.getOption().series[0].center
        let zoom = echartsInstance.getOption().series[0].zoom

        if (params.name && !allItemsFalse) {
            // Find nodes associated with the selected category
            const selectedNodes = data.nodes.filter(node => (node.category + 1) == params.name);
            if (selectedNodes.length > 0) {
                // Calculate the bounding box of selected nodes
                const minX = Math.min(...selectedNodes.map(node => node.x));
                const minY = Math.min(...selectedNodes.map(node => node.y));
                const maxX = Math.max(...selectedNodes.map(node => node.x));
                const maxY = Math.max(...selectedNodes.map(node => node.y));

                // Calculate the center and range
                const centerX = (minX + maxX) / 2;
                const centerY = (minY + maxY) / 2;

                center = [centerX, centerY]
                //zoom = 0.5
            }
        }

        echartsInstance.setOption({
            legend: {
                selected: newLegendSelected,
            },
            series: [
                {
                    center: center,
                    // zoom: zoom,
                }
            ]
        });

        lastClickedItem = params.name;
    }

    const handleOver = (params) => {
        const echartsInstance = echartsRef.current.getEchartsInstance();
        if (params.type === 'highlight') {
            const highlightIndex = data.categories.findIndex(item => item.name === params.name);
            const highlightColor = colors[highlightIndex]
            const colorsDisabled = data.categories.map(category => (category.name === params.name ? highlightColor : '#a0a0a0'));
            echartsInstance.setOption({
                color: colorsDisabled
            });
        } else if (params.type === 'downplay') {
            echartsInstance.setOption({
                color: colors
            });
        }
    }

    const onEvents = {
        click: handleClick,
        graphRoam: handleDataZoom,
        mouseover: handleOver,
        legendselectchanged: handleLegend,

        highlight: handleOver,
        downplay: handleOver,
    };

    const globalFilter = () => {
        const localData = JSON.parse(JSON.stringify(dataFull));
        let filteredNodes = null

        const enabledMarkets = markets.filter(item => item.enabled === true);
        if (enabledMarkets.length > 0) {
            filteredNodes = localData.nodes.filter(node => enabledMarkets.some(market => market.id == node.m))
            localData.nodes = filteredNodes;
        }
        // FILTER BY SECTORS
        const enabledSectors = sectors.filter(item => item.enabled === true);
        if (enabledSectors.length > 0) {
            filteredNodes = localData.nodes.filter(node => enabledSectors.some(sector => sector.name === node.s));
            localData.nodes = filteredNodes;
        }

        setData(localData)
    }

    const handleMarketsChange = (e, checked) => {
        const updatedData = markets;
        let item = updatedData.find(item => item.id == e.target.id)

        item.enabled = checked
        setMarkets(updatedData)
        globalFilter()
    }
    const handleMarketsMenu = (e) => {
        // setMenuMarketsTarget(e.currentTarget)
        setMenuMarketsOpen(true)
    }
    const resetMarkets = () => {
        const updatedData = markets
        updatedData.forEach(item => {
            item.enabled = false
        });

        setMarkets(updatedData)
        globalFilter()
    }
    const fillMarkets = () => {
        const updatedData = markets
        updatedData.forEach(item => {
            item.enabled = true
        });

        setMarkets(updatedData)
        globalFilter()
    }

    const handleSectorsChange = (e, checked) => {
        const updatedData = sectors;
        let item = updatedData.find(item => item.id == e.target.id)

        item.enabled = checked
        setSectors(updatedData)
        globalFilter()
    }
    const handleSectorsMenu = (e) => {
        setMenuSectorsTarget(e.currentTarget)
        setMenuSectorsOpen(true)
    }
    const resetSectors = () => {
        const updatedData = sectors
        updatedData.forEach(item => {
            item.enabled = false
        });

        setSectors(updatedData)
        globalFilter()
    }
    const fillSectors = () => {
        const updatedData = sectors
        updatedData.forEach(item => {
            item.enabled = true
        });

        setSectors(updatedData)
        globalFilter()
    }

    const closeMenu = () => {
        setMenuMarketsOpen(false)
        setMenuSectorsOpen(false)
    }

    const toggleHelp = () => {
        setHelpOpen(true)
    }

    const closeHelp = () => {
        setHelpOpen(false)
    }

    return (
        <>
            <Dialog open={helpOpen} onClose={closeHelp}>
                <DialogContent>
                    <Box sx={{ pb: 4 }}>
                        <Typography variant='h6' component={'div'} className='bold' gutterBottom color={'textSecondary'}>How do you read the graph?</Typography>
                        <Typography variant='body1' className='slim'>The graph shows the correlation existing between the set of assets you have limited in the current view.</Typography>
                    </Box>
                    <Box sx={{ pb: 2 }}>
                        <Typography variant='overline' component={'div'} className='bold' gutterBottom color={'textSecondary'}>What is correlation?</Typography>
                        <Typography variant='body2' className='slim'>Our AI identifies links between assets based on their recent correlation. Two assets are particularly correlated when they tend to move in the same direction. In other words, bullish moments in one asset were reflected by bullish moments in the other related asset as well (and vice versa).<br />
                            From the perspective of financial markets, it is possible to assume that two highly correlated assets are exposed to some common risk factor.</Typography>
                    </Box>
                    <Box sx={{ pb: 2 }}>
                        <Typography variant='overline' component={'div'} className='bold' gutterBottom color={'textSecondary'}>Why are two elements connected?</Typography>
                        <Typography variant='body2' className='slim'>Correlation is measurable for each individual pair of assets.<br />
                            WaveGraph summarizes the most significant links and represents them in the form of a graph, a network of elements (the assets in question) connected via a series of "edges" (links) that highlight their mutual correlation. Two connected elements in the graph represent two assets that are significantly correlated with each other.</Typography>
                    </Box>
                    <Box sx={{ pb: 2 }}>
                        <Typography variant='overline' component={'div'} className='bold' gutterBottom color={'textSecondary'}>What are Communities?</Typography>
                        <Typography variant='body2' className='slim'>Wavegraph doesn't stop there. Once the network has been built, our AI also identifies groups (<i>Communities</i>) of elements that are strongly correlated with each other and poorly linked with the rest of the universe of monitored assets. This contributes to the readability of the graph and to identifying groups of assets that should be observed together.</Typography>
                    </Box>
                    <Box>
                        <Typography variant='body2' className='slim'>Wavegraph therefore offers a tool for obtaining a rapid overview of the universe of selected assets, identifying particularly correlated instruments or vice versa highlighting "islands" that are poorly linked to each other, useful for differentiating your operativity by selecting assets less exposed to the fluctuations of the rest of the market.</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeHelp}>close</Button>
                </DialogActions>
            </Dialog>
            <Card variant="outlined">
                <CardHeader sx={{ pb: 0 }}
                    title={<Typography variant='overline'>Wave graph</Typography>}
                    action={<>
                        <Tooltip title="Reset chart">
                            <>
                                <IconButton disabled={!resettable && (!search || search === '')} onClick={handleReset}><RestartAlt color={resettable || search ? "default" : "disabled"} /></IconButton>
                            </>
                        </Tooltip>
                        <IconButton onClick={toggleHelp}><HelpOutline color="default" /></IconButton>
                    </>}
                />
                <CardContent sx={{ position: 'relative' }}>
                    {
                        loading ? <Skeleton sx={{ height: 550 }} /> :
                            options ?
                                <>
                                    <Box sx={{ position: 'absolute', right: 56, top: 24, zIndex: 2 }}>
                                        <Typography variant='caption'>Communities</Typography>
                                    </Box>
                                    <ReactECharts style={{ height: 450 }}
                                        ref={echartsRef}
                                        option={options}
                                        onChartReady={onChartReadyCallback}
                                        onEvents={onEvents} />
                                </>
                                : 'no data'
                    }
                </CardContent>
                <CardActions>
                    <Box sx={{ display: "flex", columnGap: 1 }}>
                        <Chip label="Markets"
                            sx={{ minWidth: 80 }}
                            size='small'
                            variant={markets.some((item) => item.enabled) ? "filled" : "filled"}
                            color={markets.some((item) => item.enabled) ? "primary" : "secondary"}
                            onClick={handleMarketsMenu} />
                        <Chip label="Sectors"
                            sx={{ minWidth: 80 }}
                            size='small'
                            variant={sectors.some((item) => item.enabled) ? "filled" : "filled"}
                            color={sectors.some((item) => item.enabled) ? "primary" : "secondary"}
                            onClick={handleSectorsMenu} />
                    </Box>
                    <OutlinedInput value={search} onChange={handleSearch} fullWidth size='small' placeholder='Search'
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton color='default'
                                    onClick={handleResetSearch}
                                    edge="end"
                                >
                                    <Close color='textSecondary' />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </CardActions>
            </Card>
            <Dialog maxWidth={"lg"} open={menuMarketsOpen} onClose={closeMenu} scroll="paper">
                <DialogTitle>Exchanges selection</DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        {markets.map((obj, i) =>
                            <Grid item xs={3} key={"ckb_" + i}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={obj.enabled}
                                            name={obj.name}
                                            id={obj.id + ""}
                                            onChange={handleMarketsChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography variant='body2'>{obj.name}</Typography>}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={fillMarkets}>Select all</Button>
                    <Button disableElevation onClick={resetMarkets}>Reset</Button>
                    <Button onClick={closeMenu} variant='contained' disableElevation>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth={"lg"} open={menuSectorsOpen} onClose={closeMenu} scroll="paper">
                <DialogTitle>Sectors selection</DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        {sectors.map((obj, i) =>
                            <Grid item xs={3} key={"ckb_" + i}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={obj.enabled}
                                            name={obj.name}
                                            id={obj.id + ""}
                                            onChange={handleSectorsChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography variant='body2'>{obj.name}</Typography>}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disableElevation onClick={fillSectors}>Select all</Button>
                    <Button disableElevation onClick={resetSectors}>Reset</Button>
                    <Button onClick={closeMenu} variant='contained' disableElevation>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withTheme(WaveGraph);
