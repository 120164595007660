import { Container, Fade, Grid, Toolbar } from "@mui/material";
import { useParams } from "react-router-dom";
import { Component } from "react";
import PortfolioEquityLineExport from "./PortfolioEquityLineExport";
import PortfolioAllocationExport from "./PortfolioAllocationExport";
import PortfolioQuartersExport from "./PortfolioQuartersExport";
import MetricsRiskExport from "./MetricsRiskExport";
import MetricsGeneralExport from "./MetricsGeneralExport";
import { withTheme } from "@emotion/react";
import { api } from "../api/apiBuilder";
import HeadingExport from "./HeadingExport";
import React from "react";
import html2pdf from 'html2pdf.js';
import PaginaIniziale from "./PaginaIniziale";
import './pageBreak.css'




function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class PortfolioExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldRedirect: false,
      isLoading: false,
      portfolio: 0,
      data: null,
      exportToPdf: false,
      message: '',
    };
  }
  exportPdf = () =>{ 
    this.setState({ shouldRedirect: true });
  }

  componentDidMount() {
    let { id } = this.props.params;
    this.setState({ portfolio: id });
    this.loadPortfolio();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      let { id } = this.props.params;
      this.setState({ portfolio: id });
      this.loadPortfolio();
    }
  }

  exportToPDF = () => {
    const source = document.getElementById('exportToPdf');
    const currentDate = new Date().toLocaleDateString();
    const options = {
      margin: 0.1,
      filename: 'Report_' + currentDate ,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pagebreak: {mode: ['css', 'legacy']},
    };
    html2pdf().from(source).set(options).save(); 
  };
  
  loadPortfolio(id) {
    if (id) this.setState({ portfolio: id });
    this.setState({ isLoading: true });
    let config = {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("TOKEN"),
      },
    };
    api
      .get("/builder/portfolios/" + (id ? id : this.props.portfolio), config)
      .then((response) => {
        const data = response.data;
        console.log(data);
        this.setState({ portfolios: data, isLoading: false });
        window.alert('Attendi 2 secondi per il download')
        setTimeout(() => {
          this.exportToPDF();
          window.history.back()
        }, 2500); // 3000 milliseconds = 3 seconds
        
        
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response) {
          console.log("Error 1", error.message);
          if (error.response.status === 401) {
          }
          this.setState({
            snackMessage: error.response.data,
            snackOpen: true,
          });
        } else if (error.request) {
          // The request was made but no response was received
          console.log("Error 2", error.message);
          this.setState({
            snackMessage: error.message,
            snackOpen: true,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error 3", error.message);
          this.setState({
            snackMessage: error.message,
            snackOpen: true,
          });
        }
      });
      
  }

  render() {
    const { portfolio } = this.state;
    return (
      <Fade in="true" timeout={450}>
        <div id="exportToPdf" style={{ width: '8.5in' }}>
            <Container>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={0}
              >
                <PaginaIniziale />
                </Grid>
                <HeadingExport />
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <PortfolioEquityLineExport
                      portfolio={portfolio}
                    ></PortfolioEquityLineExport>
                    
                  </Grid>
                  <div style={{width: '100%', marginTop: '2.8in'}}>
                    <HeadingExport />
                  </div>
                  <Grid item xs={12} >
                    <MetricsGeneralExport portfolio={portfolio} />
                  </Grid>
                  <div style={{width: '100%', marginTop: '2.4in'}}>
                    <HeadingExport />
                  </div>
                  <Grid item xs={12}>
                    <MetricsRiskExport portfolio={portfolio} />
                  </Grid>
                  <div style={{width: '100%', marginTop: '2.8in'}}>
                    <HeadingExport />
                  </div>
                  <Grid item xs={12} marginTop={10} className='pagebreak'>
                    <PortfolioQuartersExport
                      portfolio={portfolio}
                    ></PortfolioQuartersExport>
                  </Grid>
                  <div style={{width: '100%', marginTop: '3.8in'}}>
                    <HeadingExport />
                  </div>
                  <Grid container item xs={12}>
                  <Grid item xs={12}>
                      <PortfolioAllocationExport portfolio={portfolio} />
                   </Grid>
                  </Grid>
                </Grid>
            </Container>
        </div>
      </Fade>
    );
  }
}

export default withTheme(withParams(PortfolioExport));
