import { withTheme } from '@emotion/react';
import { FullscreenExitOutlined, FullscreenOutlined, MoreVert } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiBraino } from "../../api/apiBraino";

const columnsInit = [
  { k: "average_vol_short", id: 0, enabled: false, l: "Avg volume short" },
  { k: "average_vol_medium", id: 1, enabled: false, l: "Avg volume medium" },
  { k: "average_vol_ratio", id: 2, enabled: false, l: "Avg volume ratio" },
  { k: "perc_chng_short", id: 3, enabled: false, l: "Percentual change short" },
  { k: "perc_chng_medium", id: 4, enabled: false, l: "Percentual change medium" },
  { k: "perc_chng_long", id: 5, enabled: false, l: "Percentual change long" },
  { k: "volat_short", id: 6, enabled: true, l: "Volatility short" },
  { k: "volat_medium", id: 7, enabled: true, l: "Volatility medium" },
  { k: "volat_long", id: 8, enabled: false, l: "Volatility long" },
  { k: "volat_ratio", id: 9, enabled: false, l: "Volatility ratio" },
  { k: "average_fast", id: 10, enabled: false, l: "Moving avg fast" },
  { k: "average_slow", id: 11, enabled: false, l: "Moving avg slow" },
  { k: "mkt_cycle_short", id: 12, enabled: false, l: "Market cycle short" },
  { k: "mkt_cycle_medium", id: 13, enabled: false, l: "Market cycle medium" },
  { k: "res1", id: 14, enabled: true, l: "Resistance 1" },
  { k: "res2", id: 15, enabled: true, l: "Resistance 2" },
  { k: "sup1", id: 16, enabled: true, l: "Support 1" },
  { k: "sup2", id: 17, enabled: true, l: "Support 2" },
]

const AssetAnalysis = (props) => {
  const [loading, setLoading] = useState(false)
  const [menuTarget, setMenuTarget] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const [comment, setComment] = useState("prc_cmt")
  const [commentTitle, setCommentTitle] = useState("Price comment")
  const [collapsed, setCollapsed] = useState(false)

  const [dataComments, setDataComments] = useState(null)
  const [dataEssentials, setDataEssentials] = useState(null)
  const [expanded, setExpanded] = useState(false);
  const [columns, setColumns] = useState(columnsInit)
  const [colCount, setColCount] = useState(columnsInit.filter(column => column.enabled).length)
  const lastInstrument = useSelector(state => state.lastInstrument)

  function getBToken() {
    let bToken = sessionStorage.getItem("BTOKEN")
    return bToken
  }

  function getBId() {
    let bToken = sessionStorage.getItem("BID")
    return bToken
  }

  const fetch = () => {
    const bToken = getBToken()
    const bId = getBToken()
    if (bToken) {
      setLoading(true)
      Promise.all([
        apiBraino.get("/ta_comments/" + getBId() + "/" + props.selectedInstrument, { id: `ta_comments-${props.selectedInstrument}` }),
        apiBraino.get("/ta_essentials/" + getBId() + "/" + props.selectedInstrument, { id: `ta_essentials-${props.selectedInstrument}` })
      ])
        .then(([commentsResponse, essentialsResponse]) => {
          const commentsData = commentsResponse.data;
          const essentialsData = essentialsResponse.data;

          if (localStorage.getItem("TA_COLS")) {
            const storedCols = JSON.parse(localStorage.getItem("TA_COLS"))
            setColumns(storedCols)
          }
          if (localStorage.getItem("TA_CMNT")) {
            const cmnt = localStorage.getItem("TA_CMNT")
            const title = localStorage.getItem("TA_CMNT_TITLE")
            setComment(cmnt)
            setCommentTitle(title)
          }

          setDataComments(commentsData);
          setDataEssentials(essentialsData);
        })
        .catch(error => {
          // Handle error
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.log("No Braino token");
    }
  }

  const count = (array) => {
    array.reduce((count, column) => {
      if (column.enabled) {
        return count + 1;
      } else {
        return count;
      }
    }, 0)
  }

  useEffect(() => {
    fetch()
  }, []);

  useEffect(() => {
    if (props.selectedInstrument) fetch()
  }, [props.selectedInstrument]);

  function handleMenu(e) {
    setMenuTarget(e.currentTarget)
    setMenuOpen(true)
  }

  const handleChange = (e) => {
    setComment(e.target.value)
    setCommentTitle(e.target.name)
    localStorage.setItem("TA_CMNT", e.target.value)
    localStorage.setItem("TA_CMNT_TITLE", e.target.name)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  function handleExpanded() {
    setExpanded(!expanded);
  }

  const handleColumnsChange = (e, checked) => {
    console.log(e.target);
    const updatedData = JSON.parse(JSON.stringify(columns))
    let item = updatedData.find(item => item.id == e.target.id)
    item.enabled = checked
    const colNum = updatedData.filter(column => column.enabled).length
    localStorage.setItem("TA_COLS", JSON.stringify(updatedData))
    setColCount(colNum)
    setColumns(updatedData)
  }

  return (
    <>
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: expanded ? "fixed" : "initial", width: "100%", maxHeight: "100%", overflowY: "auto", height: expanded ? "100%" : "100%", margin: expanded ? 0 : "initial", left: 0, top: 0, transition: 'all 0.25s ease', zIndex: expanded ? 1300 : 0 }}>
        <CardHeader
          sx={{ display: "flex", alignItems: "center", position: "relative", height: 56 }}
          title={<Typography variant='overline' className='bold'>Technical analysis</Typography>}
          action={
            <IconButton onClick={handleExpanded}>{expanded ? <FullscreenExitOutlined color="default" /> : <FullscreenOutlined color="default" />}</IconButton>
          }
        />
        <CardContent sx={{ minHeight: 400, flex: 1, display: 'flex' }}>
          {
            loading ? "loading..." :
              dataComments && dataEssentials &&
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{}}>
                  <Box sx={{ borderLeft: "3px solid " + props.theme.palette.primary.main, bgcolor: props.theme.palette.primary.bgrnd, py: .5, px: 1, borderRadius: "0 2px 2px 0" }}>
                    <Typography variant='body1' className='bold' sx={{}}>Technical data</Typography>
                  </Box>
                  <Box sx={{ display: "flex", mb: 1 }}>
                    <Grid container>
                      {
                        !expanded ?
                          columns.filter(col => col.enabled).map((obj, i) => (
                            <Grid item xs={6} key={"essentials_" + i}>
                              <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                                <Typography variant="caption" color={"textSecondary"} sx={{ width: "50%" }}>{obj.l}</Typography>
                                <Typography variant='h6' sx={{ width: "50%", textAlign: "right", pr: 7 }}>
                                  {!isNaN(dataEssentials[obj.k]) ?
                                    new Intl.NumberFormat("en-GB", { notation: 'compact', maximumSignificantDigits: 4 }).format(dataEssentials[obj.k])
                                    : "-"}
                                </Typography>
                              </Box>
                            </Grid>)
                          )
                          :
                          columns.map((obj, i) => (
                            <Grid item xs={6} key={"essentials_" + i}>
                              <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                                <Typography variant="caption" color={"textSecondary"} sx={{ width: "50%" }}>{obj.l}</Typography>
                                <Typography variant='h6' sx={{ width: "50%", textAlign: "right", pr: 7 }}>
                                  {!isNaN(dataEssentials[obj.k]) ?
                                    new Intl.NumberFormat("en-GB", { notation: 'compact', maximumSignificantDigits: 4 }).format(dataEssentials[obj.k])
                                    : "-"}
                                </Typography>
                              </Box>
                            </Grid>)
                          )
                      }
                    </Grid>
                  </Box>

                </Box>
                <Box>
                  {
                    !expanded ? (
                      <>
                        <Box sx={{ borderLeft: "3px solid " + props.theme.palette.primary.main, bgcolor: props.theme.palette.primary.bgrnd, py: .5, px: 1, mb: 1, borderRadius: "0 2px 2px 0" }}>
                          <Typography variant='body1' className='bold' sx={{}}>{commentTitle}:</Typography>
                        </Box>
                        <Collapse in={collapsed} collapsedSize={120} sx={{ overflow: "auto" }}>
                          <Typography variant='caption' className='slim' sx={{ lineHeight: 1.6 }}>{dataComments[comment]}</Typography>
                        </Collapse>
                        {/* <Button size='small' onClick={() => { setCollapsed(!collapsed) }}>read more...</Button> */}
                      </>)
                      : (
                        <>
                          <Typography variant='body1' gutterBottom className='bold'>Technical comment:</Typography>
                          {Object.entries(dataComments).map(([key, value]) =>
                            <>
                              <p><Typography variant='caption' className='slim' sx={{ lineHeight: 1.6 }}>{value}</Typography></p>
                            </>
                          )}

                        </>)
                  }
                </Box>

              </Box>
          }
        </CardContent>
        <CardActions sx={{ marginTop: 'auto' }}>
          <IconButton sx={{ marginLeft: 'auto', display: expanded ? 'none' : 'inherit' }} onClick={handleMenu}><MoreVert color="default" /></IconButton>
        </CardActions>
      </Card>

      <Dialog
        maxWidth='xl'
        open={menuOpen}
        onClose={closeMenu}
      >
        <DialogTitle>Configure Technical analysis card</DialogTitle>
        <DialogContent sx={{ display: "flex" }}>
          <Box sx={{ width: "70%" }}>
            <Typography variant='body2' gutterBottom>Technical data</Typography>
            <Box sx={{ mt: 2 }}>
              <Grid container>
                {
                  columns.map((obj, i) =>
                    <Grid item xs={6} key={"ckb_" + i}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={colCount >= 6 && !obj.enabled}
                            checked={obj.enabled}
                            name={obj.l}
                            id={obj.id+""}
                            onChange={handleColumnsChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color='primary'
                          />
                        }
                        label={<Typography variant='body2' color={colCount >= 6 && !obj.enabled ? "textSecondary" : "default"}>{obj.l}</Typography>}
                      />
                    </Grid>
                  )}
              </Grid>
            </Box>
          </Box>
          <Box sx={{ width: "30%" }}>
            <FormControl>
              <Typography variant='body2' gutterBottom>Technical comment</Typography>
              <RadioGroup
                sx={{ mt: 2 }}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="prc_cmt"
                name="radio-buttons-group"
                onChange={handleChange}
              >
                {[
                  { l: "Price comment", v: "prc_cmt" },
                  { l: "Trend comment", v: "tnd_cmt" },
                  { l: "Volume comment", v: "vlm_cmt" },
                  { l: "Volatility comment", v: "vlt_cmt" },
                  { l: "RSI comment", v: "stc_cmt" }
                ].map(obj =>
                  <FormControlLabel key={"rad_" + obj.v} checked={obj.v === comment} value={obj.v} name={obj.l} control={<Radio />} label={
                    <Typography variant='body2'>{obj.l}</Typography>
                  } />
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMenu} variant='contained' disableElevation>Close</Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default withTheme(AssetAnalysis);