import { withTheme } from '@emotion/react';
import moment from 'moment';
import numeral from 'numeral';
import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import MySkeleton from './MySkeleton';

class ChartMiniBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      performances: 0,
      chart_options: 0,
      size: 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.plot()
    }
  }
  componentDidMount() {
    this.setState({ size: this.props.size }, () => { this.plot() })
  }

  plot() {
    if (this.props.performances) {
      var p_series = this.props.performances.portfolio.series;
      var p_name = this.props.performances.portfolio.name;
      var p_prices = [];

      var b_series = this.props.performances.bm.series;
      var b_name = this.props.performances.bm.name;
      var b_prices = [];

      for (var i = 0; i < p_series.length; i++) {
        var d = (moment(p_series[i].d).format('Y-MM-DD'))
        p_prices.push({ 'x': d, 'y': p_series[i].v })
        b_prices.push({ 'x': d, 'y': b_series[i].v })
      }

      var series_data = [
        {
          name: p_name,
          data: p_prices
        },
        {
          name: b_name,
          data: b_prices
        }
      ];

      //this.state.chart_options.series = series_data;
    }

    var options = {
      series: series_data,
      chart: {
        animations: {
          enabled: this.state.size > 25 ? false : true
        },
        id: 'chartyear',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false
        },
        height: 'auto',
        type: 'area',
        stacked: false,
      },
      fill: {
        opacity: 1,
        type: 'solid'
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'left',
        floating: false,
        fontFamily: this.props.theme.palette.typography.fontFamily,
        labels: { colors: this.props.theme.palette.text.primary, },
        markers: {
          radius: 0,
          width: 8,
          height: 8,
        },
        itemMargin: {
          vertical: 4
        },
      },
      animations: {
        enabled: false,
        easing: 'easein',
        speed: 250,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: false,
          speed: 350
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
      stroke: {
        width: [2, 1],
        curve: 'smooth',
      },
      grid: {
        show: true,
        borderColor: this.props.theme.palette.grid,
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false
          }
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: this.state.size > 25 ? false : true,
          rotate: 0,
          style: {
            colors: this.props.theme.palette.text.primary,
            fontSize: '11px',
            fontFamily: 'Roboto',
            fontWeight: 400,
          },
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      yaxis: {
        min: function (min) { return min },
        tickAmount: 2,
        floating: false,
        labels: {
          show: false,
          offsetX: 50,
          formatter: (value) => { return numeral(value).format('0.[00]a') },
          style: {
            colors: ['848484'],
            fontSize: '11px',
            fontFamily: 'Roboto',
            fontWeight: 400,
          },
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      tooltip: {
        theme: this.props.selectedTheme,
        enabled: true,
        shared: true,
        x: { show: false },
        y: {
          formatter: function (val) {
            return numeral(val).format('0,000.0[00]');
          }
        }
      }
    };
    this.setState({ chart_options: options });
  }

  render() {
    const { chart_options } = this.state;
    return (
      <div>
        {chart_options.series && chart_options.series.length > 0 ? (
          <ReactApexChart series={this.state.chart_options.series} type='line' options={this.state.chart_options} height='200px'></ReactApexChart>
        ) : <MySkeleton type={'chart'} animation={false}></MySkeleton>}
      </div>
    );
  }
}

function ChartMini(props) {
  const navigate = useNavigate()
  const selectedTheme = useSelector(state => state.theme)

  return <ChartMiniBase navigate={navigate} selectedTheme={selectedTheme} {...props} />
}

export default withTheme(ChartMini);
