import React, { Component } from 'react';
import numeral from 'numeral';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Chip, TableCell } from '@mui/material';
import { Typography } from '@mui/material';
import DialogMessage from '../commons/DialogMessage';
import MyRemover from '../commons/MyRemover';
import { withTheme } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { api } from "../api/apiBuilder";

const styles = theme => ({
  typo: {
    fontFamily: 'Fjalla One',
  }
});

class PortfolioSignalsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 0,
      dialogOpen: false,
      signalsOpen: true,
      dialogMsg: 'Instruments shown on this card will be traded on the current day and will affect what is displayed in Allocation card starting from tomorrow.',
    }
  }

  openDialogMessage() {
    this.setState({ dialogOpen: true })
  }
  closeDialogMessage() {
    this.setState({ dialogOpen: false })
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.portfolio != prevProps.portfolio && this.props.portfolio) {
        this.loadSignals()
      }
    }
  }

  componentDidMount() {
    if (this.props.portfolio) {
      this.loadSignals();
    }
  }

  signalsClose() {
    this.props.close()
  }

  loadSignals() {
    this.props.reloadAllocation()
    api.get('/v1/builder/portfolios/' + this.props.portfolio + '/signals/', { cache: false })
      .then(response => {
        const data = response.data;
        this.setState({ data })
      }).catch(error => {
        this.setState({ isLoading: false })
        if (error.response) {
          console.log('Error 1', error.message);
          if (error.response.status == 401) {
          }
          this.state.snackMessage = error.response.data;
          this.state.snackOpen = true;
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error 2', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error 3', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        }
      })
  }



  nothing(){
        console.log("intercepted")
  }

  render() {
    const { open } = this.props;
    const { data, dialogOpen, dialogMsg, signalsOpen } = this.state;
    return (
      <div>
        <DialogMessage open={dialogOpen} message={dialogMsg} closeDialogMessage={this.closeDialogMessage.bind(this)}></DialogMessage>
        <Dialog open={open} maxWidth='md' fullWidth onClose={() => this.signalsClose()}>
          <DialogTitle>Today signals</DialogTitle>
          <DialogContent>
            {data.length > 0 ? (
              <TableContainer sx={{ mt: 2 }}>
                <Table size={'small'} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Instrument</TableCell>
                      <TableCell align="left">Market</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Action</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0 ? data.map((obj) => (
                      <TableRow key={obj.id} hover>
                        <TableCell sx={{ textDecoration: 'none' }} align="left" button component={NavLink} to={"/stock/" + obj.id_portfolio + "/" + obj.id_instr + "/" + obj.id}>
                          <Typography variant='subtitle2'>{obj.tc}</Typography>
                          <Typography variant='caption' color='textSecondary'>{obj.n}</Typography>
                        </TableCell>
                        <TableCell sx={{ textDecoration: 'none' }} button component={NavLink} to={"/stock/" + obj.id_portfolio + "/" + obj.id_instr + "/" + obj.id} align="left">{obj.mn}</TableCell>
                        <TableCell sx={{ textDecoration: 'none' }} button component={NavLink} to={"/stock/" + obj.id_portfolio + "/" + obj.id_instr + "/" + obj.id} align="right">{numeral(obj.quantity).format('0,000')}</TableCell>
                        <TableCell sx={{ textDecoration: 'none' }} button component={NavLink} to={"/stock/" + obj.id_portfolio + "/" + obj.id_instr + "/" + obj.id} align="right">
                          <Chip size='small' label={obj.act}
                          color={ obj.act === "long" || obj.act === "short"  ? obj.act : "long"  }
                          />
                        </TableCell>
                        <TableCell align="right">{
                          obj.execution == 'o' ?
                          <>
                            <MyRemover blackList={false} onClick={this.nothing.bind(this)} id={obj.id_instr} idptf={obj.id_portfolio} loadSignals={this.loadSignals.bind(this)} />
                            <MyRemover blackList={true} onClick={this.nothing.bind(this)} id={obj.id_instr} idptf={obj.id_portfolio} loadSignals={this.loadSignals.bind(this)} />
                            </>
                            : ""}</TableCell>
                      </TableRow>
                    )) : ''}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : <TableContainer sx={{ mt: 2 }}>
              <center>
                <Typography variant='body1' component="h5">No signals today</Typography>
              </center>
            </TableContainer>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.signalsClose()}>Ok</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withTheme(PortfolioSignalsDialog);
