import { withTheme } from '@emotion/react';
import { HelpOutline } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Tooltip, Typography, styled } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import TooltipHtml from '../commons/TooltipHtml';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.primary,
    maxWidth: 220,
    opacity: 1,
    fontSize: 14,
    fontFamily: 'Roboto',
    padding: 16,
    border: '1px solid ' + theme.palette.background.grid,
    //boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px'
  },
}));

function valuetext(value) {
  return `${value}°C`;
}

function valueLabelFormat(value) {
  switch (value) {
    case 0:
      return "Extra small"
    case 1:
      return "Small"
    case 2:
      return "Medium"
    case 3:
      return "Large"
    default:
      return "-"
  }
}

function getSizeLevel(value) {
  switch (value) {
    case 10:
      return 0
    case 20:
      return 1
    case 50:
      return 2
    case 100:
      return 3
    default:
      return 2
  }
}

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const initialSettings = {
  size: 50,
  quality: 0.5,
  strategy: 'short'
}

function SettingsModel(props) {
  const [tipNameOpen, setTipNameOpen] = useState(false);
  const [tipInitOpen, setTipInitOpen] = useState(false);
  const [tipSizeOpen, setTipSizeOpen] = useState(false);
  const [tipStrengthOpen, setTipStrengthOpen] = useState(false);
  const [tipShortOpen, setTipShortOpen] = useState(false);
  const [settings, setSettings] = useState(initialSettings);
  const builtPtfSettings = useSelector(state => state.builtPtfSettings);

  useEffect(() => {
    let presets = JSON.parse(builtPtfSettings)
    if (presets) presets = presets.settings

    loadSettings();

    Object.entries(settings).forEach(([key, value]) => {
      props.sendData(key, presets ? presets[key] : value);
      props.ready(props.step, isComplete(settings))
    });
  }, []);

  const isComplete = (sett) => {
    let res = 1

    if (sett.strategy === '' || !sett.strategy) res = 0
    if (sett.size === 0 || isNaN(sett.size)) res = 0
    if (sett.quality === 0 || isNaN(sett.quality)) res = 0

    return res
  }

  const handleChange = (name, value) => {
    let updatedSettings = { ...settings };
    if (name === 'init' || name === 'quality') {
      value = parseFloat(value.toString().replace(",", ""));
    } else if (name === 'strategy') {
      value = value ? 'short' : 'long';
    } else if (name === 'size') {
      value = parseInt(value)
    }
    updatedSettings[name] = value;
    setSettings(updatedSettings);
    props.sendData(name, value);
    props.ready(props.step, isComplete(updatedSettings))
  };

  const loadSettings = () => {
    let presets = JSON.parse(builtPtfSettings)

    if (presets && presets.settings) {
      setSettings(presets.settings);
    } else {
      setSettings(initialSettings);
      const params = Object.keys(initialSettings);
      for (let i = 0; i < params.length; i++) {
        handleChange(params[i], initialSettings[params[i]]);
      }
    }
  };

  const handleTips = (tip) => {
    switch (tip) {
      case 'tipShort':
        setTipShortOpen(prevState => !prevState);
        break;
      case 'tipInitOpen':
        setTipInitOpen(prevState => !prevState);
        break;
      case 'tipNameOpen':
        setTipNameOpen(prevState => !prevState);
        break;
      case 'tipSizeOpen':
        setTipSizeOpen(prevState => !prevState);
        break;
      case 'tipStrengthOpen':
        setTipStrengthOpen(prevState => !prevState);
        break;
      default:
        break;
    }
  };

  const closeTips = () => {
    setTipNameOpen(false);
    setTipInitOpen(false);
    setTipSizeOpen(false);
    setTipStrengthOpen(false);
    setTipShortOpen(false);
  };

  return (
    <>
      {settings ?
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="body2" gutterBottom>
                Critical decisions loom large as the investor delves into the heart of strategy. Selections of model algorithms, portfolio size, and signal strength define the framework for investment execution, shaping the portfolio's trajectory and risk management approach.
              </Typography>
            </Box>
            <Box sx={{ my: 2, display: 'flex', flexDirection: 'row', gap: 2 }}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant='caption'>Model selection</Typography>
                  <HtmlTooltip
                    title={
                      <TooltipHtml
                        title="Short positions"
                        body="Allow or disallow short positions. If these positions are allowed, our system quantifies the cost of these positions using an average value of 20% per year, appropriately scaled by the number of days for which the position is open." />
                    }><HelpOutline onClick={() => handleTips('tipShort')} sx={{ ml: 1, fontSize: 21 }} /></HtmlTooltip>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                  {/* <Button disableElevation color={settings.strategy === 'long' ? 'primary' : 'default'} sx={{ width: '50%', m: 0, p: 0 }} variant={settings.strategy === 'long' ? 'contained' : 'outlined'} onClick={e => handleChange("strategy", 0)}>
                    <Box sx={{ textAlign: 'left', p: 1 }}>
                      <Typography variant='h5' gutterBottom sx={{ textTransform: 'initial' }}>Long only</Typography>
                      <Typography variant='caption' component={'div'} sx={{ textTransform: 'initial', lineHeight: 'normal', fontWeight: 300 }}>The model will create and optimize the portfolio by operating long only.</Typography>
                    </Box>
                  </Button> */}
                  <Button disableElevation color={settings.strategy === 'long' ? 'primary' : 'default'} sx={{ width: '50%', }} variant={settings.strategy === 'long' ? 'contained' : 'outlined'} onClick={e => handleChange("strategy", 0)}>
                    <Box sx={{ textAlign: 'left', p: 1, width: '100%' }}>
                      <Typography variant='h5' gutterBottom sx={{ textTransform: 'initial' }}>Long only</Typography>
                      <Typography variant='caption' component={'div'} sx={{ textTransform: 'initial', lineHeight: 'normal', fontWeight: 300 }}>The model will create and optimize the portfolio by operating long only.</Typography>
                    </Box>
                  </Button>
                  <Button disableElevation color={settings.strategy === 'short' ? 'primary' : 'default'} sx={{ width: '50%', }} variant={settings.strategy === 'short' ? 'contained' : 'outlined'} onClick={e => handleChange("strategy", 1)}>
                    <Box sx={{ textAlign: 'left', p: 1, width: '100%' }}>
                      <Typography variant='h5' gutterBottom sx={{ textTransform: 'initial' }}>Long-Short</Typography>
                      <Typography variant='caption' component={'div'} sx={{ textTransform: 'initial', lineHeight: 'normal', fontWeight: 300 }}>When allowed, the model will optimize the portfolio with short positions to actively take advantage of bearer market.</Typography>
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box sx={{ my: 2, display: 'flex', flexDirection: 'row', gap: 5 }}>
              <Box sx={{ width: '50%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant='caption'>Portfolio size</Typography>
                  <HtmlTooltip title={
                    <TooltipHtml
                      title="Portfolio size"
                      body="Maximum number of positions in the portfolio at the same time in order to manage the level of diversification. The minimum level corresponds to 10 positions and the maximum to 100, but the quantities can be dynamically adjusted by the engine based on the other settings. " />
                  }><HelpOutline sx={{ ml: 1, fontSize: 21 }} /></HtmlTooltip>
                </Box>
                <ButtonGroup onClick={e => handleChange("size", e.target.value)} fullWidth size='large' variant="outlined" aria-label="outlined button group">
                  {
                    [
                      { label: "Mini", value: 10 },
                      { label: "Small", value: 20 },
                      { label: "Medium", value: 50 },
                      { label: "Large", value: 100 },
                    ].map((obj, index) => (
                      <Button
                        key={index} disableElevation variant={settings.size == obj.value ? "contained" : "outlined"} value={obj.value}>{obj.label}</Button>
                    )
                    )
                  }
                </ButtonGroup>
              </Box>
              <Box sx={{ width: '50%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant='caption'>Signals strength</Typography>
                  <HtmlTooltip title={
                    <TooltipHtml
                      title="Signals strength"
                      body="Threshold indicator for the inclusion of positions within the portfolio. Stronger signals may be sparse over time, causing periods of higher liquidity, but may increase the win-ratio." />
                  }><HelpOutline sx={{ ml: 1, fontSize: 21 }} /></HtmlTooltip>
                </Box>
                <ButtonGroup onClick={e => handleChange("quality", e.target.value)} fullWidth size='large' variant="outlined" aria-label="outlined button group">
                  {
                    [
                      { label: "Strong", value: 0.65 },
                      { label: "Standard", value: 0.5 },
                      { label: "Moderate", value: 0.3 }
                    ].map((obj, index) => (
                      <Button key={index} disableElevation variant={settings.quality === obj.value ? "contained" : "outlined"} value={obj.value}>{obj.label}</Button>
                    )
                    )
                  }
                </ButtonGroup>
              </Box>
            </Box>
          </Box>
        </Box>
        : ""}
    </>
  );
}

SettingsModel.propTypes = {
  sendData: PropTypes.func.isRequired,
  preset: PropTypes.object,
};

export default withTheme(SettingsModel);
