import { withTheme } from "@emotion/react";
import { Container, Fade, Grid, Toolbar } from "@mui/material";
import { Component } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../api/apiBuilder";
import { notifyArea } from "../dispatcher/Actions";
import MetricsGeneral from "../elements/MetricsGeneral";
import MetricsRisk from "../elements/MetricsRisk";
import PortfolioEquityLine from "../elements/PortfolioEquityLine";
import PortfolioQuarters from "../elements/PortfolioQuarters";
import PortfoliosList from "../elements/PortfoliosList";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class ArchivedBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            portfolio: 0,
        }
    }
    componentDidMount() {
        this.props.dispatch(notifyArea("builder"))
        let { id } = this.props.params;
        this.setState({ portfolio: id })
        this.loadPortfolio()
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            let { id } = this.props.params;
            this.setState({ portfolio: id })
            this.loadPortfolio()
        }
    }

    loadPortfolio(id) {
        if (id) this.setState({ portfolio: id })
        this.setState({ isLoading: true })
        api.get('/v1/builder/portfolios/' + (id ? id : this.props.portfolio), { cache: false })
            .then(response => {
                const data = response.data;
                let filteredData = Array()
                data.forEach(item => {
                    if (parseInt(item.ptf.archived)) filteredData.push(item)
                });
                this.setState({ portfolios: data, isLoading: false })
            }).catch(error => {
                this.setState({ isLoading: false })
                if (error.response) {
                    console.log('Error 1', error.message);
                    if (error.response.status == 401) {
                    }
                    this.state.snackMessage = error.response.data;
                    this.state.snackOpen = true;
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error 2', error.message);
                    this.state.snackMessage = error.message;
                    this.state.snackOpen = true;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error 3', error.message);
                    this.state.snackMessage = error.message;
                    this.state.snackOpen = true;
                }
            })
    }

    render() {
        const { classes } = this.props;
        const { portfolio, blacklistTitle } = this.state;
        return (
            <Fade in="true" timeout={450} >
                <div>
                    <Toolbar />
                    <Container maxWidth='xl' sx={{ mb: 7 }}>
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={1}>
                            <Grid item xs={12}>
                                {portfolio ?
                                    <PortfoliosList isArchived={true} defaultPortfolio={portfolio} notifyNewPosition={this.loadPortfolio.bind(this)} />
                                    : "Loading your portfolios..."
                                }
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={12}>
                                    <PortfolioEquityLine portfolio={portfolio}></PortfolioEquityLine>
                                </Grid>
                                <Grid item xs={6}>
                                    <MetricsGeneral portfolio={portfolio} />
                                </Grid>
                                <Grid item xs={6}>
                                    <MetricsRisk portfolio={portfolio} />
                                </Grid>
                                <Grid item xs={12}>
                                    <PortfolioQuarters portfolio={portfolio}></PortfolioQuarters>
                                </Grid>
                            </Grid>
                            {/* <Grid container item xs={12} md={6} lg={4}>
                                <Grid item xs={12}>
                                    <PortfolioAllocation portfolio={portfolio} />
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Container>
                </div>
            </Fade>
        )
    }
}

function Archived(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    return <ArchivedBase navigate={navigate} dispatch={dispatch} {...props} />
}

export default withTheme(withParams(Archived));