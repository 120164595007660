import { withTheme } from "@emotion/react";
import { AccountBalanceOutlined, BoltOutlined, CategoryOutlined, DeveloperBoardOutlined, FactoryOutlined, HealthAndSafetyOutlined, MapsHomeWorkOutlined, SatelliteAltOutlined, ScienceOutlined, ShoppingCartOutlined, TipsAndUpdatesOutlined } from "@mui/icons-material"

function getSectorIcon(sector, color){
    switch (sector) {
        case "Basic Materials":
            return <ScienceOutlined color="default" />
            break;       
        case "Communication Services":
            return <SatelliteAltOutlined color="default" />
            break;
        case "Consumer Cyclical":
            return <ShoppingCartOutlined color="default" />
            break;
        case "Other":
            return <CategoryOutlined color="default" />
            break;                 
        case "Energy":
            return <BoltOutlined color="default" />
            break;                
        case "Healthcare":
            return <HealthAndSafetyOutlined color="default" />
            break;            
        case "Industrials":
            return <FactoryOutlined color="default" />
            break;           
        case "Real Estate":
            return <MapsHomeWorkOutlined color="default" />
            break;           
        case "Technology":
            return <DeveloperBoardOutlined color="default" />
            break;            
        case "Utilities":
            return <TipsAndUpdatesOutlined color="default" />
            break;             
        case "Financial Services":
            return <AccountBalanceOutlined color="default" />
            break;    
        case "Consumer Defensive":
            return <CategoryOutlined color="default" />
            break;    
        default:
            return <CategoryOutlined color="default" />
            break;
    }
}

export default getSectorIcon
