// components/NotFound.js

import { Box, Container, Fade, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { notifyArea } from '../dispatcher/Actions';
import { ReportProblem, ReportProblemOutlined } from '@mui/icons-material';

const NotFound = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(notifyArea("404"))
    }, []);

    return (
        <Fade in={true} timeout={450} >
            <div>
                <Toolbar />
                <Container maxWidth='xl'>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: 200, width: '100%' }}>
                        <ReportProblemOutlined sx={{mb: 2, fontSize: 48}} />
                        <Typography variant='h6' gutterBottom>404 - Not Found</Typography>
                        <Typography variant='caption' color={"textSecondary"}>The page you are looking for does not exist.</Typography>
                    </Box>
                </Container>
            </div>
        </Fade>
    );
};

export default NotFound;
