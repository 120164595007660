import { Dialog, DialogContent, Button, TableRow, TableCell, Typography, Table, TableBody } from '@mui/material';
import { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { DialogActions, DialogTitle } from '@mui/material';
import axios from 'axios';
import MyRemover from './MyRemover';
import MyAdder from './MyAdder';
import { api } from "../api/apiBuilder";

const styles = theme => ({
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
});

class MyBlacklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  changeTheme(v) {
    this.setState({ selectedTheme: v })
    if (v) v = 'light'
    else v = 'dark'
    this.props.changeTheme(v)
  }

  componentDidMount() {
    this.loadBlacklist()
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.loadBlacklist()
    }
  };

  loadBlacklist() {
    api.get('/v1/blacklist/instruments/', { cache: false })
      .then(response => {
        const data = response.data;
        this.setState({ data })
      }).catch(error => {
        this.setState({ isLoading: false })
        if (error.response) {
          console.log('Error 1', error.message);
          if (error.response.status == 401) {
          }
          this.state.snackMessage = error.response.data;
          this.state.snackOpen = true;
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error 2', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error 3', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        }
      })
  }

  handleClose() {
    this.props.cancel()
  };

  render() {
    const { classes } = this.props;
    const { open, data } = this.state;
    return (
      <Dialog open={this.props.open} maxWidth={'xl'}>
        <DialogTitle>
          <Typography variant='h6' component={'h6'}>{this.props.title}</Typography>
          <Typography variant='caption' color='textSecondary'>These instruments will be skipped from next portfolio rebalancing</Typography>
        </DialogTitle>
        <DialogContent>
          <Table sx={{ my: 2 }} size={'small'} aria-label="simple table">
            <TableBody>
              {data.length > 0 ? data.map((obj, index) => (
                <TableRow key={index.toString()}>
                  <TableCell align="left"><Typography variant='subtitle2'>{obj.n}</Typography> <Typography variant='caption' color='textSecondary'>
                    {obj.c} - {obj.i} - {obj.m}
                  </Typography></TableCell>
                  <TableCell align="right"><MyAdder id={obj.id} loadBlacklist={this.loadBlacklist.bind(this)} ></MyAdder></TableCell>
                </TableRow>
              )) : 'Your Blacklist is currently empty'}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MyBlacklist);
