import { Circle, CircleOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { grey, orange } from "@mui/material/colors";
import { Box } from "@mui/system";


function getScoreText(metricId, metricValue) {
    //1 Bad, 2-3 Medium, 4-5 Good
    let text = '-'
    let score = getScorePoints(metricId, metricValue)
    switch (score) {
        case 1:
            text = 'Bad'
            break;
        case 2: case 3:
            text = 'Medium'
            break;
        case 4: case 5:
            text = 'Good'
            break;
        default:
            text = score
            break;
    }

    return text;
}

function getScorePoints(metricId, metricValue) {
    const rangeArray = {
        annReturn: {
            sort: "asc",
            range: [-0.05, 0.05, 0.10, 0.15]
        },
        sharpe: {
            sort: "asc",
            range: [0, 0.3, 0.7, 1.2],
        },
        martin: {
            sort: "asc",
            range: [0, 0.3, 1, 3],
        },
        alpha: {
            sort: "asc",
            range: [-0.02, 0.02, 0.04, 0.06],
        },
        beta: {
            sort: "desc",
            range: [1.2, 0.8, 0.3, 0],
        },
        volatility: {
            sort: "desc",
            range: [0.35, 0.25, 0.18, 0.12],
        },
        maxDrawdown: {
            sort: "desc",
            range: [0.4, 0.35, 0.25, 0.20],
        },
        var: {
            sort: "desc",
            range: [0.1, 0.075, 0.05, 0.035],
        },
        ulcer: {
            sort: "desc",
            range: [0.10, 0.075, 0.06, 0.04],
        },
        betaBear: {
            sort: "desc",
            range: [1.2, 0.8, 0.3, 0]
        },
    }

    let n;
    let lookup = metricValue
    let metric = rangeArray[metricId]
    if (metric.sort == 'asc') {
        n = (metric.range).findIndex(element => element > lookup)
        n = n > -1 ? n : 4
    } else {
        n = (metric.range).findIndex(element => element < lookup)
        n = n > -1 ? n : 4
    }
    return (n + 1)

}

const Score = (props) => {

    const plot = (cb) => {
        const row = [];
        let scorePoints = getScorePoints(props.k, props.v)
        for (let i = 0; i < scorePoints; i++) {
            row.push(
                <Circle key={"full_" + i.toString()} sx={{ color: orange[500], fontSize: 16 }} />
            );
        }
        for (let i = 0; i < (5 - scorePoints); i++) {
            row.push(
                <Circle key={"empty_" + i.toString()} sx={{color: grey[200],   fontSize: 16 }} />
            );
        }
        return row;
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column'}}>
            <Box>{plot()}</Box>
            <Typography variant='caption' component={'div'} color="textSecondary">{getScoreText(props.k, props.v)}</Typography>
        </Box>
    );
}

export default Score;
