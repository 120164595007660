import { withTheme } from "@emotion/react";
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Card, CardActionArea, CardContent, CardHeader, Container, Divider, Fade, Grid, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import numeral from "numeral";
import { Component } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { api } from "../api/apiBuilder";
import { restore } from "../commons/Memo";
import notificationsInit from '../commons/Notifications';
import { notifyArea } from "../dispatcher/Actions";
import ChartMini from "../elements/ChartMini";
import SkeletonOverview from "../elements/SkeletonOverview";

function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}

class ArchiveBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            portfolios: [],
            performance: 0,
            isLoading: false,
            menuAnchor: null,
            menuOptions: [
                { id: 'inceptionDate', label: 'Inception date' },
                { id: 'totalReturn', label: 'Total return' },
                { id: 'excessReturn', label: 'Excess return' },
                { id: 'name', label: 'Name' },
            ],
            selectedIndex: 0,
            menuOpen: false,
            sortDirection: true,
        }
    }

    plot(data) {
        let filteredData = Array()
        data.forEach(item => {
            if (parseInt(item.ptf.archived)) filteredData.push(item)
        });

        this.setState(
            { portfolios: filteredData, isLoading: false },
            function () { this.sort(this.state.selectedIndex, this.state.sortDirection) }
        )
    }

    componentDidMount() {
        this.props.dispatch(notifyArea("builder"))
        this.setState({ isLoading: true })
        notificationsInit(sessionStorage.getItem('MSGT'));
        numeral.options.scalePercentBy100 = true;
        let response = restore("PF_ARCH_LIST")

        if (response) {
            const data = response.data;
            this.plot(data)
        } else {
            api.get('/v1/builder/portfolios/', { cache: false })
                .then(response => {
                    // store("PF_ARCH_LIST", response)
                    const data = response.data
                    this.plot(data)
                }).catch(error => {
                    this.setState({ isLoading: false })
                    if (error.response) {
                        console.log('Error 1', error.message);
                        if (error.response.status == 401) {
                        }
                        this.state.snackMessage = error.response.data;
                        this.state.snackOpen = true;
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log('Error 2', error.message);
                        this.state.snackMessage = error.message;
                        this.state.snackOpen = true;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error 3', error.message);
                        this.state.snackMessage = error.message;
                        this.state.snackOpen = true;
                    }
                })
        }
    }

    componentDidUpdate() {

    }

    openSortMenu(e) {
        this.setState({ menuAnchor: e.currentTarget, menuOpen: true });
    }

    handleSortMenuItem(id) {
        let direction = this.state.sortDirection
        this.sort(id, direction)
        this.setState({ menuAnchor: null, menuOpen: false, selectedIndex: id });
    }

    toggleSortDirection() {
        let newDirection = !this.state.sortDirection
        this.setState({ sortDirection: newDirection })
        let i = this.state.selectedIndex
        this.sort(i, newDirection)
    }

    sort(i, direction) {
        let portfolios = this.state.portfolios
        let sortDirection = direction
        let sortCriteria = this.state.menuOptions[i].id
        if (sortDirection) {
            portfolios.sort(function (a, b) {
                if (!isNaN(a.ptf[sortCriteria])) {
                    return a.ptf[sortCriteria] - b.ptf[sortCriteria]
                } else {
                    return a.ptf[sortCriteria].localeCompare(b.ptf[sortCriteria])
                }
            })
        } else {
            portfolios.sort(function (a, b) {
                if (!isNaN(a.ptf[sortCriteria])) {
                    return b.ptf[sortCriteria] - a.ptf[sortCriteria]
                } else {
                    return b.ptf[sortCriteria].localeCompare(a.ptf[sortCriteria])
                }
            })
        }
        this.setState({ portfolios })
    }

    handleClose() {
        this.setState({ menuAnchor: null, menuOpen: false });
    }

    render() {
        const { classes } = this.props;
        const { portfolios, isLoading, menuOptions, selectedIndex, menuAnchor, menuOpen, sortDirection } = this.state;
        return (
            <Fade in="true" timeout={450} >
                <div>
                    <Toolbar />
                    <Container maxWidth='xl'>
                        {isLoading ?
                            <>
                                <Grid container spacing={1} sx={{ pb: 5 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" sx={{ ml: 1 }} className='big'>Archived portfolios</Typography>
                                    </Grid>
                                    <Grid xs={12} md={6} lg={4} item>
                                        <SkeletonOverview />
                                    </Grid>
                                    <Grid xs={12} md={6} lg={4} item>
                                        <SkeletonOverview />
                                    </Grid>
                                    <Grid xs={12} md={6} lg={4} item>
                                        <SkeletonOverview />
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <Grid container spacing={1} sx={{ pb: 5 }}>
                                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="h4" sx={{ ml: 1 }} className='big'>Archived portfolios</Typography>
                                </Grid>
                                <Grid item xs={6} textAlign='right' sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <ButtonGroup variant="outlined" color="secondary" sx={{ mr: 1 }}>
                                        <Tooltip title="Sorting criteria">
                                            <Button
                                                variant="outlined" size="small"
                                                onClick={(e) => this.openSortMenu(e)}
                                                sx={{ textTransform: 'inherit' }}
                                            >{"Sort by " + menuOptions[selectedIndex].label}
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Sorting direction">
                                            <Button size="small" variant="outlined"
                                                onClick={() => this.toggleSortDirection()}>
                                                {sortDirection ? <ArrowUpwardOutlined sx={{ fontSize: 18 }} /> : <ArrowDownwardOutlined sx={{ fontSize: 18 }} />}
                                            </Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                    <Menu
                                        variant="menu"
                                        sx={{ marginTop: 1, minWidth: 250 }}
                                        id="lock-menu"
                                        anchorEl={menuAnchor}
                                        open={menuOpen}
                                        onClose={() => this.handleClose()}
                                        MenuListProps={{
                                            'aria-labelledby': 'lock-button',
                                            role: 'listbox',
                                        }}
                                    >
                                        {menuOptions.map((option, index) => (
                                            <MenuItem
                                                key={option.id}
                                                //disabled={index === selectedIndex}
                                                selected={index === selectedIndex}
                                                onClick={(event) => this.handleSortMenuItem(index)}
                                            >
                                                <Typography variant="body2">{option.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>

                                {portfolios && this.state.portfolios.length > 0 ? this.state.portfolios.map((obj, i) => (
                                    <Grid key={i} xs={12} md={6} lg={4} item>
                                        <Card variant='outlined' sx={{ border: 2, borderStyle: 'dashed', borderColor: grey[200] }}>
                                            <CardActionArea component={NavLink} to={"/archived/" + obj.ptf.id}>
                                                <CardHeader
                                                    sx={{ pb: 0 }}
                                                    // action={obj.ptf.is_rebalancing != '0' ? <Tooltip title={"New signals today"}><SensorsOutlined color='success' /></Tooltip> : ''}

                                                    title={<Typography variant='h6' style={{ fontWeight: 500 }}>{obj.ptf.name}</Typography>}
                                                    subheader={<Typography variant='caption' color="textSecondary">{"Portfolio value: " + obj.ptf.currency + " " + numeral(obj.ptf.portfolioValue).format('0,000')}</Typography>}
                                                />
                                                <CardContent sx={{ pt: 0 }}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box sx={{ flex: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <Typography variant='h3' style={{ fontFamily: 'Fjalla One' }}>{numeral(obj.ptf.totalReturn).format('+0,000.00%')}</Typography>
                                                            <Typography variant='caption'>Portfolio return</Typography>
                                                            <Divider style={{ margin: 8 }} component='p' variant='middle' />
                                                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                                                    {(obj.ptf.totalReturn - obj.ptf.bReturn) >= 0 ?
                                                                        <Typography variant='h4' style={{ color: green[300], fontFamily: 'Fjalla One' }}>{numeral(obj.ptf.totalReturn - obj.ptf.bReturn).format('+0,000.00%')}</Typography>
                                                                        : <Typography variant='h4' style={{ color: red[300], fontFamily: 'Fjalla One' }}>{numeral(obj.ptf.totalReturn - obj.ptf.bReturn).format('+0,000.00%')}</Typography>
                                                                    }
                                                                    <Typography variant='caption' color='textSecondary'>Excess return</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ flex: '60%', px: 2 }}>
                                                            <ChartMini isLoading={false} performances={obj.eql}></ChartMini>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )) : 'No data'}
                            </Grid>
                        }
                    </Container>
                </div>
            </Fade>
        );
    }
}

function Archive(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    return <ArchiveBase navigate={navigate} dispatch={dispatch} {...props} />
}

export default withTheme(withParams(Archive));
