import { ContentCopy, OpenInNew, VerifiedUser } from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const BlockchainDialog = (props) => {
    const [copied, setCopied] = useState(false)
    const signal = props.signal
    const _idFormatSwitchDate = '2023-10-06T00:00:00.000Z'

    useEffect(() => {
        setCopied(false)
    }, []);

    const verifyContract = () => {
        if (signal.blockchainHash) window.open(`https://polygonscan.com/tx/${signal.blockchainHash}`, "BC")
        else console.error("EMPTY HASH", signal);
        props.setOpen(false);
    };

    /**
     * For trades before 06/10/2023 horizon is not part of trade id.
     * In this case the id format must be "SIGNAL@ID_INSTR"
     */
    const getSignalKey = (signal) => {
        // Convert "open_date" string to Date object
        const openDate = new Date(signal.open_date);
        // Data di switch del formato su polygonscan
        const switchFormatDate = new Date(_idFormatSwitchDate);

        let ret = "-"
        if (signal) {
            if (openDate >= switchFormatDate) {
                ret = "@" + signal.id_instr + ":" + signal.direction.substring(0, 1) + "/" + signal.horizon
            } else {
                ret = signal.direction.substring(0, 1) + "@" + signal.id_instr
            }
        }
        return ret
    }

    /**
     * For trades before 06/10/2023 horizon is not part of trade id.
     * Its description must be omitted in the popup.
     */
    const showHorizonDescription = (signal) => {
        let ret = true
        // Convert "open_date" string to Date object
        const openDate = new Date(signal.open_date);
        // Data di switch del formato su polygonscan
        const switchFormatDate = new Date(_idFormatSwitchDate);
        if (signal) {
            if (openDate < switchFormatDate) {
                ret = false
            }
        }
        return ret
    }


    const copy = (text) => {
        navigator.clipboard.writeText(text)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1000);
    }
    return (
        <Dialog maxWidth='sm' open={props.open} onClose={props.closeDialog} scroll='body'>
            <DialogTitle sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <VerifiedUser color='primary' sx={{ verticalAlign: 'middle', fontSize: 40 }} />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    Blockchain verification
                    <Typography variant='body2' component={'div'}>{signal && signal.blockchainHash}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                {signal &&
                    <>
                        <Typography gutterBottom variant="h6">Tracked on polygonscan</Typography>
                        <Typography variant='body2' className='slim'>
                            Here's how to verify your signal:
                        </Typography>
                        <Typography variant='body2' className='slim'>
                            Click on "Verify" down below to visualize Transaction Details Overview.
                        </Typography>
                        <ul>
                            <li> <Typography variant='body2' className='slim'>Click on "Logs" tab</Typography> </li>
                            <li>
                                <Typography variant='body2' className='slim'>
                                    In Transaction receipt "Data" field search for the string: <span className='bold'>{getSignalKey(signal)}</span>
                                    {!copied ?
                                        <Tooltip arrow title={<Typography variant='caption' className='slim'>Copy identifier to clipboard</Typography>}>
                                            <IconButton sx={{ ml: 1 }} onClick={() => copy(getSignalKey(signal))}><ContentCopy color='primary' sx={{ verticalAlign: "middle", fontSize: 18 }} /></IconButton>
                                        </Tooltip>
                                        : <Typography color={"primary"} sx={{ ml: 1, lineHeight: "34px" }} variant='caption' component={"span"}>Copied!</Typography>
                                    }
                                </Typography>
                            </li>
                        </ul>
                        <Typography gutterBottom variant='body2' className='slim'>
                            <span className='bold'>{getSignalKey(signal)}</span> is the unique identifier of this signal for the transaction {signal.blockchainHash}
                        </Typography>

                        <Divider sx={{ my: 2 }} />

                        <Typography gutterBottom variant="h6">Verification info</Typography>
                        <Typography variant='body2' className='slim'>
                            Here's how to read the identifier:
                        </Typography>
                        <ul>
                            <li> <Typography variant='body2' className='slim'><b className='bold'>{signal.id_instr}</b> is the ID of the instrument/stock</Typography> </li>
                            {showHorizonDescription(signal) &&
                                <li> <Typography variant='body2' className='slim'><b className='bold'>{signal.horizon}</b> is the time horizon</Typography> </li>
                            }
                            <li> <Typography variant='body2' className='slim'><b className='bold'>{signal.direction.substring(0, 1)}</b> is the signal type ({signal.direction})</Typography> </li>
                        </ul>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.closeDialog}>Close</Button>
                <Button disableElevation variant='contained' onClick={verifyContract} endIcon={<OpenInNew />}>Verify</Button>
            </DialogActions>
        </Dialog>
    )
}

export default BlockchainDialog

