import { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';

const styles = theme => ({
});

class MySkeleton extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  runCallback = (cb) => {
    return cb();
  };

  render() {
    let rows = new Array(this.props.rows);

    return (
      <div style={{ verticalAlign: 'top' }}>
        {this.props.type == 'table' ?
          (
            this.runCallback(() => {
              const row = [];
              for (var i = 0; i < this.props.rows; i++) {
                if (i == 0) {
                  row.push(<div key={i} style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '8px' }}>
                    <span style={{ width: '33%' }}></span>
                    <Skeleton style={{ width: '33%' }} height={20} animation={this.props.animation}></Skeleton>
                    <Skeleton style={{ width: '33%' }} height={20} animation={this.props.animation}></Skeleton>
                  </div>)
                }
                row.push(
                  <div key={i} style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '8px' }}>
                    <Skeleton style={{ width: '33%' }} height={33} animation={this.props.animation}></Skeleton>
                    <Skeleton style={{ width: '33%' }} height={33} animation={this.props.animation}></Skeleton>
                    <Skeleton style={{ width: '33%' }} height={33} animation={this.props.animation}></Skeleton>
                  </div>
                );
              }
              return row;
            })
          )
          :
          (this.props.type == 'chart' ?
            (<Skeleton variant='rect' height={this.props.heigt ? this.props.heigt : '100%'} animation={this.props.animation}></Skeleton>) :
            "")
        }
      </div>
    )

  }
}

export default withStyles(styles, { withTheme: true })(MySkeleton);
