import { withTheme } from '@emotion/react';
import { MoreVert } from '@mui/icons-material';
import { Box, Card, CardActions, CardContent, CardHeader, IconButton, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { api } from "../../api/apiBuilder";
import { useSelector } from 'react-redux';
import SkeletonTable from '../../elements/SkeletonTable';

const KeyValue = (props) => {
    const [loading, setLoading] = useState(false)
    const [menuTarget, setMenuTarget] = useState(null)
    const [menuOpen, setMenuOpen] = useState(false)
    const [name, setName] = useState("-")
    const [value, setValue] = useState("-")
    const [variant, setVariant] = useState(null)
    const reload = useSelector(state => state.reload)

    const fetch = () => {
        setLoading(true)
        switch (variant || props.variant) {
            case "signals":
                api.get("/v2/dashboard/avgSignalOfTheDay", { id: 'avgSignalOfTheDay' }).then(response => {
                    const data = response.data
                    setValue(new Intl.NumberFormat("en-GB", { maximumFractionDigits: 1 }).format(data.avg_daily_signal))
                }).catch(error => {

                }).finally(() => {
                    setLoading(false)
                })
                setName("Average signals per day")
                break;
            case "winrate":
                api.get("/v2/dashboard/avgWinRatio", { id: 'avgWinRatio' }).then(response => {
                    const data = response.data
                    setValue(new Intl.NumberFormat("en-GB", { style: "percent", maximumFractionDigits: 1 }).format(data.avg_win_ratio / 100))
                }).catch(error => {

                }).finally(() => {
                    setLoading(false)
                })
                setName("Average win rate")
                break;
            case "performance":
                api.get("/v2/dashboard/avgPerformance", { id: 'avgPerformance' }).then(response => {
                    const data = response.data
                    setValue(new Intl.NumberFormat("en-GB", { style: "percent", maximumFractionDigits: 3 }).format(data.avg_performance/100))
                }).catch(error => {

                }).finally(() => {
                    setLoading(false)
                })
                setName("Average performance")
                break;

            default:
                setValue("-")
                setName("-")
                break;
        }
    }

    useEffect(() => {
        //fetch()
    }, []);

    useEffect(() => {
        if(reload || variant)
            fetch()
    }, [reload, variant])

    const handleMenu = (e) => {
        setMenuTarget(e.currentTarget)
        setMenuOpen(true)
    }
    const closeMenu = () => {
        setMenuOpen(false)
    }
    const handleChange = (value) => {
        setVariant(value)
        setMenuOpen(false)
    }

    return (
        <>
            <Card sx={{ width: "100%", margin: "initial" }}>
                <CardHeader
                    sx={{ display: "flex", alignItems: "center", position: "relative" }}
                    // title={<Typography variant='overline' className='bold'>{variant}</Typography>}
                />
                <CardContent>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {loading ? <Skeleton sx={{ width: 90, height: 72 }} /> : <Typography variant='h3' className='big'>{value}</Typography>}
                        <Typography variant='caption' color={"textSecondary"}>{name}</Typography>
                    </Box>
                </CardContent>
                {/* <CardActions>
                    <IconButton sx={{ marginLeft: 'auto', }} onClick={handleMenu}><MoreVert color="default" /></IconButton>
                </CardActions> */}
            </Card>

            <Menu
                id="basic-menu"
                anchorEl={menuTarget}
                open={menuOpen}
                onClose={closeMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {["signals", "winrate", "performance"].map(obj => (
                    obj != variant &&
                    <MenuItem key={"obj"} onClick={() => handleChange(obj)} val>{obj}</MenuItem>
                ))}
            </Menu>

        </>
    );
};

export default withTheme(KeyValue);