import { Dialog, DialogContent, Button } from '@mui/material';
import { Component } from 'react';
import { DialogActions, DialogTitle } from '@mui/material';
import { withTheme } from '@emotion/react';

const styles = theme => ({
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
});

class DialogMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  changeTheme(v){
    this.setState({selectedTheme: v})
    if(v) v = 'light'
    else v = 'dark'
    this.props.changeTheme(v)
  }

  handleClose(){
    this.props.closeDialogMessage()
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <Dialog open={this.props.open} maxWidth={'xs'}>
        <DialogTitle>Title</DialogTitle>
        <DialogContent>{this.props.message}</DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()}>Ok</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTheme(DialogMessage);
