import { Box, Typography } from "@mui/material";
import Score from "./Score";

const Metric = (props) => {

  return (
    <>
      <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 1 }}><Typography variant='subtitle2'>{props.title}</Typography></Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Box sx={{ flex: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <Typography variant='h4' component={'div'} className='big' sx={{lineHeight: 'normal'}} >{props.portfolioVal}</Typography>
          <Typography variant='caption' component={'div'} color="textSecondary">Portfolio</Typography>
        </Box>
        <Box sx={{ flex: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <Typography variant='subtitle1' component={'div'}>{props.benchmarkVal}</Typography>
          <Typography variant='caption' component={'div'} color="textSecondary">Benchmark</Typography>
        </Box>
        <Box sx={{ flex: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <Score k={props.k} v={props.v} score={props.score ? props.score : 0} />
        </Box>
      </Box>
    </Box>

      {/* <Box sx={{ width: '100%' }}>
        <Box sx={{ mb: 1 }}><Typography variant='subtitle2'>{props.title}</Typography></Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Box sx={{ flex: '35%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Typography variant='h4' component={'div'} className='big'>{props.portfolioVal}</Typography>
            <Typography variant='caption' component={'div'} color="textSecondary">Portfolio</Typography>
          </Box>
          <Box sx={{ flex: '65%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Typography variant='subtitle1' component={'div'}>{props.benchmarkVal}</Typography>
            <Typography variant='caption' component={'div'} color="textSecondary">Benchmark</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', mt: .5 }}>
          <Score k={props.k} v={props.v} score={props.score ? props.score : 0} />
        </Box>
      </Box> */}
    </>
  );
}

export default Metric;