import { withTheme } from "@emotion/react";
import log from "loglevel";

function getSectorName(value) {
    log.debug(value)
    switch (value) {
        case 9:
            return "Basic Materials"
            break;
        case 19:
            return "Communication Services"
            break;
        case 4:
            return "Consumer Cyclical"
            break;
        case 25:
            return "Other"
            break;
        case 11:
            return "Energy"
            break;
        case 7:
            return "Healthcare"
            break;
        case 15:
            return "Industrials"
            break;
        case 14:
            return "Real Estate"
            break;
        case 2:
            return "Technology"
            break;
        case 1:
            return "Utilities"
            break;
        case 22:
            return "Financial Services"
            break;
        case 10:
            return "Consumer Defensive"
            break;
        default:
            return "-"
            break;
    }
}

export default getSectorName
