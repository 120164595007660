import { Box, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from "../../api/apiBuilder";
import { MoreVert, FullscreenOutlined, FullscreenExitOutlined } from '@mui/icons-material';
import { withTheme } from '@emotion/react';

const columnsInit = {
  "Splits and dividends": [
    { k: "lastDividendDate", id: 0, enabled: true, l: "Last dividend date" },
    { k: "payoutRatio", id: 0, enabled: true, l: "Payout ratio" },
    { k: "lastSplitDate", id: 0, enabled: true, l: "Last split date" },
    { k: "lastSplit", id: 0, enabled: true, l: "Last split" },
  ],
  "Highlights": [
    { k: "marketCap", id: 1, enabled: true, l: "Market cap" },
    { k: "ebitda", id: 1, enabled: true, l: "EBITDA" },
    { k: "bookValue", id: 1, enabled: true, l: "Book value" },
    { k: "eps", id: 1, enabled: true, l: "EPS" },
  ],
  "Valuation": [
    { k: "trailingPe", id: 2, enabled: true, l: "Trailing PE" },
    { k: "forwardPe", id: 2, enabled: true, l: "Forward PE" },
    { k: "priceSales", id: 2, enabled: true, l: "Price/Sales" },
    { k: "priceBook", id: 2, enabled: true, l: "Price/Book" },
  ],
}

const AssetFundamentals = (props) => {
  const [loading, setLoading] = useState(false)
  const [menuTarget, setMenuTarget] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const [comment, setComment] = useState("prc_cmt")
  const [commentTitle, setCommentTitle] = useState("Price comment")
  const [data, setData] = useState(null)
  const [expanded, setExpanded] = useState(false);
  const [columns, setColumns] = useState(columnsInit)
  const [colCount, setColCount] = useState(0)
  const lastInstrument = useSelector(state => state.lastInstrument)

  const fetch = () => {
    setLoading(true)
    api.get("/v2/dashboard/fundamentals/" + props.selectedInstrument, { id: `fundamentals-${props.selectedInstrument}` }).then((response) => {
      const data = response.data;
      setData(data);
    })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const count = (array) => {
    array.reduce((count, column) => {
      if (column.enabled) {
        return count + 1;
      } else {
        return count;
      }
    }, 0)
  }

  useEffect(() => {
    fetch()
  }, [props.selectedInstrument]);

  function handleMenu(e) {
    setMenuTarget(e.currentTarget)
    setMenuOpen(true)
  }

  const handleChange = (e) => {

  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  function handleExpanded() {
    setExpanded(!expanded);
  }

  const handleColumnsChange = (e, checked) => {
    // console.log(e.target);
    // const updatedData = JSON.parse(JSON.stringify(columns))
    // let item = updatedData.find(item => item.id == e.target.id)
    // item.enabled = checked
    // const colNum = updatedData.filter(column => column.enabled).length
    // localStorage.setItem("TA_COLS", JSON.stringify(updatedData))
    // setColCount(colNum)
    // setColumns(updatedData)
  }

  const formatData = (value) => {
    if (value === 0 || value == null) {
      return "-"; // Display "-" for zero values
    } else if (typeof value === 'number') {
      return new Intl.NumberFormat("en-GB", { notation: 'compact', maximumSignificantDigits: 4 }).format(value);
    } else if (isDateStringValid(value)) {
      return moment(new Date(value)).format("YYYY-MM-DD"); // Modify the date formatting as needed
    } else if (typeof value === 'string') {
      return value; // No special formatting for strings
    } else {
      return value; // Handle any other cases or fallback values
    }
  };

  const isDateStringValid = (dateString) => {
    // Regular expression pattern for the ISO 8601 date format
    const pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    // Check if the dateString matches the pattern
    return pattern.test(dateString);
  };

  return (
    <>
      <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', position: expanded ? "fixed" : "initial", width: "100%", maxHeight: "100%", overflowY: "auto", height: expanded ? "100%" : "100%", margin: expanded ? 0 : "initial", left: 0, top: 0, transition: 'all 0.25s ease', zIndex: expanded ? 1300 : 0 }}>
        <CardHeader
          sx={{ display: "flex", alignItems: "center", position: "relative", height: 56 }}
          title={<Typography variant='overline' className='bold'>Fundamental analysis</Typography>}
          action={
            <IconButton onClick={handleExpanded}>{expanded ? <FullscreenExitOutlined color="default" /> : <FullscreenOutlined color="default" />}</IconButton>
          }
        />
        <CardContent>
          {
            loading ? "loading..." :
              data ?
                <Grid container spacing={0}>
                  {Object.entries(columns).map(([keyName, valueObj], index) => (
                    <React.Fragment key={"fund_" + keyName}>
                      <Grid item xs={12}>
                        <Box sx={{ borderLeft: "3px solid " + props.theme.palette.primary.main, bgcolor: props.theme.palette.primary.bgrnd, mt: index > 0 ? 3 : 0, py: .5, px: 1, borderRadius: "0 2px 2px 0" }}>
                          <Typography variant='body1' className='bold' sx={{}}>{keyName}</Typography>
                        </Box>
                      </Grid>
                      {valueObj.filter(col => col.enabled).map((obj, i) => (
                        <Grid item xs={6} key={"fund_" + i}>
                          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                            <Typography variant="caption" color={"textSecondary"} sx={{ width: "50%" }}>
                              {obj.l}
                            </Typography>
                            <Typography variant="body2" sx={{ width: "50%", textAlign: "right", pr: 7 }} className="bold">
                              {formatData(data[obj.k])}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </React.Fragment>
                  ))}
                </Grid>
                : "No data"
          }
        </CardContent>
        <CardActions sx={{ marginTop: 'auto' }}>
          <IconButton disabled sx={{ marginLeft: 'auto', display: expanded ? 'none' : 'inherit' }} onClick={handleMenu}><MoreVert color="default" /></IconButton>
        </CardActions>
      </Card>

      {/* <Dialog
        maxWidth='xl'
        open={menuOpen}
        onClose={closeMenu}
      >
        <DialogTitle>Configure Technical analysis card</DialogTitle>
        <DialogContent sx={{ display: "flex" }}>
          <Box sx={{ width: "70%" }}>
            <Typography variant='body2' gutterBottom>Technical data</Typography>
            <Box sx={{ mt: 2 }}>
              <Grid container>
                {
                  columns.map((obj, i) =>
                    <Grid item xs={6} key={"ckb_" + i}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={colCount >= 6 && !obj.enabled}
                            checked={obj.enabled}
                            name={obj.l}
                            id={obj.id}
                            onChange={handleColumnsChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color='primary'
                          />
                        }
                        label={<Typography variant='body2' color={colCount >= 6 && !obj.enabled ? "textSecondary" : "default"}>{obj.l}</Typography>}
                      />
                    </Grid>
                  )}
              </Grid>
            </Box>
          </Box>
          <Box sx={{ width: "30%" }}>
            <FormControl>
              <Typography variant='body2' gutterBottom>Technical comment</Typography>
              <RadioGroup
                sx={{ mt: 2 }}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="prc_cmt"
                name="radio-buttons-group"
                onChange={handleChange}
              >
                {[
                  { l: "Price comment", v: "prc_cmt" },
                  { l: "Trend comment", v: "tnd_cmt" },
                  { l: "Volume comment", v: "vlm_cmt" },
                  { l: "Volatility comment", v: "vlt_cmt" },
                  { l: "RSI comment", v: "stc_cmt" }
                ].map(obj =>
                  <FormControlLabel checked={obj.v === comment} value={obj.v} name={obj.l} control={<Radio />} label={
                    <Typography variant='body2'>{obj.l}</Typography>
                  } />
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMenu} variant='contained' disableElevation>Close</Button>
        </DialogActions>
      </Dialog> */}

    </>
  );
};

export default withTheme(AssetFundamentals);