import { withTheme } from '@emotion/react';
import { Box, Button, Card, CardContent, Slider, SliderThumb, Typography, styled } from '@mui/material';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import SkeletonChart from './SkeletonChart';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    opacity: .3,
    height: 3,
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};


class ChartBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      chart_options: 0,
      performances: null,
      dateRangeMarks: [],
      dateRangeStart: 0,
      dateRangeEnd: 0,
      dateRangeNowStart: 0,
      dateRangeNowEnd: 0,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { performances } = this.props;
    if (prevProps !== this.props) {
      // if (performances && (performances !== prevProps.performances)) {
        this.plot()
        this.setState({ isLoading: false })
      // }

      if (this.props.selectedTheme !== prevProps.selectedTheme) {
        const componentProps = this.props
        ApexCharts.exec("EQLINE", "updateOptions", {
          legend: {
            fontFamily: this.props.theme.typography.fontFamily,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            labels: {
              colors: [this.props.theme.palette.text.primary],
              useSeriesColors: false
            },
            itemMargin: {
              vertical: 16
            },
            markers: {
              radius: 0,
              width: 24,
              height: 3,
            },
          },
          dataLabels: {
            enabled: false
          },
          colors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
          stroke: {
            width: [3, 2],
            //curve: 'smooth',
          },
          grid: {
            borderColor: this.props.theme.palette.grid,
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
              lines: {
                show: false
              }
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              show: true,
              rotate: 0,
              style: {
                colors: this.props.theme.palette.text.primary,
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },
          yaxis: {
            min: function (min) { return min },
            forceNiceScale: true,
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.[00]a') },
              style: {
                colors: [this.props.theme.palette.text.primary],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          tooltip: {
            theme: this.props.selectedTheme,
            shared: true,
            x: { show: false },
            y: {
              formatter: function (val) {
                return numeral(val).format('0,000.0[00]');
              }
            }
          }
        })
      }
    }
  }

  plot(param) {
    const performances = this.props.performances
    if (performances) {
      var p_series = performances.portfolio.series;
      var b_series = performances.benchmark.series;
      var i_series = performances.inversion.series;
      var p_name = performances.portfolio.name;
      var b_name = performances.benchmark.name;
      var p_prices = [];
      var b_prices = [];
      var points = [];
      let dateRangeMarks = []

      let dateRangeNowEnd = this.state.dateRangeNowEnd

      for (var i = 0; i < p_series.length; i++) {
        var d = (moment(p_series[i].d).format('Y-MM-DD'))

        // slider info
        // if (i == 0) this.setState({ dateRangeStart: i })
        // if (i == p_series.length - 1) this.setState({ dateRangeEnd: i })
        dateRangeMarks.push({
          value: i, label: d, dateInt: p_series[i].d
        })
        // -----

        p_prices.push({ 'x': d, 'y': p_series[i].v })
      }
      for (var i = 0; i < b_series.length; i++) {
        var d = (moment(b_series[i].d).format('Y-MM-DD'))
        b_prices.push({ 'x': d, 'y': b_series[i].v })
      }
      for (var i = 0; i < i_series.length; i++) {
        var d = (moment(i_series[i].d).format('Y-MM-DD'))
        points.push({
          x: new Date(d).getTime(),
          y: i_series[i].v,
          marker: {
            size: 4,
            fillColor: '#f00',
            strokeColor: '#f00',
            radius: 2,
          }, label: {
            borderColor: '#FF4560',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#FF4560',
            },

            text: 'inversion',
          }

        })
      }

      var series_data = [
        {
          name: p_name,
          data: p_prices
        },
        {
          name: b_name,
          data: b_prices
        }
      ];

      var options = {
        series: series_data,
        chart: {
          id: "EQLINE",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          height: 400,
          type: "line",
          stacked: false,
        },
        legend: {
          fontFamily: this.props.theme.typography.fontFamily,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          labels: {
            colors: [this.props.theme.palette.text.primary],
            useSeriesColors: false
          },
          itemMargin: {
            vertical: 16
          },
          markers: {
            radius: 0,
            width: 24,
            height: 3,
          },
        },
        annotations: {
          position: 'front',
          points: points,
        },
        animations: {
          enabled: true,
          easing: 'easein',
          speed: 250,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
        stroke: {
          width: [3, 2],
          //curve: 'smooth',
        },
        grid: {
          borderColor: this.props.theme.palette.grid,
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: false
            }
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            show: true,
            rotate: 0,
            style: {
              colors: this.props.theme.palette.text.primary,
              fontSize: '11px',
              fontFamily: 'Roboto',
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          min: function (min) { return min },
          forceNiceScale: true,
          labels: {
            show: true,
            formatter: (value) => { return numeral(value).format('0.[00]a') },
            style: {
              colors: [this.props.theme.palette.text.primary],
              fontSize: '11px',
              fontFamily: 'Roboto',
              fontWeight: 400,
            },
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
        },
        tooltip: {
          theme: this.props.selectedTheme,
          shared: true,
          x: { show: false },
          y: {
            formatter: function (val) {
              return numeral(val).format('0,000.0[00]');
            }
          }
        }
      };

      if (this.state.dateRangeMarks.length === 0) {
        this.setState({ chart_options: options, dateRangeStart: 0, dateRangeEnd: p_series.length - 1, dateRangeNowEnd: p_series.length - 1, dateRangeMarks })
      } else {
        this.setState({ chart_options: options, dateRangeNowEnd: dateRangeNowEnd ? dateRangeNowEnd : p_series.length - 1 })
      }
    }
  }

  componentDidMount() {
    this.setState({ performances: this.props.performances }, function () {
      this.plot("mount")
    })
  }

  sliderText = (value, index) => {
    const dateRangeMarks = this.state.dateRangeMarks
    if (dateRangeMarks[value])
      return moment(dateRangeMarks[value].dateInt).format('DD-MM-YYYY')
    else
      return "--"
  }

  handleSlider = (e, dateRangeNow) => {
    const start = dateRangeNow[0]
    const end = dateRangeNow[1]
    this.setState({ dateRangeNowStart: start, dateRangeNowEnd: end })
  }

  commitSlider = (e, dateRangeNow) => {
    this.setState({ isLoading: true })
    const dateRangeMarks = this.state.dateRangeMarks
    const start = this.state.dateRangeNowStart
    const end = this.state.dateRangeNowEnd

    const startDate = dateRangeMarks[start].dateInt
    const endDate = dateRangeMarks[end].dateInt
    this.props.rebuildPortfolio(startDate, endDate)
  }

  resetSlider = () => {
    const start = this.state.dateRangeStart
    const end = this.state.dateRangeEnd
    this.setState({ dateRangeNowStart: start, dateRangeNowEnd: end })
    const dateRangeMarks = this.state.dateRangeMarks
    const startDate = dateRangeMarks[start].dateInt
    const endDate = dateRangeMarks[end].dateInt
    this.props.rebuildPortfolio(startDate, endDate)
  }

  render() {
    const { chart_options, dateRangeMarks, dateRangeStart, dateRangeEnd, dateRangeNowStart, dateRangeNowEnd } = this.state;
    // console.log("min", dateRangeStart)
    // console.log("max", dateRangeEnd)
    // console.log("start", dateRangeNowStart)
    // console.log("end", dateRangeNowEnd)
    return (
      <Card variant='outlined'>
        <CardContent>
          <Typography variant='overline' gutterBottom>
            {dateRangeMarks[dateRangeNowStart] === undefined ? "-" : "Performances if strategy started on " + moment(dateRangeMarks[dateRangeNowStart].dateInt).format('D MMM YYYY')}

          </Typography>
          {this.props.isLoading ? <SkeletonChart height={300} /> : (
            chart_options.series && chart_options.series.length > 0 ? (
              <>
                <ReactApexChart annotations={this.state.chart_options.annotations} series={this.state.chart_options.series} type={this.state.chart_options.chart.type} options={this.state.chart_options} height={this.state.chart_options.chart.height}></ReactApexChart>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Typography variant='subtitle2'>Change time-frame</Typography>
                  <Typography gutterBottom variant='caption' color={'textSecondary'}>
                    From: {this.sliderText(dateRangeNowStart, 0)} / To: {this.sliderText(dateRangeNowEnd, 0)}
                  </Typography>
                  <Box sx={{ pl: 2, mt: 1, width: '100%', display: 'flex', alignItems: 'center' }}>
                    <AirbnbSlider
                      slots={{ thumb: AirbnbThumbComponent }}
                      color='primary'
                      sx={{ mr: 4 }}
                      disabled={this.props.isLoading2}
                      onChange={this.handleSlider}
                      onChangeCommitted={this.commitSlider}
                      //marks={dateRangeMarks}
                      min={dateRangeStart}
                      max={dateRangeEnd}
                      defaultValue={[dateRangeStart, dateRangeEnd]}
                      value={[dateRangeNowStart, dateRangeNowEnd]}
                      valueLabelDisplay="off"
                      step={1}
                      aria-label="Custom marks"
                      valueLabelFormat={this.sliderText}
                      getAriaValueText={this.sliderText}
                    />
                    <Button size='small' onClick={this.resetSlider} variant='outlined'>Reset</Button>
                  </Box>
                </Box>
              </>
            ) : '')}
        </CardContent>
        {/* <CardActions>
          {this.props.isLoading ? <SkeletonChart height={300} /> : (
            chart_options.series && chart_options.series.length > 0 ? (
            ) : '')}
        </CardActions> */}
      </Card>
    );
  }
}

function Chart(props) {
  const navigate = useNavigate()
  const selectedTheme = useSelector(state => state.theme)

  return <ChartBase navigate={navigate} selectedTheme={selectedTheme} {...props} />
}

export default withTheme(Chart);

