import React from 'react';
import { Box } from "@mui/material";
import { Skeleton } from '@mui/material';
import { PropaneSharp } from '@mui/icons-material';

const SkeletonListOneLine = (props) => {
    const printSkeleton = (cb) => {
        const row = [];
        for (let i = 0; i < (props.rows ? props.rows : 5); i++) {
            row.push(
                <Box key={i.toString()} sx={{display:"flex", alignItems:"center", flexDirection:"row"}} >
                    <Box width="100%">
                        <Skeleton variant="text" width="25%" />
                    </Box>
                </Box>
            );
        }
        return row;
    };

    return (
        <>{printSkeleton()}</>
    );
}

export default SkeletonListOneLine;