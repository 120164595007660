import { AddOutlined, ArrowForward } from '@mui/icons-material';
import { Alert, Box, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Fade, Grid, TextField, Toolbar, Typography, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiBraino } from '../api/apiBraino';
import AssetAdvices from '../cards/dashboard/AssetAdvices';
import AssetAnalysis from '../cards/dashboard/AssetAnalysis';
import AssetFundamentals from '../cards/dashboard/AssetFundamentals';
import AssetSummary from '../cards/dashboard/AssetSummary';
import AssetTradingView from '../cards/dashboard/AssetTradingView';
import BestPerformances from '../cards/dashboard/BestPerformances';
import BestWinRate from '../cards/dashboard/BestWinRate';
import CustomViewAdvancedSearch from '../cards/dashboard/CustomViewAdvancedSearch';
import DaySignals from '../cards/dashboard/DaySignals';
import KeyValue from '../cards/dashboard/KeyValue';
import { notifyArea, notifyCustomView, notifyInstrument } from '../dispatcher/Actions';
import WaveGraph from '../cards/dashboard/WaveGraph';
import Watchlist from '../cards/dashboard/Watchlist';
import { api } from '../api/apiBuilder';

function AssetAnalysisCards() {
    const lastInstrument = useSelector(state => state.lastInstrument)
    const dispatch = useDispatch();
    const [instrumentList, setInstrumentList] = useState([]);
    const [selectedInstrument, setSelectedInstrument] = useState(0);
    const [mounted, setMounted] = useState(false)

    const getInstrumentList = () => {
        const storedInstrumentList = localStorage.getItem('instrumentList');
        let parsedListLimited = []
        if (storedInstrumentList) {
            let parsedList = JSON.parse(storedInstrumentList)
            if (parsedList.length > 0) {
                parsedListLimited = parsedList.filter((val, i) => i < 10)
            } else {
                parsedListLimited = parsedList
            }
        }
        localStorage.setItem('instrumentList', JSON.stringify(parsedListLimited));
        setInstrumentList(parsedListLimited)
        return parsedListLimited
    }

    useEffect(() => {
        const list = getInstrumentList()
        if (list && list.length > 0) {
            if (!selectedInstrument) {
                setSelectedInstrument(list[0].id)
            }
        }
        setMounted(true)
    }, [])

    useEffect(() => {
        if (lastInstrument && lastInstrument.data) {
            console.log("1 >>", lastInstrument);
            const list = getInstrumentList()
            setSelectedInstrument(lastInstrument.data.id_instr || lastInstrument.data.id)

            const itemExists = list.find((item) => parseInt(item.id) === parseInt(lastInstrument.data.id_instr) || item.id === lastInstrument.data.id);
            console.log("2 >> esiste?", itemExists);
            if (!itemExists) {
                if ((lastInstrument.data.id_instr || lastInstrument.data.id) && lastInstrument.data.ticker) {
                    console.log("3 >> ha tutto!",);
                    const newItem = { id: lastInstrument.data.id_instr, timestamp: Date.now(), ticker: lastInstrument.data.ticker };
                    const updatedList = [...list, newItem];
                    updatedList.sort((a, b) => b.timestamp - a.timestamp);
                    setInstrumentList(updatedList)
                    localStorage.setItem('instrumentList', JSON.stringify(updatedList));
                } else {
                    console.error("Instrument without id", lastInstrument.data);
                }
            }
        }
    }, [lastInstrument])


    const changeInstrument = (id) => {
        setSelectedInstrument(id)
    }

    /**
     * Notify that all the instruments have been removed from Asset analysis
     */
    const cleanInstruments = () => {
        setSelectedInstrument(0)
        dispatch(notifyInstrument(0))
    }

    const deleteInstrument = (id) => {
        console.log(id, instrumentList, selectedInstrument);
        const filteredData = instrumentList.filter(item => parseInt(item.id) !== parseInt(id));
        setInstrumentList(filteredData)
        localStorage.setItem('instrumentList', JSON.stringify(filteredData));
        if (id === selectedInstrument && filteredData && filteredData[0]) {
            console.log(filteredData[0])
            setSelectedInstrument(filteredData[0].id)
            const uniqueId = Math.random().toString(16).slice(2);
            dispatch(notifyInstrument({ id: uniqueId, data: filteredData[0] }))
            setSelectedInstrument(filteredData[0].id)
        } else {
            cleanInstruments()
        }
    }

    return (<>
        <Grid container spacing={1} sx={{ mx: "auto" }}>
            <Grid item xs={12}>
                <Box>{
                    instrumentList.map(obj => {
                        const active = obj.id === selectedInstrument;
                        const disableDelete = false //instrumentList.length <= 1 || active;
                        return (
                            <Chip size='small' label={obj.ticker}
                                clickable={instrumentList.length > 1}
                                onDelete={disableDelete ? undefined : () => deleteInstrument(obj.id)}
                                onClick={() => changeInstrument(obj.id)}
                                color={active ? "primary" : "secondary"}
                                variant={active ? "filled" : "filled"}
                                sx={{ mr: 1, mb: 1, minWidth: 66 }} />
                        )
                    })

                }</Box>
            </Grid>
            {selectedInstrument ?
                <>
                    <Grid item xs={12}><AssetSummary instrumentList={instrumentList} selectedInstrument={selectedInstrument} /></Grid>
                    {/* <Grid item xs={12}><AssetHistory selectedInstrument={selectedInstrument} /></Grid> */}
                    <Grid item xs={12}><AssetAdvices selectedInstrument={selectedInstrument} /></Grid>
                    <Grid container item spacing={1}>
                        <Grid item xs={6}><AssetAnalysis selectedInstrument={selectedInstrument} /></Grid>
                        <Grid item xs={6}><AssetFundamentals selectedInstrument={selectedInstrument} /></Grid>
                    </Grid>
                    <Grid item xs={12}><AssetTradingView selectedInstrument={selectedInstrument} /></Grid>
                </> :
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: 400 }}>
                        <Typography variant='h6' gutterBottom>You have no asset under analysis</Typography>
                        <Typography variant='caption' color={"textSecondary"}>Go back to "Custom Views" and select an asset</Typography>
                    </Box>
                </Grid>
            }
        </Grid>
    </>)
}

const CustomViews = () => {
    const [selected, setSelected] = useState(1)
    const [fab, setFab] = useState(false)
    const reload = useSelector(state => state.reload)
    const dispatch = useDispatch()

    useEffect(() => {
        if (reload) {
            if (selected === 1) setFab(true)
            else setFab(false)
            // setSelected(1)
            // setFab(true)
        } else {
            setFab(false)
        }
    }, [reload])

    useEffect(() => {
        setSelected(1)
        setFab(false)
    }, [])

    const handleChips = (v) => {
        dispatch(notifyCustomView(v))
        //if(v == 2) dispatch(notifyReload(false))
        setSelected(v)
    }

    const showSignals = () => {
        setSelected(2)
        setFab(false)
        //dispatch(notifyReload(false))
    }

    return (
        <>
            <Zoom in={fab} timeout={300}>
                <Fab size='large' variant='extended' color='cta' sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={showSignals}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        Signals
                        <ArrowForward sx={{ ml: 1 }} />
                    </Box>
                </Fab>
            </Zoom>
            <Grid container spacing={1} sx={{ mx: "auto" }}>
                <Grid item xs={12}>
                    <CustomViewAdvancedSearch />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                        {[
                            { 'l': "Historical stats", 'v': 1 },
                            { 'l': "Today signals", 'v': 2 },
                        ].map((item) => {
                            const checked = selected === item.v;
                            return (
                                <Chip
                                    sx={{ width: "auto" }}
                                    size='small'
                                    key={item.l}
                                    variant={checked ? 'filled' : 'filled'}
                                    color={checked ? 'primary' : 'secondary'}
                                    label={item.l}
                                    onClick={() => {
                                        handleChips(item.v);
                                    }}
                                >

                                </Chip>
                            );
                        })}
                    </Box>
                </Grid>
                {/* Statisticals Area */}
                <Grid item xs={4} sx={{ display: selected === 1 ? "block" : "none" }}><KeyValue variant="signals" /></Grid>
                <Grid item xs={4} sx={{ display: selected === 1 ? "block" : "none" }}><KeyValue variant="winrate" /></Grid>
                <Grid item xs={4} sx={{ display: selected === 1 ? "block" : "none" }}><KeyValue variant="performance" /></Grid>
                <Grid item xs={6} xl={6} sx={{ display: selected === 1 ? "block" : "none" }}> <BestPerformances /> </Grid>
                <Grid item xs={6} xl={6} sx={{ display: selected === 1 ? "block" : "none" }}> <BestWinRate /> </Grid>
                <Grid item xs={12} xl={12} sx={{ display: selected === 1 ? "block" : "none" }}> <WaveGraph /> </Grid>
                {/* Signals Area */}
                <Grid item xs={12} xl={12} sx={{ display: selected === 2 ? "block" : "none" }}> <DaySignals /> </Grid>
            </Grid>
        </>)
}

function WatchlistsCards(props) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [name, setName] = useState('')
    const [selectedWatchlist, setSelectedWatchlist] = useState(null)
    const [error, setError] = useState(null);
    const [active, setActive] = useState(false);
    

    useEffect(() => {
        if (props.view === "watchlists") {
            loadWatchlists()
            setActive(true)
        } else {
            setActive(false)
        }
    }, [props.view])

    useEffect(() => {
        if (dialogOpen === false) {
            setName('')
            setError('')
        }
    }, [dialogOpen])

    const loadWatchlists = (id) => {
        api.get("/v1/dashboard/watchlists", { cache: false })
            .then(response => {
                const data = response.data;
                setData(data)
                if (id)
                    setSelectedWatchlist(id)
                else if (data && data.length > 0)
                    setSelectedWatchlist(data[0].id)
                setLoading(false)
            }).catch(error => {
                console.log(error);
                setLoading(false)
            }).finally(() => {
            })
    }

    const handleSwitch = (v) => {
        setSelectedWatchlist(v)
    }

    const handleCreate = () => {
        setDialogOpen(true)
    }

    const handleChange = (e) => {
        setName(e.target.value)
    }

    const handlePost = () => {
        if (name) {
            api.post("/v1/dashboard/watchlists", { name }, { cache: false })
                .then(response => {
                    const data = response.data;
                    loadWatchlists()
                    setLoading(false)
                    setDialogOpen(false)
                }).catch(error => {
                    if (error.response.status == 409) {
                        setError('Maximum number of watchlists has already been reached.')
                    } else{
                        setError('Server error.')
                    }
                    console.log(error);
                    setLoading(false)
                }).finally(() => {
                })
        }
    }

    return (<>
        <Grid container spacing={1} sx={{ mx: "auto" }}>
            <Grid item xs={8}>
                <Box>{
                    data && data.map(obj => {
                        const selected = obj.id === selectedWatchlist;
                        return (
                            <Chip key={'wlist_' + obj.id} size='small' label={obj.name}
                                clickable
                                id={obj.id}
                                variant={selected ? 'filled' : 'filled'}
                                color={selected ? 'primary' : 'secondary'}
                                onClick={() => handleSwitch(obj.id)}
                                sx={{ mr: 1, mb: 1, minWidth: 80 }} />
                        )
                    })
                }</Box>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                <Button variant='contained' disableElevation color='cta' size='small' startIcon={<AddOutlined />} onClick={handleCreate}>Add watchlist</Button>
            </Grid>
            <Grid item xs={12}><Watchlist idWatchlist={selectedWatchlist} reload={loadWatchlists} active={active} /></Grid>
        </Grid>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth='sm'>
            <DialogTitle>New watchlist</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    sx={{ mt: 1 }}
                    value={name}
                    onChange={handleChange}
                    label='Insert watchlist name'
                />
                {error &&
                    <Alert severity='error' sx={{ mt: 1 }}>
                        <Typography>{error}</Typography>
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePost}>Confirm</Button>
            </DialogActions>
        </Dialog>
    </>)
}

const Dashboard = (props) => {
    const [view, setView] = useState("customViews")
    const reload = useSelector(state => state.reload)
    const dispatch = useDispatch()
    const lastInstrument = useSelector(state => state.lastInstrument)

    const handleChange = (event, nextView) => {
        if (event.target.value)
            setView(event.target.value);
    };

    useEffect(() => {
        let config = { email: process.env.REACT_APP_API_BRAINO_KEY, password: process.env.REACT_APP_API_BRAINO_SECRET };
        apiBraino.post("/login", config, { cache: false }).then(response => {
            const data = response.data;
            const bToken = data.msg.TOKEN
            const bId = data.msg.ID

            sessionStorage.setItem("BTOKEN", bToken)
            sessionStorage.setItem("BID", bId)
        }).catch(error => {
            console.error(error);
        }).finally(() => {
        })

        dispatch(notifyArea("dashboard"))
    }, [])

    useEffect(() => {
        if (lastInstrument && lastInstrument.id) {
            setView("assetAnalysis")
            window.scrollTo(0, 0);
        } else {
            setView("customViews")
            window.scrollTo(0, 0);
        }

        return () => {
            setView("customViews")
        }
    }, [lastInstrument])

    return (
        <Fade in={true} timeout={450} >
            <div>
                <Toolbar />
                <Container maxWidth='xl'>
                    <Box>
                        <Box sx={{ display: "flex", columnGap: 1, mx: "auto", px: 1, mb: 1 }}>
                            <Button onClick={handleChange} variant={view === "customViews" ? "contained" : "text"} disableElevation value="customViews" sx={{ minWidth: 150 }}>
                                Custom views
                            </Button>
                            <Button onClick={handleChange} variant={view === "assetAnalysis" ? "contained" : "text"} disableElevation value="assetAnalysis" sx={{ minWidth: 150 }}>
                                Asset analysis
                            </Button>
                            <Button onClick={handleChange} variant={view === "watchlists" ? "contained" : "text"} disableElevation value="watchlists" sx={{ minWidth: 150 }}>
                                Watchlists
                            </Button>
                        </Box>

                        <Fade in={view === 'assetAnalysis'} timeout={400}>
                            <Box sx={{ py: 1, maxWidth: "100%", display: view === 'assetAnalysis' ? 'block' : 'none' }}>
                                {view === 'assetAnalysis' && <AssetAnalysisCards />}
                            </Box>
                        </Fade>

                        <Fade in={view === "customViews"} timeout={400}>
                            <Box sx={{ py: 1, maxWidth: "100%", display: view === 'customViews' ? 'block' : 'none' }}>
                                <CustomViews />
                            </Box>
                        </Fade>

                        <Fade in={view === "watchlists"} timeout={400}>
                            <Box sx={{ py: 1, maxWidth: "100%", display: view === 'watchlists' ? 'block' : 'none' }}>
                                <WatchlistsCards view={view} />
                            </Box>
                        </Fade>
                    </Box>
                </Container>
            </div>
        </Fade>
    );
};

export default Dashboard;