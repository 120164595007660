import { Card, CardContent, Typography, Box, CardHeader, IconButton } from '@mui/material';
import { Component } from 'react';
import numeral from 'numeral';
import { withTheme } from '@emotion/react';
import { HelpOutline } from '@mui/icons-material';
import SkeletonList from './SkeletonList';
import Metric from '../commons/Metric';
import DialogHelpMetricsGeneral from '../commons/DialogHelpMetricsGeneral';
import DialogHelpMetricsRisk from '../commons/DialogHelpMetricsRisk';

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summary: props.summary
    }
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  toggleHelp(v) {
    switch (v) {
      case 1:
        this.setState({ helpMetricsGeneralOpen: true });
        break;
      case 2:
        this.setState({ helpMetricsRiskOpen: true });
        break;

      default:
        break;
    }
  }

  closeHelp(v) {
        this.setState({ helpMetricsGeneralOpen: false, helpMetricsRiskOpen: false });
  }

  render() {
    const { summary, version } = this.props;
    const { helpMetricsGeneralOpen, helpMetricsRiskOpen } = this.state;
    return (
      <>
        <DialogHelpMetricsGeneral open={helpMetricsGeneralOpen} closeDialog={() => this.closeHelp(1)} />
        <DialogHelpMetricsRisk open={helpMetricsRiskOpen} closeDialog={() => this.closeHelp(2)} />
        <Card>
          <CardHeader
            title={<Typography variant='overline' gutterBottom>
              {version == 'general' ? "Portfolio metrics" : "Risk metrics"}
            </Typography>}
            action={<IconButton onClick={() => this.toggleHelp(version == 'general' ? 1 : 2)}><HelpOutline color='default' /></IconButton>}
          />
          <CardContent>
            {this.props.isLoading ?
              <SkeletonList />
              : (
                summary ?
                  <>
                    {version == 'general' ?
                      <>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                          <Box sx={{ flex: '50%', display: 'flex' }}>
                            <Metric title={'Annualized Return'}
                              k={'annReturn'}
                              v={summary.portfolio.annReturn}
                              portfolioVal={numeral(summary.portfolio.annReturn * 100).format('+0.00') + '%'}
                              benchmarkVal={numeral(summary.benchmark.annReturn * 100).format('+0.00') + '%'}
                            />
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                          <Box sx={{ flex: '50%', display: 'flex' }}>
                            <Metric title={'Sharpe'}
                              k={'sharpe'}
                              v={summary.portfolio.sharpe}
                              portfolioVal={numeral(summary.portfolio.sharpe).format('0.00')}
                              benchmarkVal={numeral(summary.benchmark.sharpe).format('0.00')}
                            />
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                          <Box sx={{ flex: '50%', display: 'flex' }}>
                            <Metric title={'Martin Ratio'}
                              k={'martin'}
                              v={summary.portfolio.martin}
                              portfolioVal={numeral(summary.portfolio.martin).format('0.00')}
                              benchmarkVal={numeral(summary.benchmark.martin).format('0.00')}
                            />
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                          <Box sx={{ flex: '50%', display: 'flex' }}>
                            <Metric title={'Alpha'}
                              k={'alpha'}
                              v={summary.portfolio.alpha}
                              portfolioVal={numeral(summary.portfolio.alpha * 100).format('+0.00') + '%'}
                              benchmarkVal="N/D"
                            />
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                          <Box sx={{ flex: '50%', display: 'flex' }}>
                            <Metric title={'Beta'}
                              k={'beta'}
                              v={summary.portfolio.beta}
                              portfolioVal={numeral(summary.portfolio.beta).format('0.00')}
                              benchmarkVal="N/D"
                            />
                          </Box>
                        </Box>
                      </>
                      :
                      <>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                          <Box sx={{ flex: '50%', display: 'flex' }}>
                            <Metric title={'Volatility'}
                              k={'volatility'}
                              v={summary.portfolio.volatility}
                              portfolioVal={numeral(summary.portfolio.volatility * 100).format('0.00') + '%'}
                              benchmarkVal={numeral(summary.benchmark.volatility * 100).format('0.00') + '%'}
                            />
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                          <Box sx={{ flex: '50%', display: 'flex' }}>
                            <Metric title={'Max drawdown'}
                              k={'maxDrawdown'}
                              v={summary.portfolio.maxDrawdown}
                              portfolioVal={numeral(summary.portfolio.maxDrawdown * 100).format('0.00') + '%'}
                              benchmarkVal={numeral(summary.benchmark.maxDrawdown * 100).format('0.00') + '%'}
                            />
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                          <Box sx={{ flex: '50%', display: 'flex' }}>
                            <Metric title={'VaR@95% (10d)'}
                              k={'var'}
                              v={summary.portfolio.var}
                              portfolioVal={numeral(summary.portfolio.var * 100).format('0.00') + '%'}
                              benchmarkVal={numeral(summary.benchmark.var * 100).format('0.00') + '%'}
                            />
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                          <Box sx={{ flex: '50%', display: 'flex' }}>
                            <Metric title={'Ulcer Index'}
                              k={'ulcer'}
                              v={summary.portfolio.ulcer}
                              portfolioVal={numeral(summary.portfolio.ulcer * 100).format('0.00') + '%'}
                              benchmarkVal={numeral(summary.benchmark.ulcer * 100).format('0.00') + '%'}
                            />
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: 4 }}>
                          <Box sx={{ flex: '50%', display: 'flex' }}>
                            <Metric title={'Beta Bear'}
                              k={'betaBear'}
                              v={summary.portfolio.betaBear}
                              portfolioVal={numeral(summary.portfolio.betaBear).format('0.00')}
                              benchmarkVal="N/D"
                            />
                          </Box>
                        </Box>
                      </>
                    }
                  </>
                  : 'No data')
            }
          </CardContent>
        </Card>
      </>
    );
  }
}

export default withTheme(Report);
