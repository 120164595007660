import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";
import numeral from 'numeral';
import moment from 'moment';
import { Button, CardHeader, Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Snackbar, Tooltip, Typography } from '@mui/material';
import { ArchiveOutlined, DeleteOutlined, Edit, SsidChartOutlined, Tune } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';
import { Box, Card, CardContent } from '@mui/material';
import { styled } from '@material-ui/styles';
import MyEditor from '../commons/MyEditor';
import { TuneOutlined } from '@mui/icons-material';
import { withTheme } from '@emotion/react';
import SkeletonChart from './SkeletonChart';
import SkeletonList from './SkeletonList';
import SkeletonListOneLine from './SkeletonListOneLine';
import { cancel, restore, store } from '../commons/Memo';
import DialogConfirm from '../commons/DialogConfirm';
import { api } from "../api/apiBuilder";
import { useNavigate } from 'react-router';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ApexCharts from "apexcharts";

const Expand = styled((props) => {
  const { expand, ...other } = props;
  if (!expand)
    return <Tooltip title={'Settings'}><IconButton {...other} /></Tooltip>;
  else return <IconButton {...other}><Tune color='primary' /></IconButton>
})(({ expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  /*transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),*/
}));

class PortfolioEquityLineBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading1: false,
      isLoading2: false,
      chart_options: 0,
      series_data: [],
      performances: props.performances,
      data1: null,
      collapse: false,
      collapsedHeight: 110,
      editorType: 1,
      editorOpen: false,
      editorPtf: 0,
      signalsOpen: false,
      confirmOpen: false,
      snackOpen: false,
      snackMessage: false,
      deleting: false,
      shouldRedirect: false,
      portfolio: 0,
    }
  }

  exportPdf = () => {
    this.setState({ shouldRedirect: true });
  }

  collapse() {
    this.setState({ collapse: !this.state.collapse })
  }

  openDialog(type) {
    this.setState({ editorType: type, editorOpen: true, editorSettings: this.state.data1.settings })
  }

  closeDialog() {
    this.setState({ editorOpen: false })
    this.loadReturn()
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.portfolio != prevProps.portfolio && this.props.portfolio) {
        this.setState({ isLoading1: true, isLoading2: true })
        this.loadReturn()
        this.loadEqLine()
      }

      if (this.props.selectedTheme !== prevProps.selectedTheme) {
        console.log("tema cambiato!", this.props.selectedTheme);
        ApexCharts.exec("CHARTID", "updateOptions", {
          noData: {
            text: "Not enough data available",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: this.props.theme.palette.text.primary,
              fontSize: '14px',
              fontFamily: 'Roboto',
              fontWeight: 400,
            }
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            labels: {
              colors: [this.props.theme.palette.text.primary],
              useSeriesColors: false
            },
            markers: {
              radius: 0,
              width: 24,
              height: 3,
            },
            itemMargin: {
              vertical: 16
            },
          },
          colors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
          grid: {
            borderColor: this.props.theme.palette.grid,
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
              lines: {
                show: false
              }
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              show: true,
              rotate: 0,
              style: {
                colors: this.props.theme.palette.text.primary,
                fontSize: '12px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },
          yaxis: {
            logarithmic: false,
            min: function (min) { return min },
            max: function (max) { return max },
            tickAmount: 3,
            forceNiceScale: true,
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.[00]a') },
              style: {
                colors: [this.props.theme.palette.text.primary],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          tooltip: {
            theme: this.props.selectedTheme,
            shared: true,
            x: {
              show: true,
              formatter: function (val) {
                return moment(val).format('D MMM Y');
              }
            },
            y: {
              formatter: function (val) {
                return numeral(val).format('0,000.0[0]');
              }
            }
          }
        })
      }
    }

  }

  componentDidMount() {
    numeral.options.scalePercentBy100 = true;
    if (this.props.portfolio) {
      this.setState({ isLoading1: true, isLoading2: true })
      this.loadReturn()
      this.loadEqLine()
    }
  }

  loadReturn() {
    let response = restore("PF_RET_" + this.props.portfolio)
    if (response) {
      const data1 = response.data;
      this.setState({ data1 })
      this.setState({ isLoading1: false })
    } else {
      api.get('/v1/builder/portfolios/' + this.props.portfolio + '/return/', { cache: false })
        .then(response => {
          // store("PF_RET_" + this.props.portfolio, response)
          const data1 = response.data;
          this.setState({ data1 })
          this.setState({ isLoading1: false })
        }).catch(error => {
          this.setState({ isLoading1: false })
          if (error.response) {
            console.log('Error 1', error.message);
            if (error.response.status == 401) {
            }
            this.state.snackMessage = error.response.data;
            this.state.snackOpen = true;
          } else if (error.request) {
            // The request was made but no response was received
            console.log('Error 2', error.message);
            this.state.snackMessage = error.message;
            this.state.snackOpen = true;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error 3', error.message);
            this.state.snackMessage = error.message;
            this.state.snackOpen = true;
          }
        })
    }
  }

  handleData1(response) {
  }

  loadEqLine() {
    let response = restore("PF_EQLINE_" + this.props.portfolio)
    if (response) {
      this.handleData2(response)
    } else {
      api.get('/v1/builder/portfolios/' + this.props.portfolio + '/eqline/', { cache: false })
        .then(response => {
          // store("PF_EQLINE_" + this.props.portfolio, response)
          this.handleData2(response)
        }).catch(error => {
          this.setState({ isLoading2: false })
          if (error.response) {
            console.log('Error 1', error.message);
            if (error.response.status == 401) {
            }
            this.state.snackMessage = error.response.data;
            this.state.snackOpen = true;
          } else if (error.request) {
            // The request was made but no response was received
            console.log('Error 2', error.message);
            this.state.snackMessage = error.message;
            this.state.snackOpen = true;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error 3', error.message);
            this.state.snackMessage = error.message;
            this.state.snackOpen = true;
          }
        })
    }
  }

  handleData2(response) {
    const data = response.data;
    var p_prices = [];
    var b_prices = [];
    var points = [];
    var series = data.series;
    var names = data.names;
    for (var i = 0; i < series.length; i++) {
      var d = (moment(series[i].date_val).format('Y-MM-DD'))
      p_prices.push({ 'x': d, 'y': series[i].pf_val * 1 })
      b_prices.push({ 'x': d, 'y': series[i].bm_val * 1 })
      // p_prices.push({ 'x': d, 'y': Math.log10(series[i].pf_val * 1) })
      // b_prices.push({ 'x': d, 'y': Math.log10(series[i].bm_val * 1) })
    }

    for (var i = 0; i < series.length; i++) {
      if (series[i].inv_val) {
        var d = (moment(series[i].date_val).format('Y-MM-DD'))
        points.push({
          x: new Date(d).getTime(),
          y: series[i].inv_val,
          marker: {
            size: 4,
            fillColor: '#fc6979',
            strokeColor: '#fc6979',
            radius: 2,
          }, label: {
            borderColor: '#fc6979',
            offsetY: 0,
            style: {
              color: '#fff',
              background: '#fc6979',
            },

            text: 'inversion',
          }

        })
      }
    }

    var series_data = [
      {
        name: names.portfolio,
        data: p_prices
      },
      {
        name: names.benchmark,
        data: b_prices
      }
    ];

    var options = {
      noData: {
        text: "Not enough data available",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: this.props.theme.palette.text.primary,
          fontSize: '14px',
          fontFamily: 'Roboto',
          fontWeight: 400,
        }
      },
      series: series_data,
      chart: {
        id: "CHARTID",
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        height: '400',
        type: "line",
        stacked: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        labels: {
          colors: [this.props.theme.palette.text.primary],
          useSeriesColors: false
        },
        markers: {
          radius: 0,
          width: 24,
          height: 3,
        },
        itemMargin: {
          vertical: 16
        },
      },
      annotations: {
        position: 'front',
        points: points,
      },
      dataLabels: {
        enabled: false
      },
      colors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
      stroke: {
        width: [3, 2],
        curve: 'smooth',
      },
      grid: {
        borderColor: this.props.theme.palette.grid,
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false
          }
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: true,
          rotate: 0,
          style: {
            colors: this.props.theme.palette.text.primary,
            fontSize: '12px',
            fontFamily: 'Roboto',
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        logarithmic: false,
        min: function (min) { return min },
        max: function (max) { return max },
        tickAmount: 3,
        forceNiceScale: true,
        labels: {
          show: true,
          formatter: (value) => { return numeral(value).format('0.[00]a') },
          style: {
            colors: [this.props.theme.palette.text.primary],
            fontSize: '11px',
            fontFamily: 'Roboto',
            fontWeight: 400,
          },
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },
      tooltip: {
        theme: this.props.selectedTheme,
        shared: true,
        x: {
          show: true,
          formatter: function (val) {
            return moment(val).format('D MMM Y');
          }
        },
        y: {
          formatter: function (val) {
            return numeral(val).format('0,000.0[0]');
          }
        }
      }
    };
    this.setState({ chart_options: options, series_data, isLoading2: false });
  }

  signalsOpen() {
    this.setState({ signalsOpen: true })
  }

  signalsClose() {
    this.setState({ signalsOpen: false })
  }

  confirmOpen(type) {
    if (type) this.setState({ deleting: 1 })
    else this.setState({ deleting: 0 })
    this.setState({ confirmOpen: true })
  }

  confirmClose() {
    this.setState({ confirmOpen: false })
  }

  snackClose() {
    this.setState({ snackOpen: false });
  }

  confirm() {
    let deleting = this.state.deleting
    if (deleting) this.delete()
    else this.archive()
  }

  archive() {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('TOKEN')
      }
    }
    api.put('/v1/builder/portfolios/' + this.props.portfolio, { param: 'archived', value: 1 }, config)
      .then(response => {
        this.setState({ snackMessage: 'Portfolio archived', snackOpen: true })
        cancel('PF_LIST')
        cancel('PF_ARCH_LIST')
        cancel("PF_RET_" + this.props.portfolio)
        this.props.navigate("/archived/" + this.props.portfolio)
      }).catch(error => {
        cancel('PF_LIST')
        // this.props.navigate("/home/")
        if (error.response) {
          console.log('Error 1', error.message);
          if (error.response.status == 401) {
          }
          this.state.snackMessage = error.response.data;
          this.state.snackOpen = true;
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error 2', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error 3', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        }
      })
  }

  delete() {
    this.setState({ isLoading1: true, isLoading2: true })
    let config = {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('TOKEN')
      }
    }
    api.delete('/v1/builder/portfolios/' + this.props.portfolio, config)
      .then(response => {
        this.setState({ snackMessage: 'Portfolio deleted', snackOpen: true })
        cancel('PF_ARCH_LIST')
        this.props.navigate("/archive/")
      }).catch(error => {
        cancel('PF_LIST')
        this.props.navigate("/archive/")
        this.setState({ isLoading1: false, isLoading2: false })
        if (error.response) {
          console.log('Error 1', error.message);
          if (error.response.status == 401) {
          }
          this.state.snackMessage = error.response.data;
          this.state.snackOpen = true;
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error 2', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error 3', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        }
      })
  }

  render() {
    if (this.state.shouldRedirect) {
      return <Navigate to={`/portfolio-export/${this.props.portfolio}`} target="_blank" rel="noopener noreferrer" />
    }
    const { classes } = this.props;
    const { chart_options, series_data, data1, isLoading1, isLoading2, editorType, editorOpen, editorSettings, signalsOpen, confirmOpen, snackOpen, snackMessage, deleting } = this.state;

    numeral.options.scalePercentBy100 = true;

    let startDate = data1 && data1.result ? moment(data1.result.start_date, "YYYYMMDD").format('D MMM Y') : '-'
    let totalReturn = data1 && data1.result ? numeral(data1.result.totalReturn * 100).format('+0,000.00') : '-'
    let bReturn = data1 && data1.result ? numeral(data1.result.bReturn).format('+0,000.00%') : '-'
    let deltaReturn = data1 && data1.result ? (data1.result.totalReturn - data1.result.bReturn) : 0
    let excessReturn = data1 && data1.result ? numeral(data1.result.totalReturn - data1.result.bReturn).format('+0,000.00%') : '-'
    let name = data1 && data1.result ? data1.result.name : '-'
    let bName = data1 && data1.result ? data1.result.bName : '-'

    let capital = data1 && data1.settings ? numeral(data1.settings.initial_capital).format('0,000.00') : '-'
    let currency = data1 && data1.settings ? data1.settings.currency : '-'
    let markets = data1 && data1.markets ? data1.markets.join(", ") : '-'
    let sectors = data1 && data1.sectors ? data1.sectors.join(", ") : '-'
    let size = data1 && data1.settings ? data1.settings.size : '-'
    let quality = data1 && data1.settings ? data1.settings.quality : '-'
    let type = data1 && data1.settings ? data1.settings.type : '-'
    switch (type) {
      case 'short':
        type = "Long and short"
        break;
      case 'long':
        type = "Long only"
        break;
    }

    return (
      <>
        <Snackbar
          open={snackOpen}
          autoHideDuration={5000}
          message={snackMessage}
          onClose={() => this.snackClose()}
        >
        </Snackbar>
        <DialogConfirm
          title={deleting ? "Delete portfolio?" : "Archive portfolio?"}
          open={confirmOpen}
          message={"Are you sure you want to delete this portfolio? If you proceed, your portfolio will be permanently deleted."}
          cancel={() => this.confirmClose()}
          confirm={() => this.confirm()} />
        {/* <PortfolioSignalsDialog open={signalsOpen} close={() => this.signalsClose()} portfolio={this.props.portfolio}></PortfolioSignalsDialog> */}
        <MyEditor isArchived={data1 ? data1.result.archived : false} editorType={editorType} open={editorOpen} portfolio={this.props.portfolio} editorSettings={editorSettings} closeDialog={() => this.closeDialog()} />
        <Card variant='outlined'>
          <CardHeader sx={{ pb: 0 }}
            title={<Typography variant='h6'>{
              isLoading1 ?
                <SkeletonListOneLine rows='1' /> :
                "Portfolio \"" + name + "\"" + (data1 && data1.result.archived ? " (ARCHIVED)" : "")

            }</Typography>}
            action={
              <>
                {isLoading1 ? "" :
                  data1 && data1.result.archived ?
                    <Tooltip title='Delete this portfolio'>
                      <IconButton color='error' onClick={() => this.confirmOpen(1)}><DeleteOutlined /></IconButton>
                    </Tooltip>
                    : <Tooltip title='Archive this portfolio'>
                      <IconButton color={'short'} onClick={() => this.confirmOpen(0)}><ArchiveOutlined /></IconButton></Tooltip>
                }
                {/* Download PDF */}
                {/* <Tooltip title="Download PDF">
                  <IconButton color="primary"
                    onClick={() => this.exportPdf()}>
                    <FileDownloadOutlined />
                  </IconButton>
                </Tooltip> */}

                <Expand
                  expand={this.state.collapse}
                  onClick={() => this.collapse()}
                  aria-expanded={this.state.collapse}
                  aria-label="show more"
                ><TuneOutlined color="default" /></Expand>
              </>
            }>

          </CardHeader>
          <CardContent>
            {
              isLoading1 ? <SkeletonList rows={1} /> :
                data1 && data1.result ? (
                  <>
                    <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-start', p: 0, mb: 4 }}>
                      <Box sx={{ mr: 7 }}>
                        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                          <Typography variant='h3' className={'big'}>{totalReturn}</Typography>
                          <Typography sx={{ ml: 0 }} variant='h5' className={'big'} >%</Typography>
                        </Box>
                        <Typography variant='caption' color='textSecondary'>Return from {startDate}</Typography>
                      </Box>
                      <Box sx={{ mr: 7, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                        {deltaReturn >= 0 ?
                          <Typography variant='h5' style={{ color: green[300] }} className={'big'}>{excessReturn}</Typography>
                          : <Typography variant='h5' style={{ color: red[300] }} className={'big'}>{excessReturn}</Typography>
                        }
                        <Typography variant='caption' color='textSecondary'>Excess return</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                        <Typography variant='h5' className={'big'}>{bReturn}</Typography>
                        <Typography variant='caption' color='textSecondary'>{bName}</Typography>
                      </Box>
                    </Box>
                    <Collapse in={this.state.collapse} collapsedSize={0}>
                      <Box sx={{ p: 1, borderRadius: 2, mb: 2 }}>
                        <Typography variant='overline' component={'div'}>Settings</Typography>
                        <List dense>
                          <ListItem><ListItemText primary={<Typography variant='subtitle2'>Portfolio Name</Typography>} secondary={name} />
                            {!data1.result.archived ?
                              <ListItemSecondaryAction><IconButton color='primary' title='Edit' onClick={() => this.openDialog('name')}><Edit /></IconButton></ListItemSecondaryAction>
                              : ""
                            }
                          </ListItem>
                          <ListItem><ListItemText primary={<Typography variant='subtitle2'>Start date</Typography>} secondary={startDate} /></ListItem>
                          <ListItem><ListItemText primary={<Typography variant='subtitle2'>Initial capital</Typography>} secondary={capital} /></ListItem>
                          <ListItem><ListItemText primary={<Typography variant='subtitle2'>Currency</Typography>} secondary={currency} /></ListItem>
                          <ListItem>
                            <ListItemText sx={{ mr: 4 }} primary={<Typography variant='subtitle2'>Exchanges</Typography>} secondary={markets} />
                          </ListItem>
                          <ListItem>
                            <ListItemText sx={{ mr: 4 }} primary={<Typography variant='subtitle2'>Sectors</Typography>} secondary={sectors} />
                            {!data1.result.archived ?
                              <ListItemSecondaryAction><IconButton color='primary' title='Edit' onClick={() => this.openDialog('sectors')}><Edit /></IconButton></ListItemSecondaryAction>
                              : ""
                            }
                          </ListItem>
                          <ListItem>
                            <ListItemText sx={{ mr: 4 }} primary={<Typography variant='subtitle2'>Portfolio size</Typography>} secondary={size} r />
                            {!data1.result.archived ?
                              <ListItemSecondaryAction><IconButton color='primary' title='Edit' onClick={() => this.openDialog('sizes')}><Edit /></IconButton></ListItemSecondaryAction>
                              : ""
                            }
                          </ListItem>
                          <ListItem>
                            <ListItemText sx={{ mr: 4 }} primary={<Typography variant='subtitle2'>Signals strength</Typography>} secondary={quality} />
                            {!data1.result.archived ?
                              <ListItemSecondaryAction><IconButton color='primary' title='Edit' onClick={() => this.openDialog('strenghts')}><Edit /></IconButton></ListItemSecondaryAction>
                              : ""
                            }
                          </ListItem>
                          <ListItem>
                            <ListItemText sx={{ mr: 4 }} primary={<Typography variant='subtitle2'>Strategy</Typography>} secondary={type} />
                            {!data1.result.archived ?
                              <ListItemSecondaryAction><IconButton color='primary' title='Edit' onClick={() => this.openDialog('strategies')}><Edit /></IconButton></ListItemSecondaryAction>
                              : ""
                            }
                          </ListItem>
                        </List>
                        <Box sx={{ textAlign: 'right' }}><Button variant='contained' onClick={() => this.collapse()}>Close</Button></Box>
                      </Box>
                    </Collapse>
                  </>
                ) : "---"
            }

            <Typography variant='overline'>equity line</Typography>
            {isLoading2 ? <SkeletonChart height={415} /> :
              chart_options ? (
                <ReactApexChart annotations={chart_options.annotations} series={series_data} type={chart_options.chart.type} options={chart_options} height={chart_options.chart.height} />
              ) :
                <Box sx={{ p: 3 }}>
                  <SsidChartOutlined></SsidChartOutlined>
                  <Typography variant='body1' color='textSecondary'>No data</Typography>
                </Box>
            }
          </CardContent>
        </Card>
      </>
    );
  }
}

function PortfolioEquityLine(props) {
  const navigate = useNavigate()
  const selectedTheme = useSelector(state => state.theme)

  return <PortfolioEquityLineBase navigate={navigate} selectedTheme={selectedTheme} {...props} />
}

export default withTheme(PortfolioEquityLine);