import { withTheme } from '@emotion/react';
import { LockOutlined, PersonOutline } from '@mui/icons-material';
import { Alert, Box, Button, Card, CardContent, CircularProgress, Container, FormControl, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { api } from "../api/apiBuilder";
import secureLocalStorage from 'react-secure-storage';
import { notifyPerms, notifyTheme } from '../dispatcher/Actions';
import { useDispatch } from 'react-redux';
import packageJson from '../../package.json';

const messages = {
  login_errcred: 'Invalid credentials'
}

const Login = (props) => {
  const [loading, setLoading] = useState();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [message, setMessage] = useState();
  const [version, setVersion] = useState(null);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionStorage.getItem('TOKEN')) {
      navigate("/home", { replace: true });
    }
  });

  useEffect(() => {
    const versionStr = packageJson.version
    // Split the version string by periods
    let versionComponents = versionStr.split(".");
    // Take only the first three components (major, minor, patch)
    let v = versionComponents.slice(0, 3).join(".");

    setVersion(v)
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const token = logUser({
      username,
      password
    });
  }

  function resetError() {
    setMessage(null)
  }


  function loadTheme() {
    api.get("/v2/theme", { cache: false })
      .then((response) => {
        const data = response.data
        const theme = data.theme

        localStorage.setItem('THEME', theme)
        props.applyTheme(theme)
      })
      .catch((error) => {
        console.error("Error fetching theme:", error);
      });
  }

  async function logUser(credentials) {
    let config = { email: credentials.username, password: credentials.password };
    setMessage(null)
    await api.post('/v1/login', config)
      .then(response => {
        const data = response.data;
        let token = data.msg.TOKEN;

        if (token) {
          sessionStorage.setItem('TOKEN', data.msg.TOKEN)
          api.get("/v1/permissions/", { cache: false })
            .then((response) => {
              const data = response.data;
              secureLocalStorage.setItem("perms", data)
              dispatch(notifyPerms(secureLocalStorage.getItem("perms")))

              loadTheme()

              props.login()
            })
            .catch((error) => {
              console.error("Error fetching permissions:", error);
            });
        } else {
          console.log(data.error)
          setMessage(messages[data.msg])
          setLoading(false);
        }
      }).catch(error => {
        console.log(error);
        setLoading(false);
      })
  }

  return (
    <>
      {/* <Snackbar
          open={this.state.snackBar.open}
          autoHideDuration={3000}
          onClose={(e) => this.handleSnackClose()}
        >
          <Alert severity={this.state.snackBar.severity}>{this.state.snackBar.message}</Alert>
        </Snackbar> */}
      {!loading ? (
        <Container maxWidth='lg' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 64px)' }}>
          <Card variant='outlined' sx={{ minWidth: 650 }}>
            <CardContent>
              <Box sx={{ width: '100%', py: 4, px: 8, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ mb: 4 }} variant='h4'>Cognitive Pro</Typography>
                <FormControl sx={{ mb: 4 }}>
                  <TextField onFocus={() => resetError()} label="Username" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutline color='primary' />
                      </InputAdornment>
                    ),
                  }} variant="outlined" id="username" onChange={e => setUserName(e.target.value)} type='text'></TextField>
                </FormControl>
                <FormControl sx={{ mb: message ? 2 : 4 }}>
                  <TextField onFocus={() => resetError()} label="Password" InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined color='primary' />
                      </InputAdornment>
                    ),
                  }} variant="outlined" onChange={e => setPassword(e.target.value)} type='password'></TextField>
                </FormControl>
                {message ? <Alert severity='error' sx={{ mb: 4 }}>{message}</Alert> : ""}
                <FormControl>
                  <Button size="large" onClick={handleSubmit} variant="contained" disableElevation color="primary">Login</Button>
                  <Typography variant='caption' color={"textSecondary"} sx={{ mt: 1 }}>Copyright &copy; {new Date().getFullYear()} - Wavenure.com - v{version}</Typography>
                </FormControl>
              </Box>
            </CardContent>
          </Card>
        </Container>
      ) :
        <Container maxWidth='sm' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 64px)' }}>
          <Box sx={{ width: '100%', p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress></CircularProgress>
          </Box>
        </Container>
      }
    </>);
}

export default withTheme(Login);
