import { withTheme } from '@emotion/react';
import { HelpOutline } from '@mui/icons-material';
import { Alert, Box, Button, ButtonGroup, TextField, Tooltip, Typography, styled } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import log from 'loglevel';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import TooltipHtml from '../commons/TooltipHtml';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.primary,
    maxWidth: 220,
    opacity: 1,
    fontSize: 14,
    fontFamily: 'Roboto',
    padding: 16,
    border: '1px solid ' + theme.palette.background.grid,
  },
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const initialSettings = {
  "name": '',
  "currency": "EUR",
  "init": 1000000,
}

function SettingsGlobal(props) {
  const [settings, setSettings] = useState(initialSettings);
  const builtPtfSettings = useSelector(state => state.builtPtfSettings);

  const resetState = () => {
    setSettings(initialSettings);
    props.ready(props.step, isComplete(initialSettings))
  }

  useEffect(() => {
    setSettings(initialSettings)
    let presets = JSON.parse(builtPtfSettings)
    if (presets) presets = presets.settings
    loadSettings();
    log.debug('presets found', presets);
    log.debug('cycle', settings);
    Object.entries(settings).forEach(([key, value]) => {
      props.sendData(key, presets ? presets[key] : value);
      log.debug('sending...', key, presets ? presets[key] : value);
      props.ready(props.step, isComplete(presets ? presets : settings))
    });
    return () => { // unmount
      resetState()
    };
  }, []);

  const isComplete = (sett) => {
    let res = 1
    if (sett.name === '' || !sett.name) res = 0
    if (sett.currency === '' || !sett.currency) res = 0
    if (sett.init === 0 || isNaN(sett.init)) res = 0

    log.debug("isComplete", sett, res)

    return res
  }

  const handleChange = (name, value) => {
    let updatedSettings = { ...settings };
    if (name === 'init') {
      value = parseFloat(("" + value).replaceAll(",", ""));
    }
    updatedSettings[name] = value;
    setSettings(updatedSettings);

    props.sendData(name, value);
    props.ready(props.step, isComplete(updatedSettings))
  };

  const loadSettings = () => {
    let presets = JSON.parse(builtPtfSettings)

    if (presets && presets.settings) {
      setSettings(presets.settings);
    } else {
      setSettings(initialSettings);
      const params = Object.keys(initialSettings);
      for (let i = 0; i < params.length; i++) {
        handleChange(params[i], initialSettings[params[i]]);
      }
    }
  };

  return (
    <>
      {settings ?
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Typography variant="body2" gutterBottom>
                In the first step of portfolio creation, key parameters are established, shaping its identity, financial foundation, and operational context. These critical decisions lay the groundwork for strategic investment planning and execution.
              </Typography>
            </Box>

            <Box sx={{ my: 2, display: 'flex', flexDirection: 'row', gap: 2 }}>
              <Box sx={{ width: '65%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant='caption'>{props.isRebuild ? settings.name : 'Name'}</Typography>
                  <HtmlTooltip title={
                    <TooltipHtml
                      title="Portfolio name"
                      body="The unique name identifier for the new portfolio." />
                  }><HelpOutline sx={{ ml: 1, fontSize: 21 }} /></HtmlTooltip>
                </Box>
                <TextField
                  fullWidth
                  autoFocus
                  value={settings.name}
                  size="medium"
                  type="text"
                  variant="outlined"
                  name='name'
                  onChange={e => handleChange(e.target.name, e.target.value)}
                  disabled={props.isRebuild ? true : false}
                  InputProps={{
                    readOnly: props.isRebuild ? true : false,
                  }}
                />
                {props.alert &&
                  <Alert severity="error" sx={{ my: 1 }} color='error'>{props.alert}</Alert>
                }
              </Box>

              <Box sx={{ width: '35%' }}>
                {settings.init ?
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Typography variant='caption'>Initial capital</Typography>
                      <HtmlTooltip title={
                        <TooltipHtml
                          title="Initial capital"
                          body="The initial capital allocated in the portfolio. The backtest simulation will be computed starting from the same initial capital." />
                      }><HelpOutline sx={{ ml: 1, fontSize: 21 }} /></HtmlTooltip>
                    </Box>
                    <TextField
                      fullWidth
                      value={settings.init}
                      size="medium"
                      type="text"
                      variant="outlined"
                      name='init'
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      onChange={e => handleChange(e.target.name, e.target.value)}
                    />
                  </>
                  : "loading..."
                }
              </Box>
            </Box>

            <Box sx={{ my: 2, display: 'flex', flexDirection: 'row', gap: 1 }}>
              <Box sx={{ width: '50%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant='caption'>Currency</Typography>
                </Box>
                <Box sx={{ my: 2, display: 'flex', flexDirection: 'row' }}>
                  {settings.currency ?
                    <>
                      <ButtonGroup onClick={e => handleChange("currency", e.target.value)} fullWidth size='large' variant="outlined" aria-label="outlined button group">
                        {
                          [
                            { label: "EUR", value: "EUR" },
                            { label: "USD", value: "USD" },
                            { label: "GBP", value: "GBP" },
                            { label: "CHF", value: "CHF" }
                          ].map((obj, index) => (
                            <Button key={index} disableElevation variant={settings.currency === obj.value ? "contained" : "outlined"} value={obj.value}>{obj.label}</Button>
                          ))
                        }
                      </ButtonGroup>
                    </>
                    : "loading..."
                  }
                </Box>
              </Box>
            </Box>
          </Box>

        </Box>
        : 'No settings' + { settings }
      }
    </>
  );
}

export default withTheme(SettingsGlobal);
