import React from 'react';
import { Box, Skeleton } from '@mui/material';

const SkeletonOverview = (props) => {
    const printSkeleton = (cb) => {
        const row = [];
        for (let i = 0; i < (props.rows ? props.rows : 2); i++) {
            row.push(
                <Box key={i.toString()} display="flex" alignItems="center" flexDirection="row" sx={{p:2, height: 300}}>
                    <Box display="flex" alignItems="start" flexDirection="column" sx={{ flex: '40%' }}>
                        <Skeleton variant="text" width="70%" height={50} />
                        <Skeleton sx={{mb:3}} variant="text" width="60%" />

                        <Skeleton variant="text" width="60%" height={30} />
                        <Skeleton variant="text" width="45%" />
                    </Box>
                    <Box display="flex" alignItems="start" flexDirection="column" sx={{ flex: '60%' }}>
                        <Skeleton variant='rect' width={234} height={200} />
                    </Box>
                </Box>
            );
        }
        return row;
    };

    return (
        <>{printSkeleton()}</>
    );
}

export default SkeletonOverview;