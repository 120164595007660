import axios from "axios";
import { buildWebStorage, setupCache } from "axios-cache-interceptor";
import log from 'loglevel';

const inactiveTimeSlot = { "startTime": 7, "endTime": 9 }
const cachePrefix = "das-cache:"

/**
 * Determines whether caching is enabled based on the current time.
 * @returns {boolean} True if caching is enabled, false if disabled.
 */
const isCacheEnabled = () => {
    const now = new Date();
    const hours = now.getHours();
    const { startTime, endTime } = inactiveTimeSlot;

    return hours < startTime || hours > endTime;
};

/**
 * Calculates the Time-To-Live (TTL) for caching based on the current time.
 * @returns {number} The TTL value in milliseconds.
 */
const getTtl = () => {
    const now = new Date();
    const expirationTime = new Date(now);
    expirationTime.setHours(inactiveTimeSlot.startTime, 0, 0, 0);

    // Se ora è dopo le 05:00, imposta la data per le 05:00 del giorno successivo
    if (now > expirationTime) {
        expirationTime.setDate(expirationTime.getDate() + 1);
    }
    return expirationTime.getTime() - now.getTime();
}

const axiosBuilder = axios.create({
    baseURL: process.env.REACT_APP_API_BUILDER_URL,
    // timeout: 120000,
});

const api = setupCache(axiosBuilder, {
    methods: ['get'],
    storage: buildWebStorage(localStorage, cachePrefix),
});

api.removeCaches = () => {
    const cacheKeysToRemove = [
        'userFilter',
        'signals',
        'performance',
        'winrate',
        'avgSignalOfTheDay',
        'avgWinRatio',
        'avgPerformance',
        'graph',
    ];

    // Function to remove cache entries
    cacheKeysToRemove.forEach((cacheKey) => {
        api.storage.remove(cacheKey);
    });
};

api.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem("TOKEN");
    config.headers.Authorization = "Bearer " + token;

    if (config.cache) {
        if (isCacheEnabled()) {
            const ttl = getTtl()
            config.cache.ttl = ttl
        } else {
            config.cache = false
        }
    }

    return config;
});

api.interceptors.response.use(
    function (response) {
        // Check if the response data is an empty array and disable caching if so
        if (Array.isArray(response.data) && response.data.length === 0) {
            log.warn("Not cacheable data", response.config.id);
            response.config.cache = false;
            api.storage.remove(response.config.id);
        }

        if (response.status !== 200) {
            console.log("skip cache");
        }

        if (response.cached) {
            // console.log(process.env.NODE_ENV);
            // console.log("Get from cache:", response.config.url, response.id);
        } else {
            // console.log("Get from server:", response.config.url, response.id);
        }

        return response;
    },
    function (error) {
        console.log("skip cache", error.message);
        return Promise.reject(error);
    }
);

/**
 * Periodically checks if caching is enabled and clears cached data if it's disabled.
 * This interval function runs every minute to ensure cache management during specific times.
 * - If caching is disabled (based on isCacheEnabled), it clears the caches using api.removeCaches.
 * - Adjust the interval time (in milliseconds) as needed.
 */
setInterval(function () {
    let enabled = isCacheEnabled()
    if (!enabled) {
        if (api) {
            api.removeCaches()
        }
    }
}, 5000)

export { api };

