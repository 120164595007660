import React from 'react';
import { Navigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

const PrivateRoute = ({ element, requiredPermission, ...rest }) => {
    // Check if the user has the required permissions
    const permissions = secureLocalStorage.getItem('perms');
    const hasPermissions = permissions && permissions[requiredPermission]

    return (
        hasPermissions ? element : <Navigate to="/404" />
    );
};

export default PrivateRoute;
