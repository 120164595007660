import { withTheme } from '@emotion/react';
import { Close, Delete, DeleteForever, DriveFileRenameOutline, FiberManualRecord, MoreVert, PlayArrow } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Divider, IconButton, InputBase, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import dayjs from 'dayjs';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { api } from "../../api/apiBuilder";
import { notifyInstrument } from '../../dispatcher/Actions';
import SkeletonTable from '../../elements/SkeletonTable';

const columns = [
  { field: 'name', headerName: 'Name', width: 120, editable: false, },
  { field: 'avg_performance', headerName: 'Profit', align: 'right', editable: true, },
  { field: 'win_ratio', headerName: 'Winrate', align: 'right', editable: true, },
  { field: 'isin', headerName: 'ISIN', editable: true, },
  { field: 'market_name', headerName: 'Exchange', editable: true, },
  { field: 'sector_name', headerName: 'Sector', editable: true, },
  { field: 'industries_name', headerName: 'Industry', editable: true, },
  { field: 'last_price', headerName: 'Last', align: 'right', editable: true, },
  { field: 'trend_one_day', headerName: 'Trend 1D', align: 'right', editable: true, },
  { field: 'has_signals', headerName: 'Signals', align: 'center', editable: true, },
];

const dataInit = {
  enabled: {
    "name": true,
    "avg_performance": true,
    "win_ratio": true,
    "isin": true,
    "market_name": true,
    "sector_name": true,
    "industries_name": false,
    "trend_one_day": true,
    "has_signals": true,
    "last_price": true,
  },
}

const cellStyleLogo = { py: 1, px: 0 }

const Watchlist = (props) => {
  const [watchlists, setWatchlists] = useState(false)

  const [loading, setLoading] = useState(false)
  const [menuTarget, setMenuTarget] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const [menuModelTarget, setMenuModelTarget] = useState(null)
  const [menuModelOpen, setMenuModelOpen] = useState(false)

  const [menuOpDateTarget, setMenuOpDateTarget] = useState(null)
  const [menuOpDateOpen, setMenuOpDateOpen] = useState(false)

  const [menuClDateTarget, setMenuClDateTarget] = useState(null)
  const [menuClDatepen, setMenuClDateOpen] = useState(false)

  const [menuHorizonTarget, setMenuHorizonTarget] = useState(null)
  const [menuHorizonOpen, setMenuHorizonOpen] = useState(false)

  const [startDates, setStartDates] = useState([dayjs("2016-01-01").format("YYYY-MM-DD"), dayjs()]);
  const [endDates, setEndDates] = useState([dayjs("2016-01-01").format("YYYY-MM-DD"), dayjs()]);

  const [colWidth, setColWidth] = useState(parseInt(1200 / Object.values(dataInit.enabled).filter(Boolean).length))
  const [colCount, setColCount] = useState(Object.values(dataInit.enabled).filter(Boolean).length)

  const [data, setData] = useState(null)
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [name, setName] = useState('')
  const [dataFull, setDataFull] = useState(null)
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('name');

  useEffect(() => {
    if (props.idWatchlist) loadWatchlist()
  }, [props.idWatchlist]);

  useEffect(() => {
    if (props.active === true && props.idWatchlist) {
      console.log(props.idWatchlist);
      loadWatchlist()
    }
  }, [props.active]);

  const loadWatchlist = () => {
    setLoading(true)
    setIsEditing(false)
    setIsDeleting(false)
    api.get(`/v1/dashboard/watchlists/${props.idWatchlist}/instruments`, { cache: false })
      .then(response => {
        const data = response.data;
        dataInit.rows = data
        setData(dataInit)
        setDataFull(dataInit)
        setLoading(false)
      }).catch(error => {
        console.log(error);
        setLoading(false)
      }).finally(() => {
      })
  }

  const handleDeleteWatchlist = () => {
    setIsDeleting(true)
    setIsEditing(false)
  }

  const cancelDeleteWatchlist = () => {
    setIsDeleting(false)
  }

  const deleteWatchlist = () => {
    setLoading(true)
    api.delete(`/v1/dashboard/watchlists/${props.idWatchlist}`, { cache: false })
      .then(response => {
        const data = response.data;
        setLoading(false)
        props.reload()
      }).catch(error => {
        console.log(error);
        setLoading(false)
      }).finally(() => {
        setIsDeleting(false)
      })
  }

  const handleDelete = (idInstr) => {
    setLoading(true)
    api.delete(`/v1/dashboard/watchlists/${props.idWatchlist}/instruments/${idInstr}`, { cache: false })
      .then(response => {
        const data = response.data;
        setLoading(false)
        loadWatchlist()
      }).catch(error => {
        console.log(error);
        setLoading(false)
      }).finally(() => {
      })
  }

  const handleSort = (col) => {
    const isAsc = orderBy === col && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(col);
    setPage(0)
    switch (isAsc ? 'desc' : 'asc') {
      case "asc":
      default:
        return data.rows.sort((a, b) => {
          const valueA = a[col];
          const valueB = b[col];

          // Check if the values are numeric
          if (!isNaN(valueA) && !isNaN(valueB)) {
            return Number(valueA) - Number(valueB);
          }

          // If not numeric, sort as alphanumeric
          if (valueA.toLowerCase() < valueB.toLowerCase()) {
            return -1;
          }
          if (valueA.toLowerCase() > valueB.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      case "desc":
        return data.rows.sort((a, b) => {
          const valueA = a[col];
          const valueB = b[col];
          if (!isNaN(valueA) && !isNaN(valueB)) {
            return Number(valueB) - Number(valueA);
          }
          if (valueA.toLowerCase() < valueB.toLowerCase()) {
            return 1;
          }
          if (valueA.toLowerCase() > valueB.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    }

  };

  function handleMenu(e) {
    setMenuTarget(e.currentTarget)
    setMenuOpen(true)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  function handleColumns(event, v) {
    const updatedData = data;
    // Count the number of true values in data.enabled
    const enabledCount = Object.values(updatedData.enabled).filter(Boolean).length;
    updatedData.enabled[event.target.name] = event.target.checked;
    const colNum = Object.values(updatedData.enabled).filter(Boolean).length + 1
    dataInit.enabled = updatedData.enabled
    //localStorage.setItem("PERF_COLS", JSON.stringify(dataInit.enabled))
    setColWidth(parseInt(600 / colNum))
    setColCount(colNum)
  }

  const handleInstrument = (obj) => {
    const uniqueId = Math.random().toString(16).slice(2);
    obj.id_instr = obj.id
    obj.model = 2
    obj.assetName = obj.name
    dispatch(notifyInstrument({ id: uniqueId, data: obj }))
  }

  const editWatchlist = () => {
    setIsEditing(true)
    setIsDeleting(false)
  }


  const cancelEditWatchlist = () => {
    setIsEditing(false)
  }

  const handleChange = (e) => {
    setName(e.target.value)
  }

  const renameWatchlist = () => {
    if (name) {
      setLoading(true)
      api.put(`/v1/dashboard/watchlists/${props.idWatchlist}`, { name }, { cache: false })
        .then(response => {
          const data = response.data;
          props.reload(props.idWatchlist)
          setLoading(false)
        }).catch(error => {
          console.log(error);
          setLoading(false)
        }).finally(() => {
          setIsEditing(false)
        })
    }
  }

  return (
    <>
      <Card sx={{ position: expanded ? "fixed" : "initial", width: "100%", height: expanded ? "100%" : undefined, margin: expanded ? 0 : "initial", left: 0, top: 0, transition: 'all 0.25s ease', zIndex: expanded ? 1300 : 0 }}>
        <CardHeader
          sx={{ display: "flex", alignItems: "center", position: "relative" }}
          title={<Typography variant='overline' className='bold'>Watchlist</Typography>}
          action={<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {isEditing ?
              <Paper
                elevation={0}
                component="form"
                sx={{ p: 0, display: 'flex', alignItems: 'center', width: 200, background: props.theme.palette.secondary.main }}
              >
                <InputBase
                  disabled={loading}
                  sx={{ ml: 1, flex: 1, fontSize: 14 }}
                  placeholder="Rename"
                  inputProps={{ 'aria-label': 'search google maps' }}
                  onChange={handleChange}
                />
                <Divider sx={{ height: 24, m: 0.5 }} orientation="vertical" />
                <IconButton disabled={loading} color="primary" sx={{ p: .5 }} aria-label="directions" onClick={renameWatchlist}>
                  <PlayArrow />
                </IconButton>
                <IconButton disabled={loading} color="primary" sx={{ p: .5 }} aria-label="directions" onClick={cancelEditWatchlist}>
                  <Close sx={{ fontSize: 16 }} />
                </IconButton>
              </Paper>
              :
              <IconButton color='primary' size='small' onClick={editWatchlist}><DriveFileRenameOutline /></IconButton>
            }

            {isDeleting ?
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Button onClick={deleteWatchlist} variant='contained' color='error' size='small' disableElevation>Confirm deletion</Button>
                <IconButton onClick={cancelDeleteWatchlist} color='error' size='small' disableElevation><Close /></IconButton>
              </Box>
              :
              <IconButton color='error' size='small' onClick={handleDeleteWatchlist}><Delete /></IconButton>
            }
          </Box>
          }
        />
        <CardContent sx={{ minHeight: 400 }}>
          <TableContainer sx={{ maxWidth: "100%" }}>
            <Table sx={{ maxWidth: "100%" }}>
              <TableHead>
                <TableRow>
                  {data && columns.map((obj, i) =>
                    (expanded || data.enabled[obj.field]) &&
                    <TableCell key={i} sx={{ width: colWidth, maxWidth: colWidth, py: 1, px: 2, }} padding="checkbox" className='bold' align={obj.align ? obj.align : 'left'}>
                      <TableSortLabel
                        active={orderBy === obj.field}
                        direction={orderBy === obj.field ? order : 'asc'}
                        onClick={() => handleSort(obj.field)}
                      >
                        <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                          {/* <Typography variant='body2' className='bold'>{obj.headerName}</Typography> */}
                          <Typography variant='body2' color='textSecondary'>{obj.headerName}</Typography>
                        </Box>
                      </TableSortLabel>
                    </TableCell>
                  )}
                  <TableCell sx={{ width: 40, maxWidth: 40, px: 0 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  loading ?
                    <SkeletonTable rows={7} cols={9} /> :
                    data ? data.rows.map(obj =>
                      <TableRow key={"row_" + obj.id} hover>
                        {(expanded || dataInit.enabled.name) &&
                          <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth, cursor: 'pointer' }} onClick={() => handleInstrument(obj)}>
                            <Typography variant='subtitle2' className='bold'>{obj.ticker}</Typography>
                            <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                              <Typography variant='caption' color={"textSecondary"}>{obj.name}</Typography>
                            </Box>
                          </TableCell>
                        }
                        {(expanded || data.enabled.avg_performance) &&
                          <TableCell align='right' sx={{ py: 1, px: 2, maxWidth: colWidth, cursor: 'pointer' }} onClick={() => handleInstrument(obj)}>
                            <Typography variant='caption'>{numeral(obj.avg_performance).format('+0.00')}%</Typography>
                          </TableCell>
                        }
                        {(expanded || data.enabled.win_ratio) &&
                          <TableCell align='right' sx={{ py: 1, px: 2, maxWidth: colWidth, cursor: 'pointer' }} onClick={() => handleInstrument(obj)}>
                            <Typography variant='caption'>{numeral(obj.win_ratio).format('0.00')}%</Typography>
                          </TableCell>
                        }
                        {(expanded || data.enabled.isin) &&
                          <TableCell align='left' sx={{ py: 1, px: 2, maxWidth: colWidth, cursor: 'pointer' }} onClick={() => handleInstrument(obj)}>
                            <Typography variant='caption'>{obj.isin}</Typography>
                          </TableCell>
                        }
                        {(expanded || data.enabled.market_name) &&
                          <TableCell align='left' sx={{ py: 1, px: 2, maxWidth: colWidth, cursor: 'pointer', whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis" }} onClick={() => handleInstrument(obj)}>
                            <Typography variant='caption'>{obj.market_name}</Typography>
                          </TableCell>
                        }
                        {(expanded || data.enabled.sector_name) &&
                          <TableCell align='left' sx={{ py: 1, px: 2, maxWidth: colWidth, cursor: 'pointer', whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis" }} onClick={() => handleInstrument(obj)}>
                            <Typography variant='caption'>{obj.sector_name}</Typography>
                          </TableCell>
                        }
                        {(expanded || data.enabled.industries_name) &&
                          <TableCell align='left' sx={{ py: 1, px: 2, maxWidth: colWidth, cursor: 'pointer', whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis" }} onClick={() => handleInstrument(obj)}>
                            <Typography variant='caption'>{obj.industries_name}</Typography>
                          </TableCell>
                        }
                        {(expanded || data.enabled.last_price) &&
                          <TableCell align='right' sx={{ py: 1, px: 2, maxWidth: colWidth, cursor: 'pointer' }} onClick={() => handleInstrument(obj)}>
                            <Typography variant='caption'>{numeral(obj.last_price).format('0,000.00')}</Typography>
                          </TableCell>
                        }
                        {(expanded || data.enabled.trend_one_day) &&
                          <TableCell align='right' sx={{ py: 1, px: 2, maxWidth: colWidth, cursor: 'pointer' }} onClick={() => handleInstrument(obj)}>
                            <Typography variant='caption'>{numeral(obj.trend_one_day).format('0.00')}%</Typography>
                          </TableCell>
                        }
                        {(expanded || data.enabled.has_signals) &&
                          <TableCell align='center' sx={{ py: 1, px: 2, maxWidth: colWidth, cursor: 'pointer' }} onClick={() => handleInstrument(obj)}>
                            <Typography variant='caption'>{obj.signals ? <FiberManualRecord color='success' sx={{ fontSize: 12 }} /> : <FiberManualRecord color='error' sx={{ fontSize: 12 }} />}</Typography>
                          </TableCell>
                        }
                        <TableCell align='center' sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                          <IconButton onClick={() => handleDelete(obj.id)} color='error'><DeleteForever /></IconButton>
                        </TableCell>
                      </TableRow>
                    ) : <TableRow><TableCell>Data not found</TableCell></TableRow>
                }
              </TableBody>
            </Table >
          </TableContainer>
          {/* {data &&
            <TablePagination
              sx={{
                '& .MuiButtonBase-root': {
                  color: props.theme.palette.default.main,
                },
                '& .MuiButtonBase-root.Mui-disabled': {
                  color: props.theme.palette.text.disabled,
                },
              }}
              showFirstButton
              showLastButton
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={data.rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              // onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            />
          } */}

        </CardContent>
        <CardActions sx={{ justifyContent: 'space-between' }}>
          <Box></Box>
          <Box><IconButton sx={{ display: expanded ? 'none' : 'inherit' }} onClick={handleMenu}><MoreVert color="default" /></IconButton></Box>
        </CardActions>
      </Card>

      <Menu
        id="basic-menu"
        anchorEl={menuTarget}
        open={menuOpen}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {data && columns.map(obj =>
          <MenuItem disabled={!obj.editable || (colCount >= 10 && !data.enabled[obj.field])} key={"cols_" + obj.field}>
            <Checkbox
              defaultChecked={data.enabled[obj.field]}
              checked={data.enabled[obj.field]}
              disabled={colCount >= 10 && !data.enabled[obj.field]}
              name={obj.field}
              onChange={handleColumns}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {obj.headerName}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default withTheme(Watchlist);