import { Component } from 'react';
import { PlaylistRemoveOutlined, HomeOutlined, MenuOutlined, RocketLaunchOutlined, ArchiveOutlined, ModeNight, LightMode, SsidChart } from '@mui/icons-material';
import MyBlacklist from './MyBlacklist';
import { AppBar, Box, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { withTheme } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const styles = theme => ({
});

class TopBarBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blacklistOpen: false,
      blacklistTitle: 'My Blacklist',
      blacklistTooltip: 'My Blacklist'
    }
  }

  openBlacklist() {
    this.setState({ blacklistOpen: true })
  }

  closeBlacklist() {
    this.setState({ blacklistOpen: false })
  }

  changeTheme() {
    this.props.changeTheme()
  }

  render() {
    const { selectedTheme } = this.props;
    const { open, blacklistTooltip, blacklistOpen, blacklistTitle } = this.state;
    return (
      <>
        <AppBar position='fixed'>
          <Toolbar variant='regular'>

            <IconButton
              color="inherit"
              onClick={() => this.props.toggleDrawer()}
              edge="start"
              aria-label="menu"
            >
              {/* <Avatar sx={{ bgcolor: "#1565C0" }}><Typography variant='h4' component={'span'} className='logoStyle'>B</Typography></Avatar> */}
              <MenuOutlined />
            </IconButton>

            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>

            </Typography>

            {/* BUILDER TOP ICONS */}
            <Box sx={{ display: this.props.selectedArea === 'builder' ? 'block' : 'none' }}>
              <Tooltip title='Build new portfolio'>
                <IconButton sx={{ mr: 1 }} aria-label="search" color="inherit" component={NavLink} to={"/builder"}>
                  <RocketLaunchOutlined color={window.location.hash === '#/builder' ? 'primary' : 'default'} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Show active portfolios'>
                <IconButton sx={{ mr: 1 }} aria-label="search" color="inherit" component={NavLink} to={"/home"}>
                  <SsidChart color={window.location.hash === '#/home' ? 'primary' : 'default'} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Show archived portfolios'>
                <IconButton sx={{ mr: 1 }} aria-label="search" color="inherit" component={NavLink} to={"/archive"}>
                  <ArchiveOutlined color={window.location.hash === '#/archive' ? 'primary' : 'default'} />
                </IconButton>
              </Tooltip>
              <IconButton aria-label="search" color="inherit" onClick={() => this.openBlacklist()}>
                <Tooltip title={blacklistTooltip}><PlaylistRemoveOutlined color='default' /></Tooltip>
              </IconButton>
            </Box>

            <IconButton aria-label="search" color="inherit" onClick={() => this.changeTheme()}>
              {selectedTheme === 'LIGHT' ?
                <ModeNight color='default' /> : <LightMode color='default' />
              }
            </IconButton>
          </Toolbar>
        </AppBar>
        {this.props.selectedArea === 'builder' &&
          <MyBlacklist cancel={() => this.closeBlacklist()} open={blacklistOpen} title={blacklistTitle}></MyBlacklist>
        }
      </>
    );
  }
}

function TopBar(props) {
  const selectedTheme = useSelector(state => state.theme)
  const area = useSelector(state => state.area)

  return <TopBarBase selectedTheme={selectedTheme} selectedArea={area} {...props} />
}

export default withTheme(TopBar);

