import { withTheme } from '@emotion/react';
import { Alert, Avatar, Backdrop, Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Chip, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Fade, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Select, Skeleton, TextField, ThemeProvider, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from "../../api/apiBuilder";
import { getSectorLogo, isObjectEmptyOrNull } from '../../commons/Utils';
import { notifyInstrument } from '../../dispatcher/Actions';
import SkeletonList2 from '../../elements/SkeletonList2';
import { Add, AddCircle, AllOut, BarChart, BarChartOutlined, Favorite, FavoriteOutlined } from '@mui/icons-material';
import DialogAddToWatchlist from '../../commons/DialogAddToWatchlist';


const marketsInit = [
  { "id": 12, "name": "Euronext Amsterdam", "enabled": false },
  { "id": 13, "name": "Euronext Brussels", "enabled": false },
  { "id": 15, "name": "Copenhagen Exchange", "enabled": false },
  { "id": 9, "name": "Frankfurt Exchange", "enabled": false },
  { "id": 14, "name": "Helsinki Exchange", "enabled": false },
  { "id": 5, "name": "Hong Kong Exchange", "enabled": false },
  { "id": 3, "name": "Euronext Dublin", "enabled": false },
  { "id": 26, "name": "Euronext Lisbon", "enabled": false },
  { "id": 36, "name": "London Exchange", "enabled": false },
  { "id": 29, "name": "Madrid Exchange", "enabled": false },
  { "id": 1, "name": "Borsa Italiana", "enabled": false },
  { "id": 38, "name": "Nyse", "enabled": false },
  { "id": 39, "name": "Nasdaq", "enabled": false },
  { "id": 27, "name": "Oslo Stock Exchange", "enabled": false },
  { "id": 11, "name": "Euronext Paris", "enabled": false },
  { "id": 31, "name": "Stockholm Exchange", "enabled": false },
  { "id": 33, "name": "SIX Swiss Exchange", "enabled": false },
  { "id": 23, "name": "Tokyo Exchange", "enabled": false },
  { "id": 19, "name": "Vienna Exchange", "enabled": false },
  { "id": 102, "name": "Warsaw", "enabled": false },
  { "id": 7, "name": "XETRA Exchange", "enabled": false }]

const sectorsInit = [
  { v: 9, name: "Basic Materials", enabled: false },
  { v: 19, name: "Communications", enabled: false },
  { v: 4, name: "Consumer Cyclical", enabled: false },
  { v: 11, name: "Energy", enabled: false },
  { v: 7, name: "Healthcare", enabled: false },
  { v: 15, name: "Industrials", enabled: false },
  { v: 14, name: "Real Estate", enabled: false },
  { v: 2, name: "Technology", enabled: false },
  { v: 1, name: "Utilities", enabled: false },
  { v: 22, name: "Financial Services", enabled: false },
  { v: 10, name: "Consumer Defensive", enabled: false }]

const dataInit = {
  enabled: {
    "assetName": true,
    "avg_performance": true,
    "win_ratio": true,
    "last": false,
    "market": true,
    "sector": false,
    "industry": false,
    "change": false,
    "assetType": false,
    "isin": false
  },
}


const AssetSummary = (props) => {
  const [loading, setLoading] = useState(false)
  const [mounted, setMounted] = useState(false)

  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const [data, setData] = useState(null)
  const [expanded, setExpanded] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false)
  const inputRef = useRef(null);
  const [model, setModel] = useState(2)
  const lastInstrument = useSelector(state => state.lastInstrument)
  const lastInstrumentRef = useRef(props.lastInstrument);
  const selectedInstrumentRef = useRef(props.selectedInstrument);
  const [addToWatchlistOpen, setAddToWatchlist] = useState(false)

  useEffect(() => {
    if (lastInstrumentRef.current !== lastInstrument) {
      if (lastInstrument.data) setModel(lastInstrument.data.model)
      fetch()
      lastInstrumentRef.current = lastInstrument;
    } else if (selectedInstrumentRef.current !== props.selectedInstrument) {
      setModel(2)
      fetch()
      selectedInstrumentRef.current = props.selectedInstrument;
    }
  }, [lastInstrument, props.selectedInstrument])

  useEffect(() => {
    if (searchOpen) {
      document.addEventListener('keydown', (e) => {
        e.key === 'Escape' && setSearchOpen(false)
      })
      return () => {
        document.removeEventListener('keydown', (e) => e)
        document.removeEventListener('click', (e) => e)
      }
    }
  }, [searchOpen])

  const fetch = () => {
    if (props.selectedInstrument) {
      setLoading(true)

      api.get("/v2/dashboard/summary/" + props.selectedInstrument, { id: `summary-${props.selectedInstrument}` })
        .then(response => {
          const data = response.data;
          setData(data)
          setLoading(false)
        }).catch(error => {
          console.log(error);
          setLoading(false)
        }).finally(() => {
        })
    } else {

    }
  }

  useEffect(() => {
    if (searchOpen) {
      document.addEventListener('keydown', (e) => {
        e.key === 'Escape' && setSearchOpen(false)
      })
      return () => {
        document.removeEventListener('keydown', (e) => e)
        document.removeEventListener('click', (e) => e)
      }
    }
  }, [searchOpen])

  const handleSearch = () => {
    inputRef.current.focus();
    setSearchOpen(!searchOpen)
  }

  const handleModels = (id) => {
    setModel(id);
  }

  const getValue = (field) => {
    if (data.signals["model_" + model])
      return data.signals["model_" + model][field]
    else
      return "-"
  }

  const getPaletteColor = () => {
    return props.theme.palette.long.main
  }

  const handleCreate = () => {
    setAddToWatchlist(true)
  }

  return (
    <>
      <Backdrop open={searchOpen} onClick={handleSearch} sx={{ zIndex: 1 }} />
      <Card sx={{ position: expanded ? "absolute" : "initial", width: "100%", height: expanded ? "100%" : undefined, margin: expanded ? 0 : "initial", left: 0, top: 0, transition: 'all 0.25s ease', zIndex: expanded ? 1 : 0 }}>
        <CardHeader
          sx={{ display: "flex", alignItems: "center", position: "relative" }}
          title={<Typography variant='overline' className='bold'>Asset summary</Typography>}
        />
        <CardContent sx={{}}>
          {
            loading ? <SkeletonList2 /> :
              data ?
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ flex: "60%", display: "flex", flexDirection: "column", pr: 7, py: 2 }}>
                    <Box sx={{ mb: 3 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar sx={{ mr: 3, width: 60, height: 60 }}>
                          {data.logo && data.logo !== "NA" ?
                            <img alt={data.assetName + "Img"} style={{ height: 60, maxWidth: 60 }} src={"https://eodhistoricaldata.com" + data.logo} />
                            : getSectorLogo(data.sect)
                          }
                        </Avatar>
                        <Box>
                          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Typography variant='h4' className='big' sx={{ mr: 2 }}>{data.ticker}</Typography>
                            <Chip clickable onClick={handleCreate} label='Add to watchlist' size='small' color='cta' icon={<Add />} />
                          </Box>
                          <Typography variant='body1' color={"textSecondary"}>{data.assetName}</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", mt: 3, mb: 1 }}>
                        <Box sx={{ flex: "20%", width: "25%", display: "flex", flexDirection: "column", p: 1 }}> <Typography sx={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{data.o}</Typography> <Typography variant="caption" color={"textSecondary"}>Open</Typography></Box>
                        <Box sx={{ flex: "20%", width: "25%", display: "flex", flexDirection: "column", p: 1 }}> <Typography sx={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{data.h}</Typography> <Typography variant="caption" color={"textSecondary"}>High</Typography></Box>
                        <Box sx={{ flex: "30%", width: "25%", display: "flex", flexDirection: "column", p: 1 }}> <Typography sx={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{data.exch}</Typography> <Typography variant="caption" color={"textSecondary"}>Exchange</Typography></Box>
                        <Box sx={{ flex: "30%", width: "25%", display: "flex", flexDirection: "column", p: 1 }}> <Typography sx={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{data.sect}</Typography> <Typography variant="caption" color={"textSecondary"}>Sector</Typography></Box>
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ flex: "20%", width: "25%", display: "flex", flexDirection: "column", p: 1 }}> <Typography sx={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{data.l}</Typography> <Typography variant="caption" color={"textSecondary"}>Low</Typography></Box>
                        <Box sx={{ flex: "20%", width: "25%", display: "flex", flexDirection: "column", p: 1 }}> <Typography sx={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{data.c}</Typography> <Typography variant="caption" color={"textSecondary"}>Close</Typography></Box>
                        <Box sx={{ flex: "30%", width: "25%", display: "flex", flexDirection: "column", p: 1 }}> <Typography sx={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{data.curr}</Typography> <Typography variant="caption" color={"textSecondary"}>Currency</Typography></Box>
                        <Box sx={{ flex: "30%", width: "25%", display: "flex", flexDirection: "column", p: 1 }}> <Typography sx={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{data.ind}</Typography> <Typography variant="caption" color={"textSecondary"}>Industry</Typography></Box>
                      </Box>

                    </Box>
                    <Box>
                      <Typography variant='body1' gutterBottom className='bold'>Asset profile</Typography>
                      <Collapse in={descriptionOpen} collapsedSize={66}>
                        <Typography variant='body2' className='slim' sx={{ lineHeight: 1.6 }}>{data.description}</Typography>
                      </Collapse>
                      <Chip variant='filled' color='secondary' size='small' sx={{ mt: 1 }} label={descriptionOpen ? 'Show less' : 'Show more...'} onClick={() => {
                        setDescriptionOpen(!descriptionOpen)
                      }} />
                    </Box>
                  </Box>

                  <Box sx={{ flex: "40%", pl: 3, py: 2 }}>
                    <Box sx={{ pb: 4 }}>
                      <ButtonGroup size='small' sx={{ width: "100%" }}>
                        <Button sx={{ width: "50%" }} onClick={() => handleModels(1)} variant={model === 1 ? 'contained' : 'outlined'}>Model v1</Button>
                        <Button sx={{ width: "50%" }} onClick={() => handleModels(2)} variant={model === 2 ? 'contained' : 'outlined'}>Model v2</Button>
                      </ButtonGroup>
                    </Box>
                    <Fade in={true} timeout={300}>
                      <div>
                        <Box sx={{ pb: 3, minHeight: 198, position: 'relative' }}>
                          {
                            data.signals["model_" + model] && data.signals["model_" + model].sig && Object.keys(data.signals["model_" + model].sig).length !== 0
                              ? data.signals["model_" + model].sig.map((item, i) =>
                                <Box key={model + "_" + i} sx={{ display: "flex", alignItems: "center", pb: 1, height: 42, mb: 2 }}>
                                  <Box sx={{ width: 120 }}>
                                    <Typography className='big bold'>{item.sig ? item.sig : "-"}</Typography>
                                    <Typography variant='caption' component={"div"} color={"textSecondary"}>{item.hor} days</Typography>
                                  </Box>
                                  {item && item.strength ?
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      <ThemeProvider theme={outerTheme => ({ ...outerTheme, direction: item.sig && item.sig === "BUY" ? "ltr" : "rtl" })}>
                                        <LinearProgress color={item.sig === "BUY" ? "long" : "short"} variant='determinate' value={item.strength * 100} sx={{ height: 12, width: 350, ml: 2, borderRadius: 1 }} />
                                      </ThemeProvider>
                                      <Typography variant='body2' sx={{ ml: 2, width: 50, textAlign: "right" }}>{new Intl.NumberFormat("en-GB", { style: "percent", maximumFractionDigits: 1, width: 50 }).format(item.strength)}</Typography>
                                    </Box>
                                    :
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      <LinearProgress color="inherit" value={0} variant='determinate' sx={{ height: 12, width: 350, ml: 2, borderRadius: 1 }} />
                                      <Typography variant='body2' sx={{ ml: 2, width: 50, textAlign: "right" }}>-</Typography>
                                    </Box>
                                  }
                                </Box>
                              )
                              : <>
                                <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "center", gap: 1, position: 'absolute', zIndex: 1, width: '100%' }}>
                                  <BarChartOutlined sx={{ transform: 'rotate(90deg)', fontSize: 32 }} color='textSecondary' />
                                  <Typography variant='h6' component={'div'}>No signals today</Typography>
                                  <Typography variant='caption' color={"textSecondary"} component={'div'}>for the selected model</Typography>
                                </Box>
                                {/* {[...Array(3)].map((_, i) => (
                                  <Box key={i} sx={{ display: "flex", alignItems: "center", pb: 1, height: 42, mb: 2, gap: 2 }}>
                                    <Skeleton animation={'none'} variant="text" sx={{ width: 120 }} />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                      <Skeleton animation={'none'} variant="text" sx={{ width: 350 }} />
                                      <Skeleton animation={'none'} variant="text" sx={{ width: 50 }} />
                                    </Box>
                                  </Box>
                                ))} */}
                              </>
                            //       < Box sx={{ display: "flex", flexDirection: 'column', alignItems: "center", gap: 1 }}>
                            //   <BarChart sx={{ transform: 'rotate(90deg)', fontSize: 32 }} color='textSecondary' />
                            //   <Typography variant='h6' component={'div'}>No signals today</Typography>
                            //   <Typography variant='caption' color={"textSecondary"} component={'div'}>for the selected model</Typography>
                            // </Box>
                          }

                        </Box>
                        <Box>
                          <Typography variant='body1' gutterBottom className='bold'>Performance radar</Typography>
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Box sx={{ display: "flex", alignItems: "center", pb: 1 }}>
                              <Typography sx={{ flex: "50%" }} variant="caption" color={"textSecondary"}>Avg performance</Typography>
                              <Typography sx={{ flex: "50%", textAlign: "right" }} color={getValue("avgPerf") > 0 ? getPaletteColor("long") : getPaletteColor("short")}>
                                {new Intl.NumberFormat("en-GB", { style: "percent", signDisplay: "always", maximumSignificantDigits: 3 }).format(getValue("avgPerf") / 100)}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", pb: 1 }}>
                              <Typography sx={{ flex: "50%" }} variant="caption" color={"textSecondary"}>Winrate</Typography>
                              <Typography sx={{ flex: "50%", textAlign: "right" }}>
                                {new Intl.NumberFormat("en-GB", { style: "percent", maximumSignificantDigits: 3 }).format(getValue("winrate") / 100)}
                              </Typography>
                            </Box>
                            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ flex: "50%" }} variant="caption" color={"textSecondary"}>Last result</Typography>
                            <Typography sx={{ flex: "50%", textAlign: "right" }} color={getValue("lastRes") > 0 ? getPaletteColor("long") : getPaletteColor("short")}>
                              {new Intl.NumberFormat("en-GB", { style: "percent", signDisplay: "always", maximumSignificantDigits: 3 }).format(getValue("lastRes") / 100)}
                            </Typography>
                          </Box> */}
                          </Box>
                        </Box>
                      </div>
                    </Fade>
                  </Box>
                </Box>
                : "No data"
          }
        </CardContent>
        <CardActions>
        </CardActions>
      </Card >

      <DialogAddToWatchlist
        instrument={props.selectedInstrument}
        open={addToWatchlistOpen}
        onClose={() => setAddToWatchlist(false)}
        maxWidth='sm'
      />
    </>
  );
};

export default withTheme(AssetSummary);