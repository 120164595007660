import React, { Component } from 'react';
import numeral from 'numeral';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Collapse, Divider, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, styled, Switch, Tooltip, Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, } from '@mui/material';
import { Typography } from '@mui/material';
import { ExpandMoreOutlined, SensorsOutlined } from '@mui/icons-material';
import Stack from '@mui/material/Stack'
import { withTheme } from '@emotion/react';
import getSectorIcon from '../commons/SectorIcons';
import ApexCharts from "apexcharts";
import SkeletonList from "../elements/SkeletonList"
import SkeletonChart from "../elements/SkeletonChart"
import SkeletonListAvatar from "../elements/SkeletonListAvatar"
import PortfolioSignalsDialog from '../elements/PortfolioSignalsDialog';
import { NavLink } from 'react-router-dom';
import { restore } from '../commons/Memo';
import { api } from "../api/apiBuilder";
import ReactApexChart from 'react-apexcharts';
import HeadingExport from './HeadingExport';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb:before': {
      },
      '& + .MuiSwitch-track': {
        opacity: .7,
        backgroundColor: '#9f9f9f',
        borderRadius: 20 / 2,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: "#1565C0",
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: .7,
    backgroundColor: '#9f9f9f',
    borderRadius: 20 / 2,
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableCell: {
    padding: '4px 16px',
  },

}));

class PortfolioAllocationExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      chart_series: 0,
      chart_options: 0,
      allocation: [],
      sectors: [],
      report: [],
      info: 0,
      collapse: true,
      performances: props.performances,
      selectedSector: 0,
      selectedSeriesIndex: 0,
      selectedDataPointIndex: 0,
      showPie: 0,
      portfolioSignals: 0,
      portfolioSize: 0,
      signalsOpen: false,
      breakdownType: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.portfolio != prevProps.portfolio && this.props.portfolio) {
        this.reset()
        this.setState({ showPie: 0 })
        this.load()
      }
    }
  }

  componentDidMount() {
    this.setState({ showPie: 0 })
    if (this.props.portfolio) {
      this.load()
    }
  }

  load(v, reload) {
    let response = restore("PF_ALLOC_" + this.props.portfolio)
    if (response) {
      this.plot(v, response)
    } else {
      if (!reload) this.setState({ isLoading: true })
      api.get('/builder/portfolios/' + this.props.portfolio + '/allocation/', { cache: false })
        .then(response => {
          // store("PF_ALLOC_" + this.props.portfolio, response)
          this.plot(v, response)
        }).catch(error => {
          this.setState({ isLoading: false })
          if (error.response) {
            console.log('Error 1', error.message);
            if (error.response.status == 401) {
            }
            this.state.snackMessage = error.response.data;
            this.state.snackOpen = true;
          } else if (error.request) {
            // The request was made but no response was received
            console.log('Error 2', error.message);
            this.state.snackMessage = error.message;
            this.state.snackOpen = true;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error 3', error.message);
            this.state.snackMessage = error.message;
            this.state.snackOpen = true;
          }
        })
    }
  }

  plot(v, response) {
    const data = response.data;
    let values = [], names = [];
    let sectors = (v ? data.nextSectors : data.sectors)
    let breakdownType = this.state.breakdownType

    if (breakdownType) {
      sectors = (v ? data.nextMarkets : data.markets)
    } else {
    }

    sectors.forEach(obj => {
      values.push(obj.num * 1);
      names.push(obj.sn);
    });
    let options = {
      labels: names,
      chart: {
        id: "donut",
        type: 'donut',
        height: 300,
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const currSector = this.state.selectedSector

            let active = config.selectedDataPoints.length
            let selectedSector = active ? config.w.config.labels[config.dataPointIndex] : 0
            let selectedSeriesIndex = config.seriesIndex
            let selectedDataPointIndex = config.dataPointIndex
            if(currSector == selectedSector){
              this.setState({ selectedSector: 0, selectedDataPointIndex: 0 })
            } else {
              this.setState({ selectedSector, selectedSeriesIndex, selectedDataPointIndex })
            }
          },
        }
      },
      theme: {
        monochrome: {
          enabled: true,
          color: (v ? this.props.theme.palette.chartPrimary : this.props.theme.palette.chartPrimary),
          shadeTo: 'light',
          shadeIntensity: 0.9
        }
      },
      stroke: {
        width: 2,
        colors: ['#fafafa'],
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex]
          return [name, numeral(val).format('0.0') + '%']
        },
        dropShadow: {
          enabled: false,
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: '500',
          colors: ['#484848'],
          bgColor: '#fff'
        },
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: '12px',
          padding: '8px'
        },
        items: {
          display: 'flex',
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return '<div style="padding:8px">' +
            '<div>'
            + w.globals.labels[seriesIndex]
            + '</div>'
            + '<div>'
            + numeral(w.globals.seriesPercent[seriesIndex]).format('0.0') + '%</div>' +
            '</div>'
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 32,
            minAngleToShowLabel: 500,
          },
          expandOnClick: false,
          startAngle: 0,
          donut: {
            size: '65%',
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '12px',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 400,
                color: '#000048',
                offsetY: 0,
                formatter: function (val) {
                  return val
                }
              },
              value: {
                show: true,
                fontSize: '14px',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 400,
                color: undefined,
                offsetY: 8,
                formatter: function (val) {
                  return numeral(val).format('0.0%')
                }
              },
              total: {
                show: true,
                showAlways: true,
                label: (v ? 'New allocation' : 'Allocation'),
                fontSize: '14px',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 400,
                color: '#373d3f',
                formatter: function (w) {
                  return breakdownType ? '(Markets)' : '(Sectors)'
                }
              }
            }
          }
        }
      },
      legend: {
        position: "bottom",
        fontSize: '12px',
        fontFamily: 'Roboto',
        labels: {
          colors: "#a0a0a0",
        },
        markers: {
          radius: 50,
          width: 8,
          height: 8,
        }
      }
    };

    this.setState({
      isLoading: false, chart_options: options, chart_series: values,
      allocation: (v ? data.nextList : data.list),
      sectors: (v ? data.nextSectors : data.sectors),
      report: data.report,
      info: data.info
    });
  }

  reset() {
    console.log("reset")
    if(this.state.selectedDataPointIndex || this.state.selectedSector){
      ApexCharts.exec("donut", "toggleDataPointSelection", this.state.selectedDataPointIndex)
      this.setState({ selectedSector: 0, selectedDataPointIndex: 0 })
    }
  }

  collapse() {
    this.setState({ collapse: !this.state.collapse })
  }

  signalsOpen() {
    this.setState({ signalsOpen: true })
  }

  signalsClose() {
    this.setState({ signalsOpen: false })
  }

  handleSwitchPieView(v) {
    this.reset()
    this.setState({ showPie: v, selectedSector: 0 }, function () {
      this.load(v, true)
    })
  }

  handleSwitchBreakdown(breakdownType) {
    this.reset()
    this.setState({ selectedSector: 0, breakdownType }, function () {
      this.load()
    })
  }

  calculateMargin(arrayLength) {
    const maxLength = 20.0;
    const maxMargin = 4.8;
    const reductionPerItem = 0.3; 
    const minMargin = 1.0;
  
    let clampedLength = 0;
  
    for (let i = 0; i < arrayLength; i++) {
      if (i >= maxLength) {
        clampedLength = maxLength;
        break;
      }
      clampedLength++;
    }
  
    let margin = maxMargin - (clampedLength * reductionPerItem);
  
    if (margin < minMargin) {
      margin = minMargin;
    }
  
    return margin;
  }
  


  render() {
    const { portfolio } = this.props;
    const { allocation, report, info, selectedSector, isLoading, showPie, signalsOpen, breakdownType } = this.state;
    const margin = this.calculateMargin(allocation);
    const topMargin = {
      marginTop: `${margin}in`,
    };
    var long = allocation.filter((obj) => obj.direction === 'long').length;
    var short = allocation.filter((obj) => obj.direction === 'short').length;
    var long = allocation.filter((obj) => obj.direction === 'long').length;
    var longPerc = long > 0 ? long * 100 / (long + short) : 0;
    var shortPerc = short > 0 ? short * 100 / (long + short) : 0;
    //var tot = allocation.map(item => item.quantity).reduce((prev, curr) => prev * 1 + curr * 1, 0);

    let currency = report.currency ? report.currency + ' ' : '-'
    let liquidity = report.liquidity ? currency + numeral(report.liquidity).format('0,000.0') : '-'
    let liquidityShort = report.liquidityShort ? currency + numeral(report.liquidityShort).format('0,000.0') : '-'
    let portfolioValue = report.portfolioValue ? currency + numeral(report.portfolioValue).format('0,000.0') : '-'

    let pfSize = info.pfSize ? info.pfSize : 0
    let pfSignals = info.pfSignals ? info.pfSignals : 0

    return (
      <>
        <PortfolioSignalsDialog open={signalsOpen} reloadAllocation={() => this.load(showPie)} close={() => this.signalsClose()} portfolio={this.props.portfolio}></PortfolioSignalsDialog>
        <Card variant='outlined'>
          <CardHeader sx={{ pb: 0 }}
            title={<Typography gutterBottom variant="overline" component="h2">Allocation</Typography>}
            action={
              pfSignals ?
                <Button size='small' startIcon={<SensorsOutlined />} sx={{ mr: 1 }} disableElevation variant='contained' color='success' onClick={() => this.signalsOpen()}>Signals detected</Button>
                : ''
            }
          ></CardHeader>
          <CardContent sx={{ pt: 0 }}>
            <Box>
              {isLoading ? <SkeletonList rows={1} /> :
                <>
                  <Stack direction="row" spacing={2} justifyContent='center' sx={{ mt: 2, mb: 2 }}>
                    <Box sx={{ width: '33.3%', display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
                      <Typography variant='h6'>{liquidity}</Typography>
                      <Typography variant='caption' color='textSecondary'>Liquidity</Typography>
                    </Box>
                    <Box sx={{ width: '33.3%', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                      <Typography variant='h6'>{liquidityShort}</Typography>
                      <Typography variant='caption' color='textSecondary'>Liquidity short</Typography>
                    </Box>
                    <Box sx={{ width: '33.3%', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                      <Typography variant='h6'>{portfolioValue}</Typography>
                      <Typography variant='caption' color='textSecondary'>Portfolio value</Typography>
                    </Box>
                  </Stack>
                  <Divider />
                  <Stack direction="row" spacing={2} justifyContent='center' sx={{ mt: 2, mb: 2 }}>
                    <Box sx={{ width: '33.3%', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                      <Box><Typography variant='caption' color='textSecondary'>Positions: {allocation.length}</Typography></Box>
                      <Box sx={{ maxWidth: 120 }}><LinearProgress color='secondary' variant="determinate" value={allocation.length * 100 / pfSize} /></Box>
                    </Box>
                    <Box sx={{ width: '33.3%', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                      <Box><Typography variant='caption' color='textSecondary'>Long: {numeral(longPerc).format('0.0') + '%'}</Typography></Box>
                      <Box sx={{ maxWidth: 120 }}><LinearProgress variant="determinate" value={longPerc} /></Box>
                    </Box>
                    <Box sx={{ width: '33.3%', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                      <Box><Typography variant='caption' color='textSecondary'>Short: {numeral(shortPerc).format('0.0') + '%'}</Typography></Box>
                      <Box sx={{ maxWidth: 120 }}><LinearProgress color="primary" variant="determinate" value={shortPerc} /></Box>
                    </Box>
                  </Stack>
                </>
              }
            </Box>
            <Box sx={{ pt: 1, mb: 3 }}>
              <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flex: '50%' }}>
                  {pfSignals ?
                    [{ value: 0, label: "Current" }, { value: 1, label: "After changes" }].map((obj) => (
                      <Chip
                        key={obj.label}
                        onClick={() => { this.handleSwitchPieView(obj.value) }}
                        sx={{ mr: 1 }}
                        clickable={showPie == obj.value ? false : true}
                        size='small'
                        label={obj.label}
                        color={showPie == obj.value ? 'primary' : 'default'}
                        variant={showPie == obj.value ? 'filled' : 'outlined'}
                      />
                    )) : ""
                  }
                </Box>
              </Box>
              {isLoading ? <SkeletonChart height={350} /> :
                this.state.chart_series && this.state.chart_series.length > 0 ? (
                  <ReactApexChart series={this.state.chart_series} type={this.state.chart_options.chart.type} options={this.state.chart_options} height={this.state.chart_options.chart.height}></ReactApexChart>
                ) : <Box sx={{ height: 270, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f7f7f7' }}>
                  <Typography variant='caption'>No data</Typography>
                </Box>}
            </Box>
            
            {isLoading ? <SkeletonListAvatar rows={3} /> :
              <>
               
                <Box style={topMargin}>
                <HeadingExport style={{ paddingBottom: '1in' }} />
                  <Box sx={{ flex: '50%', textAlign: 'center' }}>
                    <Typography color={'textSecondary'} variant='caption' component={'div'}>{selectedSector ? "(Filtered by " + selectedSector + ")" : ""}</Typography>
                    {selectedSector ? <Button variant='outlined' size='small' onClick={() => this.reset()}>reset filter</Button> : ""}
                  </Box>
                </Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Code</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Weight</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Direction</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allocation.length > 0 ? (
                      allocation.map((obj) =>
                        !selectedSector || obj.sector === selectedSector || obj.mn === selectedSector ? (
                          <TableRow sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: 'action.hover',
                            },
                            padding: '6px',
                          }} key={obj.id} >
                            <TableCell sx={{padding: '6px',}}>{obj.tc}</TableCell>
                            <TableCell sx={{padding: '6px',}}>
                              <Typography  component="span" variant="caption" color="textSecondary">
                                {obj.n}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{padding: '6px', textAlign: 'center'}}>
                              {obj.isOpening === 1 && <Chip size="small" label="open today" />}
                              {obj.isClosing === 1 && <Chip size="small" label="exit today" />}
                            </TableCell>
                            <TableCell sx={{padding: '6px', textAlign: 'right'}}>
                              <Tooltip
                                title={
                                  'weight: ' + numeral(obj.percWeight * 100).format('0.0') + '%'
                                }
                              >
                                <span data-testid="weightTooltip">
                                  {numeral(obj.percWeight * 100).format('0.0')}%
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell sx={{padding: '6px', textAlign: 'right'}}>{numeral(obj.quantity).format('0,000.[0]')}</TableCell>
                            <TableCell sx={{padding: '6px', textAlign: 'center'}}>
                              <Typography  variant="caption" color="textSecondary">
                                {obj.direction}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : null
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Typography variant="caption">No data</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </>
            }
          </CardContent>
        </Card>
      </>
    );
  }
}

export default withTheme(PortfolioAllocationExport);
