import { withTheme } from '@emotion/react';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, RocketLaunchOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notifyPtfSettings } from '../dispatcher/Actions';
import SettingsGlobal from '../elements/SettingsGlobal';
import SettingsMarkets from '../elements/SettingsMarkets';
import SettingsModel from '../elements/SettingsModel';
import SettingsSectors from '../elements/SettingsSectors';
import log from 'loglevel';

const steps = [
  'Global',
  'Model',
  'Markets',
  'Sectors',
];

function DialogBuild(props) {
  const [alertName, setAlertName] = useState("");
  const [alertChains, setAlertChains] = useState("");
  const [alertSectors, setAlertSectors] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [stepsCompleted, setStepsCompleted] = useState({});
  const [preset, setPreset] = useState({});
  const dispatch = useDispatch();

  const resetState = () => {
    setActiveStep(0);
  }

  // useEffect(() => {
  //   const ws = new WebSocket('ws://192.168.1.112:5000?token=' + sessionStorage.getItem('TOKEN'));
  //   console.log("WS STARTED");

  //   ws.onopen = (event) => {
  //     ws.send(JSON.stringify("connetcing"));
  //   };

  //   ws.onmessage = (event) => {
  //     const message = JSON.parse(event.data);
  //     console.log("WS RECEIVED", message);
  //   };

  //   ws.onerror = function (event) {
  //     console.log("WS error observed:", event);
  //   };

  //   return () => {
  //     // ws.close();
  //   };
  // }, []);
  useEffect(() => {
    const presets = { ...props.preset };
    setPreset(presets)
    dispatch(notifyPtfSettings(JSON.stringify(presets)))
  }, [props.preset]);

  useEffect(() => {
    setAlertName(props.alertName);
    setAlertChains(props.alertChains);
    setAlertSectors(props.alertSectors);
  }, [props]);

  const handleClose = () => {
    props.onClose();
    setTimeout(() => {
      resetState()
    }, 500);
  };

  const sendData = (key, value) => {
    props.sendData(key, value);
  };

  const sendSectorsOrMarkets = (key, value) => {
    props.sendSectorsOrMarkets(key, value);
  };

  const sendBuild = (isRebuild) => {
    props.buildPortfolio(isRebuild);
    setTimeout(() => {
      resetState()
    }, 500);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const stepperReady = (stepComponent, isReady) => {
    if (!isNaN(stepComponent)) {
      let completed = stepsCompleted
      log.debug('Step ' + stepComponent, 'Complete? ' + isReady)
      completed['step_' + stepComponent] = isReady
      setStepsCompleted(completed)
    }
    return props.stepperReady()
  }

  return (
    <Dialog maxWidth='md' scroll='paper' fullWidth open={props.open} onClose={handleClose}>
      <DialogTitle>Build your portfolio</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} sx={{ my: 2 }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <Box sx={{ display: 'flex', flexDirection: 'column', p: 4 }}>
          <Box sx={{ display: activeStep === 0 ? 'block' : 'none' }}>
            <SettingsGlobal alert={alertName} preset={preset} sendData={sendData} isRebuild={props.isRebuild} step={0} ready={stepperReady} />
          </Box>
          <Box sx={{ display: activeStep === 1 ? 'block' : 'none' }}>
            <SettingsModel alert={alertChains} preset={preset} sendData={sendData} isRebuild={props.isRebuild} step={1} ready={stepperReady} />
          </Box>
          <Box sx={{ display: activeStep === 2 ? 'block' : 'none' }}>
            <SettingsMarkets alert={alertChains} preset={preset} sendSectorsOrMarkets={sendSectorsOrMarkets} isRebuild={props.isRebuild} step={2} ready={stepperReady} />
          </Box>
          <Box sx={{ display: activeStep === 3 ? 'block' : 'none' }}>
            <SettingsSectors alert={alertSectors} preset={preset} sendSectorsOrMarkets={sendSectorsOrMarkets} isRebuild={props.isRebuild} step={3} ready={stepperReady} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-between', width: '100%' }}>
          <Button
            startIcon={<KeyboardDoubleArrowLeft />}
            color={"inherit"}
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, }}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ?
            <Button
              startIcon={<RocketLaunchOutlined />}
              sx={{ minWidth: 180 }}
              disabled={props.isLoading || !stepperReady() ? true : false}
              disableElevation
              color="cta"
              variant={'contained'} size={'large'}
              onClick={() => sendBuild(props.isRebuild)}>
              {props.isLoading ? "Building..." :
                props.isRebuild ? "Re-build portfolio" : "Build portfolio"}
            </Button> :
            <Button onClick={handleNext} disableElevation variant='contained' disabled={stepsCompleted['step_' + activeStep] ? false : true}
              endIcon={<KeyboardDoubleArrowRight />}
            >
              Next
            </Button>
          }
        </Box>

      </DialogActions>
    </Dialog >
  );
}

export default withTheme(DialogBuild);
