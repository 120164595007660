import React from "react";
import { Box } from '@mui/material';
import { Skeleton } from '@mui/material';

function SkeletonList2() {
    return (
        <div>
            {[1, 2, 3, 4].map((obj, index) => (
                <Box key={index.toString()} display="flex" alignItems="center" flexDirection="row" height="48px">
                    <Box display="flex" alignItems="start" flexDirection="row" width="100%">
                        <Skeleton variant="text" width="60%" />
                        <Skeleton variant="text" width="10%" sx={{ ml: "auto" }} />
                    </Box>
                </Box>
            ))}
        </div>
    );
}

export default SkeletonList2;