import { withTheme } from '@emotion/react';
import { ArticleOutlined } from '@mui/icons-material';
import { Box, Card, CardActionArea, CardContent, CardHeader, Skeleton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiVitrum } from "../../api/apiVitrum";
import { notifyVitrumModel } from '../../dispatcher/Actions';

const ModelArea = (props) => {
    const [loading, setLoading] = useState(true)
    const [selected, setSelected] = useState(0)
    const [models, setModels] = useState(null)
    const dispatch = useDispatch();
    const vitrumModel = useSelector(state => state.vitrumModel)

    const color = {
        "BUY": props.theme.palette.long.main,
        "SELL": props.theme.palette.short.main,
        "NEUTRAL": props.theme.palette.neutral.main,
        "NA": "transparent",
    }

    const numRows = 6; // Number of rows you want to display
    const numColumns = 4; // Number of columns you want to display

    useEffect(() => {
        dispatch(notifyVitrumModel(0))
        setSelected(parseInt(0))
        loadModels()
    }, [])

    useEffect(() => {
        if (vitrumModel) {
            dispatch(notifyVitrumModel(parseInt(vitrumModel)))
            setSelected(parseInt(vitrumModel))
        }
    }, [models]);

    const loadModels = () => {
        setLoading(true)
        apiVitrum.get(`/v1/models/`, { "id": "models" }).then(response => {
            const data = response.data
            data.sort((a, b) => a.modelShortName.toUpperCase().localeCompare(b.modelShortName.toUpperCase()));
            setModels(data)
            setSelected(parseInt(vitrumModel))
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        })

    }

    const select = (e) => {
        dispatch(notifyVitrumModel(e.currentTarget.id))
        setSelected(e.currentTarget.id * 1)
    }

    const generateBoxes = () => {
        const boxes = [];
        const numColumns = 4; // Number of columns you want to display
        for (let i = 0; i < models.length; i++) {
            let model = models[i]
            boxes.push(
                <Card
                    key={`box-${model.id}`}
                    sx={{
                        width: "calc(25% - 6px)",
                        borderRadius: 1,
                        border: 2,
                        bgcolor: color[model.prediction],
                        borderColor: selected === model.id ? "white" : color[model.prediction],
                        position: "relative",
                        textAlign: "left",
                        lineHeight: 1.1
                    }}
                >
                    <Tooltip title={<Typography variant='caption'>{model.modelName + ": " + model.prediction}</Typography>} arrow>
                        <ArticleOutlined sx={{ position: "absolute", fontSize: 18, right: 1, zIndex: 1 }} />
                    </Tooltip>
                    <CardActionArea id={model.id} onClick={select} disabled={model.prediction === "UNAVAILABLE"} sx={{ cursor: "pointer" }}>
                        <CardContent>
                            <Typography variant="caption">{model.modelShortName}</Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>

            );
        }

        const rows = [];
        for (let i = 0; i < boxes.length; i += numColumns) {
            rows.push(
                <Box key={`row-${i}`} sx={{ display: 'flex', gap: 1, mb: 1, width: "100%" }}>
                    {boxes.slice(i, i + numColumns)}
                </Box>
            );
        }

        return rows;
    };


    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader title={<Typography variant='overline'>Model showdown</Typography>} />
            <CardContent>
                {loading ?
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {["r1", "r2", "r3", "r4", "r5", "r6"].map((item, index) =>
                            <Box key={item} sx={{ display: "flex", gap: 1 }}>
                                {[1, 2, 3, 4].map((childItem, index) =>
                                    <Skeleton key={item + index} sx={{ width: "25%", height: 51 }} />
                                )}
                            </Box>
                        )}
                    </Box>
                    : models ? generateBoxes() : "No data"
                }</CardContent>
        </Card >
    );
};

export default withTheme(ModelArea);
