export const notifyReload = (value) => {
    return {
        type: "RELOAD",
        value: Date.now()
    }
}

export const notifyReloaded = () => {
    return {
        type: "RELOAD",
        value: true
    }
}

export const notifyInstrument = (id) => {
    return {
        type: "INSTRUMENT",
        value: id
    }
}

export const notifyCustomView = (id) => {
    return {
        type: "CUSTOM_VIEW",
        value: id
    }
}

export const notifyTheme = (id) => {
    return {
        type: "THEME",
        value: id
    }
}

export const notifyArea = (id) => {
    return {
        type: "AREA",
        value: id
    }
}

export const notifyVitrumModel = (id) => {
    return {
        type: "VITRUM_MODEL",
        value: id
    }
}

export const notifyPerms = (id) => {
    return {
        type: "PERMS",
        value: id
    }
}

export const notifyPtfSettings = (id) => {
    return {
        type: "BUILT_PTF_SETTINGS",
        value: id
    }
}
