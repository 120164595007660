export function store(k, v) {
    if (v) {
        v = JSON.stringify(v, (key, value) => {
            return value
        })
        sessionStorage.setItem(k, v)
    }
}

/**
 * get element from storage
 * @param {*} k 
 * @returns 
 */
export function restore(k) {
    let memo = sessionStorage.getItem(k)
    memo = JSON.parse(memo)
    return memo
}

export function cancel(k) {
    sessionStorage.removeItem(k)
}

export function getStorageName(val) {
    return val.replaceAll(" ", "")
}