import { withTheme } from '@emotion/react';
import { Card, CardContent, CardHeader, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


const ModelPrediction = (props) => {
    const [loading, setLoading] = useState(false)
    const theme = useSelector(state => state.theme)
    const vitrumModel = useSelector(state => state.vitrumModel)

    useEffect(() => {

    }, [vitrumModel])

    return (
        <Card>
            <CardHeader title={<Typography variant='overline'>Model prediction</Typography>} />
            <CardContent>
                <Typography className='big' variant='h4'>Model id: {vitrumModel}</Typography>
                <Skeleton animation={false} sx={{ height: 350, transform: "scale(1)" }} />
            </CardContent>
        </Card>
    );
};

export default withTheme(ModelPrediction);
