import { createTheme } from "@mui/material";

const lighttheme = createTheme({
  green: '#1eb980',
  red: '#ff6859',
  empty: {
    background: '#c2c2c2',
    width: '100%',
    height: '100px'
  },
  cardSettings: {
    background: '#3d5afe11'
  },
  button: { textTransform: 'capitalize' },
  paper: { background: 'trasparent', boxShadow: 'none', padding: '16px' },
  palette: {
    typography: {
      fontFamily: [
        'Roboto',
        'sans-serif'
      ].join(','),
    },
    emptyChart: {
      background: '#e6e6e6',
      width: '100%',
      height: '100px'
    },
    accent: '#15BAC0',
    chartPrimary: '#3A75FD',
    chartPrimaryLight: '#3A75FD',
    chartSecondary: '#FFB623', //salmone ff8860
    chartSecondaryLight: '#FFB623', //salmone ff8860
    chartThird: '#B77EA3', //violetto B77EA3
    edges: '#d2d2d2',
    grid: '#f2f2f2',
    pieBorders: '#ffffff',
    background: {
      primary: '#F7F7F7',
      default: '#F7F7F7',
      paper: '#fff',
      innerPaper: '#fafafa'
    },
    default: {
      main: '#323537',
    },
    text: {
      primary: '#323537',
      secondary: '#a0a0a0',
      red: '#f00',
      disabled: '#b3b3b9',
    },
    textSecondary: {
      main: '#b3b3b9',
    },
    textOnLight: {
      main: "#000",
    },
    success: {
      main: '#15BAC0',
      //contrastText: "#FFFFFF"
    },
    cta: {
      main: '#ff8a5e',
      light: '#ff956e',
      dark: '#e57c54',
      contrastText: "#484848"
    },
    long: {
      main: '#27d17f',
      contrastText: "#212121"
    },
    short: {
      main: '#ff6859',
      contrastText: "#f0f0f0"
    },
    neutral: {
      main: '#e6e6e6',
    },
    primary: {
      main: '#1565C0',
      bgrnd: '#fafafa'
    },
    secondary: {
      main: '#e6e6e6',
      // dark: will be calculated from palette.secondary.main,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.1,
  },
  components: {
    common: {
      black: '#848484'
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: 'transparent',
        }
      }
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          '&:hover': {
            // background: "#f0f0f0",
          },
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #f0f0f0!important'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#f0f0f0',
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#f7f7f7',
          color: '#132F4C',
          boxShadow: 'none',
          borderRadius: '0!important',
          border: "0px solid #8d21e9!important",
          borderColor: "#8d21e9!important",
          margin: 0,
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          borderRadius: 8,
          boxShadow: 'none',
          height: 'auto',
          maxHeight: '100%',
          //margin: '8px',
          border: 0,
        },
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: 8,
        },
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          fontWeight: 300,
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: "1px solid inherit!important",
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#ccc!important',
        },
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          // backgroundColor: '#fff!important',
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 'inherit',
        },
        overline: {
          fontWeight: 400,
          letterSpacing: '.3em !important',
        },
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: "transparent!important",
          },
          '&:after': {
            position: "absolute",
            left: 0,
            top: -1,
            right: 0,
            height: 1,
            content: '""',
            opacity: 1,
            backgroundColor: "#e6e6e6!important",
          },
        },
      }
    },
  }
});

export default lighttheme;