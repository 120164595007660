import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from 'react';

const SkeletonTable = (props) => {
    const printSkeleton = (cb) => {
        const row = [];
        for (let i = 0; i < (props.rows ? props.rows : 5); i++) {
            row.push(
                <TableRow key={"skeletonRow_" + i}>
                    {
                        Array.from({ length: props.cols }).map((obj, i) =>
                            <TableCell key={"skeletonCell_" + i}><Skeleton sx={{height: 24}} variant="rectangular" /></TableCell>
                        )
                    }
                </TableRow>
            );
        }
        return row;
    };

    return (
        <>{printSkeleton()}</>
    );
}

export default SkeletonTable;