import OneSignal from 'react-onesignal';

export default async function notificationsInit(messagingtoken) {
  /*await OneSignal.init({
    appId: 'a6d0f899-70a4-4bba-b001-1122360d3fce',
    safari_web_id: "web.onesignal.auto.3c5e9739-5d2f-4f69-94b2-78aed3043174", 
    notifyButton: {
      enable: true,
    },
  });*/
  // console.log('OneSignal initializing...')
  OneSignal.init({
    appId: 'a6d0f899-70a4-4bba-b001-1122360d3fce', // PROD
    //appId: "949455d5-fe1f-4e53-a189-9ba7d6ec1dda", // LOC
    safari_web_id: "web.onesignal.auto.3c5e9739-5d2f-4f69-94b2-78aed3043174",
    notifyButton: {
      enable: true,
      position: 'bottom-left', /* Either 'bottom-left' or 'bottom-right' */
      size: 'small',
      theme: 'inverse', /* One of 'default' (red-white) or 'inverse" (white-red) */
      offset: {
        bottom: '16px',
        left: '16px', /* Only applied if bottom-left */
        //right: '0px' /* Only applied if bottom-right */
      },
      colors: { // Customize the colors of the main button and dialog popup button
        'circle.background': '#fff',
        'circle.foreground': '#1565C0',
        'badge.background': 'green',
        'badge.foreground': 'white',
        'badge.bordercolor': 'white',
        'pulse.color': 'white',
        'dialog.button.background.hovering': 'blue',
        'dialog.button.background.active': 'red',
        'dialog.button.background': '#1565C0',
        'dialog.button.foreground': 'white'
      },
      text: {
        'tip.state.unsubscribed': 'Subscribe to notifications',
        'tip.state.subscribed': "You're subscribed to notifications",
        'tip.state.blocked': "You've blocked notifications",
        'message.prenotify': 'Click to subscribe to notifications',
        'message.action.subscribed': "Thanks for subscribing!",
        'message.action.resubscribed': "You're subscribed to notifications",
        'message.action.unsubscribed': "You won't receive notifications again",
        'dialog.main.title': 'Manage Notifications',
        'dialog.main.button.subscribe': 'SUBSCRIBE',
        'dialog.main.button.unsubscribe': 'UNSUBSCRIBE',
        'dialog.blocked.title': 'Unblock Notifications',
        'dialog.blocked.message': "Follow these instructions to allow notifications:"
      },
      displayPredicate: function () {
        return OneSignal.isPushNotificationsEnabled()
          .then(function (isPushEnabled) {
            return !isPushEnabled;
          });
      }
    },
    //allowLocalhostAsSecureOrigin: true, // LOC
    promptOptions: {
      slidedown: {
        prompts: [
          {
            type: "push", // current types are "push" & "category"
            autoPrompt: true,
            text: {
              /* limited to 90 characters */
              actionMessage: "Attiva le notifiche per non perdere i movimenti sui tuoi portafogli.",
              /* acceptButton limited to 15 characters */
              acceptButton: "Attiva",
              /* cancelButton limited to 15 characters */
              cancelButton: "Annulla"
            },
            delay: {
              pageViews: 1,
              timeDelay: 20
            }
          }
        ]
      }
    }
  }).then(function () {
    // console.log('oneSignal started');
    // console.log('oneSignal check for permissions...')
    OneSignal.getNotificationPermission().then(function (data) {
      if (data == 'granted') {
        // console.log('oneSignal GOOD: permission ' + data)
        OneSignal.getExternalUserId().then(function (externalUserId) {
          if (messagingtoken != externalUserId) {
            // console.log('oneSignal setting external uid ', messagingtoken)
            OneSignal.setExternalUserId(messagingtoken);
          }
        });
      } else {
        // console.log('oneSignal permission ' + data)
        OneSignal.showSlidedownPrompt();
      }
      // console.log('oneSignal end.')
    });
  });

  OneSignal.on('subscriptionChange', function (isSubscribed) {
    // console.log("oneSignal The user's subscription state is now:", isSubscribed);
    OneSignal.setExternalUserId(messagingtoken);
    // console.log("oneSignal The user's eud is now set:", messagingtoken);
  });
  //OneSignal.showSlidedownPrompt();

  //window.OneSignal = OneSignal;

}