import { withTheme } from '@emotion/react';
import { AddOutlined, CompareArrowsOutlined, FastForwardOutlined, RocketLaunchOutlined } from '@mui/icons-material';
import { Box, Button, Card, CardActionArea, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, Grid, Snackbar, TextField, Toolbar, Tooltip, Typography, Zoom } from '@mui/material';
import { grey } from '@mui/material/colors';
import log from 'loglevel';
import numeral from 'numeral';
import { Component } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { api } from "../api/apiBuilder";
import DialogBuild from '../commons/DialogBuild';
import DialogCompare from '../commons/DialogCompare';
import getMarketName from '../commons/MarketNames';
import { cancel, getStorageName, restore, store } from '../commons/Memo';
import getSectorName from '../commons/SectorNames';
import getStrengthName from '../commons/StrengthNames';
import { notifyArea, notifyPtfSettings } from '../dispatcher/Actions';
import Allocation from '../elements/Allocation';
import Chart from '../elements/Chart';
import Quarters from '../elements/Quarters2';
import Report from '../elements/Report';
import Trades from '../elements/Trades';

function getStoredBuilds() {
    let builds = []
    Object.keys(sessionStorage).forEach((k) => {
        if (k.startsWith("BUILD_")) {
            builds.push(restore(k))
        }
    });

    builds.sort(function (a, b) { return a.timestamp - b.timestamp });

    return builds
}

function getExistingBuildTime(buildId) {
    let build = restore("BUILD_" + buildId)
    let ret = 0
    if (build) {
        if (build.timestamp) {
            ret = build.timestamp
        }
    }

    return ret
}

class BuilderBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoDialog: false,
            ready: false,
            isLoading: false,
            isLoading2: false,
            isSaving: false,
            snackOpen: false,
            snackMessage: '',
            dialogOpen: false,
            compareOpen: false,
            logged: sessionStorage.getItem('TOKEN'),
            selected: 0,
            preset: null,
            postData: {
                "settings": {
                    "name": 'My Portfolio',
                    "size": 0,
                    "init": 0,
                    "quality": 0,
                    "strategy": null
                },
                "chains": [

                ],
                "sectors": [

                ]
            },
            summary: null,
            summaryFull: null,
            allocation: null,
            performances: null,
            performancesFull: null,
            quarters: null,
            activeStatus: true,
            alertName: null,
            alertChains: null,
            alertSectors: null,
            builds: [],
            isRebuild: 0,
        }
        this.ws = null; // Store the WebSocket connection
    }

    checkParams() {
        let body = this.state.postData;
        let ret = true

        if (!body.settings.size) { this.setState({ snackMessage: 'Size is mandatory' }); return false };
        if (!body.settings.init) { this.setState({ snackMessage: 'Init is mandatory' }); return false };
        if (!body.settings.quality) { this.setState({ snackMessage: 'Quality is mandatory' }); return false };
        if (!body.settings.strategy) { this.setState({ snackMessage: 'Strategy is mandatory' }); return false };

        if (!body.settings.name) {
            this.setState({
                alertName: 'Name is mandatory'
            });
            ret = false
        } else this.setState({ alertName: null });

        if (body.chains.length === 0) {
            this.setState({
                alertChains: 'At least 1 market must be selected'
            });
            ret = false
        } else this.setState({ alertChains: null });

        if (body.sectors.length === 0) {
            this.setState({
                alertSectors: 'At least 1 sector must be selected'
            });
            ret = false
        } else this.setState({ alertSectors: null });

        return ret;
    }

    rebuildPortfolio = (start, end) => {
        this.setState({ isLoading2: true, snackOpen: false, isRebuild: true });
        const performancesFull = this.state.performancesFull
        const summaryFull = this.state.summaryFull

        if (performancesFull) {
            const body = {
                "ptf_line": this.state.performancesFull.portfolio.series,
                "bmrk_line": this.state.performancesFull.benchmark.series,
                "inversion": this.state.performancesFull.inversion.series,
                "start_date": start,
                "end_date": end
            }

            const config = {
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('TOKEN')
                }
            }
            api.put('/v1/builder/rebuild/', body, config).then(response => {
                //const data = JSON.parse(response.data);  // from old APIs
                const data = (response.data)  // from new APIs

                let performances = this.state.performances

                let metrics = data.metrics
                // var non cambia mai. Per questo viene presa sempre quella dal valore full
                metrics.portfolio.var = this.state.summaryFull.portfolio.var
                metrics.benchmark.var = this.state.summaryFull.benchmark.var

                performances.portfolio.series = data.performances.portfolio
                performances.benchmark.series = data.performances.benchmark
                performances.inversion.series = data.performances.inversion

                this.setState({ performances, summary: metrics, isLoading2: false })
            }).catch(error => {
                log.error(error)
                this.setState({ isLoading2: false })
            }).finally(

            )
        } else {
            log.debug('performancesFull are empty!!!!');
        }
    }

    listenForBuilderEngine(clientId) {
        if (this.ws) {
            this.ws.close();
        }
        // Creazione dell'URL del WebSocket
        this.ws = new WebSocket(`${process.env.REACT_APP_WS_BUILDER_URL}/ws?token=${clientId}`);
        log.info("WS Starting");

        // Creazione della connessione WebSocket
        this.ws.onopen = (event) => {
            log.info("WS Started");
            this.ws.send(JSON.stringify("connecting"));
        };

        // Gestione dei messaggi ricevuti
        this.ws.onmessage = (event) => {
            log.info("WS PTF RECEIVED");
            const data = JSON.parse(event.data);
            log.debug("WS DATA", data);

            if (data) {
                let postData = this.state.postData
                let buildId = getStorageName(postData.settings.name)
                let buildTimestamp = getExistingBuildTime(buildId)
                if (!buildTimestamp) {
                    buildTimestamp = Date.now()
                }

                this.state.postData.settings.benchmark = data.performances.benchmark.name
                postData.settings.benchmark = data.performances.benchmark.name

                const copyPerformances = JSON.parse(JSON.stringify(data.performances));
                const performancesFull = copyPerformances
                data.performancesFull = performancesFull

                const copySummary = JSON.parse(JSON.stringify(data.summary));
                const summaryFull = copySummary
                data.summaryFull = summaryFull

                store("BUILD_" + buildId, {
                    settings: postData,
                    data,
                    id: buildId,
                    timestamp: buildTimestamp
                })
                this.props.dispatch(notifyPtfSettings(JSON.stringify(postData)))
                this.setState({
                    builds: getStoredBuilds(),
                    selected: buildId,
                    summary: data.summary,
                    allocation: data.allocation,
                    performances: data.performances,
                    performancesFull,
                    summaryFull,
                    quarters: data.quarters,
                    operations: data.operations,
                    isLoading: false,
                    ready: true,
                    postData
                });
            }
        };

        // Gestione degli errori
        this.ws.onerror = (event) => {
            log.error("WS error observed:", event);
            this.setState({
                isLoading: false,
                snackMessage: "Connection error. Please retry a new build (1)",
                snackOpen: true
            })
        };

        // Gestione della chiusura della connessione
        this.ws.onclose = (event) => {
            log.info("WS connection closed");
            if (event.code === 1006) {
                log.error("WS connection closed abnormally with code 1006");
                this.setState({
                    isLoading: false,
                    snackMessage: "Connection error. Please retry a new build (2)",
                    snackOpen: true
                })
            }
        };
    }

    buildPortfolio(start, end) {
        this.setState({ isLoading: true, snackOpen: false, ready: false });
        if (this.checkParams()) {
            this.setState({ infoDialog: false });
            let body = this.state.postData;
            const clientId = sessionStorage.getItem('TOKEN')

            body.open_date = start && start.length === 8 ? start : "20191001"
            body.clientId = clientId

            api.post('/v1/builder/settings/', body)
                .then(response => {
                    this.listenForBuilderEngine(clientId)
                }).catch(error => {
                    this.setState({ isLoading: false })
                    if (error.response) {
                        log.error('Error 1', error.message);
                        if (error.response.status === 401) {
                        }
                        this.setState({
                            snackMessage: error.response.data,
                            snackOpen: true
                        })
                    } else if (error.request) {
                        log.error('Error 2', error.message);
                        this.setState({
                            snackMessage: error.message,
                            snackOpen: true
                        })
                    } else {
                        log.error('Error 3', error.message);
                        this.setState({
                            snackMessage: error.message,
                            snackOpen: true
                        })
                    }
                })
        } else {
            log.error("buildPortfolio failed. checkParams")
            this.setState({ isLoading: false });
        }
    }

    confirm(name, storedName) {
        let settings = this.state.postData.settings
        settings.name = name
        this.setState({ isSaving: true, snackOpen: false });

        let body = {
            settings: settings,
            chains: this.state.postData.chains,
            sectors: this.state.postData.sectors,
            allocation: this.state.allocation
        };

        let config = {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('TOKEN')
            }
        }
        api.post('/v1/builder/portfolios/', body, config)
            .then(response => {
                const data = (response.data);
                this.setState({ isLoading: false, ready: false });
                this.props.navigate('/portfolio/' + data.portfolioId)
                this.handleDelete(storedName)
                log.info("Portfolio " + name + " subscribed")
            }).catch(error => {
                this.setState({ isLoading: false })
                if (error.response) {
                    this.setState({ isLoading: false });
                    this.setState({ ready: false });
                    log.error('Error 1', error.message);
                    if (error.response.status === 401) {
                    } else if (error.response.status === 403) {
                        this.setState({ errMsg: error.response.data.error })
                    }
                    this.setState({
                        snackMessage: error.response.data,
                        snackOpen: true
                    })
                } else if (error.request) {
                    // The request was made but no response was received
                    this.setState({ isLoading: false });
                    this.setState({ ready: false });
                    log.error('Error 2', error.message);
                    this.setState({
                        snackMessage: error.message,
                        snackOpen: true
                    })
                } else {
                    // Something happened in setting up the request that triggered an Error
                    this.setState({ isLoading: false });
                    this.setState({ ready: false });
                    log.error('Error 3', error.message);
                    this.setState({
                        snackMessage: error.message,
                        snackOpen: true
                    })
                }
            })
    }

    openDialogMessage(id) {
        if (id) {
            this.handleSwitch(id)
        }
        this.setState({ dialogOpen: true })
    }
    closeDialogMessage() {
        this.setState({ dialogOpen: false })
    }

    openDialogCompare() {
        this.setState({ compareOpen: true })
    }
    closeDialogCompare() {
        this.setState({ compareOpen: false })
    }

    snackClose() {
        this.setState({ snackOpen: false });
    }

    componentDidMount() {
        this.props.dispatch(notifyArea("builder"))

        let builds = getStoredBuilds()

        if (builds && builds.length > 0) {
            const presets = builds[0].settings
            this.props.dispatch(notifyPtfSettings(JSON.stringify(presets)))
        } else {
            log.debug("NO presets");
        }

        this.loadDefaultBuild()
        this.setState({ infoDialog: builds.length === 0 ? true : false, builds })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            log.debug("dispatching", "builder");
        }
    }

    changeTheme(v) {
        this.setState({ selectedTheme: v })
        if (v) v = 'light'
        else v = 'dark'
        this.props.changeTheme(v)
    }

    closeInfoDialog() {
        this.setState({ infoDialog: false })
    }

    handleDelete(id) {
        const trimmedId = getStorageName(id)
        log.info("DELETE " + trimmedId)
        cancel("BUILD_" + trimmedId)
        this.loadDefaultBuild()
    }

    loadDefaultBuild() {
        let builds = getStoredBuilds()
        let buildsNumber = builds.length
        let selected = this.state.selected
        if (!selected && buildsNumber > 0) {
            selected = builds[0].id
            this.handleSwitch(selected)
        } else if (buildsNumber > 0) {
            let exists = getExistingBuildTime(selected)
            if (!exists) selected = builds[0].id
            this.handleSwitch(selected)
        } else {
            log.debug("c'è vuotooo");
            this.setState({ ready: false });
        }
        this.setState({ selected, builds })

        if (builds && builds.length > 0) {
            const presets = builds[0].settings
            this.props.dispatch(notifyPtfSettings(JSON.stringify(presets)))
        }
    }

    handleSwitch(id) {
        let stored = restore("BUILD_" + id)
        let settings = stored.settings
        settings.id = id
        let data = stored.data

        this.setState({
            selected: id,
            summary: data.summary,
            allocation: data.allocation,
            performances: data.performances,
            performancesFull: data.performancesFull,
            summaryFull: data.summaryFull,
            quarters: data.quarters,
            operations: data.operations,
            isLoading: false,
            ready: true,
            postData: settings,
            preset: settings,
        });

    }

    openSettingsFromDialog(id) {
        if (id) {
            this.handleSwitch(id)
            this.openSettings(true)
        } else {
            this.openSettings()
        }
    }

    stepperReady = () => {

        let postData = this.state.postData

        if (!postData.chains) postData.chains = []
        if (!postData.sectors) postData.sectors = []

        // Check if name and currency are not empty
        if ((postData.settings.name && postData.settings.name.trim() === '') || (postData.settings.currency && postData.settings.currency.trim() === '')) {
            return false;
        }

        // Check if size, init, and quality are greater than 0 and are numeric
        if (isNaN(postData.settings.size) || isNaN(postData.settings.init) || isNaN(postData.settings.quality) ||
            postData.settings.size <= 0 || postData.settings.init <= 0 || postData.settings.quality <= 0) {
            return false;
        }

        // Check if chains and sectors contain at least 1 item, which is numeric
        if (postData.chains.length === 0 || postData.sectors.length === 0) {
            return false;
        }
        // Checking if chains and sectors contain at least one numeric value
        const numericChains = postData.chains.filter(item => typeof item === 'number');
        const numericSectors = postData.sectors.filter(item => typeof item === 'number');
        if (numericChains.length === 0 || numericSectors.length === 0) {
            return false;
        }

        // All conditions are met
        return true;
    }

    openSettings(isRebuild) {
        let defaultSettings = {
            "settings": {
                "name": "",
                "currency": "EUR",
                "size": 50,
                "init": 1000000,
                "quality": 0.5,
                "strategy": 'short'
            },
            "chains": [

            ],
            "sectors": [

            ]
        }
        let preset = isRebuild ? this.state.preset : defaultSettings
        if (!isRebuild) {
            this.setState({
                infoDialog: true,
                selected: 0,
                summary: null,
                allocation: null,
                performances: null,
                performancesFull: null,
                quarters: null,
                operations: null,
                isLoading: false,
                ready: false,
                postData: defaultSettings,
                preset: defaultSettings,
                isRebuild: false,
                compareOpen: false,
            });
        } else {
            if (!preset) {
                this.loadDefaultBuild()
            }

            this.setState({ infoDialog: true, ready: true, isRebuild: true, preset: this.state.preset })
        }
    }

    DEPRECATED_onChangeSettings(name, value) {
        // log.debug(' ----- RECEIVED -----');
        // log.debug({ ...value });
        let postData = this.state.postData
        postData[name] = value
        // log.debug(' ----- TRANSFORMED -----');
        // log.debug({ ...postData.settings });
        this.setState({ postData })
    }

    onChangeSectorsOrMarkets(name, value) {
        let postData = this.state.postData
        postData[name] = value
        log.debug(postData);
        this.setState({ postData })
    }

    onChangeSettings(name, value) {
        let postData = this.state.postData
        postData.settings[name] = value
        this.setState({ postData })
    }

    render() {
        const { theme } = this.props;
        const { isRebuild, dialogOpen, compareOpen, postData, errMsg, ready, isLoading, infoDialog, preset, quarters, performances, summary, operations, allocation, alertName, alertChains, alertSectors, builds, selected } = this.state;
        return (
            <div>
                <Toolbar />
                <Container maxWidth='xl'>
                    <Snackbar
                        open={this.state.snackOpen}
                        autoHideDuration={20000}
                        message={this.state.snackMessage}
                        onClose={(e) => this.snackClose()}
                    >
                    </Snackbar>
                    <DialogCompare open={compareOpen} openSettings={this.openSettingsFromDialog.bind(this)} openDialogMessage={this.openDialogMessage.bind(this)} closeDialogCompare={this.closeDialogCompare.bind(this)} builds={builds} />
                    <DialogMessage open={dialogOpen} errMsg={errMsg} recap={postData} confirm={this.confirm.bind(this)} closeDialogMessage={this.closeDialogMessage.bind(this)}></DialogMessage>
                    <DialogBuild open={infoDialog}
                        onClose={() => this.closeInfoDialog()}
                        buildPortfolio={this.buildPortfolio.bind(this)}
                        sendData={this.onChangeSettings.bind(this)}
                        sendSectorsOrMarkets={this.onChangeSectorsOrMarkets.bind(this)}
                        isLoading={isLoading}
                        alertName={alertName}
                        alertChains={alertChains}
                        alertSectors={alertSectors}
                        stepperReady={this.stepperReady.bind(this)}
                        preset={preset}
                        isRebuild={isRebuild}
                    />

                    <Grid sx={{ pt: 3 }} container spacing={1}>

                        <Grid container xl={12} spacing={1}>
                            <Grid item xs={10}>
                                <Box sx={{ display: 'flex', ml: 1, alignItems: 'center' }}>
                                    <div>
                                        {
                                            builds && builds.length > 0 ?
                                                builds.map((obj) => (
                                                    obj ? <Chip
                                                        disabled={isLoading ? true : false}
                                                        size='small'
                                                        sx={{ mr: 1 }}
                                                        variant={selected === obj.id ? 'filled' : 'filled'}
                                                        color={selected === obj.id ? 'primary' : 'secondary'}
                                                        onClick={() => this.handleSwitch(obj.id)}
                                                        onDelete={() => this.handleDelete(obj.id)}
                                                        clickable
                                                        label={obj.settings.settings.name}
                                                    />
                                                        : ""
                                                )) : ""
                                        }
                                    </div>
                                    {builds.length < 3 ?
                                        <Button disabled={isLoading ? true : false} startIcon={<AddOutlined />} size='small' sx={{ mr: 1 }} onClick={() => this.openSettings()}>New build</Button>
                                        : <Tooltip arrow title={'Max 3 portfolios'}><Button startIcon={<AddOutlined />} size='small' sx={{ mr: 1 }} color='secondary'>New build</Button></Tooltip>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={2} textAlign='right'>
                                {builds.length > 1 ?
                                    <Button disabled={isLoading ? true : false} disableElevation size='small' sx={{ mr: 1 }} color='primary' variant='contained' endIcon={<CompareArrowsOutlined />}
                                        onClick={() => this.openDialogCompare()}>Compare</Button>
                                    : <Button color='primary' disableElevation disabled>Compare</Button>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ my: 3 }} />
                            </Grid>

                            {ready || isLoading ? (
                                <>
                                    <Grid container item xs={12} alignContent='start'>
                                        <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                                            {isLoading ?
                                                <Typography variant='h6'>Building your portfolio. The process may take up to one minute... <CircularProgress size={16} /></Typography>
                                                :
                                                <>
                                                    <Typography variant='h6'>{this.state.postData.settings.name} snapshot</Typography>
                                                    <Button disabled={isLoading ? true : false} sx={{ ml: 2 }} variant='outlined' size='small' onClick={() => this.openSettings(true)}>Edit</Button>
                                                </>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid container item xs={7} alignContent='start' sx={{ mb: 12 }} spacing={1}>
                                        <Grid item xs={12}>
                                            <Chart rebuildPortfolio={this.rebuildPortfolio} isLoading={this.state.isLoading} isLoading2={this.state.isLoading2} fromDate={this.state.postData.settings.date} theme={theme} performances={performances}></Chart>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Report version='general' isLoading={this.state.isLoading} summary={summary}></Report>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Report version='risk' isLoading={this.state.isLoading} summary={summary}></Report>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Quarters isLoading={this.state.isLoading} theme={theme} quarters={quarters}></Quarters>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Trades isLoading={this.state.isLoading} theme={theme} operations={operations} currency={this.state.postData.settings.currency}></Trades>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={5} alignContent='start' sx={{ mb: 12 }}>
                                        <Grid item xs={12}>
                                            <Allocation isLoading={this.state.isLoading} theme={theme} allocation={allocation}></Allocation>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : <Grid container item sx={{ width: '100%', height: 'calc(100vh - 300px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Card sx={{ p: 5, borderRadius: '50%', width: 350, height: 350, p: 0 }}>
                                    <CardActionArea onClick={() => this.openSettings()} sx={{ p: 7, borderRadius: '50%', width: 350, height: 350, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <Box sx={{ mb: 2 }}>
                                            <RocketLaunchOutlined style={{ fontSize: 160, color: grey[300] }} />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' color='textSecondary' sx={{ textTransform: 'initial', fontWeight: 400 }}>Build new portfolio</Typography>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Zoom
                        key={'fab'}
                        in={ready}
                        timeout={200}
                        style={{
                            transitionDelay: '150ms',
                        }}
                    >
                        <Fab color="cta" variant="extended" sx={{ position: 'fixed', right: '50%', bottom: 24 }} onClick={() => this.openDialogMessage()}>
                            Subscribe
                            <FastForwardOutlined sx={{ ml: 1 }} />
                        </Fab>
                    </Zoom>
                </Container>
            </div >
        );
    }
}

function Builder(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    return <BuilderBase navigate={navigate} dispatch={dispatch} {...props} />
}

export default withTheme(Builder);

class DialogMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storedName: '',
            name: '',
            err: false,
            errMsg: props.errMsg ? props.errMsg : ''
        }
    }

    handleClose() {
        this.props.closeDialogMessage()
    };
    handleConfirm() {
        this.props.confirm(this.state.name, this.state.storedName)
    }
    handleName(name) {
        log.debug(name)
        this.setState({ name, err: false, errMsg: '' })
    }

    componentDidMount() {
        if (this.props.settings)
            this.setState({ name: this.props.recap.settings.name, storedName: this.props.recap.settings.name })
    }

    componentDidUpdate(prevProps, prevState) {
        const { errMsg } = this.props;
        if (prevProps !== this.props && this.props.recap.settings) {
            this.setState({ name: this.props.recap.settings.name, storedName: this.props.recap.settings.name })
        }
        if (prevProps.errMsg === errMsg) {

        } else {
            this.setState({ errMsg, err: true })
        }

        if (this.state.preset !== prevState.preset) {
            log.debug("NEW STATE > ", this.state.preset)
        }
    }

    render() {
        const { recap } = this.props;
        const { errMsg, err } = this.state;
        return (
            <Dialog open={this.props.open} maxWidth={'sm'} fullWidth>
                <DialogTitle title='asdasd'>Portfolio subscription</DialogTitle>
                <DialogContent>
                    <Box>
                        <Box sx={{ p: 2 }} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography sx={{ mb: 1 }} variant='subtitle2'>
                                <TextField autoFocus defaultValue={recap.settings.name} error={err} helperText={errMsg} sx={{ mt: 0 }}
                                    label='Edit portfolio name' size="small" type="text" variant="outlined"
                                    name='name' onChange={e => this.handleName(e.target.value)} />
                            </Typography>
                            <Typography variant='caption'>Initial capital</Typography>
                            <Typography sx={{ mb: 1 }} variant='subtitle2'>{numeral(recap.settings.init).format('0,000') + " " + recap.settings.currency}</Typography>
                            <Typography variant='caption'>Portfolio size</Typography>
                            <Typography sx={{ mb: 1 }} variant='subtitle2'>{recap.settings.size}</Typography>
                            <Typography variant='caption'>Signals strength</Typography>
                            <Typography sx={{ mb: 1 }} variant='subtitle2'>{getStrengthName(recap.settings.quality)}</Typography>
                            <Typography variant='caption'>Short positions</Typography>
                            <Typography sx={{ mb: 1 }} variant='subtitle2'>{recap.settings.strategy === 'short' ? 'Enabled' : 'Disabled'}</Typography>
                            <Typography variant='caption'>Markets</Typography>
                            <Typography sx={{ mb: 1 }} variant='subtitle2'>{
                                recap.chains.map((obj) => (
                                    getMarketName(obj)
                                )).join(', ')
                            }</Typography>
                            <Typography variant='caption'>Sectors</Typography>
                            <Typography sx={{ mb: 1 }} variant='subtitle2'>{
                                recap.sectors.map((obj) => (
                                    getSectorName(obj)
                                )).join(', ')
                            }</Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color='textSecondary' onClick={() => this.handleClose()}>Cancel</Button>
                    <Button variant='contained' color='cta' disableElevation onClick={() => this.handleConfirm()}>Subscribe</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
