import { AccountBalance, Bolt, Category, DeveloperBoard, Factory, Google, HealthAndSafety, HolidayVillage, SatelliteAlt, Science, ShoppingCart, TipsAndUpdates } from '@mui/icons-material';

export const getSectorLogo = (idSector) => {
    let logo_alt
    switch (idSector) {
      case "Financial Services":
        logo_alt = <AccountBalance color="primary" fontSize="large" />
        break;
      case "Healthcare":
        logo_alt = <HealthAndSafety color="primary" fontSize="large" />
        break;
      case "Industrials":
        logo_alt = <Factory color="primary" fontSize="large" />
        break;
      case "Technology":
        logo_alt = <DeveloperBoard color="primary" fontSize="large" />
        break;
      case "Consumer Cyclical":
        logo_alt = <ShoppingCart color="primary" fontSize="large" />
        break;
      case "Basic Materials":
        logo_alt = <Science color="primary" fontSize="large" />
        break;
      case "Communications":
        logo_alt = <SatelliteAlt color="primary" fontSize="large" />
        break;
      case "Real Estate":
        logo_alt = <HolidayVillage color="primary" fontSize="large" />
        break;
      case "Energy":
        logo_alt = < Bolt color="primary" fontSize="large" />
        break;
      case "Utilities":
        logo_alt = <TipsAndUpdates color="primary" fontSize="large" />
        break;
      case 21:
      case "Other":
      case "Consumer Defensive":
      case 3:
        logo_alt = <Category color="primary" fontSize="large" />
        break;
    }
    return logo_alt
  }

export const isObjectEmptyOrNull =(obj, prop) => {
  return obj[prop] == null || 
         (typeof obj[prop] === 'object' && Object.keys(obj[prop]).length === 0 && obj[prop].constructor === Object);
}
