import { Dialog, DialogContent, Button, CardActions, Grid, CardContent, List, ListItem, ListItemText, ListItemSecondaryAction, Typography, Card, CardHeader, Container, Alert, CardActionArea, Box, IconButton } from '@mui/material';
import { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { DialogActions, DialogTitle } from '@mui/material';
import { withTheme } from '@emotion/react';
import numeral from 'numeral';
import { BrokenImageOutlined, Circle, Close, Error, FastForwardOutlined, KeyboardArrowLeftOutlined, RocketLaunchOutlined, SsidChartOutlined } from '@mui/icons-material';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

const styles = theme => ({
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
});

class DialogCompareBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
      seriesData: null,
      plotError: "",
      chartDeniedMessage:
        <>
          <Typography variant='subtitle1' color="textSecondary">Portfolios with different initial capital or currency base are not comparable.</Typography>
          <Typography variant='caption' color="textSecondary">Please, align portfolios settings to display the chart.</Typography>
        </>
      ,
      colors: [
        this.props.theme.palette.chartPrimary,
        this.props.theme.palette.chartSecondary,
        this.props.theme.palette.chartThird,]
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      if (this.props.builds && this.props.builds.length > 1) {
        if (this.props.open) this.plot()
      }
    }
  }

  componentDidMount() {
    if (this.props.builds && this.props.builds.length > 1) {
      if (this.props.open) this.plot()
    }
  }

  checkComparability() {
    let currency = null
    let initialCapital = null
    let ret = true

    this.props.builds.forEach(build => {
      console.log(build.settings.settings.currency, build.settings.settings.init)
      if (currency) {
        if (currency != build.settings.settings.currency) {
          this.setState({ plotError: this.state.chartDeniedMessage })
          ret = false
        }
      }

      if (initialCapital) {
        if (initialCapital != build.settings.settings.init) {
          this.setState({ plotError: this.state.chartDeniedMessage })
          ret = false
        }
      }

      currency = build.settings.settings.currency
      initialCapital = build.settings.settings.init
    })

    return ret
  }

  plot() {
    let comparable = this.checkComparability()
    if (comparable) {
      let builds = this.props.builds
      let seriesData = []
      builds.forEach(build => {
        let chartData = build.data.performances.portfolio.series
        let prices = { name: build.settings.settings.name, data: [] }
        for (var i = 0; i < chartData.length; i++) {
          var d = (moment(chartData[i].d).format('Y-MM-DD'))
          prices.data.push({ 'x': d, 'y': chartData[i].v })
        }
        seriesData.push(prices)
      });

      let options = {
        chart: {
          height: 250,
          type: "line",
          animations: {
            show: false
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 2,
          //curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
        },
        colors: this.state.colors,
        grid: {
          borderColor: this.props.theme.palette.grid,
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          fontFamily: this.props.theme.palette.typography.fontFamily,
          labels: { colors: this.props.theme.palette.text.primary, },
          markers: {
            radius: 0,
            width: 8,
            height: 8,
          },
          itemMargin: {
            vertical: 0
          },
        },
        tooltip: {
          theme: this.props.selectedTheme,
          shared: true,
          x: { show: false },
          y: {
            formatter: function (val) {
              return numeral(val).format('0,000.0[00]');
            }
          }
        },
        yaxis: {
          min: function (min) { return min },
          forceNiceScale: true,
          labels: {
            show: true,
            formatter: (value) => { return numeral(value).format('0.[00]a') },
            style: {
              fontSize: '11px',
              fontFamily: 'Roboto',
              fontWeight: 400,
            },
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
        },
      }

      this.setState({ options, seriesData })
    } else {
      this.setState({
        options: null,
        seriesData: null,
      })
    }
  }

  handleClose() {
    this.props.closeDialogCompare()
  };

  render() {
    const { options, seriesData, plotError, colors } = this.state;
    const { builds } = this.props;

    let emptySlots = 0
    if (builds) emptySlots = 3 - builds.length
    return (
      <Dialog open={this.props.open} maxWidth={'xl'} onClose={this.props.closeDialogCompare} fullScreen>
        <Box sx={{ display: 'flex', py: 1, px: 2 }}>
          <Box sx={{ flex: '50%' }}><Typography variant='h6'>Portfolios comparison</Typography></Box>
          <Box sx={{ flex: '50%', textAlign: 'right' }}>
            <IconButton onClick={() => this.handleClose()}><Close /></IconButton>
          </Box>
        </Box>
        <DialogContent>
          <Container>
            <Grid container>
              <Grid>
                <Button onClick={() => this.handleClose()} startIcon={<KeyboardArrowLeftOutlined />}>Back</Button>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title={<Typography variant='overline'>Equity lines</Typography>} />
                  <CardContent sx={{ py: "0 !important" }}>
                    {
                      options ?
                        <ReactApexChart options={options} series={seriesData} height={options.chart.height} />
                        : (plotError ?
                          <Box sx={{ height: 250, backgroundColor: '#eee', mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <SsidChartOutlined color='textSecondary' sx={{ mb: 2, fontSize: 80 }} />
                            {plotError}
                          </Box>
                          : 'No data')
                    }
                  </CardContent>
                </Card>
              </Grid>
              <>
                {
                  builds && builds.length > 0 ?
                    builds.map((obj, index) => (
                      obj ?
                        <Grid item xs={4}>
                          <Card>
                            <CardHeader sx={{ pb: 0, }} title={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Circle sx={{ color: colors[index], mr: 1, fontSize: 13 }} />
                                <Typography variant='h6'>
                                  {obj.settings.settings.name}
                                </Typography>
                              </Box>
                            }
                              subheader={
                                <Typography variant='caption' color='textSecondary'>
                                  {'Initial capital: ' + numeral(obj.settings.settings.init).format("0,000.[0]") + ' ' + obj.settings.settings.currency}
                                </Typography>
                              } />
                            <CardContent>
                              <List dense={true}>
                                <ListItem>
                                  <ListItemText><Typography variant='body2'>Return</Typography></ListItemText>
                                  <ListItemSecondaryAction><Typography variant='subtitle2'>{numeral(obj.data.summary.portfolio.annReturn * 100).format("0.00") + '%'}</Typography></ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                  <ListItemText><Typography variant='body2'>Volatility</Typography></ListItemText>
                                  <ListItemSecondaryAction><Typography variant='subtitle2'>{numeral(obj.data.summary.portfolio.volatility * 100).format("0.00") + '%'}</Typography></ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                  <ListItemText><Typography variant='body2'>Sharpe</Typography></ListItemText>
                                  <ListItemSecondaryAction><Typography variant='subtitle2'>{numeral(obj.data.summary.portfolio.sharpe).format("0.00")}</Typography></ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                  <ListItemText><Typography variant='body2'>Max drawdown</Typography></ListItemText>
                                  <ListItemSecondaryAction><Typography variant='subtitle2'>{numeral(obj.data.summary.portfolio.maxDrawdown * 100).format("0.00") + '%'}</Typography></ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                  <ListItemText><Typography variant='body2'>VaR@95% (10d)</Typography></ListItemText>
                                  <ListItemSecondaryAction><Typography variant='subtitle2'>{numeral(obj.data.summary.portfolio.var * 100).format("0.00") + '%'}</Typography></ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                  <ListItemText><Typography variant='body2'>Alpha</Typography></ListItemText>
                                  <ListItemSecondaryAction><Typography variant='subtitle2'>{numeral(obj.data.summary.portfolio.alpha * 100).format("0.00") + '%'}</Typography></ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                  <ListItemText><Typography variant='body2'>Beta</Typography></ListItemText>
                                  <ListItemSecondaryAction><Typography variant='subtitle2'>{numeral(obj.data.summary.portfolio.beta).format("0.00")}</Typography></ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                  <ListItemText><Typography variant='body2'>Beta Bear</Typography></ListItemText>
                                  <ListItemSecondaryAction><Typography variant='subtitle2'>{numeral(obj.data.summary.portfolio.betaBear).format("0.00")}</Typography></ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                  <ListItemText><Typography variant='body2'>Ulcer Index</Typography></ListItemText>
                                  <ListItemSecondaryAction><Typography variant='subtitle2'>{numeral(obj.data.summary.portfolio.ulcer * 100).format("0.00") + '%'}</Typography></ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                  <ListItemText><Typography variant='body2'>Martin Ratio</Typography></ListItemText>
                                  <ListItemSecondaryAction><Typography variant='subtitle2'>{numeral(obj.data.summary.portfolio.martin).format("0.00")}</Typography></ListItemSecondaryAction>
                                </ListItem>
                              </List>
                            </CardContent>
                            <CardActions >
                              <Button
                                disableElevation
                                size='large'
                                sx={{ mr: 1, marginLeft: 'auto' }}
                                onClick={() => this.props.openSettings(obj.id)}
                              >
                                Edit
                              </Button>
                              <Button
                                disableElevation
                                size='large'
                                color='cta'
                                variant='contained'
                                endIcon={<FastForwardOutlined />}
                                sx={{ mr: 1 }}
                                onClick={() => this.props.openDialogMessage(obj.id)}
                              >
                                Subscribe
                              </Button>

                            </CardActions>
                          </Card>

                        </Grid>
                        : ""
                    )) : "-"
                }

                {
                  builds && builds.length < 3 ?
                    <Grid item xs={4}>
                      <Card sx={{ height: "100%", border: '1px dashed  #cdcdcd' }}>
                        <CardActionArea sx={{ height: "100%" }} onClick={() => this.props.openSettings()}>
                          <CardContent sx={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ mb: 2 }}>
                              <RocketLaunchOutlined sx={{ fontSize: 80, color: grey[300] }} />
                            </Box>
                            <Box>
                              <Typography variant='subtitle1' color='textSecondary' sx={{ textTransform: 'initial', fontWeight: 400 }}>Build new portfolio</Typography>
                            </Box>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    : ""
                }
              </>
            </Grid>
          </Container>
        </DialogContent>
        {/* <DialogActions>
          <Button color='secondary' onClick={() => this.handleClose()}>Close</Button>
        </DialogActions> */}
      </Dialog>
    );
  }
}

function DialogCompare(props) {
  const navigate = useNavigate()
  const selectedTheme = useSelector(state => state.theme)

  return <DialogCompareBase navigate={navigate} selectedTheme={selectedTheme} {...props} />
}

export default withTheme(DialogCompare);
