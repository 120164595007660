import { Component } from "react";
import { Button, ButtonGroup, Card, CardActionArea, CardContent, CardHeader, Divider, Fade, Grid, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { ArrowDownwardOutlined, ArrowUpwardOutlined, RocketLaunchOutlined } from "@mui/icons-material";
import numeral from "numeral";
import { SensorsOutlined } from '@mui/icons-material';
import { Box, Container, Toolbar } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import notificationsInit from '../commons/Notifications'
import { withTheme } from "@emotion/react";
import { NavLink, useNavigate } from "react-router-dom";
import { restore } from "../commons/Memo";
import SkeletonOverview from "../elements/SkeletonOverview"
import { api } from "../api/apiBuilder";
import React, { lazy, Suspense } from 'react';
import { InView } from 'react-intersection-observer';
import { notifyArea } from "../dispatcher/Actions";
import { useDispatch } from "react-redux";

const ChartMini = lazy(() => import('../elements/ChartMini'));

class OverviewBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            portfolios: [],
            performance: 0,
            isLoading: false,
            menuAnchor: null,
            menuOptions: [
                { id: 'inceptionDate', label: 'Inception date' },
                { id: 'totalReturn', label: 'Total return' },
                { id: 'excessReturn', label: 'Excess return' },
                { id: 'name', label: 'Name' },
            ],
            selectedIndex: 1,
            menuOpen: false,
            sortDirection: false, // true = ASC, false = DESC
        }
    }

    componentDidMount() {
        this.props.dispatch(notifyArea("builder"))
        this.setState({ isLoading: true })
        notificationsInit(sessionStorage.getItem('MSGT'));
        numeral.options.scalePercentBy100 = true;
        let response = restore("PF_LIST")

        if (response) {
            const data = response.data;
            let filteredData = []
            data.forEach(item => {
                if (!parseInt(item.ptf.archived)) filteredData.push(item)
            });
            this.setState({ portfolios: filteredData, isLoading: false }, function () { this.sort(this.state.selectedIndex, this.state.sortDirection) })
        } else {
            api.get('/v1/builder/portfolios/', { cache: false })
                .then(response => {
                    // store("PF_LIST", response)
                    const data = response.data;
                    let filteredData = []
                    data.forEach(item => {
                        if (!parseInt(item.ptf.archived)) filteredData.push(item)
                    });
                    this.setState({ portfolios: filteredData, isLoading: false }, function () { this.sort(this.state.selectedIndex, this.state.sortDirection) })
                }).catch(error => {
                    this.setState({ isLoading: false })
                    if (error.response) {
                        console.log('Error 1', error.message);
                        if (error.response.status === 401) {
                        }
                        this.setState({
                            snackMessage: error.response.data,
                            snackOpen: true
                        })
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log('Error 2', error.message);
                        this.setState({
                            snackMessage: error.message,
                            snackOpen: true
                        })
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error 3', error.message);
                        this.setState({
                            snackMessage: error.message,
                            snackOpen: true
                        })
                    }
                })
        }
    }

    componentDidUpdate() {

    }

    openSortMenu(e) {
        this.setState({ menuAnchor: e.currentTarget, menuOpen: true });
    }

    handleSortMenuItem(id) {
        let direction = !this.state.sortDirection
        this.sort(id, direction)
        this.setState({ menuAnchor: null, menuOpen: false, selectedIndex: id });
    }

    toggleSortDirection() {
        let newDirection = !this.state.sortDirection
        this.setState({ sortDirection: newDirection, menuOpen: false })
        let i = this.state.selectedIndex
        this.sort(i, newDirection)
    }

    sort(i, direction) {
        let portfolios = this.state.portfolios
        let sortDirection = direction
        let sortCriteria = this.state.menuOptions[i].id
        if (sortDirection) {
            portfolios.sort(function (a, b) {
                if (!isNaN(a.ptf[sortCriteria])) {
                    return a.ptf[sortCriteria] - b.ptf[sortCriteria]
                } else {
                    return a.ptf[sortCriteria].localeCompare(b.ptf[sortCriteria])
                }
            })
        } else {
            portfolios.sort(function (a, b) {
                if (!isNaN(a.ptf[sortCriteria])) {
                    return b.ptf[sortCriteria] - a.ptf[sortCriteria]
                } else {
                    return b.ptf[sortCriteria].localeCompare(a.ptf[sortCriteria])
                }
            })
        }
        this.setState({ portfolios })
    }

    handleClose() {
        this.setState({ menuAnchor: null, menuOpen: false });
    }

    render() {
        const { portfolios, isLoading, menuOptions, selectedIndex, menuAnchor, menuOpen, sortDirection } = this.state;
        return (
            <Fade in="true" timeout={450} >
                <div>
                    <Toolbar />
                    <Container maxWidth='xl'>
                        {isLoading ?
                            <>
                                <Grid container spacing={1} sx={{ pb: 5 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" sx={{ ml: 1 }} className='big'>My portfolios</Typography>
                                    </Grid>
                                    <Grid xs={12} md={6} lg={4} item>
                                        <SkeletonOverview />
                                    </Grid>
                                    <Grid xs={12} md={6} lg={4} item>
                                        <SkeletonOverview />
                                    </Grid>
                                    <Grid xs={12} md={6} lg={4} item>
                                        <SkeletonOverview />
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <Grid container spacing={1} sx={{ pb: 5 }}>
                                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="h4" sx={{ ml: 1 }} className='big'>My portfolios</Typography>
                                </Grid>
                                <Grid item xs={6} textAlign='right' sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <ButtonGroup variant="outlined" color="default" sx={{ mr: 0 }}>
                                        <Tooltip title="Sorting criteria">
                                            <Button
                                                variant="outlined" size="small"
                                                onClick={(e) => this.openSortMenu(e)}
                                                sx={{ textTransform: 'inherit' }}
                                            >{"Sort by " + menuOptions[selectedIndex].label}
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Sorting direction">
                                            <Button size="small" variant="outlined"
                                                onClick={() => this.toggleSortDirection()}>
                                                {sortDirection ? <ArrowUpwardOutlined color="default" sx={{ fontSize: 18 }} /> : <ArrowDownwardOutlined color="default" sx={{ fontSize: 18 }} />}
                                            </Button>
                                        </Tooltip>
                                    </ButtonGroup>
                                    <Menu
                                        variant="menu"
                                        sx={{ marginTop: 1, minWidth: 250 }}
                                        id="lock-menu"
                                        anchorEl={menuAnchor}
                                        open={menuOpen}
                                        onClose={() => this.handleClose()}
                                        MenuListProps={{
                                            'aria-labelledby': 'lock-button',
                                            role: 'listbox',
                                        }}
                                    >
                                        {menuOptions.map((option, index) => (
                                            <MenuItem
                                                key={option.id}
                                                //disabled={index === selectedIndex}
                                                selected={index === selectedIndex}
                                                onClick={(event) => this.handleSortMenuItem(index)}
                                            >
                                                <Typography variant="body2">{option.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Grid>

                                {portfolios.length === 0 ? (
                                    <Grid xs={12} md={6} lg={4} container item>
                                        <Card variant='outlined' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                            <CardContent style={{ padding: 64 }}>
                                                <Typography variant='h5'>
                                                    Create your portfolio starting from this section.
                                                    Click on the empty box to get started.
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ) : ''}
                                {portfolios && this.state.portfolios.length > 0 ? this.state.portfolios.map((obj, i) => (
                                    <Grid key={i} xs={12} md={6} lg={4} item>
                                        <Card variant='outlined' sx={{m: 0}}>
                                            <CardActionArea component={NavLink} to={"/portfolio/" + obj.ptf.id}>
                                                <CardHeader
                                                    sx={{ pb: 0 }}
                                                    action=
                                                    {obj.ptf.is_rebalancing != '0' ? <Tooltip title={"New signals today"}><SensorsOutlined color='success' /></Tooltip> : ''}

                                                    title={<Typography variant='h6' style={{ fontWeight: 500 }}>{obj.ptf.name}</Typography>}
                                                    subheader={<Typography variant='caption' color="textSecondary">{"Portfolio value: " + obj.ptf.currency + " " + numeral(obj.ptf.portfolioValue).format('0,000')}</Typography>}
                                                />
                                                <CardContent sx={{ pt: 0 }}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box sx={{ flex: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <Typography variant='h3' style={{ fontFamily: 'Fjalla One', lineHeight: 'normal' }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                                    <Typography variant='h3' className={'big'}>{numeral(obj.ptf.totalReturn * 100).format('+0,000.00')}</Typography>
                                                                    <Typography sx={{ ml: 0 }} variant='h5' className={'big'} >%</Typography>
                                                                </Box>
                                                            </Typography>
                                                            <Typography variant='caption' color='textSecondary'>Portfolio return</Typography>
                                                            <Divider style={{ margin: 8 }} component='p' variant='middle' />
                                                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                                                    {(obj.ptf.totalReturn - obj.ptf.bReturn) >= 0 ?
                                                                        <Typography variant='h5' style={{ color: this.props.theme.palette.long.main, fontFamily: 'Fjalla One', lineHeight: 'normal' }}>{numeral(obj.ptf.totalReturn - obj.ptf.bReturn).format('+0,000.00%')}</Typography>
                                                                        : <Typography variant='h5' style={{ color: this.props.theme.palette.short.main, fontFamily: 'Fjalla One', lineHeight: 'normal' }}>{numeral(obj.ptf.totalReturn - obj.ptf.bReturn).format('+0,000.00%')}</Typography>
                                                                    }
                                                                    <Typography variant='caption' color='textSecondary'>Excess return</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ flex: '60%', px: 2 }}>
                                                            <InView>
                                                                {({ inView, ref }) => (
                                                                    <div ref={ref}>
                                                                        {inView && (
                                                                            <Suspense fallback={<div>Loading chart...</div>}>
                                                                                <ChartMini
                                                                                    size={portfolios.length}
                                                                                    isLoading={false}
                                                                                    performances={obj.eql}
                                                                                />
                                                                            </Suspense>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </InView>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )) : ''}
                                <Grid xs={12} md={6} lg={4} item>
                                    <Card sx={{ height: '100%', width: "100%", border: '1px dashed #e6e6e6', m: 0 }}>
                                        <CardActionArea style={{ height: '100%' }} component={NavLink} to={"/builder"}>
                                            <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Box sx={{ mb: 2 }}>
                                                    <RocketLaunchOutlined style={{ fontSize: 110, color: grey[300] }} />
                                                </Box>
                                                <Box>
                                                    <Typography variant='h6' color='cta' sx={{ textTransform: 'initial', fontWeight: 400 }}>Build new portfolio</Typography>
                                                </Box>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            </Grid>
                        }
                    </Container>
                </div>
            </Fade>
        );
    }
}


function Overview(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    return <OverviewBase navigate={navigate} dispatch={dispatch} {...props} />
}

export default withTheme(Overview);
