import { Dialog, DialogContent, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, DialogTitle, IconButton } from '@mui/material';
import { Component } from 'react';
import { DialogActions } from '@mui/material';
import { withTheme } from '@emotion/react';
import { Close, ExpandMoreOutlined } from '@mui/icons-material';
import { MathComponent } from "mathjax-react";


class DialogHelpMetricsGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPanel: ''
    }
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
  }

  handleClose() {
    this.props.closeDialog()
  }

  handleChange(selectedPanel) {
    this.setState({ selectedPanel })
  }

  render() {
    const { selectedPanel } = this.state;
    const { } = this.props;
    let formulasBackground = this.props.theme.palette.background.primary

    return (
      <Dialog open={this.props.open} maxWidth={'sm'} onClose={() => this.handleClose()} fullWidth>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', my:0 }} />
        <DialogContent sx={{  }}>

          <Accordion expanded={selectedPanel === 'panel1'} onChange={() => this.handleChange('panel1')} TransitionProps={{ unmountOnExit: true }} variant='outlined' sx={{ border: 0,  }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h6'>Annualized Return</Typography>
                <Typography variant='caption' color="textSecondary">Growth performance</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2'>Annualized return is the average return over the selected period scaled to a 12-month period. It is computed starting from the log-returns <i>r</i>.</Typography>
              <Box sx={{ backgroundColor: formulasBackground, p: 2, mt: 3 }}>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`\bar r = mean(r), \quad T=252`} />
                </Box>
                <Box sx={{ fontSize: 20, mb: 1 }}>
                  <MathComponent display={true} tex={String.raw`Annualized\ Return = \bar r \cdot T`} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={selectedPanel === 'panel2'} onChange={() => this.handleChange('panel2')} TransitionProps={{ unmountOnExit: true }} variant='outlined' sx={{ border: 0,  }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h6'>Sharpe Ratio</Typography>
                <Typography variant='caption' color="textSecondary">Volatility adjusted performance</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2'>Sharpe ratio represents the over-performance of an investment with respect to a risk-free asset, adjusted by its risk, intended as portfolio volatility (see section about Volatility on Risk metrics).</Typography>
              <Box sx={{ backgroundColor: formulasBackground, p: 2, mt: 3 }}>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`R_p = `} /> Portfolio return
                </Box>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`R_f = `} /> Risk-free return
                </Box>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`\sigma_p = `} /> Portfolio volatility
                </Box>
                <Box sx={{ fontSize: 20, mb: 1 }}>
                  <MathComponent display={true} tex={String.raw`sharpe = \frac{R_p - R_f}{\sigma_p}`} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={selectedPanel === 'panel3'} onChange={() => this.handleChange('panel3')} TransitionProps={{ unmountOnExit: true }} variant='outlined' sx={{ border: 0,  }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h6'>Martin ratio</Typography>
                <Typography variant='caption' color="textSecondary">Ulcer Performance Index</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2'>Martin ratio, or Ulcer Performance Index (UPI), is a method for measuring the over-performance of the investment with respect to a risk-free asset, adjusted by the Ulcer index (see section about Ulcer index on Risk metrics).</Typography>
              <Box sx={{ backgroundColor: formulasBackground, p: 2, mt: 3 }}>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`R_p = `} /> Portfolio return
                </Box>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`R_f = `} /> Risk-free return
                </Box>
                <Box sx={{ fontSize: 20, mb: 1 }}>
                  <MathComponent display={true} tex={String.raw`UPI = \frac{R_p - R_f}{ulcer\ index}`} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={selectedPanel === 'panel4'} onChange={() => this.handleChange('panel4')} TransitionProps={{ unmountOnExit: true }} variant='outlined' sx={{ border: 0,  }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h6'>Alpha</Typography>
                <Typography variant='caption' color="textSecondary">Overperformance</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2'>Alpha (α) represents is the ability of a portfolio to beat the market, or the benchmark. It is an indication of the over-performance of the investment. The indicator is connected to the Beta indicator (see next indicator).</Typography>
              <Box sx={{ backgroundColor: formulasBackground, p: 2, mt: 3 }}>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <span style={{ marginRight: 16 }}><MathComponent display={false} tex={String.raw`R_p = `} /> Portfolio return,</span>
                  <MathComponent display={false} tex={String.raw`R_f = `} /> Risk-free return
                </Box>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <span style={{ marginRight: 16 }}><MathComponent display={false} tex={String.raw`R_b = `} /> Benchmark return,</span>
                  <MathComponent display={false} tex={String.raw`\beta = `} /> Portfolio beta
                </Box>
                <Box sx={{ fontSize: 20, mb: 1 }}>
                  <MathComponent display={true} tex={String.raw`\alpha = (R_p - R_f) - \beta(R_b - R_f)`} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={selectedPanel === 'panel5'} onChange={() => this.handleChange('panel5')} TransitionProps={{ unmountOnExit: true }} variant='outlined' sx={{ border: 0,  }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h6'>Beta</Typography>
                <Typography variant='caption' color="textSecondary">Correlation</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2'>Beta (β) indicator is a measure of a portfolio volatility in relation to the selected market or index. It is computed using covariance between portfolio and benchmark, and the benchmark variance.</Typography>
              <Box sx={{ backgroundColor: this.props.theme.palette.background.primary, p: 2, mt: 3 }}>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`r_p = `} /> Portfolio return series
                </Box>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`r_b = `} /> Benchmark return series
                </Box>
                <Box sx={{ fontSize: 20, mb: 1 }}>
                  <MathComponent display={true} tex={String.raw`\beta = \frac{Cov(r_p,r_b)}{Var(r_b)}`} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

        </DialogContent>
        <DialogActions sx={{  }}>
          <Button onClick={() => this.handleClose()}>close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTheme(DialogHelpMetricsGeneral);
