import React, { Component } from 'react';
import numeral from 'numeral';
import { Box, Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { Typography } from '@mui/material';
import { withTheme } from '@emotion/react';
import SkeletonList from '../elements/SkeletonList';
import Metric from '../commons/Metric';
import { HelpOutline } from '@mui/icons-material';
import DialogHelpMetricsRisk from '../commons/DialogHelpMetricsRisk';
import { api } from "../api/apiBuilder";

/*const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);*/

class MetricsRiskExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      summary: 0,
      helpOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.portfolio != prevProps.portfolio && this.props.portfolio) {
        this.load()
      }
    }
  }

  componentDidMount() {
    if (this.props.portfolio) {
      this.load()
    }
  }

  load() {
    this.setState({ isLoading: true })
    api.get('/builder/portfolios/' + this.props.portfolio + '/summary/', { cache: false })
      .then(response => {
        const data = response.data;

        data.forEach(item => {
          if (item.tpe == 'pf') this.setState({ MetricsRiskExport: item })
          else if (item.tpe == 'bm') this.setState({ benchmarkSummary: item })
        })

        this.setState({ summary: data, isLoading: false })
      }).catch(error => {
        this.setState({ isLoading: false })
        if (error.response) {
          console.log('Error 1', error.message);
          if (error.response.status == 401) {
          }
          this.state.snackMessage = error.response.data;
          this.state.snackOpen = true;
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error 2', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error 3', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        }
      })
  }

  toggleHelp(){
    this.setState({helpOpen: true});
  }

  closeHelp(){
    this.setState({helpOpen: false});
  }

  render() {
    const { } = this.props;
    const { MetricsRiskExport, benchmarkSummary, isLoading, helpOpen } = this.state;

    return (
      <>
        <Card variant='outlined'>
          <CardContent>
            {isLoading ? <SkeletonList rows='5' /> :
              MetricsRiskExport && benchmarkSummary ?
                <>
                  <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ flex: '50%', display: 'flex' }}>
                      <Metric title={'Volatility'}
                        k={'volatility'}
                        v={MetricsRiskExport.volatility}
                        portfolioVal={numeral(MetricsRiskExport.volatility * 100).format('0.00') + '%'}
                        benchmarkVal={numeral(benchmarkSummary.volatility * 100).format('0.00') + '%'}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ flex: '50%', display: 'flex' }}>
                      <Metric title={'Max drawdown'}
                        k={'maxDrawdown'}
                        v={MetricsRiskExport.maxDrawdown}
                        portfolioVal={numeral(MetricsRiskExport.maxDrawdown * 100).format('0.00') + '%'}
                        benchmarkVal={numeral(benchmarkSummary.maxDrawdown * 100).format('0.00') + '%'}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ flex: '50%', display: 'flex' }}>
                      <Metric title={'VaR@95% (10d)'}
                        k={'var'}
                        v={MetricsRiskExport.VaR}
                        portfolioVal={numeral(MetricsRiskExport.VaR * 100).format('0.00') + '%'}
                        benchmarkVal={numeral(benchmarkSummary.VaR * 100).format('0.00') + '%'}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ flex: '50%', display: 'flex' }}>
                      <Metric title={'Ulcer Index'}
                        k={'ulcer'}
                        v={MetricsRiskExport.ulcer}
                        portfolioVal={numeral(MetricsRiskExport.ulcer * 100).format('0.00') + '%'}
                        benchmarkVal={numeral(benchmarkSummary.ulcer * 100).format('0.00') + '%'}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ flex: '50%', display: 'flex' }}>
                      <Metric title={'Beta Bear'}
                        k={'betaBear'}
                        v={MetricsRiskExport.betaBear}
                        portfolioVal={numeral(MetricsRiskExport.betaBear).format('0.00')}
                        benchmarkVal="N/D"
                      />
                    </Box>
                  </Box>
                </>
                : "No data"
            }

          </CardContent>
        </Card>
      </>
    );
  }
}

export default withTheme(MetricsRiskExport);
