import { withTheme } from '@emotion/react';
import { CheckOutlined, SsidChartOutlined } from '@mui/icons-material';
import { CardActions, CardHeader } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import numeral from 'numeral';
import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { api } from "../api/apiBuilder";
import SkeletonChart from '../elements/SkeletonChart';

class DetailsHistoryBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      trade: null,
      selected: '0',
      isLoading: false,
    }
  }
  async componentDidMount() {
    this.loadInfo()
  }

  componentDidUpdate(prevProps) {
    if (prevProps != this.props) {
      if (this.state.options) {
        this.loadInfo()
      }
    }
  }

  loadInfo() {
    this.setState({ isLoading: true })

    api.get('/v1/builder/portfolios/' + this.props.portfolio + '/trades/' + this.props.trade + '/info/', { cache: false })
      .then(response => {
        const data = response.data
        this.setState({ trade: data })
        if (!data.open_price || data.quotes < 2) {
          this.setState({ selected: 1 })
        } else {
          this.setState({ selected: 0 })
        }
        this.loadHistory(0)
      }).catch(error => {
        this.setState({ isLoading: false })
        console.log(error);
      })
  }

  loadHistory(tf) {
    api.get('/v1/builder/portfolios/' + this.props.portfolio + '/instruments/' + this.props.instr + '/trades/' + this.props.trade + '/depth/' + tf + '/historical/', { cache: false })
      .then(response => {
        const data = response.data

        let series = [{
          data: []
        }];
        let series_brush = [{
          data: []
        }];
        let quotes = [];
        let quotes_brush = [];
        let quote;
        let quote_brush;
        var options = {};
        var perspectiveView = {};

        for (var i = 0; i < data.length; i++) {
          quote = {
            x: data[i].d,
            y: [data[i].c * 1]
          }
          quotes.push(quote);
          quote_brush = {
            x: data[i].d,
            y: [data[i].c * 1]
          }
          quotes_brush.push(quote_brush);
        }
        series[0].data = quotes;
        series[0].name = "Price";
        series_brush[0].data = quotes_brush;

        let points = null
        let trade = this.state.trade
        let firstDate = data.length > 0 ? new Date(data[0].d).getTime() : 0
        let lastDate = data.length > 0 ? new Date(data[data.length - 1].d).getTime() : 0
        let openDate = new Date(trade.open_date_formatted).getTime()
        let exitDate = new Date(trade.exit_date_formatted).getTime()

        points = {
          position: 'back',
          xaxis: [
            firstDate <= openDate && openDate <= lastDate ? {
              x: openDate,
              offsetX: 0,
              offsetY: 0,
              strokeDashArray: 5,
              borderColor: grey[600],
              fillColor: grey[600],
              opacity: 1,
              label: {
                borderColor: this.props.theme.palette.primary.main,
                position: 'top',
                offsetX: -4,
                offsetY: 2,
                style: {
                  color: '#fff',
                  background: this.props.theme.palette.primary.main,
                },
                text: trade.direction == 'short' ? 'SELL' : 'BUY',
              }
            } : null,
            lastDate >= exitDate ? {
              x: new Date(trade.exit_date_formatted).getTime(),
              offsetX: 0,
              offsetY: 0,
              strokeDashArray: 5,
              borderColor: grey[600],
              fillColor: grey[600],
              opacity: 1,
              label: {
                borderColor: this.props.theme.palette.primary.main,
                position: 'top',
                offsetX: -4,
                offsetY: 2,
                style: {
                  color: '#fff',
                  background: this.props.theme.palette.primary.main,
                },
                text: 'EXIT',
              }
            } : null,
          ],
        }

        var options = {
          series: series,
          chart: {
            type: 'area',
            id: 'historical',
            height: 300,
            toolbar: { show: false },
            animations: {
              enabled: true,
              easing: 'easeinout',
              speed: 800,
              animateGradually: {
                enabled: true,
                delay: 150
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350
              }
            },
            zoom: {
              enabled: false,
            },
            brush: {
              autoScaleYaxis: true
            }
          },
          annotations: points,
          zoom: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
          },
          colors: [this.props.theme.palette.chartPrimary],
          fill: {
            type: "gradient",
            gradient: {
              gradientToColors: [this.props.theme.palette.chartPrimary, this.props.theme.palette.chartPrimary],
              opacityFrom: .5,
              opacityTo: .5,
              stops: [8]
            }
          },
          markers: {
            size: 0
          },
          dataLabels: {
            enabled: false
          },
          tooltip: {
            theme: this.props.selectedTheme,
          },
          grid: {
            borderColor: this.props.theme.palette.grid,
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          title: {
            show: false
          },
          xaxis: {
            type: 'datetime',
            tickAmount: 3,
            tickPlacement: 'on',
            labels: {
              show: true,
              style: {
                colors: ['#848484'],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },
          yaxis: {
            tickAmount: 3,
            forceNiceScale: true,
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.0[000]') },
              style: {
                colors: ['#848484'],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
        };

        this.setState({ options, isLoading: false });
      }).catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      })
  }

  changeTimeFrame(tf) {
    this.setState({ selected: tf })
    this.loadHistory(tf)
  }

  render() {
    const { selected, options, trade, isLoading } = this.state;

    let change = 'N.A.'
    if (trade) {
      if (trade.open_price_local) {
        change = trade.direction == 'long' ? (trade.last_close - trade.open_price_local) / trade.open_price_local * 100 : (trade.open_price_local - trade.last_close) / trade.open_price_local * 100
        change = numeral(change).format('+0,000.0[0]')
      }
    }
    return (
      <Card variant="outlined">
        <CardHeader sx={{ pb: 0 }}
          title={<Typography variant='overline'>Position tracking</Typography>}
        />
        <CardContent>
          {trade ?
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Box sx={{ flex: '40%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', }}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography variant='h3' className={'big'}>{change}</Typography>
                    <Typography sx={{ ml: 0 }} variant='h5' className={'big'} >%</Typography>
                  </Box>
                  <Chip sx={{ ml: 1 }} size='small' label={trade.direction} />
                </Box>
                <Typography variant='caption' color={'textSecondary'}>Performance from {moment(trade.open_date + "").format('DD/MM/YYYY')}</Typography>
              </Box>
              <Box sx={{ flex: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                  <Typography variant='h5' className={'big'}>{trade.open_price_local ? numeral(trade.open_price_local).format('0,000.0[0]') : 'N.A.'}</Typography>
                  <Typography sx={{ ml: 1 }} variant='caption' >{trade.CurrencyCode}</Typography>
                </Box>
                <Typography variant='caption' color='textSecondary'>Open price</Typography>
              </Box>
              <Box sx={{ flex: '30%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                  <Typography variant='h5' className={'big'}>{trade.last_close ? numeral(trade.last_close).format('0,000.0[0]') : 'N.A.'}</Typography>
                  <Typography sx={{ ml: 1 }} variant='caption' >{trade.CurrencyCode}</Typography>
                </Box>
                <Typography variant='caption' color='textSecondary'>Last close</Typography>
              </Box>
            </Box>
            : 'loading...'}
          {
            isLoading ? <SkeletonChart height={350} /> : (
              options && options.series && options.series[0].data.length > 0 ?
                <Box>
                  {options.series[0].length}
                  <ReactApexChart annotations={options.annotations} options={options} series={options.series} type={options.chart.type} height={350} />
                  {/* <ReactApexChart options={this.state.perspectiveView} series={this.state.perspectiveView.series} type={this.state.perspectiveView.chart.type} height={100} /> */}
                </Box>
                : <Box sx={{ height: 350, backgroundColor: '#eee', mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <SsidChartOutlined color='textSecondary' sx={{ mb: 2, fontSize: 80 }} />
                  No data for this frame selection
                </Box>)
          }
        </CardContent>
        <CardActions>

          {[
            { 'l': 'Fit position', 'v': 0 },
            { 'l': '1 month', 'v': 1 },
            { 'l': '2 months', 'v': 2 },
            { 'l': '3 months', 'v': 3 },
            { 'l': '1 year', 'v': 12 },
            { 'l': '3 years', 'v': 36 },
            { 'l': 'All', 'v': 120 }
          ].map((item) => {
            const checked = selected === item.v;
            return (
              <Chip
                size='medium'
                key={item.l}
                variant={checked ? 'filled' : 'filled'}
                color={checked ? 'primary' : 'secondary'}
                label={item.l}
                startDecorator={
                  checked && <CheckOutlined sx={{ zIndex: 1, pointerEvents: 'none' }} />
                }
                onClick={() => {
                  this.changeTimeFrame(item.v);
                }}
              >

              </Chip>
            );
          })}

        </CardActions>
      </Card>
    );
  }
}

function DetailsHistory(props) {
  const selectedTheme = useSelector(state => state.theme)

  return <DetailsHistoryBase selectedTheme={selectedTheme} {...props} />
}

export default withTheme(DetailsHistory);
