import { withTheme } from '@emotion/react';
import { Add, Done } from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { api } from '../api/apiBuilder';

const DialogAddToWatchlist = (props) => {
  const [loading, setLoading] = useState(false)
  const [watchlists, setWatchlists] = useState(false)
  const [whoContains, setWhoContains] = useState(false)
  const [createActive, setCreateActive] = useState(false)
  const [name, setName] = useState(null)
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [done, setDone] = useState(null);
  const [locked, setLocked] = useState(false);
  
  let timer = null

  useEffect(() => {
    loadWatchlists()
    loadPresence()
  }, [props.open])

  useEffect(() => {
    if (done) {
      setLocked(true)
      timer = setTimeout(() => {
        setDone(false);
        setLocked(false)
      }, 800)
    }
  }, [done])


  const loadWatchlists = () => {
    api.get("/v1/dashboard/watchlists", { cache: false })
      .then(response => {
        const data = response.data;
        setWatchlists(data)
        setLoading(false)
      }).catch(error => {
        console.log(error);
        setLoading(false)
      }).finally(() => {
      })
  }

  const loadPresence = () => {
    api.get(`/v1/dashboard/watchlists/instruments/${props.instrument}`, { cache: false })
      .then(response => {
        const data = response.data;
        setWhoContains(data)
        setLoading(false)
      }).catch(error => {
        console.log(error);
        setLoading(false)
      }).finally(() => {
      })
  }

  const handleChange = (e) => {
    setName(e.target.value)
  }

  const handlePost = (e) => {
    const wlist = e.target.id
    const checked = e.target.checked
    setSelectedCheckbox(wlist)
    setLoading(true)
    setDone(false);
    setLocked(true)
    if (timer) clearTimeout(timer)

    if (checked) {
      api.post(`/v1/dashboard/watchlists/${wlist}/instruments/${props.instrument}`, {}, { cache: false })
        .then(response => {
          const data = response.data;
          setLoading(false)
          setDone(true)
          loadPresence()
        }).catch(error => {
          console.log(error);
          setLoading(false)
        }).finally(() => {
        })
    } else {
      api.delete(`/v1/dashboard/watchlists/${wlist}/instruments/${props.instrument}`, {}, { cache: false })
        .then(response => {
          const data = response.data;
          setLoading(false)
          setDone(true)
          loadPresence()
        }).catch(error => {
          console.log(error);
          setLoading(false)
        }).finally(() => {
        })
    }
  }

  const handleNewWatchlist = () => {
    setCreateActive(false)
    if (name) {
      api.post("/v1/dashboard/watchlists", { name }, { cache: false })
        .then(response => {
          const data = response.data;
          setLoading(false)
          setName(null)
          loadWatchlists()
        }).catch(error => {
          console.log(error);
          setLoading(false)
        }).finally(() => {
        })
    }
  }

  const isAdded = (id) => {
    if (whoContains) {
      const item = whoContains.find(obj => obj.id == id);
      return item ? item.added : null; // Return the 'added' value if item found, otherwise null
    } else {
      return false
    }
  }

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} maxWidth='sm'>
        <DialogTitle><Typography variant='overline' className='bold'>Add to watchlist</Typography></DialogTitle>
        <DialogContent>
          <Box variant='outlined' sx={{ py: 1, }}>
            <Typography variant='body2'>
              You can add the current instrument to one or more of your existing watchlists.
              It's a convenient way to organize potential investments or simply monitor the performance of certain assets.
            </Typography>
          </Box>
          <FormControl sx={{ mt: 1 }} fullWidth>
            {watchlists && watchlists.map(item =>
              <Box key={'wlist_' + item.id} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Box>
                  <Checkbox disabled={locked} size='small' onClick={handlePost} id={item.id} checked={isAdded(item.id)} />  {item.name}
                </Box>
                <Box>
                  {loading && selectedCheckbox == item.id ? <CircularProgress size={16} /> :
                    done && selectedCheckbox == item.id ?
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Done color='primary' />
                        <Typography variant='caption' color={'primary'}>saved</Typography>
                      </Box> : ''}
                </Box>
              </Box>
            )}
          </FormControl>
          <Divider sx={{ my: 2 }} />
          <Box>
            {!createActive ?
              <Box sx={{ height: 40 }}>
                <Button variant='contained' disableElevation startIcon={<Add />} onClick={() => setCreateActive(true)}>Create new watchlist</Button>
              </Box>
              :
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', height: 40 }}>
                <TextField
                  size='small'
                  value={name}
                  onChange={handleChange}
                  label='Insert watchlist name'
                />
                <Button variant='contained' disableElevation onClick={handleNewWatchlist}>Create</Button>
              </Box>
            }
          </Box>
        </DialogContent>
      </Dialog >
    </>
  )
};

export default withTheme(DialogAddToWatchlist);