import { withTheme } from "@emotion/react";
import { Container, Fade, Grid, Toolbar } from "@mui/material";
import React, { Component } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../api/apiBuilder";
import { notifyArea } from "../dispatcher/Actions";
import MetricsGeneral from "../elements/MetricsGeneral";
import MetricsRisk from "../elements/MetricsRisk";
import PortfolioAllocation from "../elements/PortfolioAllocation";
import PortfolioEquityLine from "../elements/PortfolioEquityLine";
import PortfolioQuarters from "../elements/PortfolioQuarters";
import PortfoliosList from "../elements/PortfoliosList";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class PortfolioBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      portfolio: 0,
      data: null,
    };
  }
  componentDidMount() {
    this.props.dispatch(notifyArea("builder"))
    console.log(this.props.params);
    let { id } = this.props.params;
    this.setState({ portfolio: id });
    this.loadPortfolio();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      let { id } = this.props.params;
      this.setState({ portfolio: id });
      this.loadPortfolio();
    }
  }

  loadPortfolio(id) {
    if (id) this.setState({ portfolio: id });
    this.setState({ isLoading: true });
    let config = {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("TOKEN"),
      },
    };
    api
      .get("/v1/builder/portfolios/" + (id ? id : this.props.portfolio), config)
      .then((response) => {
        const data = response.data;
        this.setState({ portfolios: data, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response) {
          console.log("Error 1", error.message);
          if (error.response.status === 401) {
          }
          this.setState({
            snackMessage: error.response.data,
            snackOpen: true,
          });
        } else if (error.request) {
          // The request was made but no response was received
          console.log("Error 2", error.message);
          this.setState({
            snackMessage: error.message,
            snackOpen: true,
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error 3", error.message);
          this.setState({
            snackMessage: error.message,
            snackOpen: true,
          });
        }
      });
  }

  render() {
    const { portfolio } = this.state;
    return (
      <Fade in={true} timeout={450}>
        <div>
          <Toolbar />
          <Container maxWidth="xl" sx={{ mb: 7 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12}>
                {/* <Button color="primary" sx={{ mr: 3 }} size="small" startIcon={<HomeOutlined />} component={NavLink} to={"/home"} disableElevation>back</Button> */}
                {portfolio ? (
                  <PortfoliosList
                    defaultPortfolio={portfolio}
                    reload={false}
                    notifyNewPosition={this.loadPortfolio.bind(this)}
                  />
                ) : (
                  "Loading your portfolios..."
                )}
              </Grid>
              <Grid container item xs={12} md={6} lg={8} spacing={1}>
                <Grid item xs={12}>
                  <PortfolioEquityLine
                    portfolio={portfolio}
                  ></PortfolioEquityLine>
                </Grid>
                <Grid item xs={6}>
                  <MetricsGeneral portfolio={portfolio} />
                </Grid>
                <Grid item xs={6}>
                  <MetricsRisk portfolio={portfolio} />
                </Grid>
                <Grid item xs={12}>
                  <PortfolioQuarters
                    portfolio={portfolio}
                  ></PortfolioQuarters>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} lg={4}>
                <Grid item xs={12}>
                  <PortfolioAllocation portfolio={portfolio} />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Fade>
    );
  }
}

function Portfolio(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  return <PortfolioBase navigate={navigate} dispatch={dispatch} {...props} />
}

export default withTheme(withParams(Portfolio));