import { Image, ImageOutlined } from '@mui/icons-material';
import { Box, Skeleton } from '@mui/material';
import React from 'react';


const SkeletonChart = props => (    
    <Skeleton sx={{borderRadius:'4px'}} width='100%' animation='pulse' variant="rect" height={props.height ? props.height : 150}>
        <Box>
        <ImageOutlined color='disabled' />
        </Box>
    </Skeleton>
)

export default SkeletonChart;