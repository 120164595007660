import { withTheme } from "@emotion/react";

function getStrengthName(value) {
    switch (value) {
        case 0.65:
            return "Strong"
            break;
        case 0.5:
            return "Standard"
            break;
        case 0.3:
            return "Moderate"
            break;
        default:
            return "-"
            break;
    }
}

export default getStrengthName
