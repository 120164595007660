import { Component } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ToggleButtonGroup,
  ToggleButton,
  Snackbar,
  IconButton,
  TextField,
} from "@mui/material";

import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import { withTheme } from "@emotion/react";
import { api } from "../api/apiBuilder";
import eventBus from "./eventBus";

const styles = (theme) => ({
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
});

class MyEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editorType: 0,
      editorSettings: 0,
      sizesCurrent: 0,
      sizesChecked: 0,
      namesChecked: '',
      strategiesChecked: 0,
      strengthChecked: 0,
      sectorsChecked: [],
      snackOpen: false,
      refreshList: 0, 
      reloadComponent: false,
      alertName: null,
    };
  }
  componentDidMount() {}

  changeTheme(v) {
    this.setState({ selectedTheme: v });
    if (v) v = "light";
    else v = "dark";
    this.props.changeTheme(v);
  }

  changeStrategies(e, strategiesChecked) {
    this.setState({ strategiesChecked });
  }
  changeName(e, namesChecked) {
    console.log(namesChecked);
    if (namesChecked === 0) {
      alert('Please select at least one name.');
      return;
    }
    this.setState({ namesChecked });
  }
  changeStrenghts(e, strengthChecked) {
    this.setState({ strengthChecked: parseFloat(strengthChecked) });
  }
  changeSizes(e, sizesChecked) {
    this.setState({ sizesChecked: parseFloat(sizesChecked) });
  }
  changeSectors(value) {
    let sectorsChecked = this.state.sectorsChecked;
    const currentIndex = sectorsChecked.indexOf(value);

    if (currentIndex === -1) {
      sectorsChecked.push(value);
    } else {
      sectorsChecked.splice(currentIndex, 1);
    }
    this.setState({ sectorsChecked });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      let editorType = this.props.editorType;
      this.setState({ editorType });
      this.loadSettings(editorType);
    }
  }

  loadSettings(editorType) {
    let config = {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("TOKEN"),
      },
    };
    api
      .get(
        "/v1/builder/portfolios/" +
          this.props.portfolio +
          "/settings/" +
          editorType +
          "/",
        config
      )
      .then((response) => {
        const data = response.data;
        this.setState({ data });
        console.log(response);

        if (editorType === "sectors") {
          this.setState({ sectorsChecked: [] });
          data.forEach((obj) => {
            if (obj.s) this.changeSectors(obj.id);
          });
        } else if (editorType == "sizes") {
          this.setState({ sizesChecked: data.size });
        } else if (editorType == "strenghts") {
          this.setState({ strengthChecked: data.strenght });
        } else if (editorType == "strategies") {
          this.setState({ strategiesChecked: data.strategy });
        } else if (editorType == "names") {
          this.setState({ namesChecked: data.name });
        } 
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response) {
          console.log("Error 1", error.message);
          if (error.response.status == 401) {
          }
          this.state.snackMessage = error.response.data;
          this.state.snackOpen = true;
        } else if (error.request) {
          // The request was made but no response was received
          console.log("Error 2", error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error 3", error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        }
      });
  }

  handleClose() {
    this.props.closeDialog();
  }

  handleEdit() {
    let editorType = this.state.editorType;
    let payload = "";
    if (editorType == "sectors") {
      payload = this.state.sectorsChecked;
    } else if (editorType == "sizes") {
      payload = this.state.sizesChecked;
    } else if (editorType == "strenghts") {
      payload = this.state.strengthChecked;
    } else if (editorType == "strategies") {
      payload = this.state.strategiesChecked;
    } else if (editorType == "name") {
      payload = this.state.namesChecked;
      if (payload.length === 0) {
        return window.alert('Non lasciare spazi vuoti');
      }
    }

    let config = {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("TOKEN"),
      },
    };
    api
      .put(
        "/v1/builder/portfolios/" +
          this.props.portfolio +
          "/settings/" +
          editorType +
          "/",
        { data: payload },
        config
      )
      .then((response) => {
        this.props.closeDialog();
        this.setState({ snackOpen: true });
        eventBus.dispatch("reloadList", { message: "reload" });
        this.changeName('')
      })
      .catch((error) => {});
  }

  handleSnackClose() {
    this.setState({ snackOpen: false });
  }

  render() {
    const { classes } = this.props;
    const {
      sectorsChecked,
      data,
      editorType,
      strategiesChecked,
      strengthChecked,
      sizesChecked,
      snackOpen,
    } = this.state;
    const sizesMarks = [
      {
        value: 10,
        label: "Xs",
      },
      {
        value: 20,
        label: "Sm",
      },
      {
        value: 50,
        label: "Md",
      },
      {
        value: 100,
        label: "Lg",
      },
    ];
    const strenghtsMarks = [
      {
        value: 0.3,
        label: "High liquidity",
      },
      {
        value: 0.5,
        label: "Standard",
      },
      {
        value: 0.65,
        label: "Low liquidity",
      },
    ];

    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackOpen}
          autoHideDuration={4000}
          message="Parameter successfuly changed"
          action={
            <IconButton onClick={() => this.handleSnackClose()}>
              <Close color="primary" />
            </IconButton>
          }
          onClose={() => this.handleSnackClose()}
        />
        <Dialog open={this.props.open} fullWidth={true} maxWidth="sm">
          <DialogTitle>Change you settings</DialogTitle>
          <DialogContent sx={{ ml: 3, mr: 3 }}>
            {
              // -------------------------------------------------------
              // SECTORS -----------------------------------------------
              // -------------------------------------------------------
              editorType == "sectors" ? (
                <div>
                  <DialogContentText>
                    Choose portfolio sectors by selecting the desired following
                    items.
                  </DialogContentText>
                  <Box sx={{ ml: 4, mr: 4 }}>
                    <List dense={true}>
                      {data && data.length > 0 ? (
                        data.map((obj) => {
                          return (
                            <ListItem>
                              <ListItemButton
                                onClick={() => this.changeSectors(obj.id)}
                                role={undefined}
                                dense
                                disableRipple
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    checked={
                                      sectorsChecked.indexOf(obj.id) !== -1
                                    }
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={"checkbox-list-label-" + obj.id}
                                  primary={obj.n}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })
                      ) : (
                        <Typography variant="subtitle1">Loading...</Typography>
                      )}
                    </List>
                  </Box>
                </div>
              ) : // -------------------------------------------------------
              // SIZES -------------------------------------------------
              // -------------------------------------------------------
              editorType == "sizes" && sizesChecked ? (
                <div>
                  <DialogContentText>
                    Choose portfolio size by selecting your desired value.
                  </DialogContentText>
                  <Box
                    sx={{
                      ml: 4,
                      mr: 4,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ToggleButtonGroup
                      sx={{ mb: 4, mt: 4 }}
                      color="primary"
                      value={sizesChecked}
                      exclusive
                      onChange={(e) =>
                        this.changeSizes(e.target.names, e.target.value)
                      }
                    >
                      <ToggleButton value={10}>Extra small</ToggleButton>
                      <ToggleButton value={20}>Small</ToggleButton>
                      <ToggleButton value={50}>Medium</ToggleButton>
                      <ToggleButton value={100}>Large</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </div>
              ) : // -------------------------------------------------------
              // NAME --------------------------------------------------
              // -------------------------------------------------------

              editorType === "name" ? (
                <div>
                  <DialogContentText>
                    Choose a new Portfolio name.
                  </DialogContentText>
                  <Box
                    sx={{
                      ml: 4,
                      mr: 4,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ToggleButtonGroup
                      sx={{ mb: 4, mt: 4 }}
                      color="primary"
                      value={sizesChecked}
                      exclusive
                      onChange={(e) => {
                        this.changeName(e.target.name, e.target.value);
                      }}
                      
                    >
                      <TextField
                        autoFocus
                        size="small"
                        type="text"
                        variant="outlined"
                        name="name"
                        
                      />
                    </ToggleButtonGroup>
                  </Box>
                </div>
              ) : // -------------------------------------------------------
              // STRENGHTS ---------------------------------------------
              // -------------------------------------------------------
              editorType == "strenghts" && strengthChecked ? (
                <div>
                  <DialogContentText>
                    Choose portfolio strength by selecting your desired type.
                  </DialogContentText>
                  <Box
                    sx={{
                      ml: 4,
                      mr: 4,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ToggleButtonGroup
                      sx={{ mb: 4, mt: 4 }}
                      color="primary"
                      value={strengthChecked}
                      exclusive
                      onChange={(e) =>
                        this.changeStrenghts(e.target.names, e.target.value)
                      }
                    >
                      <ToggleButton value={0.65}>
                        Strongest signals, higher liquidity
                      </ToggleButton>
                      <ToggleButton value={0.5}>Standard signal</ToggleButton>
                      <ToggleButton value={0.3}>
                        More opportunities, lower liquidity
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </div>
              ) : // -------------------------------------------------------
              // STRATEGIES ---------------------------------------------
              // -------------------------------------------------------
              editorType == "strategies" && strategiesChecked ? (
                <div>
                  <DialogContentText>
                    Choose portfolio strategy by selecting your desired type.
                  </DialogContentText>
                  <Box
                    sx={{
                      ml: 4,
                      mr: 4,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ToggleButtonGroup
                      sx={{ mb: 4, mt: 4 }}
                      color="primary"
                      value={strategiesChecked}
                      exclusive
                      onChange={(e) =>
                        this.changeStrategies(e.target.names, e.target.value)
                      }
                    >
                      <ToggleButton value="long">Long only</ToggleButton>
                      <ToggleButton value="short">Long and short</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </div>
              ) : (
                <Typography variant="subtitle1">Loading...</Typography>
              )
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()}>Cancel</Button>
            <Button variant="contained" onClick={() => this.handleEdit()}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withTheme(MyEditor);
