import { withTheme } from "@emotion/react";

function getMarketName(value) {
    switch (value) {
        case 350:
            return "S&P 500"
            break;
        case 348:
            return "Nasdaq 100"
            break;
        case 389:
            return "STOXX 600"
            break;
        case 1:
            return "FTSE MIB"
            break;
        case 339:
            return "FTSE 100"
            break;
        case 52:
            return "Hang Seng"
            break;
        case 174:
            return "Nikkei"
            break;
        default:
            return "-"
            break;
    }
}

export default getMarketName
