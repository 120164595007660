import React from "react";
import { useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import HeadingExport from "./HeadingExport";
import Background from "../assets/background.png";
import Icon from "./IconComponent";
import { api } from "../api/apiBuilder";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "10in",
    padding: "0px,",
    margin: "auto",
  },
  portfolioName: {
    marginBlock: 'initial',
    fontSize: "1rem",
  },
  portfolioReport: {
    fontSize: "3rem",
    marginBlock: 'initial',
    marginBottom: "1rem",
    color: "#3b75fd",
  },
  currentDate: {
    fontSize: "1rem",
    marginBlock: 'initial',
    marginBottom: "3in",
  },
  icon: {
    width: "190px",
  },
};

const PaginaIniziale = () => {
    let { id } = useParams();
    const [result, setResult] = useState(null)
    useEffect(() => {
        api.get("/v1/builder/portfolios/" + id + "/settings/name", { cache: false }).then((response) => {
            setResult(response.data.name)
            }
        );
      }, [id]);
  const currentDate = new Date().toLocaleDateString();

  return (
    <>
      <HeadingExport />
      <div style={{ backgroundImage: `url(${Background})` }}>
        <div style={styles.container}>
          <h5 style={styles.portfolioName}>{result}</h5>
          <h1 style={styles.portfolioReport}>Portfolio Report</h1>
          <p style={styles.currentDate}>{currentDate}</p>
          <Icon style={styles.icon} />
        </div>
      </div>
    </>
  );
};

export default PaginaIniziale;

