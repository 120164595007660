import { IconButton, Tooltip } from '@mui/material';
import { Component } from 'react';
import { BlockOutlined, CancelOutlined } from '@mui/icons-material';
import axios from 'axios';
import DialogConfirm from './DialogConfirm';
import { withTheme } from '@emotion/react';
import { api } from "../api/apiBuilder";

class MyRemover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTheme: false,
      dialogOpen: false,
      dialogMsg: null,
      dialogTitle: null,
      removerTip: null
    }
  }

  openDialog(blackList) {
    if (blackList) {
      this.setState({
        dialogMsg: 'Do you want to remove this instrument from the current session and stop receiving advices on it adding it to the blacklist? You can re-enable it anytime for the next sessions.',
        dialogTitle: 'Blacklist this instrument',
        removerTip: 'Remove this instrument from opening list and blacklist it',
        dialogOpen: true
      })
    } else {
      this.setState({
        dialogMsg: 'Do you want to remove this trade from the current session?',
        dialogTitle: 'Skip this trade',
        removerTip: 'Remove this instrument from opening list',
        dialogOpen: true
      })
    }
  }

  closeDialog() {
    this.setState({ dialogOpen: false })
  }

  removeInstrument(id, idptf, blackList) {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('TOKEN')
      }
    }

    api.delete('/v1/builder/portfolios/' + idptf + '/instruments/' + id + '/allocations/', config)
      .then(response => {
        if (blackList) {
          this.addToBlacklist(id)
        } else {
          this.props.loadSignals();
        }
      }).catch(error => {
        if (error.response) {
          console.log('Error 1', error.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error 2', error.message);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error 3', error.message);
        }
      })
  }

  addToBlacklist(id) {
    api.post('/v1/blacklist/instruments/' + id + '/', '')
      .then(response => {
        this.props.loadSignals();
      }).catch(error => {
        if (error.response) {
          console.log('Error 1', error.message);
          this.props.loadSignals();
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error 2', error.message);
          this.props.loadSignals();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error 3', error.message);
          this.props.loadSignals();
        }
      })
  }

  componentDidMount(){
    let blackList = this.props.blackList
    if (blackList) {
      this.setState({
        removerTip: 'Remove this instrument from opening list and blacklist it',
      })
    } else {
      this.setState({
        removerTip: 'Remove this instrument from opening list',
      })
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps !== this.props){
      console.log("update", this.props.blackList)
    }
  }

  render() {
    const { id, idptf, blackList } = this.props;
    const { dialogOpen, dialogMsg, dialogTitle, removerTip } = this.state;
    return (
      <>
        <DialogConfirm title={dialogTitle} open={dialogOpen} message={dialogMsg} cancel={() => this.closeDialog()} confirm={() => this.removeInstrument(id, idptf, blackList)}></DialogConfirm>
        <Tooltip title={removerTip}>
          <IconButton onClick={() => this.openDialog(blackList)}>
            {blackList ? <BlockOutlined color='error' /> : <CancelOutlined />}
          </IconButton>
        </Tooltip>
      </>
    );
  }
}

export default withTheme(MyRemover);
