import { Divider, Card, CardContent, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, Tooltip, ListItemAvatar, Box, Avatar, Button, styled, Switch, CardHeader } from '@mui/material';
import { Component } from 'react';
import numeral from 'numeral';
import getSectorIcon from '../commons/SectorIcons';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from "apexcharts";
import { withTheme } from '@emotion/react';
import SkeletonChart from './SkeletonChart';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb:before': {
      },
      '& + .MuiSwitch-track': {
        opacity: .7,
        backgroundColor: '#9f9f9f',
        borderRadius: 20 / 2,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary.main,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: .7,
    backgroundColor: '#9f9f9f',
    borderRadius: 20 / 2,
  },
}));

class AllocationBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allocation: {
        composition: [],
        liquidity: 0,
        liquidityShort: 0,
        portfolioValue: 0,
        chart_series: 0,
        chart_options: 0,
        breakdownType: false,
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (prevProps.allocation !== this.props.allocation) {
        console.log("updating...")
        this.plot()
      }

      if (this.props.selectedTheme !== prevProps.selectedTheme) {
        const componentProps = this.props
        ApexCharts.exec("donut", "updateOptions", {
          theme: {
            monochrome: {
              enabled: true,
              color: this.props.theme.palette.chartPrimary,
              shadeTo: 'light',
              shadeIntensity: 0.9
            }
          },
          stroke: {
            width: 4,
            colors: [this.props.theme.palette.pieBorders],
          },
          tooltip: {
            enabled: true,
            style: {
              fontSize: '12px',
              padding: '8px',
              border: 0
            },
            items: {
              display: 'flex',
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              const { selectedTheme } = componentProps;
              let bgColor = selectedTheme === 'dark' ? "#222" : "#FFF"
              return '<div style="padding:8px;background:' + bgColor + '">'
                + '<div>' + w.globals.labels[seriesIndex] + '</div>'
                + '<div>' + numeral(w.globals.seriesPercent[seriesIndex]).format('0.0') + '%</div>'
                + '</div>'
            }
          },
        })
      }
    }
  }

  plot() {
    if (this.props.allocation) {
      let alloc = this.props.allocation
      this.state.allocation = alloc;
      let breakdownType = this.state.breakdownType

      let listData = []
      let values = [], names = [];
      alloc.composition.forEach(obj => {
        if (breakdownType) {
          listData[obj.id_market] = listData[obj.id_market] ? listData[obj.id_market] + obj.percWeight : obj.percWeight
        } else {
          listData[obj.sector] = listData[obj.sector] ? listData[obj.sector] + obj.percWeight : obj.percWeight
        }
      });

      var keys = Object.keys(listData);
      keys.forEach(k => {
        values.push(listData[k]);
        names.push(k);
      });

      let options = {
        labels: names,
        chart: {
          id: "donut",
          type: 'donut',
          height: 350,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const currSector = this.state.selectedSector

              let active = config.selectedDataPoints.length
              let selectedSector = active ? config.w.config.labels[config.dataPointIndex] : 0
              let selectedSeriesIndex = config.seriesIndex
              let selectedDataPointIndex = config.dataPointIndex
              if (currSector == selectedSector) {
                this.setState({ selectedSector: 0, selectedDataPointIndex: 0 })
              } else {
                this.setState({ selectedSector, selectedSeriesIndex, selectedDataPointIndex })
              }
            },
          }
        },
        theme: {
          monochrome: {
            enabled: true,
            color: this.props.theme.palette.chartPrimary,
            shadeTo: 'light',
            shadeIntensity: 0.9
          }
        },
        stroke: {
          width: 4,
          colors: [this.props.theme.palette.pieBorders],
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, numeral(val).format('0.0') + '%']
          },
          dropShadow: {
            enabled: false,
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: '500',
            colors: ['#484848'],
            bgColor: '#fff'
          },
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '12px',
            padding: '8px',
            border: 0
          },
          items: {
            display: 'flex',
          },
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return '<div style="padding:8px">' +
              '<div>'
              + w.globals.labels[seriesIndex]
              + '</div>'
              + '<div>'
              + numeral(w.globals.seriesPercent[seriesIndex]).format('0.0') + '%</div>' +
              '</div>'
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: 32,
              minAngleToShowLabel: 500,
            },
            expandOnClick: false,
            startAngle: 0,
            //endAngle: -315,
            donut: {
              size: '65%',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'Roboto, sans-serif',
                  fontWeight: 400,
                  color: '#000048',
                  offsetY: 0,
                  formatter: function (val) {
                    return val
                  }
                },
                value: {
                  show: true,
                  fontSize: '26px',
                  fontFamily: 'Fjalla One, sans-serif',
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return numeral(val).format('0.0%')
                  }
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: breakdownType ? 'Markets' : 'Sectors',
                  fontSize: '16px',
                  fontFamily: 'Roboto, sans-serif',
                  fontWeight: 400,
                  color: '#373d3f',
                  formatter: function (w) {
                    /*return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)*/
                    return ''
                  }
                }
              }
            }
          }
        },
        legend: {
          position: "bottom",
          fontSize: '12px',
          fontFamily: 'Roboto',
          labels: {
            colors: "#a0a0a0",
          },
          markers: {
            radius: 50,
            width: 8,
            height: 8,
          }
        }
      };

      this.setState({ chart_options: options, chart_series: values });

    }
  }
  componentDidMount() {
    this.plot()
  }

  reset() {
    if (this.state.selectedDataPointIndex || this.state.selectedSector) {
      ApexCharts.exec("donut", "toggleDataPointSelection", this.state.selectedDataPointIndex)
      //cancel("PF_ALLOC_" + this.props.portfolio)
      this.setState({ selectedSector: 0, selectedDataPointIndex: 0 })
    }
  }

  handleBreakdown(breakdownType) {
    this.reset()
    this.setState({ selectedSector: 0, breakdownType }, function () {
      this.plot()
    })
  }

  render() {
    const { isLoading, allocation } = this.props;
    const { selectedSector, chart_series, chart_options, breakdownType } = this.state;
    return (
      <>
        <Card variant='outlined'>
          <CardHeader title={<Typography gutterBottom variant="overline" component="h2">Proposed allocation</Typography>} />
          <CardContent>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant='caption'>Sectors</Typography>
              {/* <Switch defaultChecked={breakdownType} onChange={(e) => { this.handleBreakdown(e.target.checked) }} /> */}
              <MaterialUISwitch defaultChecked={breakdownType} onChange={(e) => { this.handleBreakdown(e.target.checked) }} />
              <Typography variant='caption'>Markets</Typography>
            </Box>
            {isLoading ? <SkeletonChart height={300} /> : (
              allocation && allocation.composition.length > 0 ? (
                <>
                  <Box sx={{ p: 0, mb: 3 }}>
                    {chart_options ?
                      <>
                        <ReactApexChart series={chart_series} type={chart_options.chart.type} options={chart_options} height={chart_options.chart.height}></ReactApexChart>
                      </>
                      : ""
                    }
                  </Box>

                  <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: '50%' }}><Typography variant='overline'>Assets list</Typography></Box>
                    <Box sx={{ flex: '50%', textAlign: 'right' }}>
                      {selectedSector ? <Button size='small' onClick={() => this.reset()}>reset filter</Button> : ""}
                    </Box>
                  </Box>
                  <List dense={true} sx={{ maxHeight: "calc(66px * 5)", overflow: "auto" }}>
                    {allocation.composition.map((obj) => (
                      !selectedSector || obj.sector == selectedSector || obj.id_market == selectedSector ?
                        <>
                          <ListItem>
                            <ListItemAvatar>
                              <Tooltip title={obj.sector}>
                                <Avatar sx={{ backgroundColor: this.props.theme.palette.accent }}>{getSectorIcon(obj.sector)}</Avatar>
                              </Tooltip>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  component="div"
                                  variant="subtitle2"
                                  color="text.primary"
                                >
                                  {obj.ticker}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  component="span"
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {obj.name}
                                </Typography>
                              }
                            />
                            <ListItemSecondaryAction secondary={'second'}>
                              {/* <Chip variant='outlined' label={obj.direction} size="small" /> */}
                              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }} spacing={2} >
                                <Tooltip title={"weight: " + numeral(obj.percWeight).format('0.0%')}><Box>{numeral(obj.qty).format('0,000.[0]')}</Box></Tooltip>
                                <Typography variant="caption" color='textSecondary'>{obj.dir}</Typography>
                              </Box>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider />
                        </>
                        : ""
                    ))}
                  </List>
                </>
              ) : <Box sx={{ py: 3 }}>No allocation proposed with these settings</Box>
            )}
          </CardContent>
        </Card>
      </>
    );
  }
}

function Allocation(props) {
  const navigate = useNavigate()
  const selectedTheme = useSelector(state => state.theme)

  return <AllocationBase navigate={navigate} selectedTheme={selectedTheme} {...props} />
}

export default withTheme(Allocation);

