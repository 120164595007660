import { withTheme } from '@emotion/react';
import { CurrencyBitcoin, DashboardOutlined, LogoutOutlined, SettingsSuggestOutlined } from '@mui/icons-material';
import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { notifyArea } from '../dispatcher/Actions';
import packageJson from '../../package.json';

const MyDrawer = (props) => {
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [version, setVersion] = useState(null);
  const globalArea = useSelector(state => state.area);
  const globalPerms = useSelector(state => state.perms);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [voices, setVoices] = useState([
    { "id": 1, "name": "Dashboard", "link": "dashboard", "area": "dashboard", "icon": <DashboardOutlined /> },
    { "id": 2, "name": "Builder", "link": "home", "area": "builder", "icon": <SettingsSuggestOutlined /> },
    { "id": 3, "name": "Crypto", "link": "crypto", "area": "crypto", "icon": <CurrencyBitcoin /> },
  ]);

  useEffect(() => {
    const versionStr = packageJson.version
    // Split the version string by periods
    let versionComponents = versionStr.split(".");
    // Take only the first three components (major, minor, patch)
    let v = versionComponents.slice(0, 3).join(".");

    setVersion(v)
  }, []);

  useEffect(() => {
    let data = secureLocalStorage.getItem("perms")
    if (data) {
      // Update the voices array based on response
      const updatedVoices = voices.map(voice => ({
        ...voice,
        allow: data[voice.area] === 1
      }));

      // Set the updated voices array
      setVoices(updatedVoices);
    }
  }, [globalPerms]); // Empty dependency array to run only once on mount

  useEffect(() => {
    setSelectedVoice(globalArea);
  }, [globalArea]);

  const handleArea = (link, area, id) => {
    setSelectedVoice(area);
    navigate(link);
    dispatch(notifyArea(area));
  };

  const getIconColor = (area) => (selectedVoice === area ? "primary" : "default");

  return (
    <Drawer
      sx={{
        width: 80,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 80,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
      open={props.open}
      onClick={() => props.toggleDrawer(false)}
    >
      <Box sx={{ p: 1, textAlign: "center" }}>
        {voices.map(voice => (
          voice.allow && (
            <Box sx={{ pb: 1 }} key={voice.id}>
              <IconButton onClick={() => handleArea(voice.link, voice.area, voice.id)}>
                {React.cloneElement(voice.icon, { color: getIconColor(voice.area) })}
              </IconButton>
              <Typography variant='caption' component={'div'} className='slim' color={selectedVoice === voice.area ? "primary" : "default"}>
                {voice.name}
              </Typography>
            </Box>
          )
        ))}
        <Divider sx={{ my: 2 }} />
        <Box>
          <IconButton onClick={() => props.logout()}>
            <LogoutOutlined color="default" />
          </IconButton>
          <Typography variant='caption' component={'div'}>
            Logout
          </Typography>
        </Box>
      </Box>
      <Box sx={{position: 'fixed', bottom: 8, pl: 1}}>
        <Typography variant='caption' color={'textSecondary'}>v{version}</Typography>
      </Box>
    </Drawer>
  );
};

export default withTheme(MyDrawer);
