import log from 'loglevel';

const initialState = {
    reload: 0,
    lastInstrument: 0,
    customView: 1,
    theme: localStorage.getItem('THEME') || 'dark',
    area: "dashboard",
    vitrumModel: 0,
    perms: 0,
    builtPtfSettings: 0,
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'RELOAD':
            return {
                ...state,
                reload: action.value
            }
        case 'INSTRUMENT':
            return {
                ...state,
                lastInstrument: action.value
            }
        case 'CUSTOM_VIEW':
            return {
                ...state,
                customView: action.value
            }
        case 'THEME':
            return {
                ...state,
                theme: action.value
            }
        case 'AREA':
            return {
                ...state,
                area: action.value
            }
        case 'VITRUM_MODEL':
            return {
                ...state,
                vitrumModel: action.value
            }
        case 'PERMS':
            return {
                ...state,
                perms: action.value
            }
        case 'BUILT_PTF_SETTINGS':
            log.debug('Reducer: BUILT_PTF_SETTINGS', action.value);
            return {
                ...state,
                builtPtfSettings: action.value
            }
        default:
            return state
    }
}
