import React, { Component } from 'react';
import numeral from 'numeral';
import { Box, Card, CardContent, CardHeader, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Typography } from '@mui/material';
import { withTheme } from '@emotion/react';
import SkeletonList from '../elements/SkeletonList';
import Metric from '../commons/Metric';
import { HelpOutline } from '@mui/icons-material';
import DialogHelpMetricsGeneral from '../commons/DialogHelpMetricsGeneral';
import { api } from "../api/apiBuilder";


class PortfolioSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      summary: 0,
      helpOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.portfolio != prevProps.portfolio && this.props.portfolio) {
        this.load()
      }
    }
  }

  componentDidMount() {
    if (this.props.portfolio) {
      this.load()
    }
  }

  load() {
    this.setState({ isLoading: true })
    api.get('/builder/portfolios/' + this.props.portfolio + '/summary/', { cache: false })
      .then(response => {
        const data = response.data;

        data.forEach(item => {
          if (item.tpe == 'pf') this.setState({ portfolioSummary: item })
          else if (item.tpe == 'bm') this.setState({ benchmarkSummary: item })
        })

        this.setState({ summary: data, isLoading: false })
      }).catch(error => {
        this.setState({ isLoading: false })
        if (error.response) {
          console.log('Error 1', error.message);
          if (error.response.status == 401) {
          }
          this.state.snackMessage = error.response.data;
          this.state.snackOpen = true;
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error 2', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error 3', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        }
      })
  }

  openHelp(){
    this.setState({helpOpen: true});
  }

  closeHelp(){
    this.setState({helpOpen: false});
  }

  render() {
    const {  } = this.props;
    const { portfolioSummary, benchmarkSummary, isLoading, helpOpen } = this.state;

    return (
      <>
        <Card variant='outlined'>
          <CardHeader 
          title={<Typography variant='overline'>Portfolio metrics</Typography>} 
          />
          <CardContent>
            {isLoading ? <SkeletonList rows='5' /> :
              portfolioSummary && benchmarkSummary ?
                <>
                  <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ flex: '50%', display: 'flex' }}>
                      <Metric title={'Annualized Return'}
                        k={'annReturn'}
                        v={portfolioSummary.yReturn}
                        portfolioVal={numeral(portfolioSummary.yReturn * 100).format('+0.00') + '%'}
                        benchmarkVal={numeral(benchmarkSummary.yReturn * 100).format('+0.00') + '%'}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ flex: '50%', display: 'flex' }}>
                      <Metric title={'Sharpe'}
                        k={'sharpe'}
                        v={portfolioSummary.sharpe}
                        portfolioVal={numeral(portfolioSummary.sharpe).format('0.00')}
                        benchmarkVal={numeral(benchmarkSummary.sharpe).format('0.00')}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ flex: '50%', display: 'flex' }}>
                      <Metric title={'Martin Ratio'}
                        k={'martin'}
                        v={portfolioSummary.martin}
                        portfolioVal={numeral(portfolioSummary.martin).format('0.00')}
                        benchmarkVal={numeral(benchmarkSummary.martin).format('0.00')}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ flex: '50%', display: 'flex' }}>
                      <Metric title={'Alpha'}
                        k={'alpha'}
                        v={portfolioSummary.alpha}
                        portfolioVal={numeral(portfolioSummary.alpha * 100).format('+0.00') + '%'}
                        benchmarkVal="N/D"
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ flex: '50%', display: 'flex' }}>
                      <Metric title={'Beta'}
                        k={'beta'}
                        v={portfolioSummary.beta}
                        portfolioVal={numeral(portfolioSummary.beta).format('0.00')}
                        benchmarkVal="N/D"
                      />
                    </Box>
                  </Box>
                </>


                : "No data"
            }

          </CardContent>
        </Card>
      </>
    );
  }
}

export default withTheme(PortfolioSummary);
