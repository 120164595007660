import { withTheme } from '@emotion/react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import ApexCharts from "apexcharts";
import moment from 'moment';
import numeral from 'numeral';
import { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import MySkeleton from './MySkeleton';
import { useSelector } from 'react-redux';
import log from 'loglevel';

function getQuarter(value) {
  let ret;
  var month = value.substring(4, 6);
  var day = value.substring(6, 8);
  var year = value.substring(0, 4);
  var d = new Date(year, month - 1, day);
  let m = d.getMonth();

  if (m < 3) ret = 'Q1' + " " + year;
  else if (m < 6) ret = 'Q2' + " " + year;
  else if (m < 9) ret = 'Q3' + " " + year;
  else if (m < 12) ret = 'Q4' + " " + year;
  else ret = "-"

  return ret

}

class QuartersBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quarters: props.quarters,
      quartersTableOpen: false,
      chartHover: -1,
    }
  }

  componentDidMount() {
    if (this.props.quarters)
      this.plot(this.props.quarters)
  }

  componentDidUpdate(prevProps) {
    numeral.options.scalePercentBy100 = true;
    if (prevProps !== this.props) {
      if (this.props.quarters != prevProps.quarters) {
        this.plot(this.props.quarters)
      }

      if (this.props.selectedTheme !== prevProps.selectedTheme) {
        log.debug("tema cambiato!", this.props.selectedTheme);
        ApexCharts.exec("QUARTERS_CHARTID", "updateOptions", {
          xaxis: {
            categories: this.state.chart_options.xaxis.categories,
            labels: {
              show: true,
              rotate: 0,
              style: {
                colors: this.props.theme.palette.text.primary,
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },

          yaxis: [{
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.[00]%') },
              style: {
                colors: [this.props.theme.palette.text.primary],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          }],

          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            itemMargin: {
              vertical: 16
            },
            labels: {
              colors: [this.props.theme.palette.text.primary],
              useSeriesColors: false
            },
            markers: {
              radius: 0,
              width: 4,
              height: 8
            },
          },

          grid: {
            borderColor: this.props.theme.palette.grid,
            strokeDashArray: 0,
            position: 'back',
          },

          tooltip: {
            theme: this.props.selectedTheme,
            shared: true,
            x: { show: true },
            y: {
              formatter: function (val) {
                return numeral(val).format('+0,000.00%');
              }
            }
          }
        })
      }

    }
  }

  plot(data) {
    let pfData = data.portfolio
    let bmData = data.benchmark
    var p_values = [];
    var p_cumul = [];
    var b_values = [];
    var b_cumul = [];
    var categories = [];

    var p_val = [];
    var b_val = [];

    for (var i = 0; i < pfData.length; i++) {
      var pfRecord = pfData[i]
      var bmRecord = bmData[i]
      p_values.push(pfRecord.return * 1)
      p_cumul.push(pfRecord.cumulReturn * 1)
      categories.push([
        getQuarter(pfRecord.close)
      ])
      b_values.push(bmRecord.return * 1)
      b_cumul.push(bmRecord.cumulReturn * 1)
    }
    var series_data = [
      {
        name: 'Portfolio: return',
        type: 'column',
        data: p_values
      }, {
        name: 'Benchmark: return',
        type: 'column',
        data: b_values
      }, {
        name: 'Portfolio: cumulative',
        type: 'line',
        data: p_cumul
      }, {
        name: 'Benchmark: cumulative',
        type: 'line',
        data: b_cumul
      }
    ];
    let optimalColumnWidthPercent = 20 + (60 / (1 + 30 * Math.exp(-pfData.length / 3)));
    var options = {
      chart: {
        id: "QUARTERS_CHARTID",
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        height: 'auto',
        type: "line",
        stacked: false,
        animations: {
          enabled: true,
          easing: 'easein',
          speed: 250,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        events: {
          mouseMove: (event, chartContext, config) => {
            this.setState({ chartHover: config.dataPointIndex })
          },
          mouseLeave: (event, chartContext, config) => {
            this.setState({ chartHover: -1 })
          },
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: false,
          borderRadius: 4,
          columnWidth: optimalColumnWidthPercent + "%"
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        itemMargin: {
          vertical: 16
        },
        labels: {
          colors: [this.props.theme.palette.text.primary],
          useSeriesColors: false
        },
        markers: {
          radius: 0,
          width: 4,
          height: 8
        },
      },
      stroke: {
        show: true,
        width: 3,
        colors: ['transparent', 'transparent', this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary]
      },
      markers: {
        size: 4,
        strokeWidth: 1,
        hover: {
          size: 6
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: [this.props.theme.palette.chartPrimaryLight, this.props.theme.palette.chartSecondaryLight, this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
      grid: {
        borderColor: this.props.theme.palette.grid,
        strokeDashArray: 0,
        position: 'back',
      },
      xaxis: {
        categories,
        labels: {
          show: true,
          rotate: 0,
          style: {
            colors: this.props.theme.palette.text.primary,
            fontSize: '11px',
            fontFamily: 'Roboto',
            fontWeight: 400,
          },
        },
      },
      yaxis: [{
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: (value) => { return numeral(value).format('0.[00]%') },
          style: {
            colors: [this.props.theme.palette.text.primary],
            fontSize: '11px',
            fontFamily: 'Roboto',
            fontWeight: 400,
          },
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      }],
      tooltip: {
        theme: this.props.selectedTheme,
        enabled: true,
        shared: true,
        x: { show: true },
        y: {
          formatter: function (val) {
            return numeral(val).format('+0,000.00%');
          }
        }
      }
    };
    this.setState({ chart_options: options, series_data });
  }

  handleQuartersTable() {
    this.setState({ quartersTableOpen: !this.state.quartersTableOpen })
  }

  render() {
    const { chart_options, series_data, quartersTableOpen, chartHover } = this.state;
    const { quarters } = this.props;
    const { pf } = quarters ? quarters.portfolio : [];
    const { bm } = quarters ? quarters.benchmark : [];

    return (
      <div>
        <Card variant='outlined'>
          <CardContent>
            <Typography variant='overline' gutterBottom>
              Portfolio/Benchmark: Returns
            </Typography>

            {this.props.isLoading ? <MySkeleton type={'table'} rows={3} animation={'pulse'}></MySkeleton> : (
              quarters ? (
                <>
                  {chart_options ?
                    <ReactApexChart series={series_data} type={chart_options.chart.type} options={chart_options} height={350} />
                    : "vuoto"}
                  <Collapse in={quartersTableOpen} collapsedSize={36.5}>
                    <Box sx={{ textAlign: 'right' }}>
                      <Button startIcon={quartersTableOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />} onClick={() => this.handleQuartersTable()}>{quartersTableOpen ? "Close" : "More"} details</Button>
                    </Box>
                    <Typography variant='overline'>quarters details table</Typography>
                    <TableContainer>
                      <Table size={'small'} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" colSpan={1}>

                            </TableCell>
                            <TableCell align="center" colSpan={2}>
                              Return
                            </TableCell>
                            <TableCell align="center" colSpan={2}>
                              Cumulative Return
                            </TableCell>
                            <TableCell align="center" colSpan={2}>
                              Total
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="right"><Typography variant='subtitle2' color='primary'>Quarter</Typography></TableCell>
                            <TableCell align="right"><Tooltip title='Portfolio'><Typography variant='subtitle2' color='primary'>Port.</Typography></Tooltip></TableCell>
                            <TableCell align="right"><Tooltip title='Benchmark'><Typography variant='subtitle2' color='primary'>Benc.</Typography></Tooltip></TableCell>
                            <TableCell align="right"><Tooltip title='Portfolio'><Typography variant='subtitle2' color='primary'>Port.</Typography></Tooltip></TableCell>
                            <TableCell align="right"><Tooltip title='Benchmark'><Typography variant='subtitle2' color='primary'>Benc.</Typography></Tooltip></TableCell>
                            <TableCell align="right"><Tooltip title='Portfolio'><Typography variant='subtitle2' color='primary'>Port.</Typography></Tooltip></TableCell>
                            <TableCell align="right"><Tooltip title='Benchmark'><Typography variant='subtitle2' color='primary'>Benc.</Typography></Tooltip></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quarters.portfolio.length > 0 ? quarters.portfolio.map((obj, i) => (
                            <TableRow selected={chartHover == i ? true : false} key={i}>
                              <TableCell align="right">
                                {
                                  (() => {
                                    var dateString = obj.close;
                                    var year = dateString.substring(0, 4);
                                    var month = dateString.substring(4, 6);
                                    var day = dateString.substring(6, 8);
                                    var d = new Date(year, month - 1, day);
                                    let m = d.getMonth();
                                    let title = moment(obj.open).format('DD MMM YYYY') + '/' + moment(obj.close).format('DD MMM YYYY');
                                    let ret = 0;
                                    if (m < 4) ret = 'Q1' + " " + year;
                                    else if (m < 7) ret = 'Q2' + " " + year;
                                    else if (m < 10) ret = 'Q3' + " " + year;
                                    else if (m < 13) ret = 'Q4' + " " + year;
                                    return <Tooltip title={title}><div>{ret}</div></Tooltip>
                                  })()


                                  //moment(obj.open).format('MMM YYYY')}/{moment(obj.close).format('MMM YYYY')
                                }
                              </TableCell>
                              <TableCell style={{ color: obj.color }} align="right">{numeral(obj.return * 100).format('0.00') + '%'}</TableCell>
                              <TableCell align="right">{numeral(quarters.benchmark[i].return * 100).format('0.00') + '%'}</TableCell>
                              <TableCell align="right">{numeral(obj.cumulReturn * 100).format('0.00') + '%'}</TableCell>
                              <TableCell align="right">{numeral(quarters.benchmark[i].cumulReturn * 100).format('0.00') + '%'}</TableCell>
                              <TableCell align="right">
                                <Tooltip arrow placement="top-start" title={numeral(obj.total).format('0,000.00')}>
                                  <div>{numeral(obj.total).format('0.00a')}</div>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip arrow placement="top-start" title={numeral(obj.total).format('0,000.00')}>
                                  <div>{numeral(quarters.benchmark[i].total).format('0.00a')}</div>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          )) : <div style={this.props.theme.empty}></div>}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <Box sx={{ textAlign: 'right' }}>
                      <Button startIcon={quartersTableOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />} onClick={() => this.handleQuartersTable()}>{quartersTableOpen ? "Close" : "More"} details</Button>
                    </Box> */}
                  </Collapse>
                </>
              ) : 'No data')
            }
          </CardContent>
        </Card>
      </div>
    );
  }
}

function Quarters(props) {
  const selectedTheme = useSelector(state => state.theme)

  return <QuartersBase selectedTheme={selectedTheme} {...props} />
}

export default withTheme(Quarters);