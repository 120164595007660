import { withTheme } from '@emotion/react';
import { Avatar, Box, Card, CardContent, CardHeader, Divider, LinearProgress, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { apiVitrum } from "../../api/apiVitrum";
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import numeral from 'numeral';


const ClusterArea = (props) => {
    const [loading, setLoading] = useState(false)
    const theme = useSelector(state => state.theme)
    const vitrumModel = useSelector(state => state.vitrumModel)
    const [optionsMeans, setOptionsMeans] = useState(null)
    const [optionsStdev, setOptionsStdev] = useState(null)
    const [seriesMeans, setSeriesMeans] = useState([])
    const [seriesStdev, setSeriesStdev] = useState([])
    const [data, setData] = useState(null)

    const descs = {
        "1": "Exhibits a slightly negative average return and higher market risk compared to other clusters. It shows a stable long-term trend with a modest upward momentum and moderate quantitative market performance.",
        "2": "Demonstrates a slightly positive average return with relatively low market risk. It maintains a stable long-term trend and modest quantitative market performance.",
        "3": "Displays a positive average return with moderate market risk. It shows a stable long-term trend with a moderate upward momentum and moderate quantitative market performance.",
        "4": "Shows a slightly negative average return with moderate market risk. It exhibits a stable long-term trend with a modest downward momentum and moderate quantitative market performance."
    }

    useEffect(() => {
        fetch()
    }, [])

    const fetch = () => {
        setLoading(true)
        apiVitrum.get(`/v1/cluster-stats/`, { "id": "cluster-stats" }).then(response => {
            const data = response.data
            setData(data)

            let seriesMeans = [
                {
                    name: 'Cluster 1' + (data.active == 1 ? ' (Active)' : ''),
                    data: [data.data.mean_7d["1"], data.data.mean_30d["1"]],
                }, {
                    name: 'Cluster 2' + (data.active == 2 ? ' (Active)' : ''),
                    data: [data.data.mean_7d["2"], data.data.mean_30d["2"]],
                },
                {
                    name: 'Cluster 3' + (data.active == 3 ? ' (Active)' : ''),
                    data: [data.data.mean_7d["3"], data.data.mean_30d["3"]],
                },
                {
                    name: 'Cluster 4' + (data.active == 4 ? ' (Active)' : ''),
                    data: [data.data.mean_7d["4"], data.data.mean_30d["4"]],
                }
            ]
            setSeriesMeans(seriesMeans)
            var options1 = {
                seriesMeans,
                title: {
                    text: "Performance",
                    align: 'left',
                    margin: 0,
                    offsetX: 0,
                    offsetY: 10,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: '',
                        fontFamily: 'Roboto',
                        color: props.theme.palette.text.primary
                    },
                },
                chart: {
                    type: 'bar',
                    toolbar: { show: false },
                    id: "chart1"
                },
                colors: [
                    function ({ value, seriesIndex, w }) {
                        if (seriesIndex + 1 == data.active) {
                            if (value < 0) {
                                return props.theme.palette.short.main
                            } else {
                                return props.theme.palette.long.main
                            }
                        } else {
                            return '#848484'
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '70%',
                        endingShape: 'rounded',
                    },
                },
                grid: {
                    borderColor: props.theme.palette.grid,
                    strokeDashArray: 0,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                markers: {
                    size: 0
                },
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    theme: theme,
                    x: { show: false },
                    y: {
                        formatter: function (val) {
                            return numeral(val*10).format('0,000.00');
                        }
                    }
                },
                xaxis: {
                    show: true,
                    categories: ["7d", "30d"],
                    labels: {
                        show: false,
                        style: {
                            colors: props.theme.palette.text.primary,
                            fontSize: '11px',
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                },
                yaxis: {
                    show: true,
                    tickAmount: 3,
                    min: function (min) { return min },
                    max: function (max) { return max },
                    // forceNiceScale: true,
                    labels: {
                        show: true,
                        style: {
                            colors: props.theme.palette.text.primary,
                            fontSize: '11px',
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                },
                annotations: {
                    xaxis: [
                        {
                            y: 0,
                            strokeDashArray: 0,
                            opacity: 1,
                            offsetX: 0,
                            offsetY: 0
                        }
                    ]
                },
            }
            setOptionsMeans(options1)

            let seriesStdev = [
                {
                    name: 'Cluster 1',
                    data: [data.data.std_7d["1"], data.data.std_30d["1"]],
                }, {
                    name: 'Cluster 2',
                    data: [data.data.std_7d["2"], data.data.std_30d["2"]],
                },
                {
                    name: 'Cluster 3',
                    data: [data.data.std_7d["3"], data.data.std_30d["3"]],
                },
                {
                    name: 'Cluster 4',
                    data: [data.data.std_7d["4"], data.data.std_30d["4"]],
                }
            ]
            setSeriesStdev(seriesStdev)
            var options2 = {
                seriesStdev,
                title: {
                    text: "Volatility",
                    align: 'left',
                    margin: 0,
                    offsetX: 0,
                    offsetY: 10,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: '',
                        fontFamily: 'Roboto',
                        color: props.theme.palette.text.primary
                    },
                },
                chart: {
                    type: 'bar',
                    toolbar: { show: false },
                    id: "chart2",
                },
                colors: [
                    function ({ value, seriesIndex, w }) {
                        if (seriesIndex + 1 == data.active) {
                            if (value < 0) {
                                return props.theme.palette.short.main
                            } else {
                                return props.theme.palette.long.main
                            }
                        } else {
                            return '#848484'
                        }
                    }
                ],
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '70%',
                        endingShape: 'rounded',
                    },
                },
                grid: {
                    borderColor: props.theme.palette.grid,
                    strokeDashArray: 0,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                markers: {
                    size: 0
                },
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    theme: theme,
                    x: { show: false },
                    y: {
                        formatter: function (val) {
                            return numeral(val*10).format('0,000.00');
                        }
                    }
                },
                xaxis: {
                    show: true,
                    categories: ["7d", "30d"],
                    labels: {
                        show: false,
                        style: {
                            colors: props.theme.palette.text.primary,
                            fontSize: '11px',
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                },
                yaxis: {
                    show: true,
                    tickAmount: 3,
                    min: function (min) { return min },
                    max: function (max) { return max },
                    // forceNiceScale: true,
                    labels: {
                        show: true,
                        style: {
                            colors: props.theme.palette.text.primary,
                            fontSize: '11px',
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                },
                annotations: {
                    xaxis: [
                        {
                            y: 0,
                            strokeDashArray: 0,
                            opacity: 1,
                            offsetX: 0,
                            offsetY: 0
                        }
                    ]
                },
            }
            setOptionsStdev(options2)
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        })

    }

    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader title={<Typography variant='overline'>Cluster barometer</Typography>} />
            <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                    <Box sx={{ display: 'flex', }}>
                        {loading ?
                            <Skeleton sx={{ width: '100%', height: 96 }} />
                            :
                            <>
                                <Box sx={{ width: 'fit-content', minWidth: 150, textAlign: 'center' }}>
                                    <Typography variant='h3' className='big'>{data && data.active}</Typography>
                                    <Typography variant='caption' color={"textSecondary"}>Current cluster</Typography>
                                </Box>
                                <Box sx={{ p: 2 }}>
                                    <Typography variant='body2'>
                                        {data && descs[data.active]}
                                    </Typography>
                                </Box>
                            </>
                        }
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{}}>
                        {
                            loading ?
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ flex: '50%', px: 4 }}>
                                        <Skeleton sx={{ height: 150 }} />
                                    </Box>
                                    <Box sx={{ flex: '50%', px: 4 }}>
                                        <Skeleton sx={{ height: 150 }} />
                                    </Box>
                                </Box>
                                :
                                optionsStdev && optionsMeans &&
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ flex: '50%', px: 4 }}>
                                        <ReactApexChart
                                            options={optionsMeans}
                                            series={seriesMeans}
                                            type="bar"
                                            height={180}
                                        />
                                    </Box>
                                    <Box sx={{ flex: '50%', px: 4 }}>

                                        <ReactApexChart
                                            options={optionsStdev}
                                            series={seriesStdev}
                                            type="bar"
                                            height={180}
                                        />
                                    </Box>
                                </Box>
                        }
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default withTheme(ClusterArea);
