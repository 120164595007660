import { withTheme } from '@emotion/react';
import { KeyboardArrowLeftOutlined } from '@mui/icons-material';
import { Button, Container, Fade, Grid, Toolbar } from '@mui/material';
import React, { Component } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DetailsFundamental from '../cards/DetailsFundamental';
import DetailsHistory from '../cards/DetailsHistory';
import DetailsOverview from '../cards/DetailsOverview';
import { notifyArea } from '../dispatcher/Actions';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class InstrumentBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idInstr: 0,
    }
  }

  componentDidMount() {
    this.props.dispatch(notifyArea("builder"))
    let { idInstr, idPortfolio, idTrade } = this.props.params;
    this.setState({ idInstr, idPortfolio, idTrade })
  }

  componentDidUpdate() {
  }

  render() {
    const { idInstr, idPortfolio, idTrade } = this.state;
    return (
      <>
        {idInstr && idInstr > 0 ? (
          <Fade in="true" timeout={450} >
            <div>
              <Toolbar />
              <Container maxWidth='xl' sx={{ mb: 7 }}>
                <Grid container direction="row" alignItems="flex-start" spacing={1}>
                  <Grid item xs={12} container direction={'row'}>
                    <Button sx={{ ml: 1 }} component={NavLink} to={'/portfolio/' + idPortfolio} startIcon={<KeyboardArrowLeftOutlined />}>Back</Button>
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <Grid item xs={12}>
                      <DetailsOverview id={idInstr} />
                    </Grid>
                    <Grid item xs={12}>
                      <DetailsFundamental id={idInstr} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DetailsHistory instr={idInstr} trade={idTrade} portfolio={idPortfolio} />
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Fade>
        ) : 'loading...'}
      </>
    );
  }
}

function Instrument(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  return <InstrumentBase navigate={navigate} dispatch={dispatch} {...props} />
}

export default withTheme(withParams(Instrument));