import { withTheme } from '@emotion/react';
import { FullscreenExitOutlined, FullscreenOutlined, MoreVert, VerifiedUser } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from "../../api/apiBuilder";
import SkeletonTable from '../../elements/SkeletonTable';
import BlockchainDialog from './BlockchainDialog';

const columns = [
  { field: 'direction', headerName: 'Signal', editable: false, },
  { field: 'close_date', headerName: 'Closed', type: 'number', editable: true, },
  { field: 'performance', headerName: 'Profit', editable: true, },
  { field: 'open_date', headerName: 'Opened', editable: true, },
  { field: 'open_value', headerName: 'Open', editable: true, },
  { field: 'close_value', headerName: 'Close', type: 'number', editable: true, },
  { field: 'model', headerName: 'Model', type: 'number', editable: true, },
  { field: 'horizon', headerName: 'Horizon', type: 'number', editable: true, },
  { field: 'drawdown', headerName: 'MDD', editable: true, },
  { field: 'min_trade_value', headerName: 'Min', editable: true, },
  { field: 'max_trade_value', headerName: 'Max', editable: true, },
  { field: 'percentageAboveOpen', headerName: 'Uptime', editable: true, },
  { field: 'blockchainHash', headerName: 'BCN', desc: 'Blockchain', editable: false, },
];

const dataInit = {
  enabled: {
    "direction": true,
    "close_date": true,
    "performance": true,
    "open_date": false,
    "open_value": true,
    "close_value": true,
    "model": true,
    "horizon": true,
    "drawdown": true,
    "min_trade_value": false,
    "max_trade_value": true,
    "percentageAboveOpen": true,
    "blockchainHash": true,
  },
}

const modelsInit = [
  { "name": "v1", "ds": "Model vers. 1", "enabled": true },
  { "name": "v2", "ds": "Model vers. 2", "enabled": true },
]

const horizonsInit = [
  { "name": "10", "ds": "10 days", "enabled": true },
  { "name": "22", "ds": "22 days", "enabled": true },
  { "name": "30", "ds": "30 days", "enabled": true },
  { "name": "63", "ds": "63 days", "enabled": true },
]

const cellStyleLogo = { py: 1, px: 0 }

const AssetAdvices = (props) => {
  const [horizons, setHorizons] = useState(horizonsInit)
  const [models, setModels] = useState(modelsInit)

  const [loading, setLoading] = useState(false)
  const [menuTarget, setMenuTarget] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const [menuModelTarget, setMenuModelTarget] = useState(null)
  const [menuModelOpen, setMenuModelOpen] = useState(false)

  const [menuOpDateTarget, setMenuOpDateTarget] = useState(null)
  const [menuOpDateOpen, setMenuOpDateOpen] = useState(false)

  const [menuClDateTarget, setMenuClDateTarget] = useState(null)
  const [menuClDatepen, setMenuClDateOpen] = useState(false)

  const [menuHorizonTarget, setMenuHorizonTarget] = useState(null)
  const [menuHorizonOpen, setMenuHorizonOpen] = useState(false)

  const [startDates, setStartDates] = useState([dayjs("2016-01-01").format("YYYY-MM-DD"), dayjs()]);
  const [endDates, setEndDates] = useState([dayjs("2016-01-01").format("YYYY-MM-DD"), dayjs()]);

  const [colWidth, setColWidth] = useState(parseInt(600 / Object.values(dataInit.enabled).filter(Boolean).length))
  const [colCount, setColCount] = useState(Object.values(dataInit.enabled).filter(Boolean).length)

  const [data, setData] = useState(null)
  const [dataFull, setDataFull] = useState(null)
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchNeedle, setSearchNeedle] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false)
  const inputRef = useRef(null);
  const lastInstrument = useSelector(state => state.lastInstrument)
  const reload = useSelector(state => state.reload)
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('close_date');
  const [open, setOpen] = useState(false);
  const [signal, setSignal] = useState(null);

  const fetch = () => {
    if (props.selectedInstrument) {
      setLoading(true)

      api.get(`/v2/dashboard/adviceHistory/${props.selectedInstrument}`, { id: `adviceHistory-${props.selectedInstrument}` }).then(response => {
        const data = response.data;
        if (localStorage.getItem("ADVICES_COLS")) {
          dataInit.enabled = JSON.parse(localStorage.getItem("ADVICES_COLS"))
        }
        dataInit.rows = data
        setData(dataInit)
        setDataFull(null)
        setDataFull(dataInit)
        globalFilter()
      }).catch(error => {

      }).finally(() => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    if (props.selectedInstrument) fetch()
  }, [props.selectedInstrument]);

  useEffect(() => {
    fetch()
  }, []);

  useEffect(() => {
    //if(dataFull) fetch()
  }, [dataFull])

  useEffect(() => {
    fetch()
  }, [reload])

  useEffect(() => {
    if (models || horizons || startDates || endDates) {
      globalFilter()
    }
  }, [models, horizons, startDates, endDates]);


  function handleMenu(e) {
    setMenuTarget(e.currentTarget)
    setMenuOpen(true)
  }

  // ----- MENUS
  const handleModelMenu = (e) => {
    setMenuModelTarget(e.currentTarget)
    setMenuModelOpen(true)
  }

  const handleHorizonMenu = (e) => {
    setMenuHorizonTarget(e.currentTarget)
    setMenuHorizonOpen(true)
  }

  const handleOpenDate = (e) => {
    setMenuOpDateTarget(e.currentTarget)
    setMenuOpDateOpen(true)
  }

  const handleCloseDate = (e) => {
    setMenuClDateTarget(e.currentTarget)
    setMenuClDateOpen(true)
  }

  // ----- CARD FILTERS
  const handleModels = (e, checked) => {
    const updatedData = JSON.parse(JSON.stringify(models));
    let item = updatedData.find(item => item.name == e.target.name)
    item.enabled = checked
    setModels(updatedData)
    // globalFilter()
  }

  const handleHorizons = (e, checked) => {
    const updatedData = JSON.parse(JSON.stringify(horizons));
    let item = updatedData.find(item => item.name == e.target.name)
    item.enabled = checked
    setHorizons(updatedData)
    // globalFilter()
  }

  const resetStartDates = () => {
    setStartDates([dayjs("2016-01-01").format("YYYY-MM-DD"), dayjs()])
  }

  const resetEndDates = () => {
    setEndDates([dayjs("2016-01-01").format("YYYY-MM-DD"), dayjs()])
  }

  const handleStartDates = (name, date) => {
    if (name === "from") {
      setStartDates([dayjs(date).format("YYYY-MM-DD"), startDates[1]]);
    } else {
      setStartDates([startDates[0], dayjs(date).format("YYYY-MM-DD")]);
    }
  }

  const handleEndDates = (name, date) => {
    if (name === "from") {
      setEndDates([dayjs(date).format("YYYY-MM-DD"), endDates[1]]);
    } else {
      setEndDates([endDates[0], dayjs(date).format("YYYY-MM-DD")]);
    }
  }

  const globalFilter = () => {
    const searchedRows = JSON.parse(JSON.stringify(dataFull));
    if (!searchedRows) return
    // FILTER BY HORIZON
    const filteredHorizons = horizons.filter(item => item.enabled === true);
    if (filteredHorizons.length > 0) {
      const filteredItems = searchedRows.rows.filter(item => filteredHorizons.some(filter => filter.name == item.horizon))
      searchedRows.rows = filteredItems;
    }
    // FILTER BY MODELS
    const filteredModels = models.filter(item => item.enabled === true);
    if (filteredModels.length > 0) {
      const filteredItems = searchedRows.rows.filter(item => filteredModels.some(filter => filter.name.includes(item.model)))
      searchedRows.rows = filteredItems;
    }
    // FILTER BY START DATE
    if (startDates.length === 2) {
      const filteredItems = searchedRows.rows.filter(main => startDates.some(filter => {
        const mainDate = new Date(main.open_date)
        const from = new Date(startDates[0])
        const to = new Date(startDates[1])
        const pass = mainDate >= from && mainDate <= to
        return pass
      }))
      searchedRows.rows = filteredItems;
    }
    // FILTER BY END DATE
    if (endDates.length === 2) {
      const filteredItems = searchedRows.rows.filter(main => endDates.some(filter => {
        const mainDate = new Date(main.close_date)
        const from = new Date(endDates[0])
        const to = new Date(endDates[1])
        const pass = mainDate >= from && mainDate <= to
        return pass
      }))
      searchedRows.rows = filteredItems;
    }

    setData(searchedRows)
    setPage(0)
  }

  // ----- COLUMNS
  function handleColumns(event, v) {
    const updatedData = data;
    // Count the number of true values in data.enabled
    const enabledCount = Object.values(updatedData.enabled).filter(Boolean).length;
    updatedData.enabled[event.target.name] = event.target.checked;
    const colNum = Object.values(updatedData.enabled).filter(Boolean).length
    dataInit.enabled = updatedData.enabled
    localStorage.setItem("ADVICES_COLS", JSON.stringify(dataInit.enabled))
    setColWidth(parseInt(600 / colNum))
    setColCount(colNum)
  }

  const closeMenu = () => {
    setMenuOpen(false)
    setMenuModelOpen(false)
    setMenuHorizonOpen(false)
    setMenuOpDateOpen(false)
    setMenuClDateOpen(false)
  }

  function handleExpanded() {
    let newState = !expanded
    setExpanded(newState)
    setRowsPerPage(newState ? 12 : 7)
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSort = (col) => {
    const isAsc = orderBy === col && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(col);
    setPage(0)
    switch (isAsc ? 'desc' : 'asc') {
      case "asc":
      default:
        return data.rows.sort((a, b) => {
          const valueA = a[col];
          const valueB = b[col];

          // Check if the values are numeric
          if (!isNaN(valueA) && !isNaN(valueB)) {
            return Number(valueA) - Number(valueB);
          }

          // If not numeric, sort as alphanumeric
          if (valueA.toLowerCase() < valueB.toLowerCase()) {
            return -1;
          }
          if (valueA.toLowerCase() > valueB.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      case "desc":
        return data.rows.sort((a, b) => {
          const valueA = a[col];
          const valueB = b[col];
          if (!isNaN(valueA) && !isNaN(valueB)) {
            return Number(valueB) - Number(valueA);
          }
          if (valueA.toLowerCase() < valueB.toLowerCase()) {
            return 1;
          }
          if (valueA.toLowerCase() > valueB.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    }

  };

  const openDialog = (obj) => {
    setSignal(obj)
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <BlockchainDialog
        open={open}
        setOpen={setOpen}
        closeDialog={closeDialog}
        signal={signal}
      />

      <Card sx={{ position: expanded ? "fixed" : "initial", width: "100%", height: expanded ? "100%" : undefined, margin: expanded ? 0 : "initial", left: 0, top: 0, transition: 'all 0.25s ease', zIndex: expanded ? 1300 : 0 }}>
        <CardHeader
          sx={{ display: "flex", alignItems: "center", position: "relative" }}
          title={<Typography variant='overline' className='bold'>Advices history</Typography>}
          action={
            <IconButton onClick={handleExpanded}>{expanded ? <FullscreenExitOutlined color="default" /> : <FullscreenOutlined color="default" />}</IconButton>
          }
        />
        <CardContent sx={{ minHeight: 400 }}>
          <TableContainer sx={{ maxWidth: "100%" }}>
            <Table sx={{ maxWidth: "100%" }}>
              <TableHead>
                <TableRow>
                  {data && columns.map((obj, i) =>
                    (expanded || dataInit.enabled[obj.field]) &&
                    <Tooltip title={obj.desc && obj.desc} key={"tip_" + obj.field}>
                      <TableCell key={"col_" + i} sx={{ maxWidth: (obj.width ? obj.width : colWidth), py: 1, px: 2, }} className='bold'>
                        <TableSortLabel
                          active={orderBy === obj.field}
                          direction={orderBy === obj.field ? order : 'asc'}
                          onClick={() => handleSort(obj.field)}
                        >
                          <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                            <Typography variant='body2' color='textSecondary'>{obj.headerName}</Typography>
                          </Box>
                        </TableSortLabel>
                      </TableCell>
                    </Tooltip>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  loading ? <SkeletonTable rows={5} cols={colCount} /> :
                    data && data.rows ? data.rows
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((obj, i) =>
                        <TableRow key={"row_" + i}>
                          {/* {"direction": "BUY", "model": 1, "hor": 30, "close_date": "2023-05-08", "open_date": "2023-04-08", "performance": 12.28, "open_value": 8.212, "close_value": 9.157, "dd": -6.12, "min_trade_value": 8.012, "max_trade_value": 8.257, "percentageAboveOpen": 72.5   }, */}
                          {(expanded || dataInit.enabled.direction) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Typography variant='caption' color={obj.direction === "BUY" ? props.theme.palette.long.main : props.theme.palette.short.main} className='bold'>
                              {obj.direction}
                            </Typography>
                          </TableCell>}
                          {(expanded || dataInit.enabled.close_date) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                              <Typography variant='caption'>{moment(new Date(obj.close_date)).format('yyyy-MM-DD')}</Typography>
                            </Box>
                          </TableCell>}
                          {(expanded || dataInit.enabled.performance) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }} align='right'>
                            <Typography variant='caption' color={obj.performance > 0 ? props.theme.palette.long.main : props.theme.palette.short.main}>{new Intl.NumberFormat("en-GB", { style: "percent", maximumSignificantDigits: 4 }).format(obj.performance / 100)}</Typography>
                          </TableCell>}
                          {(expanded || dataInit.enabled.open_date) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                              <Typography variant='caption'>{moment(new Date(obj.open_date)).format('yyyy-MM-DD')}</Typography>
                            </Box>
                          </TableCell>}
                          {(expanded || dataInit.enabled.open_value) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }} align='right'>
                            <Typography variant='caption'>{new Intl.NumberFormat("en-GB", { maximumSignificantDigits: 4 }).format(obj.open_value)}</Typography>
                          </TableCell>}
                          {(expanded || dataInit.enabled.close_value) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }} align='right'>
                            <Typography variant='caption'>{new Intl.NumberFormat("en-GB", { maximumSignificantDigits: 4 }).format(obj.close_value)}</Typography>
                          </TableCell>}
                          {(expanded || dataInit.enabled.model) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Typography variant='caption'>{"v" + obj.model}</Typography>
                          </TableCell>}
                          {(expanded || dataInit.enabled.horizon) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }} align='right'>
                            <Typography variant='caption'>{obj.horizon} days</Typography>
                          </TableCell>}
                          {(expanded || dataInit.enabled.drawdown) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }} align='right'>
                            <Typography variant='caption'>{new Intl.NumberFormat("en-GB", { style: "percent", maximumSignificantDigits: 3 }).format(obj.drawdown / 100)}</Typography>
                          </TableCell>}
                          {(expanded || dataInit.enabled.min_trade_value) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }} align='right'>
                            <Typography variant='caption'>{new Intl.NumberFormat("en-GB", { maximumSignificantDigits: 4 }).format(obj.min_trade_value)}</Typography>
                          </TableCell>}
                          {(expanded || dataInit.enabled.max_trade_value) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }} align='right'>
                            <Typography variant='caption'>{new Intl.NumberFormat("en-GB", { maximumSignificantDigits: 4 }).format(obj.max_trade_value)}</Typography>
                          </TableCell>}
                          {(expanded || dataInit.enabled.percentageAboveOpen) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }} align='right'>
                            <Typography variant='caption'>{new Intl.NumberFormat("en-GB", { style: "percent", maximumFractionDigits: 0 }).format(obj.percentageAboveOpen / 100)}</Typography>
                          </TableCell>}
                          <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Tooltip title={obj.blockchainHash ? "Tracked on blockchain" : "Untracked"}>
                              <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                                <IconButton disabled={obj.blockchainHash ? false : true} onClick={() => openDialog(obj)}>
                                  {obj.blockchainHash ? <VerifiedUser color='primary' /> : <VerifiedUser />}
                                </IconButton>
                              </Box>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ) : <TableRow><TableCell>Loading...</TableCell></TableRow>}
              </TableBody>
            </Table >
          </TableContainer>
          {data &&
            <TablePagination
              sx={{
                '& .MuiButtonBase-root': {
                  color: props.theme.palette.default.main,
                },
                '& .MuiButtonBase-root.Mui-disabled': {
                  color: props.theme.palette.text.disabled,
                },
              }}
              showFirstButton
              showLastButton
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={data.rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }

        </CardContent>
        <CardActions>
          <Box sx={{ display: "flex", columnGap: 1 }}>
            <Chip label="Date open"
              sx={{ minWidth: 80 }}
              size='small'
              clickable
              variant={startDates.length === 2 ? "filled" : "filled"}
              color={startDates.length === 2 ? "primary" : "secondary"}
              onClick={handleOpenDate}
            />
            <Chip label="Date close"
              sx={{ minWidth: 80 }}
              size='small'
              clickable
              variant={endDates.length === 2 ? "filled" : "filled"}
              color={endDates.length === 2 ? "primary" : "secondary"}
              onClick={handleCloseDate}
            />
            <Chip label="Horizon"
              sx={{ minWidth: 80 }}
              size='small'
              clickable
              variant={horizons.some((item) => item.enabled) ? "filled" : "filled"}
              color={horizons.some((item) => item.enabled) ? "primary" : "secondary"}
              onClick={handleHorizonMenu}
            />
            <Chip label="Source"
              sx={{ minWidth: 80 }}
              size='small'
              clickable
              variant={models.some((item) => item.enabled) ? "filled" : "filled"}
              color={models.some((item) => item.enabled) ? "primary" : "secondary"}
              onClick={handleModelMenu}
            />
          </Box>
          <IconButton sx={{ marginLeft: 'auto', display: expanded ? 'none' : 'inherit' }} onClick={handleMenu}><MoreVert color="default" /></IconButton>
        </CardActions>
      </Card>

      <Dialog open={menuOpDateOpen} onClose={closeMenu}>
        <DialogTitle><Typography color={'default'} variant='body2'>Open date range</Typography></DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <LocalizationProvider adapterLocale={"en-us"} dateAdapter={AdapterDayjs}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <DesktopDatePicker value={dayjs(startDates[0])} onChange={(v) => handleStartDates("from", v)} label={'From'} minDate={dayjs("2016-01-01")}
                  slotProps={{ textField: { size: 'small' } }} sx={{ width: "50%", height: 30 }} />
                <DesktopDatePicker value={dayjs(startDates[1])} onChange={(v) => handleStartDates("to", v)} label={'To'}
                  slotProps={{ textField: { size: 'small' } }} sx={{ width: "50%" }} />
              </Box>
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button size='small' onClick={resetStartDates} variant='contained' disableElevation>Reset</Button>
          <Button size='small' onClick={closeMenu} variant='contained' disableElevation>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={menuClDatepen} onClose={closeMenu}>
        <DialogTitle><Typography color={'default'} variant='body2'>Close date range</Typography></DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            <LocalizationProvider adapterLocale={"it-it"} dateAdapter={AdapterDayjs}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <DesktopDatePicker value={dayjs(endDates[0])} onChange={(v) => handleEndDates("from", v)} label={'From'}
                  slotProps={{ textField: { size: 'small' } }} sx={{ width: "50%", height: 30 }} />
                <DesktopDatePicker value={dayjs(endDates[1])} onChange={(v) => handleEndDates("to", v)} label={'To'}
                  slotProps={{ textField: { size: 'small' } }} sx={{ width: "50%" }} />
              </Box>
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button size='small' onClick={resetEndDates} variant='contained' disableElevation>Reset</Button>
          <Button size='small' onClick={closeMenu} variant='contained' disableElevation>Close</Button>
        </DialogActions>
      </Dialog>

      <Menu
        id="basic-menu"
        anchorEl={menuHorizonTarget}
        open={menuHorizonOpen}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled style={{ opacity: 1 }}>
          <Typography color={'default'} variant='body2'>Horizons</Typography>
        </MenuItem>
        <Divider />
        {horizons.map(obj =>
          <MenuItem key={obj.name}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={obj.enabled}
                  checked={obj.enabled}
                  name={obj.name}
                  onChange={handleHorizons}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={obj.ds}
            />
          </MenuItem>
        )}
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={menuModelTarget}
        open={menuModelOpen}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled style={{ opacity: 1 }}>
          <Typography color={'default'} variant='body2'>Models</Typography>
        </MenuItem>
        <Divider />
        {models.map(obj =>
          <MenuItem key={obj.name}>
            <Checkbox
              checked={obj.enabled}
              name={obj.name}
              onChange={handleModels}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {obj.ds}
          </MenuItem>
        )}
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={menuTarget}
        open={menuOpen}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled style={{ opacity: 1 }}>
          <Typography color={'default'} variant='body2'>Columns</Typography>
        </MenuItem>
        <Divider />
        {data && columns.map(obj =>
          // data.enabled[obj.field] &&
          <MenuItem disabled={!obj.editable || (colCount >= 13 && !data.enabled[obj.field])} key={"cols_" + obj.field}>
            <Checkbox
              defaultChecked={data.enabled[obj.field]}
              checked={data.enabled[obj.field]}
              disabled={colCount >= 13 && !data.enabled[obj.field]}
              name={obj.field}
              onChange={handleColumns}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {obj.headerName}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default withTheme(AssetAdvices);