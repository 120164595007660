import { withTheme } from '@emotion/react';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Button, Card, Container, Grid, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Tour from 'reactour';
import MainChartArea from '../cards/vitrum/MainChartArea';
import ModelArea from '../cards/vitrum/ModelArea';
import ModelPrediction from '../cards/vitrum/ModelPrediction';
import SpecificModelArea from '../cards/vitrum/SpecificModelArea';
import WeightedPredictionArea from '../cards/vitrum/WeightedPredictionArea';
import { notifyArea } from '../dispatcher/Actions';
import ClusterArea from '../cards/vitrum/ClusterArea';

const Vitrum = (props) => {
    const dispatch = useDispatch();
    const [tourOpen, setTourOpen] = useState(false);
    const introVersion = 'v2'

    const tourSteps = [
        {
            selector: ".startIntro",
            content: <>
                <img src="crypto_library/bitcoin.svg" />&nbsp;&nbsp;
                <img src="crypto_library/ethereum.svg" />
                <Typography variant='h6'>Let's start with Vitrum. The crypto predictive analysis system</Typography>
            </>,
            style: {
                background: props.theme.palette.background.paper,
                border: "2px solid" + props.theme.palette.primary.main
            },
        },
        {
            selector: ".cryptoSelector",
            content: <>
                <Typography gutterBottom variant='body2' className='bold'>SELECT CRYPTO</Typography>
                <Typography variant='body2' className='slim'>Select here the Crypto you want to delve into.</Typography>
            </>,
            style: {
                background: props.theme.palette.background.paper,
                border: "2px solid" + props.theme.palette.primary.main
            },
        },
        {
                selector: ".wpred",
                content: <>
                    <Typography gutterBottom variant='body2' className='bold'>WEIGHTED PREDICTION</Typography>
                    <Typography variant='body2' className='slim'>Here you'll find the the signal obtained combining the best of each model to provide a weighted prediction on the models who best worked according to the current market cluster.</Typography>
                </>,
                style: {
                    background: props.theme.palette.background.paper,
                    border: "2px solid" + props.theme.palette.primary.main
                },
        },
        {
                    selector: ".cluster",
                    content: <>
                        <Typography gutterBottom variant='body2' className='bold'>CLUSTER BAROMETER</Typography>
                        <Typography variant='body2' className='slim'>Vitrum identifies the current market cluster for the analyzed crypto. See the current market phase </Typography>
                    </>,
                    style: {
                        background: props.theme.palette.background.paper,
                        border: "2px solid" + props.theme.palette.primary.main
                     },
        },
        {
            selector: ".models",
            content: <>
                <Typography gutterBottom variant='body2' className='bold'>MODEL SHOWDOWN</Typography>
                <Typography variant='body2' className='slim'>Here's the complete overview of the available models for the selected crypto. Click on a model to see how each of them is set today.</Typography>
            </>,
            style: {
                background: props.theme.palette.background.paper,
                border: "2px solid" + props.theme.palette.primary.main
            },
        },
        {
            selector: ".mainc",
            content: <>
                <Typography gutterBottom variant='body2' className='bold'>POSITION HISTORY</Typography>
                <Typography variant='body2' className='slim'>Here's how the selected model acted on the history of the crypto. The coloured area on the horizontal axis show how the model has set a position in that days.</Typography>
            </>,
            style: {
                background: props.theme.palette.background.paper,
                border: "2px solid" + props.theme.palette.primary.main
            },
        },
        {
            selector: ".specm",
            content: <>
                <Typography gutterBottom variant='body2' className='bold'>MODEL INSIGHTS</Typography>
                <Typography variant='body2' className='slim'>Here you can have a complete insight on the current model. Compare the behaviour with the benchmark and discover its performances and other metrics. </Typography>
            </>,
            style: {
                background: props.theme.palette.background.paper,
                border: "2px solid" + props.theme.palette.primary.main
            },
        }
    ];

    useEffect(() => {
        dispatch(notifyArea("crypto"))
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        setTimeout(() => {
            startTour()
        }, 2000);
    }, [])

    const TourComponent = ({ isOpen, onRequestClose }) => {

        return (
            <Tour
                badgeContent={(curr, tot) => `${curr}/${tot}`}
                rounded={4}
                disableDotsNavigation={true}
                lastStepNextButton={<Button>Done</Button>}
                showNavigationNumber={false}
                showNavigation={false}
                showCloseButton={false}
                disableInteraction={false}
                steps={tourSteps}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                accentColor={props.theme.palette.primary.main}
                nextButton={<Button endIcon={<NavigateNext />}>Next</Button>}
                prevButton={<Button startIcon={<NavigateBefore />}>Back</Button>}
            />
        );
    };

    const startTour = () => {
        if (!localStorage.getItem('vitrumTourDone') || localStorage.getItem('vitrumTourDone') != introVersion) {
            localStorage.removeItem('vitrumTourDone')
            setTourOpen(true);
        }
    };

    const closeTour = () => {
        setTourOpen(false);
        localStorage.setItem('vitrumTourDone', introVersion);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    };

    return (
        <>
            <Toolbar />
            <TourComponent isOpen={tourOpen} onRequestClose={closeTour} />
            <Container maxWidth="xl" sx={{ mb: 7 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid item xs={12} className='startIntro'>
                        <Typography variant="h4" sx={{ ml: 1 }} className='big'>Vitrum</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ p: .5 }} className='cryptoSelector'>
                            <IconButton><img src="crypto_library/bitcoin.svg" style={{ width: 40, height: 40 }} /></IconButton>
                            <Tooltip title={"ETH - Ethereum: coming soon..."}><span><IconButton disabled><img src="crypto_library/ethereum.svg" style={{ width: 40, height: 40, filter: "saturate(0) opacity(.5)" }} /></IconButton></span></Tooltip>
                        </Card>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={5} className="wpred">
                            <WeightedPredictionArea />
                        </Grid>
                        <Grid item xs={7} className="cluster">
                            <ClusterArea />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={4} className="models">
                            <ModelArea />
                        </Grid>
                        <Grid item xs={8} className="mainc">
                            <MainChartArea />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="specm">
                        <SpecificModelArea />
                    </Grid>

                </Grid>
            </Container>
        </>
    );
};

export default withTheme(Vitrum);
