import { Card, CardContent, Typography, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, IconButton, Tooltip, Collapse, CardActions, TablePagination } from '@mui/material';
import { Component } from 'react';
import { styled, withStyles } from '@material-ui/styles';
import numeral from 'numeral';
import { Skeleton } from '@material-ui/lab';
import MySkeleton from './MySkeleton';
import CsvDownload from 'react-json-to-csv';
import { CloudDownloadOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';

const styles = theme => ({
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

class Trades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      offset: 0,
      page: 1,
      rows: 5,
      ops: props.operations,
      showOps: [],
    }
  }

  collapse() {
    this.setState({ collapse: !this.state.collapse })
  }

  handleChangePage(e, e2) {
    this.setState({ offset: e2 })
  }

  handleChangeRowsPerPage(e, e2) {
    this.setState({ rows: e2.props.value, offset: 0 })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.operations) {
        this.extract()
      }
    } else if (this.state !== prevState && prevState.offset !== this.state.offset || prevState.rows !== this.state.rows) {
      this.extract()
    }
  }

  componentDidMount() {
    if (this.props.operations)
      this.extract()

  }

  extract() {
    let rows = this.state.rows
    let offset = this.state.offset
    let ops = this.props.operations
    let start = rows * offset
    let showOps = []
    if (ops && ops.length > 1) {
      ops.sort(function (a, b) { return new Date(b.exit_date).getTime() - new Date(a.exit_date).getTime() })
    }
    showOps = ops && ops.length > 0 ? ops.slice(start, start + rows) : []
    this.setState({ ops, showOps })
  }

  render() {
    const { offset, rows, ops, showOps } = this.state;

    var filename = ops && ops.length > 0 ? "trades_" + this.props.currency + ".csv" : "trades.csv"
    return (
      <Card variant='outlined'>
        <CardContent>
          <Typography variant='overline' gutterBottom>
            Trades
          </Typography>
          {!this.props.isLoading ? (
            showOps && showOps.length > 0 ? (
              <div>
                <TableContainer>
                  <Table size={'small'} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left"><Typography variant='subtitle2' color='primary'>Name</Typography></TableCell>
                        <TableCell align="right"><Typography variant='subtitle2' color='primary'>Quantity</Typography></TableCell>
                        <TableCell align="right"><Typography variant='subtitle2' color='primary'>Open</Typography></TableCell>
                        <TableCell align="right"><Typography variant='subtitle2' color='primary'>Exit</Typography></TableCell>
                        <TableCell align="right"><Typography variant='subtitle2' color='primary'>Profit</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {showOps.map((obj) => (
                        <TableRow>
                          <TableCell style={{ maxWidth: '150px' }}>
                            <Tooltip arrow title={obj.name} placement="top-start"><Typography variant='body2' noWrap>{obj.name}</Typography></Tooltip>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant='body2'>{numeral(obj.quantity).format("0,000.[0]")}</Typography>
                            <Typography sx={{ textTransform: 'lowercase' }} variant='caption' color={'textSecondary'}>{obj.open_signal}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant='body2'>{numeral(obj.open_price).format("0.000")}</Typography>
                            <Typography variant='caption' color={'textSecondary'}>{obj.open_date}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant='body2'>{numeral(obj.exit_price).format("0.000")}</Typography>
                            <Typography variant='caption' color={'textSecondary'}>{obj.exit_date}</Typography>
                          </TableCell>
                          <TableCell align="right"><Typography variant='subtitle2'>{numeral(obj.perc_profit).format("+0.00%")}</Typography></TableCell>
                        </TableRow>
                      ))

                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  labelRowsPerPage="Rows"
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={ops.length}
                  rowsPerPage={rows}
                  page={offset}
                  showLastButton={true}
                  showFirstButton={true}
                  onPageChange={(event, page) => this.handleChangePage(event, page)}
                  onRowsPerPageChange={(event, page) => this.handleChangeRowsPerPage(event, page)}
                />
              </div>
            ) :
              ''
          ) :
            <MySkeleton type={'table'} rows={5} animation={'pulse'}></MySkeleton>
          }
        </CardContent>
        <CardActions>
          {!this.props.isLoading ? (
            ops && ops.length > 0 ? (

              <CsvDownload
                data={this.props.operations}
                filename={filename}
                style={{ //pass other props, like styles
                  backgroundColor: 'transparent',
                  border: 0,
                }}
              >
                <Button sx={{ mr: 2 }} disableElevation variant={'contained'} size={'medium'} startIcon={<CloudDownloadOutlined />}>Download</Button>
                <Typography variant="caption" component="span" color="textSecondary"> * All prices are in {this.props.currency}</Typography>
              </CsvDownload>
            ) :
              ''
          ) : ""}

        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Trades);
