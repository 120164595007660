import React from 'react'
import { useEffect, useState } from "react";
import ExportLogo from '../assets/logo.svg'
import { api } from "../api/apiBuilder";
import { useParams } from 'react-router';

const div = {
  width: '100%'
}

const image = {
  float: 'right',
  margin: '35px',
  width: '180px'
};

const text = {
  float: 'left',
  paddingTop: '22px'
}

const HeadingExport = () => {
  let { id } = useParams();
  const [name, setName] = useState(null)
  useEffect(() => {
    api.get("/builder/portfolios/" + id + "/settings/name", { cache: false })
      .then((response) => {
        setName(response.data.name)
      });
  }, [id]);
  return (
    <div style={div}>
      <h4 style={text}>{name}</h4>
      <img style={image} src={ExportLogo} alt="wavenure" />
    </div>
  )
}

export default HeadingExport