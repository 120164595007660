import axios from "axios";
import { buildWebStorage, setupCache } from "axios-cache-interceptor";

const inactiveTimeSlot = { "startTime": 7, "endTime": 9 }
const cachePrefix = "vit-cache:"

/**
 * Determines whether caching is enabled based on the current time.
 * @returns {boolean} True if caching is enabled, false if disabled.
 */
const isCacheEnabled = () => {
    const now = new Date();
    const hours = now.getHours();
    const { startTime, endTime } = inactiveTimeSlot;

    return hours < startTime || hours > endTime;
};

/**
 * Calculates the Time-To-Live (TTL) for caching based on the current time.
 * TTL is calculated as the time remaining until 05:00 AM (next day) or 0 if it's already past 05:00 AM.
 * @returns {number} The TTL value in milliseconds.
 */
const getTtl = () => {
    const now = new Date();
    const expirationTime = new Date(now);
    expirationTime.setHours(inactiveTimeSlot.startTime, 0, 0, 0);

    // Se ora è dopo le 05:00, imposta la data per le 05:00 del giorno successivo
    if (now > expirationTime) {
        expirationTime.setDate(expirationTime.getDate() + 1);
    }
    return expirationTime.getTime() - now.getTime();
}

const AxiosBraino = axios.create({
    baseURL: process.env.REACT_APP_API_VITRUM_URL,
    timeout: 20000,
});

const apiVitrum = setupCache(AxiosBraino, {
    methods: ['get'],
    storage: buildWebStorage(localStorage, cachePrefix),
});

apiVitrum.removeCaches = () => {
    const instancePrefix = cachePrefix
    // Function to remove cache entries
    for (const key in localStorage) {
        if (key.startsWith(instancePrefix)) {
            const cacheKeyWithoutPrefix = key.replace(instancePrefix, '');
            apiVitrum.storage.remove(cacheKeyWithoutPrefix);
        }
    }
};

apiVitrum.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem("TOKEN");
    config.headers.Authorization = "Bearer " + token;

    if (config.cache) {
        if (isCacheEnabled()) {
            const ttl = getTtl()
            config.cache.ttl = ttl
        } else {
            config.cache = false
        }
    }

    return config;
});

apiVitrum.interceptors.response.use(
    function (response) {
        if (response.cached) {
            // console.log("Get from cache:", response.config.url, response.id);
        } else {
            // console.log("Get from server:", response.config.url, response.id);
        }

        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

/**
 * Periodically checks if caching is enabled and clears cached data if it's disabled.
 * This interval function runs every minute to ensure cache management during specific times.
 * - If caching is disabled (based on isCacheEnabled), it clears the caches using api.removeCaches.
 * - Adjust the interval time (in milliseconds) as needed.
 */
setInterval(function () {
    let enabled = isCacheEnabled()
    if (!enabled) {
        if (apiVitrum) {
            apiVitrum.removeCaches()
        }
    }
}, 5000)

export { apiVitrum };

