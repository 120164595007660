import { withTheme } from '@emotion/react';
import { CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../api/apiBuilder';
import { widget } from '../../charting_library';

const AssetTradingView = (props) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [options, setOptions] = useState(null)
  const lastInstrument = useSelector(state => state.lastInstrument)
  const reload = useSelector(state => state.reload)
  const theme = useSelector(state => state.theme)
  const chartContainerRef = useRef();
  const tvWidgetRef = useRef(null);
  const overrides = {
    "paneProperties.backgroundType": "solid",
    "paneProperties.background": props.theme.palette.background.paper,
    "paneProperties.vertGridProperties.color": "transparent",
    "paneProperties.horzGridProperties.color": props.theme.palette.grid,
    "symbolWatermarkProperties.transparency": 90,
    "scalesProperties.textColor": props.theme.palette.text.primary,
    "mainSeriesProperties.candleStyle.wickUpColor": props.theme.palette.long.main,
    "mainSeriesProperties.candleStyle.wickDownColor": props.theme.palette.short.main,
    "mainSeriesProperties.candleStyle.upColor": props.theme.palette.long.main,
    "mainSeriesProperties.candleStyle.downColor": props.theme.palette.short.main,
    "mainSeriesProperties.barStyle.upColor": props.theme.palette.long.main,
    "mainSeriesProperties.barStyle.downColor": props.theme.palette.short.main,
    "mainSeriesProperties.candleStyle.borderUpColor": props.theme.palette.long.main,
    "mainSeriesProperties.candleStyle.borderDownColor": props.theme.palette.short.main,
    "mainSeriesProperties.hollowCandleStyle.upColor": props.theme.palette.long.main,
    "mainSeriesProperties.hollowCandleStyle.downColor": props.theme.palette.short.main,
    "mainSeriesProperties.hollowCandleStyle.borderColor": props.theme.palette.long.main,
    "mainSeriesProperties.hollowCandleStyle.borderUpColor": props.theme.palette.long.main,
    "mainSeriesProperties.hollowCandleStyle.borderDownColor": props.theme.palette.short.main,
    "mainSeriesProperties.hollowCandleStyle.wickUpColor": props.theme.palette.long.main,
    "mainSeriesProperties.hollowCandleStyle.wickDownColor": props.theme.palette.short.main,
    "mainSeriesProperties.lineStyle.color": props.theme.palette.primary.main,
    "mainSeriesProperties.areaStyle.color1": props.theme.palette.primary.main,
    "mainSeriesProperties.areaStyle.color2": props.theme.palette.background.paper,
    "mainSeriesProperties.areaStyle.linecolor": props.theme.palette.primary.main,
    "mainSeriesProperties.areaStyle.linewidth": 1,
    "mainSeriesProperties.areaStyle.transparency": 80
  }

  const defaultProps = {
    symbol: 'AAPL',
    interval: 'D',
    libraryPath: '../../charting_library/',
    clientId: "test",//'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  const fetchSummary = (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    api.get(`/v2/dashboard/summary/${props.selectedInstrument}`, { id: `summary-${props.selectedInstrument}` })
      .then(response => {
        const data = response.data;
        // console.log(data.assetName, data.ticker, data.id);
        var symbol_stub = {
          name: data.ticker,
          description: data.assetName,
          type: 'stock',
          session: "0930-1630",
          timezone: 'Europe/Rome',
          ticker: data.ticker,
          minmov: 1,
          pricescale: 100,
          has_intraday: false,
          // intraday_multipliers: ['1', '60'],
          supported_resolution: ["D"],
          volume_precision: 8,
          data_status: 'endofday',
        }

        setTimeout(function () {
          onSymbolResolvedCallback(symbol_stub)
        }, 0)
      });

  }

  function fetchPrices(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    api.get(`/v2/dashboard/prices/${props.selectedInstrument}`, { id: `prices-${props.selectedInstrument}` })
      .then(response => {
        const data = response.data;
        const bars = data.map(element => {
          return {
            time: new Date(element.d).getTime(),
            low: element.l,
            high: element.h,
            open: element.o,
            close: element.c,
            volume: element.v
          };
        });
        // console.log(bars[0].time <= periodParams.from * 1000);
        if (bars[0].time <= periodParams.from * 1000) {
          onHistoryCallback(bars, { noData: false });
        } else {
          onHistoryCallback([], { noData: true });
        }
      });
  }

  useEffect(() => {
    const widgetOptions = {
      debug: false,
      symbol: "XXX",
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: {
        /* mandatory methods for realtime chart */
        onReady: cb => {
          // console.log('=====onReady running', theme)
          setTimeout(() => cb({
            supported_resolutions: ['1D'/*, '1W', '1M'*/],
          }), 0)
        },
        resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
          var split_data = symbolName.split(/[:/]/)
          // console.log(symbolName, split_data);
          fetchSummary(symbolName, onSymbolResolvedCallback, onResolveErrorCallback)
        },

        getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
          // console.log('[getBars]: Method call', symbolInfo, resolution, periodParams);
          fetchPrices(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback);
        }

      },
      interval: defaultProps.interval,
      container_id: chartContainerRef.current,
      library_path: '/charting_library/',
      time_frames: [
        { text: "3m", resolution: "1D", description: "3 Months", title: "3m" },
        { text: "6m", resolution: "1D", description: "6 Months", title: "6m" },
        { text: "1y", resolution: "1D", description: "1 Year", title: "1yr" },
        { text: "3y", resolution: "1W", description: "3 Years", title: "3yr" },
        { text: "5y", resolution: "1W", description: "5 Years", title: "5yr" },
        { text: "10y", resolution: "1M", description: "All", title: "All" },
      ],
      locale: /*getLanguageFromURL() ||*/ 'en',
      //disabled_features: ['use_localstorage_for_settings'],
      enabled_features: [],
      // charts_storage_url: defaultProps.chartsStorageUrl,
      // charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      theme: theme.charAt(0).toUpperCase() + theme.slice(1),
      //toolbar_bg: props.theme.palette.background.paper,
      custom_font_family: "Roboto",
      custom_css_url: "/tradingview.css",
      loading_screen: { backgroundColor: props.theme.palette.background.paper, foregroundColor: props.theme.palette.primary.main },
      overrides,
      enabled_features: [
        "header_fullscreen_button",
        "snapshot_trading_drawings",
        "header_screenshot",
      ],
      disabled_features: [
        "use_localstorage_for_settings",
        "header_resolutions",
        "header_symbol_search",
        "header_compare",
        "volume_force_overlay",
        "header_settings",
        "timezone_menu",
        "show_object_tree",
      ]
    };
    const tvWidget = new widget(widgetOptions);
    tvWidget.onChartReady(() => {
      tvWidgetRef.current = tvWidget

      tvWidget.headerReady().then(() => {
      });
    });

    return () => {
      tvWidget.remove();
    };
  }, [props.selectedInstrument]);

  useEffect(() => {
    const tvw = tvWidgetRef.current;
    if (tvw) {
      const themeName = theme.charAt(0).toUpperCase() + theme.slice(1);
      tvw.changeTheme(themeName).then(() => {
        tvw.applyOverrides(overrides)
      })
    }
  }, [props.theme])

  return (
    <Card variant="outlined">
      <CardHeader sx={{ pb: 0 }}
        title={<Typography variant='overline'>Technical chart</Typography>}
      />
      <CardContent>
        <div style={{ height: 700 }}
          ref={chartContainerRef}
          className={'TVChartContainer'}
        />
      </CardContent>
    </Card>
  );
}

export default withTheme(AssetTradingView);
