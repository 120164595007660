import { IconButton } from '@mui/material';
import { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { PlaylistAddOutlined } from '@mui/icons-material';
import axios from 'axios';
import DialogConfirm from './DialogConfirm';
import { api } from "../api/apiBuilder";

const styles = theme => ({
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
});

class MyAdder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTheme: false,
      dialogOpen: false,
      dialogMsg: 'Do you want to remove this from your blacklist?',
      dialogTitle: 'Whitelist instrument'
    }
  }

  openDialog(){
    this.setState({dialogOpen: true})
  }

  closeDialog(){
    this.setState({dialogOpen: false})
  }

  removeFromBlacklist(id){
    api.delete('/v1/blacklist/instruments/'+ id +'/', { cache: false })
      .then(response => {
        this.props.loadBlacklist();
      }).catch(error => {
        if (error.response) {
          console.log('Error 1', error.message);
          this.props.loadBlacklist();
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error 2', error.message);
          this.props.loadBlacklist();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error 3', error.message);
          this.props.loadBlacklist();
        }
      })
  }

  render() {
    const { classes } = this.props;
    const { dialogOpen, dialogMsg, dialogTitle } = this.state;
    return (
        <div className={classes.drawerHeader}>
          <DialogConfirm title={dialogTitle} open={dialogOpen} message={dialogMsg} cancel={() => this.closeDialog()} confirm={() => this.removeFromBlacklist(this.props.id)}></DialogConfirm>
          <IconButton onClick={() => this.openDialog()}>
            <PlaylistAddOutlined color='primary' />
          </IconButton>
        </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MyAdder);
