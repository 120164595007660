import { withTheme } from '@emotion/react';
import { Close, FullscreenExitOutlined, FullscreenOutlined, MoreVert } from '@mui/icons-material';
import { Avatar, Backdrop, Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, Menu, MenuItem, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from "../../api/apiBuilder";
import { getSectorLogo } from '../../commons/Utils';
import { notifyInstrument } from '../../dispatcher/Actions';
import SkeletonTable from '../../elements/SkeletonTable';

const columns = [
  { field: 'assetName', headerName: 'Name', width: 100, editable: false, },
  { field: 'avg_performance', headerName: 'Performance', type: 'number', editable: true, },
  { field: 'win_ratio', headerName: 'Winrate', type: 'number', editable: true, },
  // { field: 'lastPrice', headerName: 'Last', type: 'number', editable: true, },
  // { field: 'lastChange', headerName: 'Var %', type: 'number', editable: true, },
  { field: 'marketName', headerName: 'Market', width: 100, editable: true, },
  { field: 'sectorName', headerName: 'Sector', width: 100, editable: true, },
  { field: 'industryName', headerName: 'Industry', editable: true, },
  { field: 'assetType', headerName: 'Type', editable: true, },
  { field: 'isin', headerName: 'Isin', width: 80, editable: true, },
];

const marketsInit = [
  { "id": 12, "name": "Euronext Amsterdam", "enabled": false },
  { "id": 13, "name": "Euronext Brussels", "enabled": false },
  { "id": 15, "name": "Copenhagen Exchange", "enabled": false },
  { "id": 9, "name": "Frankfurt Exchange", "enabled": false },
  { "id": 14, "name": "Helsinki Exchange", "enabled": false },
  { "id": 5, "name": "Hong Kong Exchange", "enabled": false },
  { "id": 3, "name": "Euronext Dublin", "enabled": false },
  { "id": 26, "name": "Euronext Lisbon", "enabled": false },
  { "id": 36, "name": "London Exchange", "enabled": false },
  { "id": 29, "name": "Madrid Exchange", "enabled": false },
  { "id": 1, "name": "Borsa Italiana", "enabled": false },
  { "id": 38, "name": "Nyse", "enabled": false },
  { "id": 39, "name": "Nasdaq", "enabled": false },
  { "id": 27, "name": "Oslo Stock Exchange", "enabled": false },
  { "id": 11, "name": "Euronext Paris", "enabled": false },
  { "id": 31, "name": "Stockholm Exchange", "enabled": false },
  { "id": 33, "name": "SIX Swiss Exchange", "enabled": false },
  { "id": 23, "name": "Tokyo Exchange", "enabled": false },
  { "id": 19, "name": "Vienna Exchange", "enabled": false },
  { "id": 102, "name": "Warsaw", "enabled": false },
  { "id": 7, "name": "XETRA Exchange", "enabled": false }]

const sectorsInit = [
  { id: 9, name: "Basic Materials", enabled: false },
  { id: 19, name: "Communications", enabled: false },
  { id: 4, name: "Consumer Cyclical", enabled: false },
  { id: 11, name: "Energy", enabled: false },
  { id: 7, name: "Healthcare", enabled: false },
  { id: 15, name: "Industrials", enabled: false },
  { id: 14, name: "Real Estate", enabled: false },
  { id: 2, name: "Technology", enabled: false },
  { id: 1, name: "Utilities", enabled: false },
  { id: 22, name: "Financial Services", enabled: false },
  { id: 10, name: "Consumer Defensive", enabled: false }]

const industryInit = [
  { name: "AdvertISINg Agencies", enabled: false },
  { name: "Aerospace & Defense", enabled: false },
  { name: "Agricultural Inputs", enabled: false },
  { name: "Airlines", enabled: false },
  { name: "Airports & Air Services", enabled: false },
  { name: "Aluminum", enabled: false },
  { name: "Apparel Manufacturing", enabled: false },
  { name: "Apparel Retail", enabled: false },
  { name: "Application Software", enabled: false },
  { name: "Asset Management", enabled: false },
  { name: "Auto & Truck Dealerships", enabled: false },
  { name: "Auto Manufacturers", enabled: false },
  { name: "Auto Parts", enabled: false },
  { name: "Automobiles", enabled: false },
  { name: "Banks", enabled: false },
  { name: "Banks-Diversified", enabled: false },
  { name: "Banks-Regional", enabled: false },
  { name: "Beverages-Brewers", enabled: false },
  { name: "Beverages-Non-Alcoholic", enabled: false },
  { name: "Beverages-Wineries & Distilleries", enabled: false },
  { name: "Biotechnology", enabled: false },
  { name: "Broadcasting", enabled: false },
  { name: "Building Materials", enabled: false },
  { name: "Building Materials Wholesale", enabled: false },
  { name: "Building Products & Equipment", enabled: false },
  { name: "Business Equipment & Supplies", enabled: false },
  { name: "Business Services", enabled: false },
  { name: "Business Software & Services", enabled: false },
  { name: "Capital Markets", enabled: false },
  { name: "Chemicals", enabled: false },
  { name: "Chemicals - Major Diversified", enabled: false },
  { name: "Coking Coal", enabled: false },
  { name: "Commercial Services & Supplies", enabled: false },
  { name: "Communication Equipment", enabled: false },
  { name: "Communication Services", enabled: false },
  { name: "Communications Equipment", enabled: false },
  { name: "Computer Hardware", enabled: false },
  { name: "Confectioners", enabled: false },
  { name: "Conglomerates", enabled: false },
  { name: "Consulting Services", enabled: false },
  { name: "Consumer Discretionary", enabled: false },
  { name: "Consumer Electronics", enabled: false },
  { name: "Containers & Packaging", enabled: false },
  { name: "Copper", enabled: false },
  { name: "Credit Services", enabled: false },
  { name: "Department Stores", enabled: false },
  { name: "Diagnostics & Research", enabled: false },
  { name: "Discount Stores", enabled: false },
  { name: "Diversified Communication Services", enabled: false },
  { name: "Diversified Consumer Services", enabled: false },
  { name: "Diversified Electronics", enabled: false },
  { name: "Diversified Financial Services", enabled: false },
  { name: "Diversified Machinery", enabled: false },
  { name: "Diversified Telecommunication Services", enabled: false },
  { name: "Drug Manufacturers-General", enabled: false },
  { name: "Drug Manufacturers-Specialty & Generic", enabled: false },
  { name: "Education & Training Services", enabled: false },
  { name: "Electrical Equipment & Parts", enabled: false },
  { name: "Electronic Components", enabled: false },
  { name: "Electronic Equipment, Instruments & Comp", enabled: false },
  { name: "Electronic Gaming & Multimedia", enabled: false },
  { name: "Electronics & Computer Distribution", enabled: false },
  { name: "Engineering & Construction", enabled: false },
  { name: "Entertainment", enabled: false },
  { name: "Entertainment - Diversified", enabled: false },
  { name: "Equity Real Estate Investment Trusts (RE", enabled: false },
  { name: "Farm & Heavy Construction Machinery", enabled: false },
  { name: "Farm Products", enabled: false },
  { name: "Financial", enabled: false },
  { name: "Financial Conglomerates", enabled: false },
  { name: "Financial Data & Stock Exchanges", enabled: false },
  { name: "Food & Staples Retailing", enabled: false },
  { name: "Food - Major Diversified", enabled: false },
  { name: "Food Distribution", enabled: false },
  { name: "Food Products", enabled: false },
  { name: "Footwear & Accessories", enabled: false },
  { name: "Furnishings, Fixtures & Appliances", enabled: false },
  { name: "Gambling", enabled: false },
  { name: "General Contractors", enabled: false },
  { name: "Gold", enabled: false },
  { name: "Grocery Stores", enabled: false },
  { name: "Health Care Equipment & Supplies", enabled: false },
  { name: "Health Care Providers & Services", enabled: false },
  { name: "Health Information Services", enabled: false },
  { name: "Healthcare", enabled: false },
  { name: "Healthcare Plans", enabled: false },
  { name: "Home Furnishings & Fixtures", enabled: false },
  { name: "Home Improvement Retail", enabled: false },
  { name: "Household & Personal Products", enabled: false },
  { name: "Independent Power and Renewable Electric", enabled: false },
  { name: "Industrial Distribution", enabled: false },
  { name: "Industrial Equipment Wholesale", enabled: false },
  { name: "Industrials", enabled: false },
  { name: "Information Technology", enabled: false },
  { name: "Information Technology Services", enabled: false },
  { name: "Infrastructure Operations", enabled: false },
  { name: "Insurance", enabled: false },
  { name: "Insurance Brokers", enabled: false },
  { name: "Insurance-Diversified", enabled: false },
  { name: "Insurance-Life", enabled: false },
  { name: "Insurance-Property & Casualty", enabled: false },
  { name: "Insurance-Reinsurance", enabled: false },
  { name: "Insurance-Specialty", enabled: false },
  { name: "Integrated Freight & Logistics", enabled: false },
  { name: "Interactive Media & Services", enabled: false },
  { name: "Internet & Direct Marketing Retail", enabled: false },
  { name: "Internet Content & Information", enabled: false },
  { name: "Internet Retail", enabled: false },
  { name: "Internet Software & Services", enabled: false },
  { name: "IT Services", enabled: false },
  { name: "Leisure", enabled: false },
  { name: "Lodging", enabled: false },
  { name: "Lumber & Wood Production", enabled: false },
  { name: "Luxury Goods", enabled: false },
  { name: "Machinery", enabled: false },
  { name: "Marine Shipping", enabled: false },
  { name: "Media", enabled: false },
  { name: "Medical Care Facilities", enabled: false },
  { name: "Medical Devices", enabled: false },
  { name: "Medical Distribution", enabled: false },
  { name: "Medical Instruments & Supplies", enabled: false },
  { name: "Metal Fabrication", enabled: false },
  { name: "Metals & Mining", enabled: false },
  { name: "Mortgage Finance", enabled: false },
  { name: "NA", enabled: false },
  { name: "Oil & Gas Drilling", enabled: false },
  { name: "Oil & Gas E&P", enabled: false },
  { name: "Oil & Gas Equipment & Services", enabled: false },
  { name: "Oil & Gas Integrated", enabled: false },
  { name: "Oil & Gas Midstream", enabled: false },
  { name: "Oil & Gas Refining & Marketing", enabled: false },
  { name: "Oil, Gas & Consumable Fuels", enabled: false },
  { name: "Other", enabled: false },
  { name: "Other Industrial Metals & Mining", enabled: false },
  { name: "Other Precious Metals & Mining", enabled: false },
  { name: "Packaged Foods", enabled: false },
  { name: "Packaging & Containers", enabled: false },
  { name: "Paper & Paper Products", enabled: false },
  { name: "Personal Products", enabled: false },
  { name: "Personal Services", enabled: false },
  { name: "Pharmaceutical Retailers", enabled: false },
  { name: "Pharmaceuticals", enabled: false },
  { name: "Pollution & Treatment Controls", enabled: false },
  { name: "Publishing", enabled: false },
  { name: "Railroads", enabled: false },
  { name: "Real Estate", enabled: false },
  { name: "Real Estate Development", enabled: false },
  { name: "Real Estate Management & Development", enabled: false },
  { name: "Real Estate Services", enabled: false },
  { name: "Real Estate-Development", enabled: false },
  { name: "Real Estate-Diversified", enabled: false },
  { name: "Recreational Vehicles", enabled: false },
  { name: "REIT-Diversified", enabled: false },
  { name: "REIT-Healthcare Facilities", enabled: false },
  { name: "REIT-Hotel & Motel", enabled: false },
  { name: "REIT-Industrial", enabled: false },
  { name: "REIT-Mortgage", enabled: false },
  { name: "REIT-Office", enabled: false },
  { name: "REIT-Residential", enabled: false },
  { name: "REIT-Retail", enabled: false },
  { name: "REIT-Specialty", enabled: false },
  { name: "Rental & Leasing Services", enabled: false },
  { name: "Residential Construction", enabled: false },
  { name: "Resorts & Casinos", enabled: false },
  { name: "Restaurants", enabled: false },
  { name: "Scientific & Technical Instruments", enabled: false },
  { name: "Security & Protection Services", enabled: false },
  { name: "Semiconductor - Broad Line", enabled: false },
  { name: "Semiconductor Equipment & Materials", enabled: false },
  { name: "Semiconductors", enabled: false },
  { name: "Shell Companies", enabled: false },
  { name: "Silver", enabled: false },
  { name: "Software", enabled: false },
  { name: "Software-Application", enabled: false },
  { name: "Software-Infrastructure", enabled: false },
  { name: "Solar", enabled: false },
  { name: "Specialty Business Services", enabled: false },
  { name: "Specialty Chemicals", enabled: false },
  { name: "Specialty Industrial Machinery", enabled: false },
  { name: "Specialty Retail", enabled: false },
  { name: "Staffing & Employment Services", enabled: false },
  { name: "Staffing & Outsourcing Services", enabled: false },
  { name: "Steel", enabled: false },
  { name: "Telecom Services", enabled: false },
  { name: "Textile Manufacturing", enabled: false },
  { name: "Thermal Coal", enabled: false },
  { name: "Tobacco", enabled: false },
  { name: "Tools & Accessories", enabled: false },
  { name: "Transportation Infrastructure", enabled: false },
  { name: "Travel Services", enabled: false },
  { name: "Trucking", enabled: false },
  { name: "Uranium", enabled: false },
  { name: "Utilities", enabled: false },
  { name: "Utilities-Diversified", enabled: false },
  { name: "Utilities-Independent Power Producers", enabled: false },
  { name: "Utilities-Regulated Electric", enabled: false },
  { name: "Utilities-Regulated Gas", enabled: false },
  { name: "Utilities-Regulated Water", enabled: false },
  { name: "Utilities-Renewable", enabled: false },
  { name: "Waste Management", enabled: false },
  { name: "Wireless Telecommunication Services" },
]

const dataInit = {
  enabled: {
    "assetName": true,
    "avg_performance": true,
    "win_ratio": true,
    // "lastPrice": true,
    // "lastChange": false,
    "marketName": true,
    "sectorName": false,
    "industryName": false,
    "assetType": false,
    "isin": false,
  },
}

const cellStyle = { py: 1, px: 2 }
const cellStyleLogo = { py: 1, px: 0 }

const BestPerformances = (props) => {
  const [loading, setLoading] = useState(false)
  const [menuTarget, setMenuTarget] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const [colWidth, setColWidth] = useState(parseInt(600 / Object.values(dataInit.enabled).filter(Boolean).length))
  const [colCount, setColCount] = useState(Object.values(dataInit.enabled).filter(Boolean).length + 1)

  const [menuMarketsTarget, setMenuMarketsTarget] = useState(null)
  const [menuMarketsOpen, setMenuMarketsOpen] = useState(false)
  const [markets, setMarkets] = useState(marketsInit);

  const [menuSectorsTarget, setMenuSectorsTarget] = useState(null)
  const [menuSectorsOpen, setMenuSectorsOpen] = useState(false)
  const [sectors, setSectors] = useState(sectorsInit);

  const [menuIndustryTarget, setMenuIndustryTarget] = useState(null)
  const [menuIndustryOpen, setMenuIndustryOpen] = useState(false)
  const [industry, setIndustry] = useState(industryInit);

  const [data, setData] = useState(null)
  const [dataFull, setDataFull] = useState(null)

  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchNeedle, setSearchNeedle] = useState("");
  const [searchOpen, setSearchOpen] = useState(false)
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const reload = useSelector(state => state.reload)
  const [mounted, setMounted] = useState(false)
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('avg_performance');

  const fetch = () => {
    setLoading(true)

    api.get("/v2/dashboard/performance", { id: 'performance' }).then(response => {
      const data = response.data;
      if (localStorage.getItem("PERF_COLS")) {
        dataInit.enabled = JSON.parse(localStorage.getItem("PERF_COLS"))
      }
      dataInit.rows = data

      setPage(0)
      setData(dataInit)
      setDataFull(null)
      setDataFull(dataInit)
    }).catch(error => {

    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (dataFull) {
      markets.sort((a, b) => { return a.name.localeCompare(b.name) });
      sectors.sort((a, b) => { return a.name.localeCompare(b.name) });
      globalFilter()
    }
  }, [dataFull]);

  useEffect(() => {
    setMounted(true)
  }, []);

  useEffect(() => {
    if (reload) {
      fetch()
    }
  }, [reload])

  useEffect(() => {
    if ((searchNeedle || searchNeedle === "") && mounted) {
      globalFilter()
    };
  }, [searchNeedle]);

  useEffect(() => {
    if (searchOpen) {
      document.addEventListener('keydown', (e) => {
        e.key === 'Escape' && setSearchOpen(false)
      })
      return () => {
        document.removeEventListener('keydown', (e) => e)
        document.removeEventListener('click', (e) => e)
      }
    }
  }, [searchOpen])

  function handleMenu(e) {
    setMenuTarget(e.currentTarget)
    setMenuOpen(true)
  }

  function handleColumns(event, v) {
    const updatedData = data;
    // Count the number of true values in data.enabled
    const enabledCount = Object.values(updatedData.enabled).filter(Boolean).length;
    updatedData.enabled[event.target.name] = event.target.checked;
    const colNum = Object.values(updatedData.enabled).filter(Boolean).length + 1
    dataInit.enabled = updatedData.enabled
    localStorage.setItem("PERF_COLS", JSON.stringify(dataInit.enabled))
    setColWidth(parseInt(600 / colNum))
    setColCount(colNum)
  }

  const closeMenu = () => {
    setMenuOpen(false)
    setMenuMarketsOpen(false)
    setMenuSectorsOpen(false)
    setMenuIndustryOpen(false)
  }

  function handleExpanded() {
    let newState = !expanded
    setExpanded(newState)
    setRowsPerPage(newState ? 8 : 5)
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    inputRef.current.focus();
    setSearchOpen(!searchOpen)
  }
  const closeSearch = () => {
    setSearchOpen(false)
  }

  const handleMarketsChange = (e, checked) => {
    const updatedData = markets;
    let item = updatedData.find(item => item.id == e.target.id)

    item.enabled = checked
    setMarkets(updatedData)
    globalFilter()
  }
  const handleMarketsMenu = (e) => {
    // setMenuMarketsTarget(e.currentTarget)
    setMenuMarketsOpen(true)
  }
  const resetMarkets = () => {
    const updatedData = markets
    updatedData.forEach(item => {
      item.enabled = false
    });

    setMarkets(updatedData)
    globalFilter()
  }
  const fillMarkets = () => {
    const updatedData = markets
    updatedData.forEach(item => {
      item.enabled = true
    });

    setMarkets(updatedData)
    globalFilter()
  }

  const handleSectorsChange = (e, checked) => {
    const updatedData = sectors;
    let item = updatedData.find(item => item.id == e.target.id)

    item.enabled = checked
    setSectors(updatedData)
    globalFilter()
  }
  const handleSectorsMenu = (e) => {
    setMenuSectorsTarget(e.currentTarget)
    setMenuSectorsOpen(true)
  }
  const resetSectors = () => {
    const updatedData = sectors
    updatedData.forEach(item => {
      item.enabled = false
    });

    setSectors(updatedData)
    globalFilter()
  }
  const fillSectors = () => {
    const updatedData = sectors
    updatedData.forEach(item => {
      item.enabled = true
    });

    setSectors(updatedData)
    globalFilter()
  }

  // Industry --------------------------------------
  const handleIndustryMenu = (e) => {
    setMenuIndustryTarget(e.currentTarget)
    setMenuIndustryOpen(true)
  }

  const handleIndustryChange = (e, checked) => {
    const updatedData = industry;

    let item = updatedData.find(item => item.name == e.target.name)
    item.enabled = checked
    setIndustry(updatedData)
    globalFilter()
  }

  const resetIndustries = () => {
    const updatedData = industry
    updatedData.forEach(item => {
      item.enabled = false
    });

    setIndustry(updatedData)
    globalFilter()
  }
  const fillIndustries = () => {
    const updatedData = industry
    updatedData.forEach(item => {
      item.enabled = true
    });

    setIndustry(updatedData)
    globalFilter()
  }

  const makeSearch = (event) => {
    const needle = event.target.value;
    setSearchNeedle(needle ? needle.toLowerCase() : "") // triggers an useEffect
  }
  const resetSearch = (e) => {
    setSearchNeedle("")
  }

  const globalFilter = () => {
    const searchedRows = JSON.parse(JSON.stringify(dataFull));
    // FILTER BY NAME/TICKER
    if (searchNeedle && searchNeedle.length > 0) {
      const filteredItems = searchedRows.rows.filter((item) => {
        const { assetName, ticker } = item;
        return assetName.toLowerCase().includes(searchNeedle) || ticker.toLowerCase().includes(searchNeedle);
      });
      searchedRows.rows = filteredItems;
    }
    // FILTER BY MARKETS
    const enabledMarkets = markets.filter(item => item.enabled === true);
    if (enabledMarkets.length > 0) {
      const filteredItems = searchedRows.rows.filter(item => enabledMarkets.some(filter => filter.id === item.marketIds))
      searchedRows.rows = filteredItems;
    }
    // FILTER BY SECTORS
    const enabledSectors = sectors.filter(item => item.enabled === true);
    if (enabledSectors.length > 0) {
      const filteredItems = searchedRows.rows.filter(item => enabledSectors.some(filter => filter.id === item.sectorIds));
      searchedRows.rows = filteredItems;
    }
    // FILTER BY INDUSTRY
    const enabledIndustry = industry.filter(item => item.enabled === true);
    if (enabledIndustry.length > 0) {
      const filteredItems = searchedRows.rows.filter(item => enabledIndustry.some(filter => filter.name.trim().toLowerCase() === item.industryName.trim().toLowerCase()));
      searchedRows.rows = filteredItems;
    }

    setData(searchedRows)
    setPage(0)
  }

  const handleInstrument = (obj) => {
    const uniqueId = Math.random().toString(16).slice(2);
    dispatch(notifyInstrument({ id: uniqueId, data: obj }))
  }

  const handleSort = (col) => {
    const isAsc = orderBy === col && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(col);
    setPage(0)
    switch (isAsc ? 'desc' : 'asc') {
      case "asc":
      default:
        return data.rows.sort((a, b) => {
          const valueA = a[col];
          const valueB = b[col];

          // Check if the values are numeric
          if (!isNaN(valueA) && !isNaN(valueB)) {
            return Number(valueA) - Number(valueB);
          }

          // If not numeric, sort as alphanumeric
          if (valueA.toLowerCase() < valueB.toLowerCase()) {
            return -1;
          }
          if (valueA.toLowerCase() > valueB.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      case "desc":
        return data.rows.sort((a, b) => {
          const valueA = a[col];
          const valueB = b[col];
          if (!isNaN(valueA) && !isNaN(valueB)) {
            return Number(valueB) - Number(valueA);
          }
          if (valueA.toLowerCase() < valueB.toLowerCase()) {
            return 1;
          }
          if (valueA.toLowerCase() > valueB.toLowerCase()) {
            return -1;
          }
          return 0;
        });
    }

  };

  return (
    <>
      <Backdrop open={searchOpen} onClick={handleSearch} sx={{ zIndex: 1 }} />

      <Card sx={{ position: expanded ? "fixed" : "initial", width: "100%", height: expanded ? "100%" : undefined, margin: expanded ? 0 : "initial", left: 0, top: 0, transition: 'all 0.25s ease', zIndex: expanded ? 1300 : 0 }}>
        <CardHeader
          sx={{ display: "flex", alignItems: "center", position: "relative" }}
          title={<Typography variant='overline' className='bold'>Best performing</Typography>}
          action={
            <Box sx={{ display: "flex", columnGap: 1 }}>
              <IconButton onClick={handleExpanded}>{expanded ? <FullscreenExitOutlined color="default" /> : <FullscreenOutlined color="default" />}</IconButton>
            </Box>
          }
        />
        <CardContent sx={{ minHeight: 410.16 }}>
          <TableContainer sx={{ maxWidth: "100%" }}>
            <Table sx={{ maxWidth: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 40, maxWidth: 40, px: 0 }}></TableCell>
                  {data && columns.map((obj, i) =>
                    (expanded || data.enabled[obj.field]) &&
                    <TableCell key={i} sx={{ width: colWidth, maxWidth: colWidth, py: 1, px: 2, }} padding="checkbox" className='bold' align='left'>
                      <TableSortLabel
                        active={orderBy === obj.field}
                        direction={orderBy === obj.field ? order : 'asc'}
                        onClick={() => handleSort(obj.field)}
                      >
                        <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                          {/* <Typography variant='body2' className='bold'>{obj.headerName}</Typography> */}
                          <Typography variant='body2' color='textSecondary'>{obj.headerName}</Typography>
                        </Box>
                      </TableSortLabel>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  loading ? <SkeletonTable rows={rowsPerPage} cols={colCount} /> :
                    data && data.rows ? data.rows
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((obj, i) =>
                        <TableRow key={"row_" + i} onClick={() => handleInstrument(obj)} hover sx={{ cursor: 'pointer' }}>
                          <TableCell sx={cellStyleLogo}>
                            <Avatar variant='square'>
                              {
                                obj.logo && obj.logo.length > 0 && obj.logo.startsWith("/img") ?
                                  <img alt={obj.assetName + "Img"} style={{ maxHeight: 40, maxWidth: 40 }} src={"https://eodhistoricaldata.com" + obj.logo} />
                                  : getSectorLogo(obj.sectorName)
                              }
                            </Avatar>
                          </TableCell>
                          {(expanded || data.enabled.assetName) &&
                            <Tooltip title={obj.assetName}><TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                              <Typography variant='subtitle2'>{obj.ticker}</Typography>
                              <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                                <Typography variant='caption' color={"textSecondary"}>{obj.assetName}</Typography>
                              </Box>
                            </TableCell></Tooltip>
                          }
                          {(expanded || data.enabled.avg_performance) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }} align="right">
                            <Typography variant='caption' color={obj.avg_performance > 0 ? "long" : "short"}>
                              {new Intl.NumberFormat("en-GB", { style: "percent", maximumSignificantDigits: 4 }).format(obj.avg_performance / 100)}
                            </Typography>
                          </TableCell>}
                          {(expanded || data.enabled.win_ratio) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }} align="right">
                            <Typography variant='caption' color={obj.win_ratio > 0 ? "long" : "short"}>
                              {new Intl.NumberFormat("en-GB", { style: "percent", maximumFractionDigits: 2 }).format(obj.win_ratio / 100)}
                            </Typography>
                          </TableCell>}
                          {/* {(expanded || data.enabled.lastPrice) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                              {new Intl.NumberFormat("en-GB", { maximumSignificantDigits: 4 }).format(obj.lastPrice)}
                            </Box>
                          </TableCell>}
                          {(expanded || data.enabled.lastChange) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", }}>
                              <Typography variant='caption'>
                                {new Intl.NumberFormat("en-GB", { maximumSignificantDigits: 4 }).format(obj.lastChange)}
                              </Typography>
                            </Box>
                          </TableCell>} */}
                          {(expanded || data.enabled.marketName) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                              <Typography variant='caption'>{obj.marketName}</Typography>
                            </Box>
                          </TableCell>}
                          {(expanded || data.enabled.sectorName) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                              {obj.sectorName}
                            </Box>
                          </TableCell>}
                          {(expanded || data.enabled.industryName) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                              {obj.industryName}
                            </Box>
                          </TableCell>}
                          {(expanded || data.enabled.assetType) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                              <Typography variant='caption'>{obj.assetType}</Typography>
                            </Box>
                          </TableCell>}
                          {(expanded || data.enabled.isin) && <TableCell sx={{ py: 1, px: 2, maxWidth: colWidth }}>
                            <Box sx={{ whiteSpace: 'nowrap', overflow: "hidden", maxWidth: "100%", textOverflow: "ellipsis" }}>
                              <Typography variant='caption'>{obj.isin}</Typography>
                            </Box>
                          </TableCell>}
                        </TableRow>
                      ) : <TableRow><TableCell>No data</TableCell></TableRow>}
              </TableBody>
            </Table >
          </TableContainer>
          {data &&
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={data.rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }

        </CardContent>
        <CardActions sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: "flex", columnGap: 1, alignItems: 'center' }}>
            <Chip label="Markets"
              sx={{ minWidth: 80 }}
              size='small'
              variant={markets.some((item) => item.enabled) ? "filled" : "filled"}
              color={markets.some((item) => item.enabled) ? "primary" : "secondary"}
              onClick={handleMarketsMenu} />
            <Chip label="Sectors"
              sx={{ minWidth: 80 }}
              size='small'
              variant={sectors.some((item) => item.enabled) ? "filled" : "filled"}
              color={sectors.some((item) => item.enabled) ? "primary" : "secondary"}
              onClick={handleSectorsMenu} />
            <Chip label="Industry"
              sx={{ minWidth: 80 }}
              size='small'
              variant={industry.some((item) => item.enabled) ? "filled" : "filled"}
              color={industry.some((item) => item.enabled) ? "primary" : "secondary"}
              onClick={handleIndustryMenu} />
            <OutlinedInput
              ref={inputRef}
              onChange={makeSearch}
              size='small'
              placeholder='Filter by name/ticker'
              value={searchNeedle}
              fullWidth
              sx={{ transform: "transition: opacity 0.5s ease-in-out" }}
              id="search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={!searchNeedle|| searchNeedle == ''}
                    size='small'
                    aria-label="toggle search visibility"
                    onClick={resetSearch}
                    edge="end"
                  >
                    <Close fontSize='12' color={!searchNeedle|| searchNeedle == '' ? 'textSecondary': 'default'} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <IconButton sx={{ display: expanded ? 'none' : 'inherit' }} onClick={handleMenu}><MoreVert color="default" /></IconButton>
        </CardActions>
      </Card>

      <Menu
        id="basic-menu"
        anchorEl={menuTarget}
        open={menuOpen}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {data && columns.map(obj =>
          <MenuItem disabled={!obj.editable || (colCount >= 5 && !data.enabled[obj.field])} key={"cols_" + obj.field}>
            <Checkbox
              defaultChecked={data.enabled[obj.field]}
              checked={data.enabled[obj.field]}
              disabled={colCount >= 5 && !data.enabled[obj.field]}
              name={obj.field}
              onChange={handleColumns}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {obj.headerName}
          </MenuItem>
        )}
      </Menu>

      <Dialog maxWidth={"lg"} open={menuMarketsOpen} onClose={closeMenu} scroll="paper">
        <DialogTitle>Exchanges selection</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            {markets.map((obj, i) =>
              <Grid item xs={3} key={"ckb_" + i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={obj.enabled}
                      name={obj.name}
                      id={obj.id + ""}
                      onChange={handleMarketsChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color='primary'
                    />
                  }
                  label={<Typography variant='body2'>{obj.name}</Typography>}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disableElevation onClick={fillMarkets}>Select all</Button>
          <Button disableElevation onClick={resetMarkets}>Reset</Button>
          <Button onClick={closeMenu} variant='contained' disableElevation>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth={"lg"} open={menuSectorsOpen} onClose={closeMenu} scroll="paper">
        <DialogTitle>Sectors selection</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            {sectors.map((obj, i) =>
              <Grid item xs={3} key={"ckb_" + i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={obj.enabled}
                      name={obj.name}
                      id={obj.id + ""}
                      onChange={handleSectorsChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color='primary'
                    />
                  }
                  label={<Typography variant='body2'>{obj.name}</Typography>}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disableElevation onClick={fillSectors}>Select all</Button>
          <Button disableElevation onClick={resetSectors}>Reset</Button>
          <Button onClick={closeMenu} variant='contained' disableElevation>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth={"xl"} open={menuIndustryOpen} onClose={closeMenu} scroll="paper">
        <DialogTitle>Industries selection</DialogTitle>
        <DialogContent dividers sx={{ maxHeight: 400 }}>
          <Grid container spacing={2}>
            {industry.map((obj, i) =>
              <Grid item xs={3} key={"ckb_" + i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={obj.enabled}
                      name={obj.name}
                      id={"" + i}
                      onChange={handleIndustryChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color='primary'

                    />
                  }
                  label={<Typography variant='body2'>{obj.name}</Typography>}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disableElevation onClick={fillIndustries}>Select all</Button>
          <Button disableElevation onClick={resetIndustries}>Reset</Button>
          <Button onClick={closeMenu} variant='contained' disableElevation>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withTheme(BestPerformances);