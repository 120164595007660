import { withTheme } from '@emotion/react';
import { Box, Card, CardContent, CardHeader, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { apiVitrum } from "../../api/apiVitrum";
import { useSelector } from 'react-redux';


const WeightedPredictionArea = (props) => {
    const [loading, setLoading] = useState(false)
    const theme = useSelector(state => state.theme)
    const vitrumModel = useSelector(state => state.vitrumModel)
    const [prediction, setPrediction] = useState(null)

    useEffect(() => {

    }, [vitrumModel])

    useEffect(() => {
        loadWPrediction()
    }, [])

    const loadWPrediction = () => {
        setLoading(true)
        apiVitrum.get(`/v1/weightedPrediction/`, { "id": "weightedPrediction" }).then(response => {
            const data = response.data
            setPrediction(data)
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        })

    }

    return (
<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardHeader title={<Typography variant='overline'>Weighted prediction</Typography>} />
            <CardContent sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                {loading ?
                    <Skeleton animation={false} sx={{ height: 350, width: '100%', transform: "scale(1)" }} />
                    : prediction ?
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ flex: '40%' }}><Typography variant='h3' className='big'>{prediction.predictionType}</Typography></Box>
                            <Box sx={{ flex: '60%' }}>
                                <Typography variant='caption'>This consolidated prediction, a key feature of our investment platform, is derived from a blend of outputs from diverse financial models. It’s a process where each model's prediction is thoughtfully weighted, taking into account its historical accuracy and overall performance. This selective weighting ensures that the final forecast is heavily influenced by the models proven to be most reliable and effective.</Typography>
                            </Box>
                        </Box>
                        : "No data"
                }
            </CardContent>
        </Card>
    );
};

export default withTheme(WeightedPredictionArea);
