import { Dialog, DialogContent, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, DialogTitle } from '@mui/material';
import { Component } from 'react';
import { DialogActions } from '@mui/material';
import { withTheme } from '@emotion/react';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { MathComponent } from "mathjax-react";


class DialogHelpMetricsRisk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPanel: ''
    }
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentDidMount() {
  }

  handleClose() {
    this.props.closeDialog()
  }

  handleChange(selectedPanel) {
    this.setState({ selectedPanel })
  }

  render() {
    const { selectedPanel } = this.state;
    const { } = this.props;
    let formulasBackground = this.props.theme.palette.background.primary

    return (
      <Dialog open={this.props.open} maxWidth={'sm'} onClose={() => this.handleClose()} fullWidth>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', my:0 }} />
        <DialogContent>

          <Accordion expanded={selectedPanel === 'panel1'} onChange={() => this.handleChange('panel1')} TransitionProps={{ unmountOnExit: true }} variant='outlined' sx={{ border: 0 }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0 }}>
                <Typography variant='h6'>Volatility</Typography>
                <Typography variant='caption' color="textSecondary">Degree of variation</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2'>Volatility (annualized) is the standard deviation over the selected period scaled to a 12-month period.
                It is computed starting from the log-returns <i>r</i>. It is a measure of the fluctuation in value of the portfolio.</Typography>
              <Box sx={{ backgroundColor: formulasBackground, p: 2, mt: 3 }}>
                <Box sx={{ fontSize: 20 }}>
                  <MathComponent tex={String.raw`\sigma_p = stdev(r) \cdot \sqrt T, \quad T = 252`} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={selectedPanel === 'panel2'} onChange={() => this.handleChange('panel2')} TransitionProps={{ unmountOnExit: true }} variant='outlined' sx={{ border: 0 }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0 }}>
                <Typography variant='h6'>Max Drawdown</Typography>
                <Typography variant='caption' color="textSecondary">Maximum observed loss</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2' sx={{ textAlign: 'justify' }}>Maximum Drawdown (%) is the maximum observed percentage loss from a peak to a trough of a portfolio. It is a measure of the maximum fluctuation registered in the history of a portfolio.</Typography>
              <Box sx={{ backgroundColor: formulasBackground, p: 2, mt: 3 }}>
                <Box sx={{ fontSize: 15, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`Max_t = `} /> Maximum value achieved by portfolio up to time t
                </Box>
                <Box sx={{ fontSize: 15, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`V_t = `} /> Value of portfolio at time t
                </Box>
                <Box sx={{ fontSize: 15, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`Drawdown_t = \frac{Max_t-V_t}{Max_t}`} />
                </Box>
                <Box sx={{ fontSize: 20 }}>
                  <MathComponent tex={String.raw`Maximum\ Drawdown = max(Drawdown_t)`} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={selectedPanel === 'panel3'} onChange={() => this.handleChange('panel3')} TransitionProps={{ unmountOnExit: true }} variant='outlined' sx={{ border: 0 }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0 }}>
                <Typography variant='h6'>VaR@95% (10d)</Typography>
                <Typography variant='caption' color="textSecondary">Value at Risk</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2' sx={{ textAlign: 'justify' }}>Value at Risk (VaR) quantifies the measure of possible losses of a portfolio over a defined time frame (10 days), with a certain level of level of probability p (95%). It can be computed using different methods, the represented VaR is computed using a parametric variance-covariance method based on the current portfolio allocation.</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={selectedPanel === 'panel4'} onChange={() => this.handleChange('panel4')} TransitionProps={{ unmountOnExit: true }} variant='outlined' sx={{ border: 0 }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0 }}>
                <Typography variant='h6'>Ulcer Index</Typography>
                <Typography variant='caption' color="textSecondary">Downwards volatility</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2' sx={{ textAlign: 'justify' }}>Ulcer Index is a risk measure quantifying the downwards volatility of retracement over a period  of 14 business days. It is computed based on drawdown measures (see drawdown section above) and owes its name to the stress caused by the downside movements of an investment.</Typography>
              <Box sx={{ backgroundColor: formulasBackground, p: 2, mt: 3 }}>
                <Box sx={{ fontSize: 20, mb: 1 }}>
                  <MathComponent display={true} tex={String.raw`Ulcer_t = \frac{1}{N}\sum^t_{i=t-N}Drawdown_i, \quad N=14`} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={selectedPanel === 'panel5'} onChange={() => this.handleChange('panel5')} TransitionProps={{ unmountOnExit: true }} variant='outlined' sx={{ border: 0 }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} sx={{mb: 0}}>
              <Box sx={{ display: 'flex', flexDirection: 'column', mb: 0 }}>
                <Typography variant='h6'>Beta Bear</Typography>
                <Typography variant='caption' color="textSecondary">Downwards correlation</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2' sx={{ textAlign: 'justify' }}>Beta Bear indicator, similarly to Beta indicator (see section about Beta in portfolio metrics), is a measure of a portfolio volatility in relation to the selected market or index, considering only periods with downside movements of the market. It is computed using covariance between portfolio and benchmark, and the benchmark variance. It indicates the characteristic correlation of a portfolio with the benchmark during negative periods.</Typography>
              <Box sx={{ backgroundColor: formulasBackground, p: 2, mt: 3 }}>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`\tilde r_p = `} /> portfolio return series given benchmark return is negative
                </Box>
                <Box sx={{ fontSize: 14, mb: 1 }}>
                  <MathComponent display={false} tex={String.raw`\tilde r_b = `} /> benchmark return series given benchmark return is negative
                </Box>
                <Box sx={{ fontSize: 20 }}>
                  <MathComponent tex={String.raw`Beta\ Bear = \frac{Cov(\tilde r_p, \tilde r_b)}{Var(\tilde r_b)}`} />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

        </DialogContent>
        <DialogActions sx={{  }}>
          <Button onClick={() => this.handleClose()}>close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTheme(DialogHelpMetricsRisk);
