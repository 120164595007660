import React, { Component } from 'react';
import './App.css';

import styled from '@emotion/styled';
import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import numeral from 'numeral';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router';
import secureLocalStorage from 'react-secure-storage';
import darktheme from './commons/DarkTheme';
import MyDrawer from './commons/Drawer';
import lighttheme from './commons/LightTheme';
import notificationsInit from './commons/Notifications';
import PrivateRoute from './commons/PrivateRoute';
import TopBar from './commons/TopBar';
import { notifyInstrument, notifyTheme } from './dispatcher/Actions';
import Login from './elements/Login';
import PortfolioExport from './exports/PortfolioExport';
import Archive from './views/Archive';
import Archived from './views/Archived';
import Builder from './views/Builder';
import Dashboard from './views/Dashboard';
import Instrument from './views/Instrument';
import NotFound from './views/NotFound';
import Overview from './views/Overview';
import Portfolio from './views/Portfolio';
import Vitrum from './views/Vitrum';
import { api } from './api/apiBuilder';
import log from 'loglevel';

// Set log level based on environment
if (process.env.NODE_ENV === 'development') {
  log.setLevel('trace');
} else if (process.env.NODE_ENV === 'test') {
  log.setLevel('debug');
} else {
  log.setLevel('info');
}

log.methodFactory = function (methodName, logLevel, loggerName) {
  const rawMethod = log.originalFactory(methodName, logLevel, loggerName);

  return function (message) {
    rawMethod(`[${methodName.toUpperCase()}] ${message}`);
  };
};

const styles = theme => ({
  fabStyle: {
    position: 'absolute',
    top: 16,
    left: 16,
    opacity: .3,
    zIndex: '1301!important',
    "&:hover": {
      opacity: 1,
      transition: '.200s linear'
    },
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode.toUpperCase() === 'DARK' ? darktheme.palette : lighttheme.palette),
  },
});

const createThemeFromStorage = () => {
  const currentTheme = localStorage.getItem('THEME') || 'DARK'
  let themeObj = createTheme(getDesignTokens(currentTheme));
  if (currentTheme.toUpperCase() === "LIGHT") {
    themeObj.components = lighttheme.components;
  } else {
    themeObj.components = darktheme.components;
  }
  return themeObj
}

let theme = createThemeFromStorage()

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'DARK',
      view: 'O',
      drawerOpen: false,
      ready: false,
      isLoading: false,
      snackOpen: false,
      snackMessage: '',
      logged: sessionStorage.getItem('TOKEN'),
      postData: {
        "settings": {
          "date": null,
          "size": 0,
          "init": 0,
          "quality": 0,
          "strategy": null
        },
        "chains": [

        ],
        "sectors": [

        ]
      },
      summary: null,
      allocation: null,
      performances: null,
      quarters: null,
      trades: null
    }
  }

  storeTheme(newTheme) {
    api.put("/v2/theme", { theme: newTheme.toUpperCase() }, { cache: false })
      .then((response) => {
        // theme stored on server
      })
      .catch((error) => {
        console.error("Error during theme storing", error);
      });
  }

  applyTheme(tme) {
    theme = createTheme(getDesignTokens(tme))

    if (tme.toUpperCase() === 'LIGHT') {
      theme.components = lighttheme.components;
    } else {
      theme.components = darktheme.components;
    }

    this.props.dispatch(notifyTheme(tme))
    localStorage.setItem('THEME', tme)
    this.setState({ mode: tme });
  }

  changeTheme() {
    let prevMode = this.state.mode

    if (prevMode == 'DARK') {
      this.setState({ mode: 'LIGHT' });
      theme = createTheme(getDesignTokens('LIGHT'))
      theme.components = lighttheme.components
      this.props.dispatch(notifyTheme("LIGHT"))
      localStorage.setItem('THEME', 'LIGHT')
      this.storeTheme("LIGHT")
    } else {
      this.setState({ mode: 'DARK' });
      theme = createTheme(getDesignTokens('DARK'))
      theme.components = darktheme.components
      this.props.dispatch(notifyTheme("DARK"))
      localStorage.setItem('THEME', 'DARK')
      this.storeTheme("DARK")
    }
  }

  logout() {
    for (var obj in sessionStorage) {
      if (sessionStorage.hasOwnProperty(obj) && obj != "session_id") {
        sessionStorage.removeItem(obj);
      }
    }
    this.setState({ logged: sessionStorage.getItem('TOKEN') })
    secureLocalStorage.removeItem("perms")
    this.props.dispatch(notifyInstrument({ id: 0, data: null }))
    this.props.navigate('/')
  }

  componentDidMount() {
    if (!sessionStorage.getItem('TOKEN')) {
      this.logout();
    }

    const currentTheme = localStorage.getItem('THEME') || 'DARK';

    const themeComponents = currentTheme.toUpperCase() === 'LIGHT' ? lighttheme.components : darktheme.components;
    theme = createTheme(getDesignTokens(currentTheme));
    theme.components = themeComponents;
    const permissions = secureLocalStorage.getItem('perms')
    this.setState({ permissions, mode: currentTheme })

    try {
      numeral.register('locale', 'it', {
        delimiters: {
          thousands: ',',
          decimal: '.'
        },
        abbreviations: {
          thousand: 'k',
          million: 'm',
          billion: 'b',
          trillion: 't'
        },
        ordinal: function (number) {
          return number === 1 ? 'st' : 'th';
        },
        currency: {
          symbol: '€'
        }
      });

      // switch between locales
      numeral.locale('it');
    } catch (e) {
    }

  }

  changeView(who, view) {
    this.setState({ view })
  }

  toggleDrawer() {
    this.setState({ drawerOpen: !this.state.drawerOpen })
  }

  getLocation() {
    const hash = window.location.hash
    const location = hash.replace('#/', '')
    return location
  }

  componentDidUpdate(e) {
    if (this.getLocation() != "" && !sessionStorage.getItem('TOKEN')) {
      this.logout()
    }
  }

  login() {
    this.setState({ logged: true })
    const permissions = secureLocalStorage.getItem('perms')
    this.setState({ permissions })
    notificationsInit()
    let path = '404'
    if (permissions && permissions.dashboard)
      path = 'dashboard'
    else if (permissions && permissions.builder)
      path = 'home'
    else if (permissions && permissions.crypto)
      path = 'crypto'

    this.props.navigate(path)
  }

  render() {
    const { classes } = this.props;
    const { mode, permissions } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {this.state.logged ? (
            <>
              <TopBar mode={mode} changeTheme={() => this.changeTheme()} toggleDrawer={this.toggleDrawer.bind(this)}></TopBar>
              <MyDrawer open={this.state.drawerOpen} logout={this.logout.bind(this)} toggleDrawer={this.toggleDrawer.bind(this)} changeView={this.changeView.bind(this)} changeTheme={this.changeTheme.bind(this)}></MyDrawer>
            </>
          ) : ''}
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {/* <DrawerHeader /> */}
            <Routes>
              <Route exact path="/" element={<Login login={() => this.login()} applyTheme={(tme) => this.applyTheme(tme)} />} />

              <Route exact path="/home" element={<PrivateRoute element={<Overview />} requiredPermission={'builder'} />} />
              <Route exact path="/archive" element={<PrivateRoute element={<Archive />} requiredPermission={'builder'} />} />
              <Route path="/portfolio/:id" element={<PrivateRoute element={<Portfolio />} requiredPermission={'builder'} />} />
              <Route path="/portfolio-export/:id" element={<PrivateRoute element={<PortfolioExport />} requiredPermission={'builder'} />} />
              <Route path="/archived/:id" element={<PrivateRoute element={<Archived />} requiredPermission={'builder'} />} />
              <Route path="/stock/:idPortfolio/:idInstr/:idTrade" element={<PrivateRoute element={<Instrument />} requiredPermission={'builder'} />} />
              <Route exact path="/builder" element={<PrivateRoute element={<Builder />} requiredPermission={'builder'} />} />

              <Route exact path="/dashboard" element={<PrivateRoute element={<Dashboard />} requiredPermission={'dashboard'} />} />

              <Route exact path="/crypto" element={<PrivateRoute element={<Vitrum />} requiredPermission={'crypto'} />} />

              <Route path="*" element={<NotFound />} />
            </Routes>

            {/*this.state.logged ? (

            this.state.view == 'O' ? <Overview logout={this.logout.bind(this)} changeView={this.changeView.bind(this)} />
              :
              this.state.view == 'B' ? <Builder logout={this.logout.bind(this)} changeView={this.changeView.bind(this)} />
                : <Portfolio portfolio={this.state.view} logout={this.logout.bind(this)} changeView={this.changeView.bind(this)} />

          ) : <Login></Login>*/}
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}


export function AppWithRouter(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return <App navigate={navigate} dispatch={dispatch} {...props} />
}

export default App;
