import { withTheme } from '@emotion/react';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Typography } from '@mui/material';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { apiVitrum } from '../../api/apiVitrum';
import moment from 'moment';


const SpecificModelArea = (props) => {
    const [loading, setLoading] = useState(false)
    const theme = useSelector(state => state.theme)
    const vitrumModel = useSelector(state => state.vitrumModel)
    const [options, setOptions] = useState(null)
    const [data, setData] = useState(null)

    useEffect(() => {
        if (vitrumModel)
            loadModelChart()
    }, [vitrumModel])

    const loadModelChart = () => {
        setLoading(true)
        apiVitrum.get(`/v1/modelarea/` + vitrumModel, { "id": `modelarea-${vitrumModel}` }).then(response => {
            const data = response.data

            const modelSeries = data.model.time_series
            const benchmarkSeries = data.benchmark.time_series
            let series = [];
            let quotes = [];
            let quote;

            for (var i = 0; i < modelSeries.length; i++) {
                const recordDate = new Date(modelSeries[i].date);
                quote = {
                    x: (moment(modelSeries[i].d).format('Y-MM-DD')),
                    y: [modelSeries[i].v * 1]
                }
                quotes.push(quote);
            }
            series.push({
                data: quotes,
                name: "Model"
            })

            quotes = [];
            for (var i = 0; i < benchmarkSeries.length; i++) {
                const recordDate = new Date(benchmarkSeries[i].date);
                quote = {
                    x: (moment(benchmarkSeries[i].d).format('Y-MM-DD')),
                    y: [benchmarkSeries[i].v * 1]
                }
                quotes.push(quote);
            }
            series.push({
                data: quotes,
                name: "Benchmark"
            })

            const options = {
                series,
                chart: {
                    toolbar: { show: false },
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                colors: [props.theme.palette.chartPrimary, props.theme.palette.chartSecondary],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 1.5,
                    curve: 'straight'
                },
                grid: {
                    borderColor: props.theme.palette.background.paper,
                    strokeDashArray: 6,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    floating: false,
                    fontFamily: props.theme.palette.typography.fontFamily,
                    labels: { colors: props.theme.palette.text.primary, },
                    markers: {
                        radius: 0,
                        width: 8,
                        height: 8,
                    },
                    itemMargin: {
                        vertical: 0
                    },
                },
                tooltip: {
                    theme: theme,
                    shared: true,
                    x: { show: true },
                    y: {
                        formatter: function (val) {
                            return numeral(val).format('0,000.0[00]');
                        }
                    }
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 3,
                    tickPlacement: 'on',
                    labels: {
                        show: true,
                        style: {
                            colors: props.theme.palette.text.primary,
                            fontSize: '11px',
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                        },
                    },
                },
                yaxis: {
                    tickAmount: 3,
                    //forceNiceScale: true,
                    labels: {
                        show: true,
                        formatter: (value) => { return numeral(value).format('0.0[000]') },
                        style: {
                            colors: props.theme.palette.text.primary,
                            fontSize: '11px',
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                },
            }
            setOptions(options)
            setData(data.model)
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Card>
            <CardHeader title={<Typography variant='overline'>model insights</Typography>} />
            <CardContent>
                {/* <Skeleton animation={false} sx={{ height: 350, transform: "scale(1)" }} /> */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {
                        loading ?
                            <>
                                <Box sx={{ flex: '40%', }}>
                                    <Skeleton variant='rectangular' height={350} sx={{bgcolor: props.theme.palette.background.default}} />
                                </Box>
                                <Box sx={{ flex: '60%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', px: 4 }}>
                                        <Typography variant='overline' gutterBottom>Performance metrics</Typography>
                                        <Grid container>
                                            {
                                                data ?
                                                    [1, 2, 3, 4, 5, 6].map(item =>
                                                        <Grid xs={4} item sx={{ p: 1 }}>
                                                            <Skeleton height={60} />
                                                        </Grid>
                                                    )
                                                    : "-"
                                            }
                                        </Grid>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', px: 8 }}><Divider /></Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', px: 4 }}>
                                        <Typography variant='overline' gutterBottom>Risk metrics</Typography>
                                        <Grid container>
                                            {
                                                data ?
                                                    [1, 2, 3, 4, 5, 6].map(item =>
                                                        <Grid xs={4} item sx={{ p: 1 }}>
                                                            <Skeleton height={60} />
                                                        </Grid>
                                                    )
                                                    : "-"
                                            }
                                        </Grid>
                                    </Box>
                                </Box>
                            </>
                            : data ?
                                <>
                                    <Box sx={{ flex: '40%', p: 2, bgcolor: props.theme.palette.background.default, borderRadius: 1 }}>
                                        {options && <ReactApexChart
                                            options={options}
                                            series={options.series}
                                            type="line"
                                            height={300}
                                        />}
                                    </Box>
                                    <Box sx={{ flex: '60%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', px: 4 }}>
                                            <Typography variant='overline' gutterBottom>Performance metrics</Typography>
                                            <Grid container>
                                                {data ?
                                                    [
                                                        { label: 'Annualized Return', value: numeral(data.annualized_return).format('+0,000.0[0]%'), raw: data.annualized_return },
                                                        { label: 'Information Ratio', value: numeral(data.information_ratio).format('0,000.0[0]'), raw: data.information_ratio },
                                                        { label: 'Sharpe Ratio', value: numeral(data.sharpe_ratio).format('0,000.0[0]'), raw: data.sharpe_ratio },
                                                        { label: 'Martin Ratio', value: numeral(data.martin_ratio).format('0,000.0[0]'), raw: data.martin_ratio },
                                                        { label: 'Alpha', value: numeral(data.alpha).format('+0,000.0[0]%'), raw: data.alpha },
                                                        { label: 'Beta', value: numeral(data.beta).format('0,000.0[0]'), raw: data.beta },
                                                    ].map(item =>
                                                        <Grid xs={4} item sx={{ p: 1 }}>
                                                            <Typography variant='h5' className='big'>{item.value ? item.value : "-"}</Typography>
                                                            <Typography variant='caption' color={'textSecondary'} component={'div'}>{item.label}</Typography>
                                                        </Grid>
                                                    )
                                                    : "-"
                                                }
                                            </Grid>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', px: 8 }}><Divider /></Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', px: 4 }}>
                                            <Typography variant='overline' gutterBottom>Risk metrics</Typography>
                                            <Grid container>
                                                {
                                                    data ?
                                                        [
                                                            { label: 'Volatility', value: numeral(data.volatility).format('+0,000.0[0]%'), raw: data.volatility },
                                                            { label: 'Max Drawdown', value: numeral(data.maximum_drawdown).format('+0,000.0[0]') + "%", raw: data.maximum_drawdown },
                                                            { label: 'VaR@95% (10d)', value: numeral(data.var_95d).format('+0,000.0[0]%'), raw: data.var_95d },
                                                            { label: 'Expected Shortfall', value: numeral(data.expected_shortfall_95d).format('+0,000.0[0]%'), raw: data.expected_shortfall_95d },
                                                            { label: 'Ulcer', value: numeral(data.ulcer_index).format('+0,000.0[0]%'), raw: data.ulcer_index },
                                                            { label: 'Beta bear', value: numeral(data.beta_bear).format('0,000.0[0]'), raw: data.beta_bear },
                                                        ].map(item =>
                                                            <Grid xs={4} item sx={{ p: 1 }}>
                                                                <Typography variant='h5' className='big'>{item.value ? item.value : "-"}</Typography>
                                                                <Typography variant='caption' color={'textSecondary'} component={'div'}>{item.label}</Typography>
                                                            </Grid>
                                                        )
                                                        : "-"
                                                }
                                            </Grid>
                                        </Box>
                                    </Box>
                                </>
                                :
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: 200, width: '100%' }}>
                                    <Typography variant='h6' gutterBottom>No Model Selected</Typography>
                                    <Typography variant='caption' color={"textSecondary"}>Choose one model to inspect from "Models"</Typography>
                                </Box>
                    }
                </Box>
            </CardContent>
        </Card>
    );
};

export default withTheme(SpecificModelArea);
