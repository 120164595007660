import { Dialog, DialogContent, Button } from '@mui/material';
import { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { DialogActions, DialogTitle } from '@mui/material';

const styles = theme => ({
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
});

class DialogConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  changeTheme(v){
    this.setState({selectedTheme: v})
    if(v) v = 'light'
    else v = 'dark'
    this.props.changeTheme(v)
  }

  confirm(){
    this.props.confirm();
    this.props.cancel()
  };

  handleClose(){
    this.props.cancel()
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <Dialog open={this.props.open} maxWidth={'xs'}>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>{this.props.message}</DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()}>Cancel</Button>
          <Button onClick={() => this.confirm()}>Ok</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DialogConfirm);
