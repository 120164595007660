import { Component } from "react";
import { Card, CardContent, Chip, Typography } from "@mui/material";
import { Box } from "@mui/material";
import notificationsInit from '../commons/Notifications'
import { withTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { api } from "../api/apiBuilder";
import eventBus from "../commons/eventBus";

const styles = theme => ({
    root: {
        maxHeight: 'calc(100vh - 64px)', overflowY: 'auto', overflowX: 'hidden'
    }
});

class PortfoliosListBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            portfolios: [],
            performance: 0,
            selected: this.props.defaultPortfolio,
            isArchived: false,
        }
    }

    componentWillUnmount() {
        eventBus.remove("reloadList");
    }
    
    componentDidMount() {
        eventBus.on("reloadList", (data) =>
            this.reloadComponent()
        );
        this.reloadComponent();
    }

    reloadComponent() {
        let isArchived = this.props.isArchived
        this.setState({isArchived})
        notificationsInit(sessionStorage.getItem('MSGT'));
        api.get('/v1/builder/portfolios/', { cache: false })
            .then(response => {
                const data = response.data;
                let filteredData = Array()
                if (isArchived) {
                    data.forEach(item => {
                        if (parseInt(item.ptf.archived)) filteredData.push(item)
                    })
                } else{
                    data.forEach(item => {
                        if (!parseInt(item.ptf.archived)) filteredData.push(item)
                    })
                }
                this.setState({ portfolios: filteredData })
            }).catch(error => {
                this.setState({ isLoading: false })
                if (error.response) {
                    console.log('Error 1', error.message);
                    if (error.response.status == 401) {
                    }
                    this.state.snackMessage = error.response.data;
                    this.state.snackOpen = true;
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log('Error 2', error.message);
                    this.state.snackMessage = error.message;
                    this.state.snackOpen = true;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error 3', error.message);
                    this.state.snackMessage = error.message;
                    this.state.snackOpen = true;
                }
            })
    }

    switchPortfolio(id) {
        let isArchived = this.state.isArchived
        this.setState({ selected: id })
        this.props.navigate((isArchived ? "/archived/" : "/portfolio/") + id)
    }

    render() {
        const { classes } = this.props;
        const { selected, isArchived } = this.state;
        return (
            <Card>
                <CardContent sx={{ p: 2, pb: "16px!important" }}>
                    <Box>
                        <Typography variant="subtitle2" sx={{ mr: 2 }} component={'span'}>
                            {isArchived ? "Archived list: " : "Portfolios list: "}
                        </Typography>
                        {
                            this.state.portfolios && this.state.portfolios.length > 0 ? this.state.portfolios.map((obj, i) => (
                                <Chip
                                    key={obj.ptf.id}
                                    color={selected == obj.ptf.id ? "primary" : "secondary"}
                                    variant={selected == obj.ptf.id ? "filled" : "filled"}
                                    size="small"
                                    sx={{ mr: 1 }}
                                    clickable
                                    onClick={() => this.switchPortfolio(obj.ptf.id)}
                                    label={obj.ptf.name} />
                            )) : ''
                        }
                    </Box>
                </CardContent>
            </Card>
        );
    }
}

function PortfoliosList(props) {
    const navigate = useNavigate()

    return <PortfoliosListBase navigate={navigate} {...props} />
}


export default withTheme(PortfoliosList);
