import { createTheme } from "@mui/material";

const darktheme = createTheme({
  green: '#4D7654',
  red: '#ff6859',
  empty: {
    background: '#eee',
    width: '100%',
    height: '100px'
  },
  cardSettings: {
    background: '#323232'
  },
  button: { textTransform: 'capitalize' },
  paper: { background: 'trasparent', boxShadow: 'none', padding: '16px' },
  palette: {
    typography: {
      fontFamily: [
        'Roboto Condensed',
        'sans-serif'
      ].join(','),
    },
    emptyChart: {
      background: '#20242f',
      width: '100%',
      height: '100px'
    },
    accent: '#15BAC0',
    chartPrimary: '#00e5a8',
    chartPrimaryLight: '#3A75FD',
    chartSecondary: '#ffcf44',
    chartSecondaryLight: '#FFB623', //salmone ff8860
    chartThird: '#B77EA3', //violetto B77EA3
    edges: '#848484',
    grid: '#27272f',
    pieBorders: '#373740',
    background: {
      primary: '#27272f',
      default: '#27272f',
      paper: '#373740',
      innerPaper: '#27272f'
    },
    default: {
      main: '#f6f6f6',
    },
    text: {
      primary: '#f6f6f6',
      secondary: '#b3b3b9',
      red: '#f00',
      disabled: '#b3b3b9',
    },
    textSecondary: {
      main: '#b3b3b9',
    },
    textOnLight: {
      main: "#000",
    },
    success: {
      main: '#15BAC0',
      //contrastText: "#FFFFFF"
    },
    error: {
      main: '#cf6679',
    },
    cta: {
      main: '#ff8a5e',
      light: '#ff956e',
      dark: '#e57c54',
      contrastText: "#484848"
    },
    long: {
      main: '#08bb81',
      contrastText: "#212121"
    },
    short: {
      main: '#fa3645',
      contrastText: "#f0f0f0"
    },
    neutral: {
      main: '#555560',
    },
    primary: {
      main: '#1eb980',
      bgrnd: '#3e3e47'
    },
    secondary: {
      main: '#4b4b58',
      // dark: will be calculated from palette.secondary.main,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.1,
  },
  components: {
    common: {
      black: '#848484'
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#4b4b58',
          borderRadius: 4,
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: '#4b4b58',
          borderRadius: 4,
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: '#f0f0f0',
          padding: 0,
          border: "0"
        }
      }
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          '&:hover': {
            // background: "#f0f0f0",
          },
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #2c2c2c!important'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#27272f',
        },
      }
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: '#212121',
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#27272f',
          color: '#132F4C',
          boxShadow: 'none',
          borderRadius: '0!important',
          border: "0px solid #8d21e9!important",
          borderColor: "#8d21e9!important",
          margin: 0,
        }
      }
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       background: "#33333d",
    //       backgroundColor: "#33333d",
    //     },
    //     rounded: {
    //       background: "#33333d",
    //       backgroundColor: "#33333d",
    //     },
    //   }
    // },
    MuiCard: {
      styleOverrides: {
        root: {
          background: "#373740",
          borderRadius: 2,
          boxShadow: 'none',
          height: 'auto',
          maxHeight: '100%',
          //margin: '8px',
          border: 0,
        },
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: 8,
        },
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          fontWeight: 300,
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderColor: "#484848" ,
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#4e4e57!important',
        },
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          //backgroundColor: '#373740!important',
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Roboto",
          lineHeight: 'inherit',
        },
        overline: {
          fontWeight: 400,
          letterSpacing: '.3em !important',
        },
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: "transparent!important",
          },
          '&:after': {
            position: "absolute",
            left: 0,
            top: -1,
            right: 0,
            height: 1,
            content: '""',
            opacity: 1,
            backgroundColor: "#27272f!important",
          },
        },
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          color: '#f0f0f0',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          //backgroundColor: "#4b4b58!important",
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: "#e6e6e6",
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          //color: "inherit",
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#333",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },
});

export default darktheme;