import { withTheme } from '@emotion/react';
import { Backdrop, Box, Card, CardContent, CardHeader, CircularProgress, Typography } from '@mui/material';
import ApexCharts from "apexcharts";
import moment from 'moment';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { apiVitrum } from "../../api/apiVitrum";
import { useRef } from 'react';

const MainChartArea = (props) => {
    const [loading, setLoading] = useState(false)
    const [coin, setCoin] = useState("bitcoin")
    const [historical, setHistorical] = useState([])
    const [options, setOptions] = useState(null)
    const theme = useSelector(state => state.theme)
    const previousTheme = useRef(theme);
    const vitrumModel = useSelector(state => state.vitrumModel)
    const previousVitrumModel = useRef(vitrumModel);
    const [annotations, setAnnotations] = useState([])
    const previousAnnotations = useRef(annotations);
    const [predictions, setPredictions] = useState([])
    const [modelName, setModelName] = useState("")

    useEffect(() => {
        // console.log("First load. fetchHistorical:");
        fetchHistorical()
    }, [])

    useEffect(() => {
        // console.log("Plotting chart:", vitrumModel);
        plot(historical)
    }, [historical])

    useEffect(() => {
        if (vitrumModel) {
            console.log(`Model ${vitrumModel} selected, fetchModel()`);
            fetchModel()
        }
    }, [vitrumModel])

    useEffect(() => {
        if (annotations && annotations.length > 0) {
            // console.log("New annotations found, updateChart()");
            updateChart()
        } else {
            // console.log("NO annotations found");
            if(!historical)
                fetchHistorical()
        }
    }, [annotations])

    useEffect(() => {
        if (theme !== previousTheme.current) {
            console.log("theme reloading", theme);
            updateChart()
        }
    }, [theme])

    const updateChart = () => {
        try {
            console.log("updateOptions by exec...");
            setLoading(true)
            ApexCharts.exec("historical", "updateOptions", {
                annotations: {
                    xaxis: annotations,
                },
                stroke: {
                    show: true,
                    width: 2,
                },
                tooltip: {
                    theme: theme,
                    y: {
                        formatter: (value) => {
                            const dataPoint = historical.find(point => point.c * 1 === value * 1);
                            if (dataPoint) {
                                const color = getPredictionColor(getPrediction(dataPoint.d))
                                const pred = getPrediction(dataPoint.d)
                                if (pred)
                                    return numeral(value).format('0,000.0[000]') + " " + '(<span style="color:' + color + '">' + pred + '</span>)'
                                else
                                    return numeral(value).format('0,000.0[000]')
                            }
                        },
                    },
                    x: {
                        formatter: (value) => { return moment(value).format('YYYY-MM-DD') },
                    }
                },
                colors: [props.theme.palette.chartPrimary],
                markers: {
                    size: 0
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: props.theme.palette.grid,
                    strokeDashArray: 0,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                },
                title: {
                    show: false
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 3,
                    tickPlacement: 'on',
                    labels: {
                        show: true,
                        style: {
                            colors: props.theme.palette.text.primary,
                            fontSize: '11px',
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                        },
                    },
                },
                yaxis: {
                    tickAmount: 3,
                    //forceNiceScale: true,
                    labels: {
                        show: true,
                        formatter: (value) => { return numeral(value).format('0.0[000]') },
                        style: {
                            colors: props.theme.palette.text.primary,
                            fontSize: '11px',
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                },

            }).then(() => {
                console.log("updated");
                setLoading(false)
            })
        } catch {

        }

    }

    const fetchModel = () => {
        if (vitrumModel) {
            console.log("Fetching model for annotations...");
            setLoading(true)
            apiVitrum.get(`/v1/dailySignals/` + vitrumModel, { "id": "dailySignals-" + vitrumModel }).then(response => {
                const preds = response.data.signals;
                const name = response.data.name;
                setPredictions(preds)
                setModelName(name)
                let anns = preds.map((item, index) => {
                    return {
                        x: new Date(item.date).getTime(),
                        x2: preds[index + 1] ? new Date(preds[index + 1].date).getTime() : new Date().getTime(),
                        borderColor: getPredictionColor(item.prediction),
                        fillColor: getPredictionColor(item.prediction),
                        strokeDashArray: 0,
                        opacity: 1,
                        borderWidth: 2,
                        offsetY: 280,//Math.floor(Math.random() * 285),
                        strokeWidth: 1
                    };
                });
                console.log("Fetching model for annotations...");

                setAnnotations(anns)
            }).catch(error => {
                console.log(error);
                setLoading(false)
            }).finally(() => {
            })
        }
    }

    const fetchHistorical = () => {
        if (!historical || historical.length == 0) {
            // console.log("Fetching historical...");
            setLoading(true)
            apiVitrum.get(`/v1/` + coin, { "id": "coin-" + coin }).then(response => {
                const data = response.data;
                setHistorical(data)
                //plot(data)

            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false)
            })
        } else {
            // console.log("Only plot...");
            plot(historical)
        }
    }

    const getPredictionColor = (prediction) => {
        let color;
        switch (prediction) {
            case 'BUY':
                color = props.theme.palette.long.main; // Green for BUY
                break;
            case 'SELL':
                color = props.theme.palette.short.main; // Red for SELL
                break;
            case 'NEUTRAL':
                color = props.theme.palette.neutral.main; // Grey for NEUTRAL
                break;
            default:
                color = '#000'; // Default color if no prediction
        }
        return color
    }

    const getPrediction = (targetDate) => {
        let prediction = null;

        // Find the prediction for the exact target date if it exists
        const exactPrediction = predictions.find(item => item.date === targetDate);
        if (exactPrediction) {
            prediction = exactPrediction.prediction;
        } else {
            // If the exact target date is not found, find the nearest previous prediction
            const sortedData = predictions.sort((a, b) => a.date.localeCompare(b.date));

            for (let i = sortedData.length - 1; i >= 0; i--) {
                if (sortedData[i].date < targetDate) {
                    prediction = sortedData[i].prediction;
                    break;
                }
            }
        }

        return prediction
    }

    const plot = (data) => {
        let series = [{
            data: []
        }];
        let quotes = [];
        let quote;
        var options = {};

        for (var i = 0; i < data.length; i++) {
            const recordDate = new Date(data[i].d);
            const cutoffDate = new Date("2023-06-15T00:00:00.000Z");
            if (recordDate >= cutoffDate) {
                quote = {
                    x: (moment(data[i].d).format('Y-MM-DD')),
                    y: [data[i].c * 1]
                }
                quotes.push(quote);
            }
        }
        series[0].data = quotes;
        series[0].name = "Price";

        var options = {
            series: series,
            chart: {
                type: 'line',
                id: 'historical',
                height: 300,
                toolbar: { show: false },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                },
                zoom: {
                    enabled: false,
                },
                brush: {
                    autoScaleYaxis: true
                }
            },
            annotations: {
                xaxis: annotations,
            },
            stroke: {
                show: true,
                width: 2,
            },
            tooltip: {
                theme: theme,
                y: {
                    formatter: (value) => {
                        const dataPoint = historical.find(point => point.c * 1 === value * 1);
                        if (dataPoint) {
                            const color = getPredictionColor(getPrediction(dataPoint.d))
                            const pred = getPrediction(dataPoint.d)
                            if (pred)
                                return numeral(value).format('0,000.0[000]') + " " + '<span style="color:' + color + '">' + pred + '</span>'
                            else
                                return numeral(value).format('0,000.0[000]')
                        }
                    },
                },
                x: {
                    formatter: (value) => { return moment(value).format('YYYY-MM-DD') },
                }
            },
            colors: [props.theme.palette.chartPrimary],
            markers: {
                size: 0
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                borderColor: props.theme.palette.grid,
                strokeDashArray: 6,
                position: 'back',
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                },
            },
            title: {
                show: false
            },
            xaxis: {
                type: 'datetime',
                tickAmount: 3,
                tickPlacement: 'on',
                labels: {
                    show: true,
                    style: {
                        colors: props.theme.palette.text.primary,
                        fontSize: '11px',
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                    },
                },
            },
            yaxis: {
                tickAmount: 3,
                //forceNiceScale: true,
                labels: {
                    show: true,
                    formatter: (value) => { return numeral(value).format('0.0[000]') },
                    style: {
                        colors: props.theme.palette.text.primary,
                        fontSize: '11px',
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                    },
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
            },
        };
        setLoading(false)
        setOptions(options)
    }

    return (
        <Card>
            <CardHeader title={<Typography variant='overline'>Position history</Typography>} subheader={<Typography variant='caption' color={"textSecondary"} component={"div"}>{modelName}</Typography>} />
            <CardContent>
                {options &&
                    <Box sx={{ position: "relative", width: "100%" }}>
                        {loading &&
                            <>
                                <Box sx={{ opacity: 0.5, backgroundColor: "#212121", position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 1, }} />
                                <CircularProgress sx={{ position: 'absolute', left: "50%", top: "50%", transform: 'translate(-50%, -50%)', }} />
                            </>
                        }
                        <ReactApexChart options={options} series={options.series} type={options.chart.type} height={350} />
                    </Box>
                }
            </CardContent>
        </Card>
    );
};

export default withTheme(MainChartArea);
