import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";
import numeral from 'numeral';
import moment from 'moment';
import { Button, Collapse, Typography } from '@mui/material';
import { TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Tooltip, Card, CardContent } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box } from '@mui/system';
import axios from 'axios';
import { withTheme } from '@emotion/react';
import SkeletonChart from './SkeletonChart';
import SkeletonList from './SkeletonList';
import { api } from "../api/apiBuilder";
import { useSelector } from 'react-redux';
import ApexCharts from "apexcharts";

function getQuarter(value) {
  let ret;
  var month = value.substring(4, 6);
  var day = value.substring(6, 8);
  var year = value.substring(0, 4);
  var d = new Date(year, month - 1, day);
  let m = d.getMonth();
  if (m < 3) ret = 'Q1' + " " + year;
  else if (m < 6) ret = 'Q2' + " " + year;
  else if (m < 9) ret = 'Q3' + " " + year;
  else if (m < 12) ret = 'Q4' + " " + year;
  else ret = "-"

  return ret
}

class PortfolioQuartersBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      chart_options: 0,
      series_data: [],
      quarters: {},
      quartersTableOpen: false,
      chartHover: -1,
    }
  }

  componentDidUpdate(prevProps) {
    numeral.options.scalePercentBy100 = false;
    if (this.props !== prevProps) {
      if (this.props.portfolio != prevProps.portfolio && this.props.portfolio) {
        this.load()
      }

      if (this.props.selectedTheme !== prevProps.selectedTheme) {
        console.log("tema cambiato!", this.props.selectedTheme);
        ApexCharts.exec("QUARTERS_CHARTID", "updateOptions", {
          xaxis: {
            categories: this.state.chart_options.xaxis.categories,
            labels: {
              show: true,
              rotate: 0,
              style: {
                colors: this.props.theme.palette.text.primary,
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },

          yaxis: [{
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.[00]%') },
              style: {
                colors: [this.props.theme.palette.text.primary],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          }],

          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            itemMargin: {
              vertical: 16
            },
            labels: {
              colors: [this.props.theme.palette.text.primary],
              useSeriesColors: false
            },
            markers: {
              radius: 0,
              width: 4,
              height: 8
            },
          },

          grid: {
            borderColor: this.props.theme.palette.grid,
            strokeDashArray: 0,
            position: 'back',
          },

          tooltip: {
            theme: this.props.selectedTheme,
            shared: true,
            x: { show: true },
            y: {
              formatter: function (val) {
                return numeral(val).format('+0,000.00%');
              }
            }
          }
        })
      }
    }
  }

  componentDidMount() {
    numeral.options.scalePercentBy100 = false;
    if (this.props.portfolio) {
      this.load()
    }
  }

  load() {
    this.setState({ isLoading: true })
    api.get('/v1/builder/portfolios/' + this.props.portfolio + '/quarters/', { cache: false })
      .then(response => {
        const data = response.data;
        var p_values = [];
        var p_cumul = [];
        var b_values = [];
        var b_cumul = [];
        var categories = [];

        var p_val = [];
        var b_val = [];
        for (var i = 0; i < data.length; i++) {
          var record = data[i];
          if (record.type == 'p') {
            p_val.push(record)
          } else {
            b_val.push(record)
          }
        }
        this.setState({
          quarters: {
            p: p_val,
            b: b_val
          }
        })
        let optimalColumnWidthPercent = 20 + (60 / (1 + 30 * Math.exp(-data.length / 3)));
        for (var i = 0; i < data.length; i++) {
          var record = data[i]
          if (record.type == 'p') {
            p_values.push(record.retrn * 100)
            p_cumul.push(record.cumulReturn * 100)
            categories.push([
              getQuarter(record.close)
            ])
          }
          else {
            b_values.push(record.retrn * 100)
            b_cumul.push(record.cumulReturn * 100)
          }
        }
        var series_data = [
          {
            name: 'Portfolio: return',
            type: 'column',
            data: p_values
          }, {
            name: 'Benchmark: return',
            type: 'column',
            data: b_values
          }, {
            name: 'Portfolio: cumulative',
            type: 'line',
            data: p_cumul
          }, {
            name: 'Benchmark: cumulative',
            type: 'line',
            data: b_cumul
          }
        ];

        var options = {
          chart: {
            id: "QUARTERS_CHARTID",
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            },
            height: 'auto',
            type: "line",
            stacked: false,
            animations: {
              enabled: true,
              easing: 'easein',
              speed: 250,
              animateGradually: {
                enabled: true,
                delay: 150
              },
              dynamicAnimation: {
                enabled: true,
                speed: 350
              }
            },
            events: {
              mouseMove: (event, chartContext, config) => {
                this.setState({ chartHover: config.dataPointIndex })
              },
              mouseLeave: (event, chartContext, config) => {
                this.setState({ chartHover: -1 })
              },
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              distributed: false,
              borderRadius: 4,
              columnWidth: optimalColumnWidthPercent + "%"
            },
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            itemMargin: {
              vertical: 16
            },
            labels: {
              colors: [this.props.theme.palette.text.primary],
              useSeriesColors: false
            },
            markers: {
              radius: 0,
              width: 4,
              height: 8
            },
          },
          stroke: {
            show: true,
            width: 3,
            colors: ['transparent', 'transparent', this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary]
          },
          markers: {
            size: 4,
            strokeWidth: 1,
            hover: {
              size: 6
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: [this.props.theme.palette.chartPrimaryLight, this.props.theme.palette.chartSecondaryLight, this.props.theme.palette.chartPrimary, this.props.theme.palette.chartSecondary],
          grid: {
            borderColor: this.props.theme.palette.grid,
            strokeDashArray: 0,
            position: 'back',
          },
          xaxis: {
            categories,
            labels: {
              show: true,
              rotate: 0,
              style: {
                colors: this.props.theme.palette.text.primary,
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
          },

          yaxis: [{
            axisTicks: {
              show: true,
            },
            labels: {
              show: true,
              formatter: (value) => { return numeral(value).format('0.[00]%') },
              style: {
                colors: [this.props.theme.palette.text.primary],
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          }],
          tooltip: {
            theme: this.props.selectedTheme,
            shared: true,
            x: { show: true },
            y: {
              formatter: function (val) {
                return numeral(val).format('+0,000.00%');
              }
            }
          }
        };
        this.setState({ chart_options: options, series_data, isLoading: false });
      }).catch(error => {
        this.setState({ isLoading: false })
        if (error.response) {
          console.log('Error 1', error.message);
          if (error.response.status == 401) {
          }
          this.state.snackMessage = error.response.data;
          this.state.snackOpen = true;
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error 2', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error 3', error.message);
          this.state.snackMessage = error.message;
          this.state.snackOpen = true;
        }
      })
  }

  handleQuartersTable() {
    this.setState({ quartersTableOpen: !this.state.quartersTableOpen })
  }

  render() {
    const { classes } = this.props;
    const { chart_options, quarters, series_data, quartersTableOpen, chartHover, isLoading } = this.state;
    return (
      <Card variant='outlined'>
        <CardContent>
          <Typography variant='overline'>quarters</Typography>
          {
            isLoading ? <SkeletonChart height={350} /> :
              chart_options ?
                <ReactApexChart series={series_data} type={chart_options.chart.type} options={chart_options} height={350} />
                : "vuoto"
          }
          <Collapse in={quartersTableOpen} collapsedSize={36.5}>
            <Box sx={{ textAlign: 'right' }}>
              <Button data-testid='collapse' startIcon={quartersTableOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />} onClick={() => this.handleQuartersTable()}>{quartersTableOpen ? "Close" : "More"} details</Button>
            </Box>
            <Typography variant='overline'>quarters details table</Typography>
            {isLoading ? <SkeletonList rows={3} /> :

              <TableContainer sx={{ mt: 2 }}>
                <Table size={'small'} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={1}>

                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        Return
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        Cumulative Return
                      </TableCell>
                      <TableCell align="center" colSpan={2}>
                        Total
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">Quarter</TableCell>
                      <TableCell align="right"><Tooltip title='Portfolio'><span>Port.</span></Tooltip></TableCell>
                      <TableCell align="right"><Tooltip title='Benchmark'><span>Benc.</span></Tooltip></TableCell>
                      <TableCell align="right"><Tooltip title='Portfolio'><span>Port.</span></Tooltip></TableCell>
                      <TableCell align="right"><Tooltip title='Benchmark'><span>Benc.</span></Tooltip></TableCell>
                      <TableCell align="right"><Tooltip title='Portfolio'><span>Port.</span></Tooltip></TableCell>
                      <TableCell align="right"><Tooltip title='Benchmark'><span>Benc.</span></Tooltip></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      quarters.p && quarters.p.length > 0 ? quarters.p.map((obj, i) => (
                        <TableRow key={i} sx={{ background: chartHover == i ? '#e6e6e6' : 'transparent' }}>
                          <TableCell align="right">
                            {
                              (() => {
                                var dateString = obj.close;
                                var year = dateString.substring(0, 4);
                                var month = dateString.substring(4, 6);
                                var day = dateString.substring(6, 8);
                                var d = new Date(year, month - 1, day);
                                let m = d.getMonth();
                                let title = moment(obj.open).format('DD MMM YYYY') + '/' + moment(obj.close).format('DD MMM YYYY');
                                let ret = 0;
                                if (m < 3) ret = 'Q1' + " " + year;
                                else if (m < 6) ret = 'Q2' + " " + year;
                                else if (m < 9) ret = 'Q3' + " " + year;
                                else if (m < 12) ret = 'Q4' + " " + year;
                                return <Tooltip key={obj.open} title={title}><div>{ret}</div></Tooltip>
                              })()
                            }
                          </TableCell>
                          <TableCell style={{ color: obj.color }} align="right">{numeral(obj.retrn * 100).format('0.00') + '%'}</TableCell>
                          <TableCell align="right">{numeral(quarters.b[i].retrn * 100).format('0.00') + '%'}</TableCell>
                          <TableCell align="right">{numeral(obj.cumulReturn * 100).format('0.00') + '%'}</TableCell>
                          <TableCell align="right">{numeral(quarters.b[i].cumulReturn * 100).format('0.00') + '%'}</TableCell>
                          <TableCell align="right">
                            <Tooltip arrow placement="top-start" title={numeral(obj.total).format('0,000.00')}>
                              <div>{numeral(obj.total).format('0.00a')}</div>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip arrow placement="top-start" title={numeral(obj.total).format('0,000.00')}>
                              <div>{numeral(quarters.b[i].total).format('0.00a')}</div>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )) :
                        <TableRow>
                          <TableCell colSpan={7}>
                            <Typography variant='body1' color='textSecondary'>No data</Typography>
                          </TableCell>
                        </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Collapse>
        </CardContent>
      </Card>
    );
  }
}

function PortfolioQuarters(props) {
  const selectedTheme = useSelector(state => state.theme)

  return <PortfolioQuartersBase selectedTheme={selectedTheme} {...props} />
}

export default withTheme(PortfolioQuarters);