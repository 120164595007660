import { IndeterminateCheckBoxOutlined, LibraryAddCheckOutlined } from '@mui/icons-material';
import { Alert, Avatar, Box, Button, Grid, IconButton, Typography } from '@mui/material';
import log from 'loglevel';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function SettingsMarkets(props) {
  const [selected, setSelected] = useState([]);
  const [cboxes, setCboxes] = useState([
    { v: 350, l: "S&P 500", isChecked: false, isEnabled: true, flag: 'USA', description: "US large-cap stock index" },
    { v: 348, l: "Nasdaq 100", isChecked: false, isEnabled: true, flag: 'USA', description: "US technology stock index" },
    { v: 389, l: "STOXX 600", isChecked: false, isEnabled: true, flag: 'EURO', description: "European stock index" },
    { v: 1, l: "FTSE MIB", isChecked: false, isEnabled: true, flag: 'ITA', description: "Italian stock index" },
    { v: 339, l: "FTSE 100", isChecked: false, isEnabled: true, flag: 'GBR', description: "UK large-cap stock index" },
    { v: 52, l: "Hang Seng", isChecked: false, isEnabled: true, flag: 'HKG', description: "Hong Kong stock index" },
    { v: 174, l: "Nikkei", isChecked: false, isEnabled: true, flag: 'JPN', description: "Japanese stock index" }
  ]);
  const builtPtfSettings = useSelector(state => state.builtPtfSettings);

  useEffect(() => {
    let defaultChains = [];
    let presets = JSON.parse(builtPtfSettings)
    log.debug(presets);
    if (presets && presets.chains) {
      setSelected(presets.chains);
      props.sendSectorsOrMarkets("chains", presets.chains);
      props.ready(props.step, isComplete(presets.chains))
    } else {
      setSelected(defaultChains);
      props.sendSectorsOrMarkets("chains", defaultChains);
      props.ready(props.step, isComplete(defaultChains))
    }
  }, []);

  const handleAllChecked = (value) => {
    let newSelected = [];
    if (value) {
      cboxes.forEach((item) => {
        newSelected.push(item.v);
      });
    }
    setSelected(newSelected);
    sessionStorage.setItem("CHAINS", JSON.stringify(newSelected));
    props.sendSectorsOrMarkets("chains", newSelected);
    props.ready(props.step, isComplete(newSelected))
  };

  const handleChange = (value) => {
    let newSelected = selected.includes(value) ? selected.filter(v => v !== value) : [...selected, value];
    setSelected(newSelected);
    sessionStorage.setItem("CHAINS", JSON.stringify(newSelected));
    props.sendSectorsOrMarkets("chains", newSelected);
    props.ready(props.step, isComplete(newSelected))
  };

  const isComplete = (sett) => {
    let res = 1

    if (!sett || sett.length === 0) res = 0

    return res
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <Typography variant="body2" gutterBottom>
            In this step toward refining the portfolio, the investor navigates the intricate landscape of global markets. With discerning eyes, choices are made regarding which markets to engage with. Whether domestic or international, emerging or established, market selection plays a pivotal role in diversification and exposure, shaping the portfolio's resilience and growth potential.
          </Typography>
        </Box>
        <Box sx={{ mt: 2, display: "flex", flexDirection: "row", alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flex: "15%", alignItems: 'center' }}>
            <Typography variant='body2'>Market selection</Typography>
          </Box>
          <Box sx={{ flex: "85%" }}>
            <IconButton onClick={e => handleAllChecked(true)} sx={{ mr: 1 }}><LibraryAddCheckOutlined color='primary' /></IconButton>
            <IconButton onClick={e => handleAllChecked(false)} sx={{ mr: 1 }}><IndeterminateCheckBoxOutlined color='primary' /></IconButton>
          </Box>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={1}>
            {cboxes.map((item) => {
              const checked = selected && selected.includes(item.v);
              return (
                <Grid item xs={3} key={item.l} sx={{ minHeight: '100px' }}>
                  <Button
                    disableElevation
                    sx={{ p: 1.5, justifyContent: 'start', height: '100%', display: 'flex', flexDirection: 'column', borderWidth: 1, borderStyle: 'solid' }}
                    fullWidth
                    size='large'
                    variant={checked ? 'contained' : 'outlined'}
                    color={checked ? 'primary' : 'default'}
                    onClick={() => {
                      handleChange(item.v);
                    }}
                  >
                    <Box sx={{ textAlign: 'left', textTransform: 'capitalize', flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', }}>
                        <Avatar sx={{ width: 36, height: 36, border: '1px solid white' }} src={`img/${item.flag}.png`} />
                        <Typography variant='body1'>{item.l}</Typography>
                      </Box>
                      <Box>
                        <Typography variant='caption' component={'div'} sx={{ textTransform: 'initial', lineHeight: 'normal', fontWeight: 300 }}>{item.description}</Typography>
                      </Box>
                    </Box>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {props.alert ?
          <Alert severity="error" color='error'>{props.alert}</Alert>
          : ""
        }
      </Box>
    </Box>
  );
}

export default SettingsMarkets;
