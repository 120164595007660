import { withTheme } from '@emotion/react';
import { Add, AddCircle, AddCircleOutline, ArrowDownward, ArrowDropDown, ArrowLeft, ArrowRight, ArrowUpward, CalendarMonthOutlined, CalendarToday, ChevronLeft, DoNotDisturb, ExpandLess, ExpandMore, HideSource, KeyboardArrowDown, KeyboardArrowUp, MoreHoriz, Remove, Today, TodayOutlined } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fade, FormControlLabel, Grid, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, OutlinedInput, Radio, RadioGroup, Slider, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { pink, red } from '@mui/material/colors';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { api } from "../../api/apiBuilder";
import { useDispatch, useSelector } from 'react-redux';
import { notifyReload } from '../../dispatcher/Actions';
import 'dayjs/locale/it';

const markets = [
    { "id": 12, "name": "Euronext Amsterdam", "enabled": false },
    { "id": 13, "name": "Euronext Brussels", "enabled": false },
    { "id": 15, "name": "Copenhagen Exchange", "enabled": false },
    { "id": 9, "name": "Frankfurt Exchange", "enabled": false },
    { "id": 14, "name": "Helsinki Exchange", "enabled": false },
    { "id": 5, "name": "Hong Kong Exchange", "enabled": false },
    { "id": 3, "name": "Euronext Dublin", "enabled": false },
    { "id": 26, "name": "Euronext Lisbon", "enabled": false },
    { "id": 36, "name": "London Exchange", "enabled": false },
    { "id": 29, "name": "Madrid Exchange", "enabled": false },
    { "id": 1, "name": "Borsa Italiana", "enabled": false },
    { "id": 38, "name": "Nyse", "enabled": false },
    { "id": 39, "name": "Nasdaq", "enabled": false },
    { "id": 27, "name": "Oslo Stock Exchange", "enabled": false },
    { "id": 11, "name": "Euronext Paris", "enabled": false },
    { "id": 31, "name": "Stockholm Exchange", "enabled": false },
    { "id": 33, "name": "SIX Swiss Exchange", "enabled": false },
    { "id": 23, "name": "Tokyo Exchange", "enabled": false },
    { "id": 19, "name": "Vienna Exchange", "enabled": false },
    { "id": 102, "name": "Warsaw", "enabled": false },
    { "id": 7, "name": "XETRA Exchange", "enabled": false }]

const sectors = [
    {
        "id": 9, "name": "Basic Materials", "industries": [
            { "id": 1, "name": "Specialty Chemicals" },
            { "id": 2, "name": "Chemicals" },
            { "id": 3, "name": "Building Materials" },
            { "id": 4, "name": "Copper" },
            { "id": 5, "name": "Agricultural Inputs" },
            { "id": 6, "name": "Paper & Paper Products" },
            { "id": 7, "name": "Aluminum" },
            { "id": 8, "name": "Other Industrial Metals & Mining" },
            { "id": 9, "name": "Steel" },
            { "id": 10, "name": "Gold" },
            { "id": 11, "name": "Chemicals - Major Diversified" },
            { "id": 12, "name": "Other Precious Metals & Mining" },
            { "id": 13, "name": "Lumber & Wood Production" },
            { "id": 14, "name": "Silver" },
            { "id": 15, "name": "Coking Coal" },]
    },

    {
        "id": 19, "name": "Communications", "industries": [
            { "id": 16, "name": "Telecom Services" },
            { "id": 17, "name": "Entertainment" },
            { "id": 18, "name": "Publishing" },
            { "id": 19, "name": "Advertising Agencies" },
            { "id": 20, "name": "Electronic Gaming & Multimedia" },
            { "id": 21, "name": "Internet Content & Information" },
            { "id": 22, "name": "Broadcasting" },
            { "id": 23, "name": "Media" },
            { "id": 24, "name": "Staffing & Outsourcing Services" },
            { "id": 25, "name": "Wireless Telecommunication Services" },
            { "id": 26, "name": "Interactive Media & Services" },
            { "id": 27, "name": "Diversified Telecommunication Services" },
            { "id": 28, "name": "Other" },]
    },

    {
        "id": 4, "name": "Consumer Cyclical", "industries": [
            { "id": 29, "name": "Footwear & Accessories" },
            { "id": 30, "name": "Apparel Manufacturing" },
            { "id": 31, "name": "Restaurants" },
            { "id": 32, "name": "Internet Retail" },
            { "id": 33, "name": "Apparel Retail" },
            { "id": 34, "name": "Auto Manufacturers" },
            { "id": 35, "name": "Auto Parts" },
            { "id": 36, "name": "Luxury Goods" },
            { "id": 37, "name": "Textile Manufacturing" },
            { "id": 38, "name": "Furnishings, Fixtures & Appliances" },
            { "id": 39, "name": "Resorts & Casinos" },
            { "id": 40, "name": "Specialty Retail" },
            { "id": 41, "name": "Leisure" },
            { "id": 42, "name": "Packaging & Containers" },
            { "id": 43, "name": "Gambling" },
            { "id": 44, "name": "Lodging" },
            { "id": 45, "name": "Travel Services" },
            { "id": 46, "name": "Grocery Stores" },
            { "id": 47, "name": "Residential Construction" },
            { "id": 48, "name": "Department Stores" },
            { "id": 49, "name": "Home Improvement Retail" },
            { "id": 50, "name": "Auto & Truck Dealerships" },
            { "id": 51, "name": "Personal Services" },
            { "id": 52, "name": "Recreational Vehicles" },
            { "id": 53, "name": "Food - Major Diversified" },
            { "id": 54, "name": "Home Furnishings & Fixtures" },
            { "id": 55, "name": "Personal Products" },
            { "id": 56, "name": "Entertainment - Diversified" },]
    },

    {
        "id": 11, "name": "Energy", "industries": [
            { "id": 57, "name": "Oil & Gas Integrated" },
            { "id": 58, "name": "Oil & Gas E&P" },
            { "id": 59, "name": "Oil & Gas Equipment & Services" },
            { "id": 60, "name": "Oil & Gas Refining & Marketing" },
            { "id": 61, "name": "Thermal Coal" },
            { "id": 62, "name": "Oil & Gas Midstream" },
            { "id": 63, "name": "Uranium" },
            { "id": 64, "name": "Oil & Gas Drilling" },
            { "id": 65, "name": "Oil, Gas & Consumable Fuels" },]
    },

    {
        "id": 7, "name": "Healthcare", "industries": [
            { "id": 66, "name": "Medical Instruments & Supplies" },
            { "id": 67, "name": "Drug Manufacturers-General" },
            { "id": 68, "name": "Diagnostics & Research" },
            { "id": 69, "name": "Medical Devices" },
            { "id": 70, "name": "Medical Care Facilities" },
            { "id": 71, "name": "Drug Manufacturers-Specialty & Generic" },
            { "id": 72, "name": "Pharmaceuticals" },
            { "id": 73, "name": "Biotechnology" },
            { "id": 74, "name": "Health Information Services" },
            { "id": 75, "name": "Pharmaceutical Retailers" },
            { "id": 76, "name": "Healthcare Plans" },
            { "id": 77, "name": "Medical Distribution" },
            { "id": 78, "name": "Health Care Equipment & Supplies" },
            { "id": 79, "name": "Health Care Providers & Services" },]
    },

    {
        "id": 15, "name": "Industrials", "industries": [
            { "id": 80, "name": "Airports & Air Services" },
            { "id": 81, "name": "Waste Management" },
            { "id": 82, "name": "Engineering & Construction" },
            { "id": 83, "name": "Infrastructure Operations" },
            { "id": 84, "name": "Specialty Business Services" },
            { "id": 85, "name": "Specialty Industrial Machinery" },
            { "id": 86, "name": "Farm & Heavy Construction Machinery" },
            { "id": 87, "name": "Marine Shipping" },
            { "id": 88, "name": "Integrated Freight & Logistics" },
            { "id": 89, "name": "Airlines" },
            { "id": 90, "name": "Aerospace & Defense" },
            { "id": 91, "name": "Tools & Accessories" },
            { "id": 92, "name": "Railroads" },
            { "id": 93, "name": "Electrical Equipment & Parts" },
            { "id": 94, "name": "Staffing & Employment Services" },
            { "id": 95, "name": "Business Services" },
            { "id": 96, "name": "Conglomerates" },
            { "id": 97, "name": "Business Equipment & Supplies" },
            { "id": 98, "name": "Security & Protection Services" },
            { "id": 99, "name": "Building Products & Equipment" },
            { "id": 100, "name": "Metal Fabrication" },
            { "id": 101, "name": "Rental & Leasing Services" },
            { "id": 102, "name": "Consulting Services" },
            { "id": 103, "name": "Pollution & Treatment Controls" },
            { "id": 104, "name": "Industrial Distribution" },
            { "id": 105, "name": "Transportation Infrastructure" },
            { "id": 106, "name": "Trucking" },
            { "id": 107, "name": "Diversified Machinery" },
            { "id": 108, "name": "Machinery" },
            { "id": 109, "name": "Industrial Equipment Wholesale" },
            { "id": 110, "name": "General Contractors" },
            { "id": 111, "name": "Building Materials Wholesale" },
            { "id": 112, "name": "Commercial Services & Supplies" },]
    },

    {
        "id": 14, "name": "Real Estate", "industries": [
            { "id": 113, "name": "REIT-Specialty" },
            { "id": 114, "name": "Real Estate-Development" },
            { "id": 115, "name": "REIT-Diversified" },
            { "id": 116, "name": "REIT-Office" },
            { "id": 117, "name": "Real Estate Services" },
            { "id": 118, "name": "REIT-Residential" },
            { "id": 119, "name": "Real Estate-Diversified" },
            { "id": 120, "name": "REIT-Retail" },
            { "id": 121, "name": "Equity Real Estate Investment Trusts (RE" },
            { "id": 122, "name": "REIT-Industrial" },
            { "id": 123, "name": "REIT-Healthcare Facilities" },
            { "id": 124, "name": "REIT-Hotel & Motel" },
            { "id": 125, "name": "Real Estate Management & Development" },
            { "id": 126, "name": "REIT-Mortgage" },]
    },

    {
        "id": 2, "name": "Technology", "industries": [
            { "id": 127, "name": "Semiconductor Equipment & Materials" },
            { "id": 128, "name": "Electronics & Computer Distribution" },
            { "id": 129, "name": "Computer Hardware" },
            { "id": 130, "name": "Software-Infrastructure" },
            { "id": 131, "name": "Solar" },
            { "id": 132, "name": "Software-Application" },
            { "id": 133, "name": "Information Technology Services" },
            { "id": 134, "name": "Electronic Components" },
            { "id": 135, "name": "Semiconductors" },
            { "id": 136, "name": "Communication Equipment" },
            { "id": 137, "name": "Business Software & Services" },
            { "id": 138, "name": "Scientific & Technical Instruments" },
            { "id": 139, "name": "Consumer Electronics" },
            { "id": 140, "name": "Diversified Electronics" },
            { "id": 141, "name": "Semiconductor - Broad Line" },
            { "id": 142, "name": "Internet Software & Services" },
            { "id": 143, "name": "Application Software" },
            { "id": 144, "name": "Diversified Communication Services" },]
    },

    {
        "id": 1, "name": "Utilities", "industries": [
            { "id": 145, "name": "Utilities-Diversified" },
            { "id": 146, "name": "Utilities-Renewable" },
            { "id": 147, "name": "Utilities-Regulated Gas" },
            { "id": 148, "name": "Utilities-Regulated Electric" },
            { "id": 149, "name": "Independent Power and Renewable Electric" },
            { "id": 150, "name": "Utilities-Independent Power Producers" },
            { "id": 151, "name": "Utilities-Regulated Water" },]
    },

    {
        "id": 22, "name": "Financial Services", "industries": [
            { "id": 152, "name": "Insurance-Diversified" },
            { "id": 153, "name": "Capital Markets" },
            { "id": 154, "name": "Asset Management" },
            { "id": 155, "name": "Insurance Brokers" },
            { "id": 156, "name": "Banks-Regional" },
            { "id": 157, "name": "Banks-Diversified" },
            { "id": 158, "name": "Financial Data & Stock Exchanges" },
            { "id": 159, "name": "Credit Services" },
            { "id": 160, "name": "Banks" },
            { "id": 161, "name": "Insurance-Reinsurance" },
            { "id": 162, "name": "Diversified Financial Services" },
            { "id": 163, "name": "Insurance-Property & Casualty" },
            { "id": 164, "name": "Mortgage Finance" },
            { "id": 165, "name": "Financial Conglomerates" },
            { "id": 166, "name": "Insurance-Life" },
            { "id": 167, "name": "Insurance" },
            { "id": 168, "name": "Insurance-Specialty" },
            { "id": 169, "name": "Shell Companies" },
            { "id": 170, "name": "Real Estate Development" },]
    },

    {
        "id": 10, "name": "Consumer Defensive", "industries": [
            { "id": 171, "name": "Beverages-Brewers" },
            { "id": 172, "name": "Household & Personal Products" },
            { "id": 173, "name": "Beverages-Wineries & Distilleries" },
            { "id": 174, "name": "Packaged Foods" },
            { "id": 175, "name": "Food Distribution" },
            { "id": 176, "name": "Farm Products" },
            { "id": 177, "name": "Tobacco" },
            { "id": 178, "name": "Discount Stores" },
            { "id": 179, "name": "Education & Training Services" },
            { "id": 180, "name": "Beverages-Non-Alcoholic" },
            { "id": 181, "name": "Confectioners" }]
    }
]

let timer = null
let writeTimeout;

const CustomViewAdvancedSearch = (props) => {
    const [loading, setLoading] = useState(false)
    const [collapse, setCollapse] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [data, setData] = useState(null)
    const [expanded, setExpanded] = useState(false);
    const [horizons, setHorizons] = useState(["1", "2", "3"]);
    const [directions, setDirections] = useState(["BUY"]);
    const [dates, setDates] = useState(["2016-01-01", dayjs(new Date()).format("YYYY-MM-DD")]);
    const [period, setPeriod] = useState(null);
    const [lockedToday, setLockedToday] = useState(false);
    const [models, setModels] = useState([1, 2]);
    const [strengthMinValue, setStrengthMinValue] = useState(40);
    const [strengthMaxValue, setStrengthMaxValue] = useState(80);
    const strengthLimits = { min: 0, max: 100 }
    const [performanceMinValue, setPerformanceMinValue] = useState(0);
    const [performanceMaxValue, setPerformanceMaxValue] = useState(100);
    const [performanceEnabled, setPerformanceEnabled] = useState(true);
    const performanceLimits = { min: -100, max: 300 }
    const [winrateMinValue, setWinrateMinValue] = useState(0);
    const [winrateMaxValue, setWinrateMaxValue] = useState(100);
    const [winrateEnabled, setWinrateEnabled] = useState(true);
    const winrateLimits = { min: 0, max: 100 }
    const [delisted, setDelisted] = useState(1);
    // Sectors
    const [menuSectorsOpen, setMenuSectorsOpen] = useState(false)
    const [selectedSectors, setSelectedSectors] = useState([]);
    const [statusSectors, setStatusSectors] = useState({});
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [expandedSectors, setExpandedSectors] = useState([]);
    const [showSectors, setShowSectors] = useState(false);
    // Markets
    const [menuMarketsOpen, setMenuMarketsOpen] = useState(false)
    const [selectedMarkets, setSelectedMarkets] = useState([]);
    const [showMarkets, setShowMarkets] = useState(false);

    const customView = useSelector(state => state.customView)
    const dispatch = useDispatch();

    useEffect(() => {
        markets.sort((a, b) => { return a.name.localeCompare(b.name) });
        sectors.sort((a, b) => { return a.name.localeCompare(b.name) });
        fetch();
    }, []);

    /**
     * Handle minimum increments/decrements for:
     * - performance range
     * - winrate range
     * - strength range
     * @param {event object} e used to detect the target element
     */
    const step = (e) => {
        const id = e.currentTarget.id
        let newVal
        switch (id) {
            case 'performance-min-dec':
                newVal = performanceMinValue - 1
                if (!isNaN(newVal)) newVal = Math.max(performanceLimits.min, Math.min(newVal, performanceLimits.max));
                setPerformanceMinValue(newVal)
                validatePerformanceRange(newVal, performanceMaxValue, 0)
                break;
            case 'performance-max-dec':
                newVal = performanceMaxValue - 1
                if (!isNaN(newVal)) newVal = Math.max(performanceLimits.min, Math.min(newVal, performanceLimits.max));
                setPerformanceMaxValue(newVal)
                validatePerformanceRange(performanceMinValue, newVal, 1)
                break;
            case 'performance-min-inc':
                newVal = performanceMinValue + 1
                if (!isNaN(newVal)) newVal = Math.max(performanceLimits.min, Math.min(newVal, performanceLimits.max));
                setPerformanceMinValue(newVal)
                validatePerformanceRange(newVal, performanceMaxValue, 0)
                break;
            case 'performance-max-inc':
                newVal = performanceMaxValue + 1
                if (!isNaN(newVal)) newVal = Math.max(performanceLimits.min, Math.min(newVal, performanceLimits.max));
                setPerformanceMaxValue(newVal)
                validatePerformanceRange(performanceMinValue, newVal, 1)
                break;

            case 'winrate-min-dec':
                newVal = winrateMinValue - 1
                if (!isNaN(newVal)) newVal = Math.max(winrateLimits.min, Math.min(newVal, winrateLimits.max));
                setWinrateMinValue(newVal)
                validateWinrateRange(newVal, winrateMaxValue, 0)
                break;
            case 'winrate-max-dec':
                newVal = winrateMaxValue - 1
                if (!isNaN(newVal)) newVal = Math.max(winrateLimits.min, Math.min(newVal, winrateLimits.max));
                setWinrateMaxValue(newVal)
                validateWinrateRange(winrateMinValue, newVal, 0)
                break;
            case 'winrate-min-inc':
                newVal = winrateMinValue + 1
                if (!isNaN(newVal)) newVal = Math.max(winrateLimits.min, Math.min(newVal, winrateLimits.max));
                setWinrateMinValue(newVal)
                validateWinrateRange(newVal, winrateMaxValue, 1)
                break;
            case 'winrate-max-inc':
                newVal = winrateMaxValue + 1
                if (!isNaN(newVal)) newVal = Math.max(winrateLimits.min, Math.min(newVal, winrateLimits.max));
                setWinrateMaxValue(newVal)
                validateWinrateRange(winrateMinValue, newVal, 1)
                break;

            case 'strength-min-dec':
                newVal = strengthMinValue - 1
                if (!isNaN(newVal)) newVal = Math.max(strengthLimits.min, Math.min(newVal, strengthLimits.max));
                setStrengthMinValue(newVal)
                validateStrengthRange(newVal, strengthMaxValue, 0)
                break;
            case 'strength-max-dec':
                newVal = strengthMaxValue - 1
                if (!isNaN(newVal)) newVal = Math.max(strengthLimits.min, Math.min(newVal, strengthLimits.max));
                setStrengthMaxValue(newVal)
                validateStrengthRange(strengthMinValue, newVal, 1)
                break;
            case 'strength-min-inc':
                newVal = strengthMinValue + 1
                if (!isNaN(newVal)) newVal = Math.max(strengthLimits.min, Math.min(newVal, strengthLimits.max));
                setStrengthMinValue(newVal)
                validateStrengthRange(newVal, strengthMaxValue, 0)
                break;
            case 'strength-max-inc':
                newVal = strengthMaxValue + 1
                if (!isNaN(newVal)) newVal = Math.max(strengthLimits.min, Math.min(newVal, strengthLimits.max));
                setStrengthMaxValue(newVal)
                validateStrengthRange(strengthMinValue, newVal, 1)
                break;

            default:
                break;
        }
    }

    /**
     * Validates and adjusts the range based on the provided minimum and maximum values.
     * If the minimum value is greater than the maximum, it updates the appropriate winrate value
     * (min or max) to ensure a valid range.
     *
     * @param {number} min - The minimum winrate value.
     * @param {number} max - The maximum winrate value.
     * @param {number} index - Indicates whether the change is associated with the minimum (0) or maximum (1) value.
     */
    const validatePerformanceRange = (min, max, index) => {
        if (min > max) {
            index === 0 ? setPerformanceMinValue(max) : setPerformanceMaxValue(min);
        }
    }

    const validatePerformanceLimits = (value) => value >= performanceLimits.min && value <= performanceLimits.max;

    /**
     * Validates and adjusts the range based on the provided minimum and maximum values.
     * If the minimum value is greater than the maximum, it updates the appropriate winrate value
     * (min or max) to ensure a valid range.
     *
     * @param {number} min - The minimum winrate value.
     * @param {number} max - The maximum winrate value.
     * @param {number} index - Indicates whether the change is associated with the minimum (0) or maximum (1) value.
     */
    const validateWinrateRange = (min, max, index) => {
        if (min > max) {
            index === 0 ? setWinrateMinValue(max) : setWinrateMaxValue(min);
        }
    }

    /**
     * Validates and adjusts the range based on the provided minimum and maximum values.
     * If the minimum value is greater than the maximum, it updates the appropriate winrate value
     * (min or max) to ensure a valid range.
     *
     * @param {number} min - The minimum winrate value.
     * @param {number} max - The maximum winrate value.
     * @param {number} index - Indicates whether the change is associated with the minimum (0) or maximum (1) value.
     */
    const validateStrengthRange = (min, max, index) => {
        if (min > max) {
            index === 0 ? setStrengthMinValue(max) : setStrengthMaxValue(min);
        }
    }



    const handlePerformanceRange = () => {
        if (!performanceEnabled) {
            setPerformanceMinValue(0)
            setPerformanceMaxValue(100)
        } else {
            setPerformanceMinValue(performanceLimits.min)
            setPerformanceMaxValue(performanceLimits.max)
        }
        setPerformanceEnabled(!performanceEnabled)
    }

    const handlePerformance = (e) => {
        let value = e.target.value && !isNaN(e.target.value) ? parseInt(e.target.value) : e.target.value;
        const id = e.target.id;
        if (writeTimeout) clearTimeout(writeTimeout)
        if (isNaN(value) && value !== '-') return
        switch (id) {
            case 'performance-min':
                // Ensure min and max are within the limits
                if (!isNaN(value)) value = Math.max(performanceLimits.min, Math.min(value, performanceLimits.max));
                setPerformanceMinValue(value)
                writeTimeout = setTimeout(() => {
                    validatePerformanceRange(value, performanceMaxValue, 0)
                }, 500);
                break;

            case 'performance-max': {
                if (!isNaN(value)) value = Math.max(performanceLimits.min, Math.min(value, performanceLimits.max));
                setPerformanceMaxValue(value)
                writeTimeout = setTimeout(() => {
                    validatePerformanceRange(performanceMinValue, value, 1)
                }, 500);
                break;
            }
            default:
                break;
        }
    };

    const handleWinrateRange = () => {
        setWinrateMinValue(winrateLimits.min)
        setWinrateMaxValue(winrateLimits.max)
        setWinrateEnabled(!winrateEnabled)
    }

    const handleWinrate = (e) => {
        let value = e.target.value && !isNaN(e.target.value) ? parseInt(e.target.value) : e.target.value;
        const id = e.target.id;
        if (writeTimeout) clearTimeout(writeTimeout)

        switch (id) {
            case 'winrate-min':
                if (!isNaN(value)) value = Math.max(winrateLimits.min, Math.min(value, winrateLimits.max));
                setWinrateMinValue(value)
                writeTimeout = setTimeout(() => {
                    validateWinrateRange(value, winrateMaxValue, 0)
                }, 500);
                break;

            case 'winrate-max': {
                if (!isNaN(value)) value = Math.max(winrateLimits.min, Math.min(value, winrateLimits.max));
                setWinrateMaxValue(value)
                writeTimeout = setTimeout(() => {
                    validateWinrateRange(winrateMinValue, value, 1)
                }, 500);
                break;
            }
            default:
                break;
        }
    };

    const closeMenu = () => {
        setMenuOpen(false)
        setMenuSectorsOpen(false)
        setMenuMarketsOpen(false)
    }

    const handleStrengthChange = (e) => {
        let value = e.target.value && !isNaN(e.target.value) ? parseInt(e.target.value) : e.target.value;
        const id = e.target.id;
        if (writeTimeout) clearTimeout(writeTimeout)

        switch (id) {
            case 'strength-min':
                if (!isNaN(value)) value = Math.max(strengthLimits.min, Math.min(value, strengthLimits.max));
                setStrengthMinValue(value)
                writeTimeout = setTimeout(() => {
                    validateStrengthRange(value, strengthMaxValue, 0)
                }, 500);
                break;

            case 'strength-max': {
                if (!isNaN(value)) value = Math.max(strengthLimits.min, Math.min(value, strengthLimits.max));
                setStrengthMaxValue(value)
                writeTimeout = setTimeout(() => {
                    validateStrengthRange(strengthMinValue, value, 1)
                }, 500);
                break;
            }
            default:
                break;
        }
    }

    const handleHorizons = (id) => {
        const currentIndex = horizons.indexOf(id);
        const newSelected = [...horizons];

        if (currentIndex === -1) {
            newSelected.push(id);
        } else {
            newSelected.splice(currentIndex, 1);
        }

        setHorizons(newSelected);
    }

    const handleDirections = (id) => {
        const currentIndex = directions.indexOf(id);
        const newSelected = [...directions];

        if (currentIndex === -1) {
            newSelected.push(id);
        } else {
            newSelected.splice(currentIndex, 1);
        }

        setDirections(newSelected);
    }

    const handleModels = (id) => {
        const currentIndex = models.indexOf(id);
        const newSelected = [...models];

        if (currentIndex === -1) {
            newSelected.push(id);
        } else {
            newSelected.splice(currentIndex, 1);
        }

        setModels(newSelected);
    }

    const handleDates = (name, date) => {
        setPeriod(null)
        if (name === "start") {
            setDates([dayjs(date).format("YYYY-MM-DD"), dates[1]]);
        } else {
            setLockedToday(false)
            setDates([dates[0], dayjs(date).format("YYYY-MM-DD")]);
        }
    }

    const sendUpdate = (keepSettingsOpened) => {
        setShowMarkets(false)
        setShowSectors(false)

        api.removeCaches()
        let newDates = []
        if (!period && lockedToday) {
            newDates = [dates[0], 'TODAY']
        } else if (period && lockedToday) {
            newDates = null
        }

        const json = {
            "strengthRange": [strengthMinValue / 100, strengthMaxValue / 100],
            "horizons": horizons,
            "directions": directions,
            "modelIds": models,
            "dateRange": lockedToday ? newDates : dates,
            period,     // period in months (numeric)
            "marketIds": selectedMarkets,
            "sectorIds": selectedSectors,
            "industryIds": selectedIndustries,
            "performanceRange": [performanceMinValue, performanceMaxValue],
            "winrateRange": [winrateMinValue, winrateMaxValue],
            "isDelisted": delisted
        }

        setLoading(true)
        api.post("/v1/dashboard/userFilter", json).then(response => {
            const data = response.data;
            dispatch(notifyReload(true))
        }).catch(error => {

        }).finally(() => {
            setLoading(false)
            if (!keepSettingsOpened) setCollapse(false)
        })
    }

    const fetch = () => {
        setLoading(true);

        api.get("/v1/dashboard/userFilter", { id: 'userFilter' }).then(response => {
            const data = response.data;

            if (data.period) {
                setDates([data.dateRange[0], 'TODAY'])
                setLockedToday(true)
                setPeriod(data.period)
            } else if (data.isToday) {
                setDates([data.dateRange[0], 'TODAY'])
                setLockedToday(true)
            } else {
                setDates(data.dateRange ? data.dateRange : dates);
                setLockedToday(false)
                setPeriod(null)
            }

            setDirections(data.directions ? data.directions : directions);
            setModels(data.modelIds ? data.modelIds : models);
            setStrengthMinValue(data.strengthRange.length == 2 ? data.strengthRange[0] * 100 : [0])
            setStrengthMaxValue(data.strengthRange.length == 2 ? data.strengthRange[1] * 100 : [100])
            setSelectedMarkets(data.marketIds);
            setSelectedSectors(data.sectorIds);
            setSelectedIndustries(data.industryIds)
            setHorizons(data.horizons);
            setPerformanceMinValue(data.performanceRange.length == 2 ? data.performanceRange[0] : [0])
            setPerformanceMaxValue(data.performanceRange.length == 2 ? data.performanceRange[1] : [100])
            setWinrateMinValue(data.winrateRange.length == 2 ? data.winrateRange[0] : [0])
            setWinrateMaxValue(data.winrateRange.length == 2 ? data.winrateRange[1] : [100])
            setDelisted(data.isDelisted)

            dispatch(notifyReload(true));
        }).catch(error => {
            if (error.response.status == 404) {
                sendUpdate(true)
                setCollapse(true);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    function handleCollapse() {
        setCollapse(!collapse);
    }

    function valuetext(value) {
        return `${value}`;
    }

    const handleMarketsChange = (marketId) => () => {
        const currentIndex = selectedMarkets.indexOf(marketId);
        const newSelectedMarkets = [...selectedMarkets];
        if (currentIndex === -1) {
            // Add the sector and its industries to the selected lists
            newSelectedMarkets.push(marketId);
        } else {
            newSelectedMarkets.splice(currentIndex, 1)
        }
        setSelectedMarkets(newSelectedMarkets)
    }

    const handleSectorToggle = (sectorId, isPartial, justSelectedIndustries) => () => {
        const currentIndex = selectedSectors.indexOf(sectorId);
        const newSelectedSectors = [...selectedSectors];
        const newSelectedIndustries = justSelectedIndustries ? justSelectedIndustries : [...selectedIndustries];

        if (currentIndex === -1) {
            // Add the sector and its industries to the selected lists
            newSelectedSectors.push(sectorId);
            if (!isPartial) {
                let industriesObjs = [...sectors.find((sector) => sector.id === sectorId).industries]
                const industryIds = industriesObjs.map(i => i.id);
                industryIds.forEach(id => {
                    if (!newSelectedIndustries.includes(id)) {
                        newSelectedIndustries.push(id);
                    }
                });

            }
        } else {
            // Remove the sector and its industries from the selected lists
            if (!isPartial || newSelectedIndustries.length == 0) {

                let industriesOfSector = sectors.find((sector) => sector.id === sectorId).industries
                industriesOfSector = industriesOfSector.map(ind => ind.id)

                newSelectedSectors.splice(currentIndex, 1);
                newSelectedIndustries.splice(
                    newSelectedIndustries.findIndex((industry) =>
                        industriesOfSector.includes(industry)
                    ),
                    sectors.find((sector) => sector.id === sectorId).industries.length
                );
            }
        }

        const curStatus = statusSectors
        const checkedIndustries = getSelectedIndustries(sectorId, newSelectedIndustries)
        const totalIndustries = sectors.find((sector) => sector.id === sectorId).industries.length
        const partial = checkedIndustries && checkedIndustries < totalIndustries
        curStatus[sectorId] = partial

        setSelectedSectors(newSelectedSectors);
        setSelectedIndustries(newSelectedIndustries);
    };
    const resetSectors = () => {
        setSelectedSectors([]);
        setSelectedIndustries([]);
    }
    const resetMarkets = () => {
        setSelectedMarkets([])
    }
    const fillSectors = () => {
        setSelectedSectors(sectors.map(item => item.id));
        const industriesIds = sectors.flatMap(sector => sector.industries.map(industry => industry.id));
        setSelectedIndustries(industriesIds);
    }
    const fillMarkets = () => {
        setSelectedMarkets(markets.map(item => item.id));
    }
    const handleExpandSector = (sectorName) => () => {
        const currentIndex = expandedSectors.indexOf(sectorName);
        const newExpandedSectors = [...expandedSectors];

        if (currentIndex === -1) {
            newExpandedSectors.push(sectorName);
        } else {
            newExpandedSectors.splice(currentIndex, 1);
        }

        setExpandedSectors(newExpandedSectors);
    };
    const handleIndustryToggle = (industryId) => () => {
        const { sectorId, sectorName } = getSectorFromIndustry(industryId)
        const currentIndex = selectedIndustries.indexOf(industryId);
        const newSelectedIndustries = [...selectedIndustries];

        if (currentIndex === -1) {
            // Add the industry to the selected list
            newSelectedIndustries.push(industryId);
        } else {
            // Remove the industry from the selected list
            newSelectedIndustries.splice(currentIndex, 1);
        }

        setSelectedIndustries(newSelectedIndustries);

        // Check the parent sector partially or fully based on the selected industries
        const sectorIndustries = sectors.find((sector) => sector.id === sectorId).industries;
        const sectorSelectedIndustries = newSelectedIndustries.filter((industry) => sectorIndustries.includes(industry));
        const sectorCheckbox = document.getElementById(`checkbox-${sectorId}`);
        if (sectorSelectedIndustries.length === 0) {
            sectorCheckbox.indeterminate = false;
            sectorCheckbox.checked = false;
            handleSectorToggle(sectorId, true, newSelectedIndustries)();
        } else if (sectorSelectedIndustries.length === sectorIndustries.length) {
            sectorCheckbox.indeterminate = false;
            sectorCheckbox.checked = true;
            handleSectorToggle(sectorId)();
        } else {
            sectorCheckbox.indeterminate = true;
            handleSectorToggle(sectorId, true, newSelectedIndustries)();
        }
    };

    const getSelectedIndustries = (sectorId, newSelectedIndustries) => {
        let industries = sectors.find((sector) => sector.id === sectorId).industries
        industries = industries.map(ind => ind.id)
        let selected = newSelectedIndustries.filter((selectedIndustry) => industries.includes(selectedIndustry))
        return selected.length
    }

    const getSectorFromIndustry = (industryId) => {
        for (let sector of sectors) {
            for (let industry of sector.industries) {
                if (industry.id === industryId) {
                    return { sectorId: sector.id, sectorName: sector.name };
                }
            }
        }
        return null; // industryId not found
    }

    const handleChips = (value) => {
        setPeriod(value)
        setLockedToday(true)
        setDates([getFromDate(value), getFromDate(0)])
    }

    const getFromDate = (monthsAgo) => {
        const today = new Date();
        // Subtract 6 months
        today.setMonth(today.getMonth() - monthsAgo);

        // Get year, month, and day components
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-indexed
        const day = String(today.getDate()).padStart(2, '0');

        // Formatted date in yyyy-mm-dd format
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const handleLockToday = (e) => {
        setLockedToday(e.target.checked)
        setPeriod(null)
        setDates([dates[0], getFromDate(0)])
    }

    const handleDelisted = (e) => {
        const val = e.target.checked ? 1 : 0
        setDelisted(val)
    }

    const isSectorSelected = (sectorId) => selectedSectors.indexOf(sectorId) !== -1;
    const isIndustrySelected = (industryId) => selectedIndustries.indexOf(industryId) !== -1;
    const isSectorExpanded = (sectorId) => expandedSectors.indexOf(sectorId) !== -1;

    const isMarketSelected = (marketId) => selectedMarkets.indexOf(marketId) !== -1;

    return (
        <>
            <Card sx={{ width: "100%", m: "initial" }}>
                <CardHeader
                    sx={{ display: "flex", alignItems: "center", p: 2 }}
                    title={<Typography variant='overline' className='bold'>Main settings</Typography>}
                    action={
                        <Box sx={{ display: "flex", columnGap: 1 }}>
                            <IconButton onClick={handleCollapse}>{collapse ? <KeyboardArrowUp color="default" /> : <KeyboardArrowDown color="default" />}</IconButton>
                        </Box>
                    }
                />
                <Collapse in={collapse}>
                    <CardContent>
                        <Box sx={{ display: "flex", flexDirection: "row", p: 1, gap: 10, }}>
                            <Box>
                                <Typography sx={{ mb: 0 }} variant='subtitle2' component={'div'}>Model selector</Typography>
                                <Typography sx={{ mb: 2 }} variant='caption' color={"textSecondary"} component={'div'}>Select signal generator models</Typography>
                                <ButtonGroup size='small' sx={{ width: 200 }} disableElevation variant="contained">
                                    <Button sx={{ width: "50%" }} onClick={() => handleModels(1)} variant={models.includes(1) ? 'contained' : 'outlined'}>Model v1</Button>
                                    <Button sx={{ width: "50%" }} onClick={() => handleModels(2)} variant={models.includes(2) ? 'contained' : 'outlined'}>Model v2</Button>
                                </ButtonGroup>
                            </Box>
                            <Box>
                                <Typography sx={{ mb: 0 }} variant='subtitle2' component={'div'}>Strategies</Typography>
                                <Typography sx={{ mb: 2 }} variant='caption' color={"textSecondary"} component={'div'}>Select signal directions (long/short)</Typography>
                                <ButtonGroup size='small' sx={{ width: 200 }} disableElevation variant="contained">
                                    <Button sx={{ width: "50%" }} onClick={() => handleDirections("BUY")} variant={directions.includes("BUY") ? 'contained' : 'outlined'}>Bullish</Button>
                                    <Button sx={{ width: "50%" }} onClick={() => handleDirections("SELL")} variant={directions.includes("SELL") ? 'contained' : 'outlined'}>Bearish</Button>
                                </ButtonGroup>
                            </Box>
                            <Box>
                                <Typography sx={{ mb: 0 }} variant='subtitle2' component={'div'}>Investment Horizons</Typography>
                                <Typography sx={{ mb: 2 }} variant='caption' color={"textSecondary"} component={'div'}>Select signal durations</Typography>
                                <ButtonGroup size='small' sx={{ width: 200 }} disableElevation variant="contained">
                                    <Button sx={{ width: "33.33%" }} onClick={() => handleHorizons("1")} variant={horizons.includes("1") ? 'contained' : 'outlined'}>Short</Button>
                                    <Button sx={{ width: "33.33%" }} onClick={() => handleHorizons("2")} variant={horizons.includes("2") ? 'contained' : 'outlined'}>Medium</Button>
                                    <Button sx={{ width: "33.33%" }} onClick={() => handleHorizons("3")} variant={horizons.includes("3") ? 'contained' : 'outlined'}>Long</Button>
                                </ButtonGroup>
                            </Box>
                            <Box>
                                <Typography sx={{ mb: 0 }} variant='subtitle2' component={'div'}>Show delisted</Typography>
                                <Typography sx={{ mb: 2 }} variant='caption' color={"textSecondary"} component={'div'}>Show/Hide delisted instruments</Typography>
                                <Box sx={{ display: "flex", gap: 1, alignItems: 'center' }}>
                                    <Switch checked={delisted} onChange={handleDelisted} size='small' />
                                    <Typography variant='body2' color={delisted ? 'default' : 'textSecondary'}>
                                        {delisted ? 'Yes' : 'No'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider sx={{ color: props.theme.palette.grid, my: 3, }} />
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <Box sx={{ flex: "35%", display: "flex", p: 1, }}>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                                    <Box>
                                        <Typography sx={{ mb: 0 }} variant='subtitle2' component={'div'}>Signals strength</Typography>
                                        <Typography sx={{ mb: 2 }} variant='caption' color={"textSecondary"} component={'div'}>Handle strength range (0 - 100%)</Typography>
                                        <Box sx={{ display: "flex", columnGap: 1 }}>
                                            <TextField
                                                label="Min %"
                                                value={strengthMinValue}
                                                size='small'
                                                id='strength-min'
                                                onChange={handleStrengthChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><IconButton id='strength-min-dec' onClick={step} edge='start'><ArrowDownward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                                    endAdornment: <InputAdornment position="end"><IconButton id='strength-min-inc' onClick={step} edge='end'><ArrowUpward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                                }}
                                                type="text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <TextField
                                                label="Max %"
                                                value={strengthMaxValue}
                                                size='small'
                                                id='strength-max'
                                                onChange={handleStrengthChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"><IconButton id='strength-max-dec' onClick={step} edge='start'><ArrowDownward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                                    endAdornment: <InputAdornment position="end"><IconButton id='strength-max-inc' onClick={step} edge='end'><ArrowUpward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                                }}
                                                type="text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Fade in={customView != 2} timeout={200}>
                                        <div>
                                            <Typography sx={{ mb: 0 }} variant='subtitle2' component={'div'}>Time range</Typography>
                                            <Typography sx={{ mb: 2 }} variant='caption' color={"textSecondary"} component={'div'}>Define time range</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                <Box sx={{ display: "flex", gap: 1 }}>
                                                    <LocalizationProvider adapterLocale={"it"} dateAdapter={AdapterDayjs}>
                                                        <DesktopDatePicker loc value={dayjs(dates[0])} onChange={(v) => handleDates("start", v)}
                                                            label={'Start date'} minDate={dayjs("2016-01-01")}
                                                            slotProps={{ textField: { size: 'small' } }} sx={{ width: "50%", height: 30 }}
                                                            slots={{
                                                                openPickerIcon: (props) => <CalendarMonthOutlined color='textSecondary' {...props} />,
                                                                leftArrowIcon: (props) => <ArrowLeft color='textSecondary' {...props} />,
                                                                rightArrowIcon: (props) => <ArrowRight color='textSecondary' {...props} />,
                                                                switchViewIcon: (props) => <ArrowDropDown color='textSecondary' {...props} />,
                                                            }}
                                                        />
                                                        {lockedToday ?
                                                            <TextField
                                                                sx={{ flex: '50%' }}
                                                                disabled
                                                                InputProps={{ endAdornment: <InputAdornment position="end"><Today color='textSecondary' /></InputAdornment> }}
                                                                size='small' label='End date' value='Today' /> :
                                                            <DesktopDatePicker value={dayjs(dates[1])} onChange={(v) => handleDates("end", v)} label={'End date'}
                                                                slotProps={{ textField: { size: 'small' } }} sx={{ width: "50%" }}
                                                                slots={{
                                                                    openPickerIcon: (props) => <CalendarMonthOutlined color='textSecondary' {...props} />
                                                                }} />

                                                        }
                                                    </LocalizationProvider>
                                                </Box>
                                                <Box sx={{ display: "flex", gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <Box sx={{ display: "flex", gap: 1, alignItems: 'center' }}>
                                                        {[
                                                            { 'l': "3 months", 'v': 3 },
                                                            { 'l': "6 months", 'v': 6 },
                                                            { 'l': "1 year", 'v': 12 },
                                                        ].map((item) => {
                                                            const checked = period === item.v;
                                                            return (
                                                                <Chip
                                                                    sx={{ minWidth: 80 }}
                                                                    size='small'
                                                                    key={item.l}
                                                                    variant={checked ? 'filled' : 'filled'}
                                                                    color={checked ? 'primary' : 'secondary'}
                                                                    label={item.l}
                                                                    onClick={() => {
                                                                        handleChips(item.v);
                                                                    }}
                                                                >

                                                                </Chip>
                                                            );
                                                        })}
                                                    </Box>
                                                    <FormControlLabel
                                                        control={<Switch checked={lockedToday} onChange={handleLockToday} size='small' />}
                                                        label={<Tooltip arrow title='Lock the end date as today, even for the upcoming days'><Typography variant='body2'>Lock today?</Typography></Tooltip>}
                                                        labelPlacement='end' />
                                                </Box>
                                            </Box>
                                        </div>
                                    </Fade>
                                </Box>
                            </Box>
                            <Divider sx={{ mx: 3, height: 'auto', width: '1px', background: props.theme.palette.grid }} />
                            <Box sx={{ width: "30%", display: "flex", flexDirection: "column", gap: 3, p: 1, }}>
                                <Box>
                                    <Typography sx={{ mb: 0 }} variant='subtitle2' component={'div'}>Markets selector</Typography>
                                    <Typography sx={{ mb: 2 }} variant='caption' color={"textSecondary"} component={'div'}>Select markets to include</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Grid container spacing={1}>
                                            {selectedMarkets && markets ? selectedMarkets.map((obj, i) =>
                                                <Grid item xs={3} sx={{ p: 1, display: (i < 3 || (i >= 3 && showMarkets)) ? 'block' : 'none' }} key={"mkt_" + i}>
                                                    <Tooltip title={markets.find((market) => market.id === obj).name}>
                                                        <Chip
                                                            sx={{ minWidth: 80, justifyContent: 'space-between' }}
                                                            label={markets.find((market) => market.id === obj).name}
                                                            size='small'
                                                            variant='filled'
                                                            color='primary'
                                                            clickable
                                                            onDelete={handleMarketsChange(obj)}
                                                        /></Tooltip></Grid>

                                            ) : "loading..."}
                                            {selectedMarkets.length > 3 &&
                                                <Grid item xs={3} sx={{ p: '4px!important' }}>
                                                    <IconButton size='small' onClick={() => { setShowMarkets(!showMarkets) }}>
                                                        {showMarkets ? <ChevronLeft color='default' /> : <MoreHoriz color='default' />}
                                                    </IconButton>
                                                </Grid>}
                                            {/* {selectedMarkets.length > 0 && <Grid item xs={3} sx={{ p: 0, m: 0 }}><IconButton size='small' onClick={() => { setMenuMarketsOpen(true) }}><AddCircle color="default" /></IconButton></Grid>} */}
                                        </Grid>
                                        <Box>
                                            {selectedMarkets.length == 0 &&
                                                <Button variant='outlined' disableElevation size='small' onClick={() => { setMenuMarketsOpen(true) }}>Add Markets</Button>
                                            }
                                            {selectedMarkets.length > 0 &&
                                                <Button variant='outlined' disableElevation size='small' onClick={() => { setMenuMarketsOpen(true) }}>Handle Markets</Button>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography sx={{ mb: 0 }} variant='subtitle2' component={'div'}>Sectors selector</Typography>
                                    <Typography sx={{ mb: 2 }} variant='caption' color={"textSecondary"} component={'div'}>Select markets to include</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Grid container spacing={1}>
                                            {selectedSectors && sectors ? selectedSectors.map((obj, i) =>
                                                <Grid item xs={3} sx={{ p: 1, display: (i < 3 || (i >= 3 && showSectors)) ? 'block' : 'none' }} key={"mkt_" + i}>
                                                    <Tooltip title={sectors.find((sector) => sector.id === obj).name}>
                                                        <Chip
                                                            sx={{ minWidth: 80, justifyContent: 'space-between' }}
                                                            label={sectors.find((sector) => sector.id === obj).name}
                                                            size='small'
                                                            variant='filled'
                                                            color='primary'
                                                            clickable
                                                            onDelete={handleSectorToggle(obj)}
                                                        /></Tooltip></Grid>

                                            ) : "loading..."}
                                            {selectedSectors.length > 3 &&
                                                <Grid item xs={3} sx={{ p: '4px!important' }}>
                                                    <IconButton size='small' onClick={() => { setShowSectors(!showSectors) }}>
                                                        {showSectors ? <ChevronLeft color='default' /> : <MoreHoriz color='default' />}
                                                    </IconButton>
                                                </Grid>}
                                        </Grid>
                                        <Box>
                                            {selectedSectors.length == 0 &&
                                                <Button variant='outlined' disableElevation size='small' onClick={() => { setMenuSectorsOpen(true) }}>Add Sectors</Button>
                                            }
                                            {selectedSectors.length > 0 &&
                                                <Button variant='outlined' disableElevation size='small' onClick={() => { setMenuSectorsOpen(true) }}>Handle Sectors</Button>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Divider sx={{ mx: 3, height: 'auto', width: '1px', background: props.theme.palette.grid }} />
                            <Box sx={{ flex: "35%", display: "flex", flexDirection: "column", p: 1, gap: 3, }}>
                                <Box>
                                    <Typography sx={{ mb: 0 }} variant='subtitle2' component={'div'}>Performance range</Typography>
                                    <Typography sx={{ mb: 2 }} variant='caption' color={"textSecondary"} component={'div'}>Handle total performance range</Typography>
                                    <Box sx={{ display: "flex", gap: 1, alignItems: 'center' }}>
                                        <Tooltip title='Enable/disable'>
                                            <Checkbox size="small" checked={performanceEnabled} onChange={handlePerformanceRange} />
                                        </Tooltip>
                                        <Typography variant='caption' sx={{ display: !performanceEnabled ? 'block' : 'none' }}>
                                            No range has been defined
                                        </Typography>
                                        <TextField
                                            sx={{ display: performanceEnabled ? 'block' : 'none' }}
                                            disabled={!performanceEnabled}
                                            label="Min %"
                                            value={performanceMinValue}
                                            size='small'
                                            id='performance-min'
                                            onChange={handlePerformance}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><IconButton id='performance-min-dec' onClick={step} disabled={!performanceEnabled} edge='start'><ArrowDownward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                                endAdornment: <InputAdornment position="end"><IconButton id='performance-min-inc' onClick={step} disabled={!performanceEnabled} edge='end'><ArrowUpward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                            }}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <TextField
                                            sx={{ display: performanceEnabled ? 'block' : 'none' }}
                                            disabled={!performanceEnabled}
                                            label="Max %"
                                            value={performanceMaxValue}
                                            size='small'
                                            id='performance-max'
                                            onChange={handlePerformance}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><IconButton id='performance-max-dec' onClick={step} disabled={!performanceEnabled} edge='start'><ArrowDownward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                                endAdornment: <InputAdornment position="end"><IconButton id='performance-max-inc' onClick={step} disabled={!performanceEnabled} edge='end'><ArrowUpward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                            }}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography sx={{ mb: 0 }} variant='subtitle2' component={'div'}>Winrate interval</Typography>
                                    <Typography sx={{ mb: 2 }} variant='caption' color={"textSecondary"} component={'div'}>Handle preferred winrate range (0 - 100%)</Typography>
                                    <Box sx={{ display: "flex", gap: 1, alignItems: 'center' }}>
                                        <Tooltip title='Enable/disable'>
                                            <Checkbox size="small" checked={winrateEnabled} onChange={handleWinrateRange} />
                                        </Tooltip>
                                        <Typography variant='caption' sx={{ display: !winrateEnabled ? 'block' : 'none' }}>
                                            No range has been defined
                                        </Typography>
                                        <TextField
                                            sx={{ display: winrateEnabled ? 'block' : 'none' }}
                                            disabled={!winrateEnabled}
                                            label="Min %"
                                            value={winrateMinValue}
                                            size='small'
                                            id='winrate-min'
                                            onChange={handleWinrate}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><IconButton id='winrate-min-dec' onClick={step} disabled={!winrateEnabled} edge='start'><ArrowDownward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                                endAdornment: <InputAdornment position="end"><IconButton id='winrate-min-inc' onClick={step} disabled={!winrateEnabled} edge='end'><ArrowUpward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                            }}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <TextField
                                            sx={{ display: winrateEnabled ? 'block' : 'none' }}
                                            disabled={!winrateEnabled}
                                            label="Max %"
                                            value={winrateMaxValue}
                                            size='small'
                                            id='winrate-max'
                                            onChange={handleWinrate}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><IconButton id='winrate-max-dec' onClick={step} disabled={!winrateEnabled} edge='start'><ArrowDownward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                                endAdornment: <InputAdornment position="end"><IconButton id='winrate-max-inc' onClick={step} disabled={!winrateEnabled} edge='end'><ArrowUpward color='primary' sx={{ fontSize: 16 }} /></IconButton></InputAdornment>,
                                            }}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button disabled={loading} color={loading ? 'default' : 'cta'} variant='contained' sx={{ ml: "auto" }} disableElevation onClick={sendUpdate}>
                            {loading ? 'Loading...' : 'Confirm'}
                        </Button>
                    </CardActions>
                </Collapse>
            </Card>

            <Dialog fullWidth maxWidth={"sm"} open={menuSectorsOpen} onClose={closeMenu} aria-labelledby="sector-dialog-title">
                <DialogTitle id="sector-dialog-title">Select Sectors and Industries</DialogTitle>
                <DialogContent>
                    <List dense>
                        {sectors.map((sector) => (
                            <React.Fragment key={sector.id}>
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox
                                            color={isSectorSelected(sector.id) ? "primary" : "secondary"}
                                            id={"checkbox-" + sector.id}
                                            indeterminate={statusSectors[sector.id]}
                                            checked={isSectorSelected(sector.id)}
                                            onClick={handleSectorToggle(sector.id)} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography sx={{ textTransform: "uppercase" }} variant='body2'>{sector.name}</Typography>} />
                                    <IconButton edge="end" onClick={handleExpandSector(sector.id)}>
                                        {isSectorExpanded(sector.id) ? <ExpandLess color={"default"} /> : <ExpandMore color="default" />}
                                    </IconButton>
                                </ListItem>
                                <Collapse in={isSectorExpanded(sector.id)} timeout="auto" unmountOnExit>
                                    <List dense sx={{ pl: 4 }}>
                                        {sector.industries.map((industry) => (
                                            <ListItemButton key={industry.id} onClick={handleIndustryToggle(industry.id)}>
                                                <Checkbox checked={isIndustrySelected(industry.id)} color={isSectorSelected(sector.id) ? "primary" : "default"} />
                                                <ListItemText primary={industry.name} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetSectors} color="primary">Reset all</Button>
                    <Button onClick={fillSectors} color="primary">Select all</Button>
                    <Button variant="outlined" onClick={closeMenu} color="primary">Close</Button>
                </DialogActions>
            </Dialog>


            <Dialog maxWidth={"lg"} open={menuMarketsOpen} onClose={closeMenu} scroll="paper">
                <DialogTitle>Exchanges selection</DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        {markets.map((obj, i) =>
                            <Grid item xs={3} key={"ckb_" + i}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isMarketSelected(obj.id)}
                                            name={obj.name}
                                            id={"" + i}
                                            onChange={handleMarketsChange(obj.id)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography variant='body2'>{obj.name}</Typography>}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetMarkets} color="primary">Reset all</Button>
                    <Button onClick={fillMarkets} color="primary">Select all</Button>
                    <Button onClick={closeMenu} variant='contained' disableElevation>Close</Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default withTheme(CustomViewAdvancedSearch);