import { Component } from 'react';
import { Typography } from '@mui/material';
import { withTheme } from '@emotion/react';
import { ArrowRightOutlined, ForkRightOutlined, HelpOutlineOutlined, KeyboardArrowRight } from '@mui/icons-material';

class TooltipHtml extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  openDialog() {

  }

  closeDialog() {
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  render() {
    const { title, body } = this.props;
    const { } = this.state;
    console.log(title, body)

    return (
      <>
        <Typography sx={{ mb: 1.5 }} variant='subtitle2'><b>{title}</b></Typography>
        <Typography variant='caption'>{body}</Typography>
      </>
    );
  }
}

export default withTheme(TooltipHtml);
